import useFlag from './useFlag';
import { FeatureFlag } from 'constants-flags';

const FLAG = FeatureFlag.BillingPaymentsDownloadInvoice;

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-12-23T09:14:30-0800.
 */
export function useBillingAndPaymentDownloadInvoiceEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
