import React, { useState, useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Flex } from 'components/layout';
import styles from './CredentialTemplateSelector.scss';
import { useTranslation } from 'react-i18next';
import type { PropertiesInterface } from 'lane-shared/types/properties/Property';
import type { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import { Button, H5, Icon } from 'design-system-web';
import { convertToUUID } from 'uuid-encoding';
import { makeSaveTemplateFunc } from '../AccessManagementEditor/credentialTemplate';
import { useHIDCredTemplateStrategy } from '../AccessManagementEditor/hooks/useHIDCredTemplateStrategy';
import { HIDTemplatesMap, SelectorProperties, Template } from './types';
import { useSavedCredentialTemplates } from './hooks/useSavedCredentialTemplates';
import { useConnectedCredentialTemplate } from './hooks/useConnectedCredentialTemplates';
import { Selector } from './Selector';
import {
  useChannelsFromHierarchy,
  useRelatedChannels,
} from './hooks/useRelatedChannels';

type Props = {
  properties: PropertiesInterface;
  channel: ChannelIntegrationEditorProps['channel'];
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  metadataLoading?: boolean;
  metadataPopulated?: boolean;
};

export function CredentialTemplateSelector({
  channel,
  settings,
  properties,
  metadataLoading,
  metadataPopulated,
}: Props) {
  const { t } = useTranslation();
  const [selectorError, setSelectorError] = useState<string>('');
  const relatedChannels = useRelatedChannels(channel?._id || '', channel.type);
  const channelsFromHierarchy = useChannelsFromHierarchy(
    channel?.parent?._id || channel?._id
  );
  const channels = useMemo(
    () => [...relatedChannels, ...channelsFromHierarchy],
    [relatedChannels, channelsFromHierarchy]
  );

  const strategy = useHIDCredTemplateStrategy(settings);
  // if the user switches strategy we won't have the neceessary data to populate the selector
  // so we need to disable the inputs until user clicks load settings again
  const initialStrategy = useRef(strategy);
  const disableInputs = initialStrategy.current !== strategy;

  const hidTemplatesMap: HIDTemplatesMap = properties?.credentialTemplateSelector?.validators?.find(
    t => t.name === 'In'
  )?.value;

  // if HIDTemplatesMap changed, it means that user initiated a new fetch
  // we can safely reset the initial strategy
  useEffect(() => {
    if (disableInputs) {
      initialStrategy.current = strategy;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidTemplatesMap]);

  const dropdownTemplates = useMemo(
    () =>
      hidTemplatesMap &&
      Object.entries(hidTemplatesMap).map(([key]) => ({
        label: key,
        value: key,
      })),
    [hidTemplatesMap]
  );

  const [
    selectedCredentialTemplates,
    setSelectedTemplates,
  ] = useSavedCredentialTemplates(strategy, hidTemplatesMap);
  const [
    connectedCredentialTemplateSelector,
    setCredentialTemplateSelector,
  ] = useConnectedCredentialTemplate(strategy, hidTemplatesMap);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSelectorError('');
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [selectorError]);

  useEffect(() => {
    if (
      !('_id' in channel) ||
      !settings ||
      !selectedCredentialTemplates ||
      !connectedCredentialTemplateSelector ||
      !hidTemplatesMap
    ) {
      return;
    }

    const savingFunc = makeSaveTemplateFunc(strategy);

    savingFunc({
      newConnectedCredentialTemplates: connectedCredentialTemplateSelector,
      newCredentialTemplates: selectedCredentialTemplates,
      channelId: convertToUUID(channel?._id),
      hidTemplatesMap,
      settings,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedCredentialTemplateSelector, selectedCredentialTemplates.length]);

  function createNewSelector() {
    if (selectedCredentialTemplates.length === 0) {
      setSelectorError(
        t(
          'web.admin.channel.integrations.access.access-control-settings.template-error'
        )
      );

      return;
    }

    if (connectedCredentialTemplateSelector.length !== 0) {
      const lastSelector =
        connectedCredentialTemplateSelector[
          connectedCredentialTemplateSelector.length - 1
        ];

      // Don't create new selector unless the latest
      // has channel and templates selected
      if (!isSelectorFilled(lastSelector)) {
        if (lastSelector.channel.trim().length === 0) {
          setSelectorError(
            t(
              'web.admin.channel.integrations.access.access-control-settings.channel-error'
            )
          );

          return;
        }

        if (lastSelector.templates.length === 0) {
          setSelectorError(
            t(
              'web.admin.channel.integrations.access.access-control-settings.template-error'
            )
          );

          return;
        }
      }
    }

    const newSelector = {
      id: uuidv4(),
      channel: '',
      templates: [],
    };

    setCredentialTemplateSelector([
      ...connectedCredentialTemplateSelector,
      newSelector,
    ]);
  }

  function removeSelector(id: string) {
    const newSelectors = connectedCredentialTemplateSelector.filter(
      selector => selector.id !== id
    );

    setCredentialTemplateSelector(newSelectors);
  }

  function isSelectorFilled(selector: SelectorProperties) {
    return selector.channel && selector.templates.length > 0;
  }

  function handleChannelChange(id: string, value: string) {
    const newSelectors = [...connectedCredentialTemplateSelector];
    const index = newSelectors.findIndex(selector => selector.id === id);

    newSelectors[index] = {
      ...newSelectors[index],
      channel: value,
    };
    setCredentialTemplateSelector(newSelectors);
  }

  function handleTemplateChange(id: string, value: Template[]) {
    const newSelectors = [...connectedCredentialTemplateSelector];
    const index = newSelectors.findIndex(selector => selector.id === id);

    if (newSelectors[index].channel.trim().length === 0) {
      setSelectorError(
        t(
          'web.admin.channel.integrations.access.access-control-settings.channel-error'
        )
      );

      return;
    }

    newSelectors[index] = {
      ...newSelectors[index],
      templates: value,
    };
    setCredentialTemplateSelector(newSelectors);
  }

  const selectedChannels = connectedCredentialTemplateSelector.map(
    selector => selector.channel
  );

  function isChannelSelected(channelId: string) {
    return selectedChannels.includes(channelId);
  }

  const dropdownChannels = useMemo(() => {
    if (channel?.parent && channel?.parent?.name && channel?.parent?._id) {
      const parentChannel = {
        label: channel.parent.name,
        value: convertToUUID(channel.parent._id),
      };

      const channelsWithParent = channels.map(channel => ({
        label: channel?.name || '',
        value: channel?._id ? convertToUUID(channel?._id) : '',
      }));

      channelsWithParent.push(parentChannel);
      channelsWithParent.sort((first, second) =>
        (first.label as string).localeCompare(second.label)
      );

      return channelsWithParent;
    }

    return channels.map(channel => ({
      label: channel?.name || '',
      value: channel?._id ? convertToUUID(channel?._id) : '',
    }));
  }, [channels, channel?.parent]);

  function filterChannel(channelId: string) {
    return dropdownChannels.filter(
      chan =>
        // keep the current selected channel,
        // but remove any other selected channels
        chan.value === channelId ||
        (!isChannelSelected(chan.value) &&
          chan.value !== convertToUUID(channel._id))
    );
  }

  function disableNewSelectorButton() {
    return connectedCredentialTemplateSelector.length === channels.length - 1;
  }

  return (
    <>
      <H5 mb={6}>
        {t(
          'web.admin.channel.integrations.access.access-control-settings.channel-credential-settings'
        )}
      </H5>
      <Flex direction="column" justify="space-between" gap={6} mb={8}>
        <Selector
          id={uuidv4()}
          dropdownItems={[
            {
              label: channel?.name || '',
              value: channel?._id || '',
            },
          ]}
          dropdownLabel={t(
            'web.admin.channel.integrations.access.access-control-settings.current-channel'
          )}
          dropdownValue={channel?._id || ''}
          dropdownCallback={(id, value) => handleChannelChange(id, value)}
          multiSelectCallback={(_, value) => setSelectedTemplates([...value])}
          multiSelectItems={dropdownTemplates}
          multiSelectLabel={t(
            'web.admin.channel.integrations.access.access-control-settings.select-template'
          )}
          multiSelectValues={selectedCredentialTemplates}
          disableDropdown
          disableMultiSelect={disableInputs || !metadataPopulated}
        />
        {connectedCredentialTemplateSelector.map((selector, index) => (
          <Selector
            id={selector.id}
            key={index}
            dropdownItems={filterChannel(selector.channel)}
            dropdownLabel={t(
              'web.admin.channel.integrations.access.access-control-settings.select-channel'
            )}
            multiSelectItems={dropdownTemplates}
            multiSelectLabel={t(
              'web.admin.channel.integrations.access.access-control-settings.select-template'
            )}
            dropdownValue={selector.channel}
            dropdownCallback={(id, value) => handleChannelChange(id, value)}
            multiSelectCallback={(id, value) => handleTemplateChange(id, value)}
            multiSelectValues={selector.templates}
            removable
            disableDropdown={disableInputs || !metadataPopulated}
            removeCallback={id => removeSelector(id)}
            disableMultiSelect={disableInputs || !metadataPopulated}
            disableRemoveButton={!metadataPopulated}
          />
        ))}
      </Flex>
      <NewSelectorButton
        onClick={() => createNewSelector()}
        disabled={
          metadataLoading || !metadataPopulated || disableNewSelectorButton()
        }
        error={selectorError}
      />
    </>
  );
}

function NewSelectorButton({
  onClick,
  disabled,
  error,
}: {
  onClick: () => void;
  disabled: boolean;
  error: string;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={onClick}
        startIcon={<Icon name="plus" />}
        variant="secondary"
        size="large"
        disabled={disabled}
        testId="addAnotherCredential"
      >
        {t(
          'web.admin.channel.integrations.access.access-control-settings.another-credential'
        )}
      </Button>
      {error.length ? (
        <div>
          <p className={styles.error} data-test="errorMessage">
            {error}
          </p>
        </div>
      ) : null}
    </>
  );
}
