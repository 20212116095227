import { ThemeColorPaletteType } from 'lane-shared/types/Theme';
import { getTextColor } from 'lane-shared/helpers';
import fallbackBackgroundImage from 'static/img/onboarding-background.jpg';
import { fonts, FontType } from 'lane-shared/config/fonts';

type FontOptions = {
  bodyFont?: FontType;
  headerFont?: FontType;
};

export function applyTheme(
  theme: ThemeColorPaletteType,
  { bodyFont = fonts.inter, headerFont = fonts.inter }: FontOptions = {}
): HTMLStyleElement | null {
  const existingStyle: HTMLStyleElement | null =
    document.querySelector(`style#theme`);

  if (!theme.primary) {
    return existingStyle;
  }

  const styleElement = existingStyle ?? document.createElement('style');

  styleElement.id = 'theme';

  // NOTE: Unused but available within palette: Background, "Custom"
  styleElement.innerHTML = `
      :root {
        --theme-font-body:${bodyFont.fontFamily};
        --theme-font-header:${headerFont.fontFamily};
        --theme-primary: ${theme.primary};
        --theme-primary-button-color: ${getTextColor(theme.primary)};
        --theme-secondary: ${theme.secondary};
        --theme-secondary-button-color: ${getTextColor(theme.secondary)};
        --theme-accent-button-color: ${theme.accentColor};
        --theme-background-image: url(${
          theme.backgroundImage || fallbackBackgroundImage
        });

        /* UNSAFE (Testing) */
        --theme-text-color: ${theme.text};
        --theme-tertiary: ${theme.tertiary};
      }
    `;

  if (!existingStyle) {
    const head = document.getElementsByTagName('head')[0];

    head.appendChild(styleElement);
  }

  return styleElement;
}
