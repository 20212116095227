import useFlag from './useFlag';
import { FeatureFlag } from 'constants-flags';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-03-07T14:05:08-0800.
 */
export function useWorkplaceOnboardingEnabled() {
  const isEnabled = useFlag<boolean>(FeatureFlag.WorkplaceOnboarding, false);

  return Boolean(isEnabled);
}
