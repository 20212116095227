import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';
import i18n from 'localization';
import {
  getTableReOrderedColumns,
  getTableVisibleColumns,
} from 'pages/portal/admin/channel/charges-invoices/helpers/exportCSV';
import { Column } from 'design-system-web';

const TOASTER_SECONDS = 5000;
export const buildCSVRows = (rows: any[], columns: any[]) => {
  const csvRows = [];

  const header = columns?.map(({ header }: { header: string }) => header);

  csvRows.push(header);

  rows.forEach(row => {
    const values = columns?.map(({ key, renderCell, renderForCSV }) => {
      if (renderForCSV) {
        return renderForCSV(row[key], row);
      }

      if (renderCell) {
        return renderCell(row[key], row);
      }

      return row[key];
    });

    csvRows.push(values);
  });

  return csvRows;
};

export const exportCSV = (
  rows: any[],
  columns: Column[],
  filename: string,
  tableKey?: string
) => {
  try {
    const visibleColumns = getTableVisibleColumns(columns, tableKey);
    const orderedColumns = getTableReOrderedColumns(visibleColumns, tableKey);

    const csvRows = buildCSVRows(rows, orderedColumns);
    const csv = Papa.unparse(csvRows);

    makeFileDownload({
      name: `${filename}`,
      contents: csv,
      type: 'application/csv',
    });

    return csv;
    // FIXME: Log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    window.Toast.show(
      i18n.t('web.admin.workOrder.table.export.error'),
      TOASTER_SECONDS
    );

    return '';
  }
};
