import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from 'design-system-web/components/Loading/Loading';
import { getChannel } from 'lane-shared/graphql/query';
import React from 'react';
import { useParams } from 'react-router-dom';
import ContentPage from 'components/lane/ContentPage';
import { SiteHeader } from './SiteHeader/SiteHeader';
import styles from './ChannelProfile.scss';
import { PageNotFound } from './PageNotFound/PageNotFound';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import routes from 'lane-shared/config/routes';
import { UserDataContext } from 'lane-shared/contexts';

type ChannelResponse = {
  channel: ChannelType;
};
export const ChannelProfile = () => {
  const { channelSlug }: { channelSlug: string } = useParams();
  const { data, error, loading } = useQuery<ChannelResponse>(getChannel, {
    variables: { slug: channelSlug },
    fetchPolicy: 'cache-and-network',
  });

  const { isAuthenticated: isAuthenticatedViaAuth0 } = useAuth0();

  const { isLoggedIn: isLoggedInViaUserData } = useContext(UserDataContext);

  const { channel } = data ?? {};
  const internalChannelProfileUrl = channel?.profile?.content
    ? routes.content.replace(':id', channel?.profile?.content?._id)
    : routes.home;

  if (loading) {
    return <Loading />;
  }

  if (isLoggedInViaUserData || isAuthenticatedViaAuth0) {
    return <Redirect to={internalChannelProfileUrl} />;
  }

  if (error || !channel || (channel && !channel?.profile?.content)) {
    return <PageNotFound />;
  }

  return (
    <div className={styles.PublicPage}>
      <section className={styles.body}>
        <SiteHeader channelId={channel._id} />
        <section className={styles.content}>
          <ContentPage content={channel!.profile!.content!} />
        </section>
      </section>
    </div>
  );
};
