import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useChannelForAdminQuery, useChannelSidebarLinks } from 'hooks';

import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import { UserDataContext } from 'lane-shared/contexts';
import { getDisplayName, hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ADMIN,
  PERMISSION_ANALYTICS,
  PERMISSION_ANALYTICS_ADVANCED,
  PERMISSION_CONTENT_INTERACTIONS_DELETE,
  PERMISSION_CONTENT_INTERACTIONS_READ,
  PERMISSION_CONTENT_INTERACTIONS_UPDATE,
} from 'lane-shared/helpers/constants/permissions';

import { ChannelInteractiveContents } from 'components/dashboard';
import { AdminPage } from 'components/layout';
import { LookerIframeWidget } from 'components/reports/LookerIframeWidget';
import { H3 } from 'components/typography';

import ChannelAdminContext from '../ChannelAdminContext';

import { LookerReportType } from 'graphql-query-contracts';

import styles from './styles.scss';

type MenuItem = {
  route?: string;
  links?: MenuItem[];
};

export default function ChannelDashboard() {
  const history = useHistory();
  const { user } = useContext(UserDataContext);
  const { channel, timeZone } = useContext(ChannelAdminContext);
  const { t } = useTranslation();

  // Find sidebar links to which the user has access
  const routeMatch = useRouteMatch();
  const { specialPermissions } = useChannelForAdminQuery();
  const { isInvoicingDisabled } = useBillingPaymentSettingsForChannel({
    channelId: channel?._id,
  });
  const sidebarLinks = useChannelSidebarLinks({
    user,
    channel,
    specialPermissions,
    url: routeMatch.url.split('/dashboard')[0],
    channelModules: channel?.channelModules,
    serviceRequestFeatures: {
      createServiceRequest: false,
      updateServiceRequest: false,
    },
    isInvoicingDisabled,
  });

  // It's possible the user got here without having this permission
  // rather than draw graphs they can't see, just show the name
  // of the channel.

  const hasDashboardPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_ADMIN, PERMISSION_ANALYTICS, PERMISSION_ANALYTICS_ADVANCED],
      channel?._id
    );

  if (!hasDashboardPermission) {
    const alternativeRoute = getNextRoute(sidebarLinks);

    if (alternativeRoute) {
      history.push(alternativeRoute);
    }
  }

  function getNextRoute(menu: MenuItem[]): string | undefined {
    for (const item of menu) {
      if (item?.route) {
        return item.route;
      }

      if (item?.links?.length) {
        return item.links[0]?.route;
      }
    }

    return '';
  }

  const hasAnalyticsPermission =
    user?.isSuperUser ||
    hasPermission(user?.roles, [PERMISSION_ANALYTICS], channel?._id);

  const hasInteractionsPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [
        PERMISSION_ADMIN,
        PERMISSION_CONTENT_INTERACTIONS_READ,
        PERMISSION_CONTENT_INTERACTIONS_UPDATE,
        PERMISSION_CONTENT_INTERACTIONS_DELETE,
      ],
      channel?._id
    );

  return (
    <AdminPage className={styles.ChannelDashboard}>
      {hasDashboardPermission && (
        <>
          <div className={styles.header}>
            <H3>{t('web.admin.channel.dashboard.header.title')}</H3>
          </div>
          {hasAnalyticsPermission && (
            <LookerIframeWidget
              title={t('web.admin.channel.dashboard.header.title')}
              channelId={channel?._id}
              channelName={channel?.name}
              reportType={LookerReportType.AdminDashboardAnalytics}
            />
          )}

          {hasInteractionsPermission && (
            <ChannelInteractiveContents
              className={styles.widget}
              channelId={channel?._id}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; channelId: string | undefi... Remove this comment to see the full error message
              timeZone={timeZone}
            />
          )}
        </>
      )}
      {!hasDashboardPermission && <h1>{getDisplayName(channel)}</h1>}
    </AdminPage>
  );
}
