import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { UserDataContext } from 'lane-shared/contexts';
import { ICON_SET_REMIX } from 'lane-shared/helpers/constants/icons';
import { LANE_TEMPLATE_LIBRARY } from 'lane-shared/helpers/constants/library';
import { useFlag } from 'lane-shared/hooks';

import SiteHeader from 'components/misc/SiteHeader';
import { Sidebar, SidebarItem } from 'components/navigation';

import TemplateEdit from '../../../components/lane/TemplateEdit';
import { RecentJobs } from './auto-setup/RecentJobs';
import ChannelManagement from './channel-management';
import Channels from './channels';
import Contracts from './contracts';
import ContractEdit from './contracts/edit';
import ContractNew from './contracts/new';
import Integrations from './integrations';
import IntegrationEdit from './integrations/edit';
import IntegrationNew from './integrations/new';
import NewChannel from './new-channel';
import PortalManagementQueries from './queries';
import LaneTemplates from './templates/LaneTemplates';
import UserManagement from './users';
import UserManagementEdit from './users/edit';
import Whitelabels from './whitelabels';
import WhitelabelEdit from './whitelabels/edit';
import WhitelabelNew from './whitelabels/new';

import { ContentNotifications } from './content-notifications';

import styles from './styles.scss';

function PortalManagement() {
  const { user } = useContext(UserDataContext);
  const isChannelManagementFeatureEnabled = useFlag(
    'channel-management-feature',
    false
  );
  const { t } = useTranslation();
  const sidebarType = 'super';

  if (user && user.isSuperUser) {
    return (
      <>
        <SiteHeader />
        <div className={styles.PortalManagement}>
          <Switch>
            <Route
              exact
              path={routes.portalManagementIntegrations}
              component={Integrations}
            />

            <Route
              exact
              path={routes.portalManagementChannels}
              component={Channels}
            />

            <Route
              exact
              path={routes.portalManagementNewChannel}
              component={NewChannel}
            />

            <Route
              exact
              path={routes.portalManagementVTSTemplates}
              component={LaneTemplates}
            />

            <Route
              exact
              path={routes.portalManagementContentNotifications}
              component={ContentNotifications}
            />

            <Route
              exact
              path={routes.portalManagementVTSTemplatesNew}
              render={() => (
                <TemplateEdit library={LANE_TEMPLATE_LIBRARY} forCreate />
              )}
            />

            <Route
              exact
              path={routes.portalManagementVTSTemplatesEdit}
              render={() => (
                <TemplateEdit library={LANE_TEMPLATE_LIBRARY} forEdit />
              )}
            />

            <Route
              exact
              path={routes.portalManagementIntegrationsNew}
              render={({ match, history }) => (
                <IntegrationNew forCreate match={match} history={history} />
              )}
            />

            <Route
              exact
              path={routes.portalManagementIntegrationsEdit}
              component={IntegrationEdit}
            />

            <Route
              exact
              path={routes.portalManagementWhitelabels}
              component={Whitelabels}
            />

            <Route
              exact
              path={routes.portalManagementWhitelabelsNew}
              component={WhitelabelNew}
            />

            <Route
              exact
              path={routes.portalManagementWhitelabelsEdit}
              component={WhitelabelEdit}
            />

            <Route
              exact
              path={routes.portalManagementUsers}
              component={UserManagement}
            />

            <Route
              exact
              path={routes.portalManagementUserEdit}
              component={UserManagementEdit}
            />

            <Route
              exact
              path={routes.portalManagementQueries}
              component={PortalManagementQueries}
            />
            {isChannelManagementFeatureEnabled && (
              <Route
                exact
                path={routes.portalManagementChannelManagement}
                component={ChannelManagement}
              />
            )}

            <Route
              exact
              path={routes.portalManagementContracts}
              component={Contracts}
            />

            <Route
              exact
              path={routes.portalManagementContractNew}
              component={ContractNew}
            />

            <Route
              exact
              path={routes.portalManagementContractEdit}
              component={ContractEdit}
            />
            <Route
              exact
              path={routes.portalManagementRecentJobs}
              component={RecentJobs}
            />

            <Route
              exact
              path={routes.portalManagement}
              render={() => (
                <h1>{t('web.admin.portalManagement.page.title')}</h1>
              )}
            />
          </Switch>
        </div>

        <Sidebar type={sidebarType}>
          <SidebarItem
            isCategory={false}
            route={routes.portalManagementChannels}
            icon="search"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.searchChannels.title'
            )}
            exact
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.searchChannels.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementContracts}
            icon="dollar-sign"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.contracts.title'
            )}
            exact
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.contracts.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementUsers}
            icon="users"
            headerMessage={t('web.admin.portalManagement.sideBar.users.title')}
            exact
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.users.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementNewChannel}
            icon="plus-circle"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.newOrganization.title'
            )}
            exact
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.newOrganization.title')}
          </SidebarItem>

          {isChannelManagementFeatureEnabled && (
            <SidebarItem
              isCategory={false}
              route={routes.portalManagementChannelManagement}
              icon="folder user line"
              headerMessage={t(
                'web.admin.portalManagement.sideBar.channelManagement.title'
              )}
              type={sidebarType}
              iconSet={ICON_SET_REMIX}
            >
              {t('web.admin.portalManagement.sideBar.channelManagement.title')}
            </SidebarItem>
          )}

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementIntegrations}
            icon="bolt"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.integrations.headerMessage'
            )}
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.integrations.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementWhitelabels}
            icon="edit"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.whiteLables.headerMessage'
            )}
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.whiteLables.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementVTSTemplates}
            icon="edit"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.vtsTemplates.headerMessage'
            )}
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.vtsTemplates.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementContentNotifications}
            icon="bell"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.contentNotifications.headerMessage'
            )}
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.contentNotifications.title')}
          </SidebarItem>

          <SidebarItem
            isCategory={false}
            route={routes.portalManagementQueries}
            icon="print"
            headerMessage={t(
              'web.admin.portalManagement.sideBar.queries.headerMessage'
            )}
            type={sidebarType}
          >
            {t('web.admin.portalManagement.sideBar.queries.title')}
          </SidebarItem>
        </Sidebar>
      </>
    );
  }

  if (user && !user.isSuperUser) {
    return (
      <>
        <SiteHeader />
        <div className={styles.loginWrapper}>
          <div className={styles.login}>
            <h1>{t('web.admin.portalManagement.access.error')}</h1>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SiteHeader />
      <div className={styles.loginWrapper}>
        <div className={styles.login}>
          <h1>{t('web.admin.portalManagement.login.error')}</h1>
        </div>
      </div>
    </>
  );
}

export default PortalManagement;
