import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import * as WHITELABELS from 'lane-shared/config/whitelabels';
import { WHITELABEL_LANE } from 'lane-shared/config/whitelabels';
import {
  ChannelsContext,
  AppContext,
  ThemeContext,
} from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import Thumbnail from 'components/general/Thumbnail';

import styles from './PrivacyAndTermsAndConditions.scss';

export default function PrivacyAndTermsAndConditions() {
  const { primaryChannel, secondaryChannel } = useContext(ChannelsContext);
  const { whitelabel } = useContext(AppContext);
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);

  const isWhiteLabelApp = theme.instance !== WHITELABEL_LANE;

  const locationChannel =
    primaryChannel?.type === ChannelTypeEnum.Property
      ? primaryChannel
      : secondaryChannel;

  const buildingName = getDisplayName(locationChannel);
  const buildingIcon = imageUrl(
    locationChannel?.profile.logo || locationChannel?.profile.image
  );

  const vtsActivate: any = WHITELABELS.cache.find(
    whitelabel => whitelabel.commonName === 'com.lane.lane'
  );

  function WhiteLabelView() {
    return (
      <div>
        <hr />
        <div className={styles.PrivacyTermsAndConditions}>
          <div className={styles.imageContainer}>
            <Thumbnail
              className={styles.imageWrapper}
              src={buildingIcon}
              name={buildingName}
            />
            <p className={styles.whiteLabelName}>{buildingName}</p>
          </div>
          <div className={styles.linksContainer}>
            <a
              href={whitelabel.privacyPolicyUrls[1]}
              target="blank"
              className={styles.homeLink}
            >
              <u className={styles.link}>{t('Privacy Policy')}</u>
            </a>
            <a
              href={whitelabel.termsAndConditionsUrls[1]}
              target="blank"
              className={styles.homeLink}
            >
              <u className={styles.link}>{t('Terms and Conditions')}</u>
            </a>
          </div>
        </div>
      </div>
    );
  }

  function checkIfPrivacyUrlsExist() {
    let response;

    if (
      whitelabel.privacyPolicyUrls[1] &&
      whitelabel.termsAndConditionsUrls[1]
    ) {
      response = true;
    }

    return response;
  }

  return (
    <div className={styles.PrivacyTermsAndConditions}>
      <div className={styles.card}>
        <div>
          <p className={styles.header}>VTS Activate</p>
          <div className={styles.linksContainer}>
            <a
              href={vtsActivate.privacyPolicyUrls[0]}
              target="blank"
              className={styles.homeLink}
            >
              <u className={styles.link}>{t('Privacy Policy')}</u>
            </a>
            <a
              href={vtsActivate.termsAndConditionsUrls[0]}
              target="blank"
              className={styles.homeLink}
            >
              <u className={styles.link}>{t('Terms and Conditions')}</u>
            </a>
          </div>
        </div>
        {isWhiteLabelApp && checkIfPrivacyUrlsExist() ? (
          <WhiteLabelView />
        ) : null}
      </div>
    </div>
  );
}
