import React, { useContext, useEffect, useState } from 'react';

import { Button, Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { getMailchimpAudienceMetadata } from 'lane-shared/helpers/integrations/Mailchimp/getProviderMetadata';
import useChannelGroupRolesQuery from 'lane-shared/hooks/useChannelGroupRolesQuery';

import { H4 } from 'components/typography';

import { ValidationErrorContext } from 'lane-shared/contexts';
import { convertToUUID } from 'uuid-encoding';

import { ChannelIntegrationEditorProps } from './ChannelIntegrationEditorProps';
import styles from './MailChimp.scss';

interface Audience {
  Id: string;
  Name: string;
}

const MAILCHIMP_3_0 = 'MAILCHIMP_3_0';

export default function MailChimp({
  channelIntegration,
  onUpdateChannelIntegration,
  channel,
}: ChannelIntegrationEditorProps) {
  const { t } = useTranslation();
  const [isLoadingAudienceList, setLoadingAudienceList] = useState(false);
  const [audienceList, setAudienceList] = useState<Audience[]>([]);
  const [selectedAudience, setSelectedAudience] = useState<Audience | null>(
    null
  );
  const [selectedGroupRole, setSelectedGroupRole] = useState<string | null>(
    null
  );
  const { groupRoles } = useChannelGroupRolesQuery({
    channelId: channel._id,
    includeWorkplaceMember: true,
  });

  const errors = useContext(ValidationErrorContext);

  async function setupAudienceListField() {
    try {
      if (
        channelIntegration?.settings?.apiKey &&
        channelIntegration?.settings?.serverDomain
      ) {
        setLoadingAudienceList(true);
        const data = await getMailchimpAudienceMetadata(
          MAILCHIMP_3_0,
          convertToUUID(channel._id),
          channelIntegration.settings
        );

        setLoadingAudienceList(false);

        if (!data) {
          setLoadingAudienceList(false);

          return;
        }

        setAudienceList(data.Lists);
      }
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: any) {
      window.Toast.show(
        t('web.admin.channel.integrations.mailchimp.fetch.config.error'),
        5000
      );
      setLoadingAudienceList(false);
    }
  }

  function findAndSetSelectedAudience(audienceId: string) {
    const audience = audienceList.find(item => (item as any).Id === audienceId);

    if (audience) {
      setSelectedAudience(audience);

      return audience;
    }

    return null;
  }

  function updateSelectedAudience(value: any) {
    const audience = findAndSetSelectedAudience(value);

    if (audience) {
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          audienceListId: (audience as any).Id,
        },
      });
    }
  }

  function updateSelectedGroupRole(groupId: string) {
    if (groupId) {
      setSelectedGroupRole(groupId);
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          groupId,
        },
      });
    }
  }

  useEffect(() => {
    async function fetchData() {
      await setupAudienceListField();
    }

    fetchData();
  }, []);

  useEffect(() => {
    const settings = channelIntegration?.settings;

    if (settings?.audienceListId) {
      findAndSetSelectedAudience(settings.audienceListId);
    }

    if (settings?.groupId) {
      setSelectedGroupRole(settings?.groupId);
    }
  }, [audienceList, selectedGroupRole]);

  return (
    <div>
      <H4 mb={5}>{t('web.admin.channel.integrations.mailchimp.title')}</H4>
      <Dropdown
        className={styles.editorInput}
        label={t`web.admin.channel.integrations.mailchimp.grouprole`}
        fixedLabel
        onValueChange={value => {
          updateSelectedGroupRole(value);
        }}
        value={selectedGroupRole || null}
        items={groupRoles.map(groupRole => ({
          label: groupRole.name,
          value: groupRole._id,
        }))}
      />
      <Input
        className={styles.editorInput}
        label={t`web.admin.channel.integrations.mailchimp.apikey`}
        value={channelIntegration.settings.apiKey}
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        error={errors?.inner?.find(error => error.path === 'apiKey')?.errors}
        onChange={value =>
          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              apiKey: value,
            },
          })
        }
      />
      <Input
        className={styles.editorInput}
        label={t`web.admin.channel.integrations.mailchimp.serverdomain`}
        value={channelIntegration.settings.serverDomain}
        error={
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          errors?.inner?.find(error => error.path === 'serverDomain')?.errors
        }
        onChange={value =>
          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              serverDomain: value,
            },
          })
        }
      />
      <div className={`${styles.buttonContainer} ${styles.editorInput}`}>
        <Button
          loading={isLoadingAudienceList}
          onClick={() => setupAudienceListField()}
          testId="buttonFetchAudienceList"
        >
          {t`web.admin.channel.integrations.mailchimp.fetchaudience`}
        </Button>
      </div>

      <Dropdown
        label={t`web.admin.channel.integrations.mailchimp.audience`}
        fixedLabel
        onValueChange={value => {
          updateSelectedAudience(value);
        }}
        value={selectedAudience?.Id}
        items={audienceList.map(audience => ({
          label: audience.Name,
          value: audience.Id,
        }))}
      />
    </div>
  );
}
