import React, { useMemo } from 'react';

import { Loading, Button, ControlMenu } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { getChannelIntegrations } from 'lane-shared/graphql/query';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';
import { getIntegrationDisplayName } from 'lane-shared/helpers/integrations/getIntegrationDefinition';
import { byName } from 'lane-shared/helpers/sort';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import { AdminPage } from 'components/layout';
import { H5 } from 'components/typography';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

export default function ChannelIntegrations() {
  const { channel } = useChannelAdminContext();
  const { t } = useTranslation();

  const deleteIntegrationFeatureEnabled = useFlag(
    FeatureFlag.DeleteChannelIntegrationAccess,
    true
  );

  const isYardiFlagEnabled = useFlag(FeatureFlag.YardiConfigSetting, false);
  const isYardiCommercialFlagEnabled = useFlag(
    FeatureFlag.YardiCommercialConfigSetting,
    false
  );
  const isEntrataFlagEnabled = useFlag(FeatureFlag.EntrataConfigSetting, false);
  const isRealPageFlagEnabled = useFlag(
    FeatureFlag.RealPageConfigSetting,
    false
  );

  const { data, loading, called } = useQuery(getChannelIntegrations, {
    skip: !channel?._id,
    variables: { id: channel?._id },
    fetchPolicy: 'network-only',
  });

  const integrations = useMemo(
    () =>
      cloneDeep(data?.channel?.integrations || []).sort((a: any, b: any) =>
        byName(a.integration, b.integration)
      ),
    [data?.channel?.integrations]
  );

  const hasNoChannelIntegrations =
    called && !loading && integrations.length === 0;

  const filterIntegrations = (integrations: []) => {
    return integrations.filter((integration: any) => {
      if (
        !isYardiFlagEnabled &&
        integration.integration.name === IntegrationProviderEnum.Yardi
      ) {
        return false;
      }

      if (
        !isYardiCommercialFlagEnabled &&
        integration.integration.name === IntegrationProviderEnum.YardiCommercial
      ) {
        return false;
      }

      if (
        !isRealPageFlagEnabled &&
        integration.integration.name === IntegrationProviderEnum.RealPage
      ) {
        return false;
      }

      if (
        !isEntrataFlagEnabled &&
        integration.integration.name === IntegrationProviderEnum.Entrata
      ) {
        return false;
      }

      return true;
    });
  };

  return (
    <AdminPage>
      <ControlMenu>
        <hr />
        <Link to="integrations/new">
          <Button testId="addIntegration" variant="contained">
            {t`web.admin.channel.integrations.add`}
          </Button>
        </Link>
      </ControlMenu>

      {loading && <Loading />}

      {hasNoChannelIntegrations && (
        <H5>{t('web.admin.channel.integrations.empty')}</H5>
      )}

      {integrations.length > 0 && (
        <table data-test="integrationsTable">
          <thead>
            <tr>
              <th>{t`web.admin.channel.integrations.table.name`}</th>
              <th>{t`web.admin.channel.integrations.table.type`}</th>
              <th>{t`web.admin.channel.integrations.table.created`}</th>
              <th>{t`web.admin.channel.integrations.table.updated`}</th>
              {deleteIntegrationFeatureEnabled && (
                <th>{t`web.admin.channel.integrations.table.deleted`}</th>
              )}
            </tr>
          </thead>

          <tbody>
            {filterIntegrations(integrations).map((integration: any) => (
              <tr key={integration._id}>
                <td>
                  <Link to={`integrations/${integration._id}/edit`}>
                    {getIntegrationDisplayName(integration)}
                  </Link>
                </td>
                <td>{integration.integration.type}</td>
                <td>{simpleDate(integration._created)}</td>
                <td>{fromNow(integration._updated)}</td>
                {deleteIntegrationFeatureEnabled && (
                  <td>{simpleDate(integration.deletedAt)}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </AdminPage>
  );
}
