import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import { InvoiceDetailsResponse } from '../types';
import { RequestError } from '../errorClasses';
import { getInvoiceDetailsQuery } from 'graphql-queries';
import { GroupType } from 'graphql-query-contracts';

type Props = {
  groupId?: string;
  invoiceDisplayId?: string;
  invoiceId?: string;
};

export function useInvoiceDetails({
  groupId,
  invoiceDisplayId,
  invoiceId,
}: Props) {
  const { loading, error, data } = useQuery<InvoiceDetailsResponse>(
    getInvoiceDetailsQuery,
    {
      variables: {
        getInvoiceDetailsRequest: {
          invoiceDisplayId,
          invoiceId,
          groupId,
          groupType: GroupType.GroupTypeActivateChannel,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  if (error) {
    console.error(
      'BPError - useInvoiceDetails error:',
      error,
      invoiceDisplayId,
      invoiceId,
      groupId
    );
    Sentry.captureException(error);
  }

  return {
    loading,
    error: error ? new RequestError() : null,
    invoiceDetails: data?.accounts?.getInvoiceDetails,
  };
}
