import React from 'react';

import { Checkbox } from 'components';
import { useTranslation } from 'react-i18next';

import { ModifierType } from 'pages/portal/admin/channel/products-services/products/RateModifierInput';
import styles from './CreateProductForm.scss';

export function RateModifierCheckbox({
  selected,
  toggleRateModifier,
  type,
}: {
  selected: boolean;
  toggleRateModifier: (value: ModifierType) => void;
  type: ModifierType;
}) {
  const { t } = useTranslation();

  return (
    <Checkbox
      className={styles.checkbox}
      testId={`${type}OptionCheckbox`}
      value={type}
      text={t(`abp.productsServices.createProduct.checkbox.apply${type}`)}
      name={type}
      selected={selected}
      onChange={(value: ModifierType) => toggleRateModifier(value)}
    />
  );
}
