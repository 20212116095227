import { useTranslation } from 'react-i18next';
import { RecurringCharge } from 'graphql-query-contracts';
import { useMutation } from '@apollo/client';
import { ExternalActorType } from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'uuid-encoding';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useUserData } from 'lane-shared/hooks';
import { cancelRecurringChargeMutation } from '../../recurringChargeQueries';

export function useCancelSubscription({
  subscription,
  refetch,
}: {
  subscription: RecurringCharge;
  refetch: Function;
}) {
  const { t } = useTranslation();
  const { user } = useUserData();
  const [cancelRecurringCharge] = useMutation(cancelRecurringChargeMutation);

  const { nextOccurrence, recurrenceId } = subscription.recurrence;
  const { name } = subscription.charge;

  async function cancelSubscription() {
    try {
      await window.Alert.confirm(
        confirmSubscriptionCancellationMessage(t, name, nextOccurrence)
      );

      // User confirmed
      await cancelRecurringCharge({
        variables: {
          cancelRecurringChargeRequest: {
            recurrenceId,
            externalActorId: convertToUUID(user?._id) || '',
            externalActorType:
              ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
          },
        },
      });

      window.Toast.show(
        t(
          'abp.subscription.cancelSubscription.subscriptionCancelledSuccessfully'
        )
      );

      refetch();
    } catch (err) {
      if (err === false) {
        // user cancelled
      } else {
        window.Toast.show(err.message, 5000);
      }
    } finally {
      window.Alert.hide();
    }
  }

  return { cancelSubscription };
}

function confirmSubscriptionCancellationMessage(
  t: any,
  chargeName: string,
  nextOccurrence: Date
) {
  return {
    title: t('abp.subscription.cancelSubscription.user.title', {
      chargeName,
    }),
    message: t('abp.subscription.cancelSubscription.user.message', {
      chargeName,
      nextOccurrence: dateFormatter(nextOccurrence, dates.SIMPLE_DATE),
    }),
    confirmText: t('abp.actions.cancelSubscription'),
    cancelText: t('abp.subscription.cancelSubscription.goBack'),
  };
}
