import React from 'react';

import { TabStrip, ErrorMessage, CreatedBy, Flex } from 'components';
import { ButtonLink } from 'components/general/ButtonLink';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { SORT_CREATED } from 'lane-shared/helpers/interactions/types';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { ContentUCIs } from 'components/interactions';
import ContentTargetingDetails from 'components/lane/ContentTargetingDetails';
import { AdminPage } from 'components/layout';
import { Alert, BreadCrumbs } from 'components/lds';
import { LookerIframeWidget } from 'components/reports/LookerIframeWidget';
import { H3 } from 'components/typography';

import { LookerReportType } from 'graphql-query-contracts';
import styles from '../post/AdminViewContent.scss';
import draftViewStyles from './DraftView.scss';
import { WaitlistTableView } from 'domains/reservableManagement/components/WaitlistTableView';
import { useReservableWaitlist } from 'lane-shared/hooks/useReservableWaitlist';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { InfoTab } from '../post/InfoTab';
import {
  breadCrumbsLabel,
  breadCrumbsUrlForContentTypeMap,
} from '../post/helpers/getBreadCrumbs';
import { useQuery } from '@apollo/client';
import { getDraftContent } from 'lane-shared/graphql/query';
import { Chip, ChipStyle } from 'components/ads';

export const DraftView = ({ channel }: any) => {
  const { draftId } = useParams<{ draftId: string }>();

  const location = useLocation();
  const { t } = useTranslation();

  const {
    data: draftContentResponse,
    loading,
    error,
  } = useQuery(getDraftContent, {
    variables: {
      id: draftId,
    },
  });

  const content = draftContentResponse?.draftContent;

  const { reservableFeature } = explodeFeatures(content?.features || []);

  const contentName = content?.name;

  const timeZone = content?.state?.timezone
    ? content.state.timezone
    : getTimeZoneByGeoLocation({
        latitude: content?.geo[1],
        longitude: content?.geo[0],
      });

  type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
  type TabItem = ArrayElement<React.ComponentProps<typeof TabStrip>['tabs']>;

  const TabsMap = Object.freeze({
    info: {
      label: 'web.admin.channel.contentCenter.tab.info',
      value: 'info' as const,
      Component: (
        <>
          {content?.isInteractive ? (
            <>
              <Flex justify="center" style={{ width: '100%' }}>
                <div className={draftViewStyles.alertWrapper}>
                  <Alert type="info" fullWidth>
                    {t(
                      'web.admin.channel.contentCenter.tab.info.alert.contentNotInteractable'
                    )}
                  </Alert>
                </div>
              </Flex>
              <div className={draftViewStyles.transparentClickBlocker} />
            </>
          ) : null}

          <InfoTab content={content} channel={channel} />
        </>
      ),
    },
    table: {
      label: 'web.admin.channel.contentCenter.tab.table',
      value: 'table' as const,
      Component: (
        <ContentUCIs
          content={content}
          interactionSortKey={SORT_CREATED}
          timeZone={timeZone}
        />
      ),
    },
    waitlist: {
      label: 'web.admin.channel.contentCenter.tab.waitlist',
      value: 'waitlist' as const,
      Component: (
        <WaitlistTableView
          channelId={channel?._id}
          contentId={draftId}
          timeZone={timeZone}
        />
      ),
    },
    targeting: {
      label: 'web.admin.channel.contentCenter.tab.targeting',
      value: 'targeting' as const,
      Component: (
        <ContentTargetingDetails
          content={content}
          channel={channel}
          timeZone={timeZone}
        />
      ),
    },
    analytics: {
      label: 'web.admin.channel.contentCenter.tab.analytics',
      value: 'analytics' as const,
      Component: contentName && (
        <LookerIframeWidget
          title="Looker Post Analytics"
          reportType={LookerReportType.PostAnalytics}
          channelName={channel?.name}
          channelId={channel?._id}
          contentName={contentName}
        />
      ),
    },
  });

  const [query, goToUrl] = useQueryString({
    tab: TabsMap.info.value,
  });

  const isReservableWaitlistEnabled = useReservableWaitlist();

  type Tab = (typeof TabsMap)[keyof typeof TabsMap]['value'];

  const selectedTab: Tab =
    typeof query.tab === 'string'
      ? (query.tab.toLowerCase() as Tab)
      : TabsMap.info.value;

  const availableTabs: TabItem[] = [
    TabsMap.info,
    TabsMap.targeting,
    TabsMap.analytics,
  ];

  if (content?.isInteractive) {
    if (isReservableWaitlistEnabled && reservableFeature?.useWaitlist) {
      availableTabs.splice(1, 0, TabsMap.table, TabsMap.waitlist);
    } else {
      availableTabs.splice(1, 0, TabsMap.table);
    }
  }

  return (
    <AdminPage className={styles.AdminViewContent}>
      <BreadCrumbs
        links={[
          {
            label: t(breadCrumbsLabel(content?.type)),
            url: content?.type
              ? breadCrumbsUrlForContentTypeMap[
                  content.type as ContentTypeEnum
                ](channel?.slug)
              : '',
          },
          { label: content?.name },
        ]}
      />
      <Flex direction="row" justify="space-between" m={[5, 0, 3, 0]}>
        <Flex>
          <H3>{content?.name}</H3>
          <div style={{ marginLeft: 12, paddingTop: 2 }}>
            <Chip
              value="web.pages.portal.admin.audiences.show.draft"
              type={ChipStyle.Indigo}
              size="sm"
            />
          </div>
        </Flex>

        <Flex gap={3} className={styles.buttonGroupContainer}>
          <ButtonLink
            size="large"
            to={{
              pathname: routes.channelAdminDraft
                .replace(':id', channel?.slug)
                .replace(':draftId', content?._id),
              state: { from: location.pathname },
            }}
            className="btnEdit"
            testId="buttonEdit"
          >
            {t('Edit')}
          </ButtonLink>
        </Flex>
      </Flex>
      <TabStrip
        disabled={loading}
        tabs={availableTabs}
        selected={TabsMap[selectedTab]}
        onSelectTab={tab => goToUrl({ tab: tab.value })}
        fullWidth
      />
      <ErrorMessage error={error} />

      <div className={styles.main} style={{ position: 'relative' }}>
        {!loading && TabsMap[selectedTab].Component}
      </div>
      <CreatedBy className={styles.createdBy} object={content} forAdmin />
    </AdminPage>
  );
};
