import React, { useContext, useEffect } from 'react';

import {
  PopupMenu,
  TextButton,
  Table,
  Column,
  getPageSizeFromQueryString,
} from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useQueryString } from 'hooks';
import {
  Charge,
  currencyToPaymentCurrency,
  currencyFromJSON,
  ExternalActorType,
  CHARGE_STATUS,
} from 'lane-shared/domains/billingPayments/types';
import { CurrencyFormatMap } from 'lane-shared/domains/billingPayments/constants';

import { GroupType } from 'graphql-query-contracts';
import { bpErrorsCodes } from 'activate-errors';
import {
  getMessagesFromError,
  getTimeZoneByGeoLocation,
} from 'lane-shared/helpers';
import { dates } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'uuid-encoding';
import { getChargeStatus } from 'lane-shared/helpers/features';
import {
  simpleCurrencyFormatter,
  dateFormatter,
  getThreeLetterTimeZoneAbbreviation,
} from 'lane-shared/helpers/formatters';
import {
  ChargeQueryString,
  DEFAULT_FILTER_PARAMS,
} from './helpers/useGetChargeTableFilters';
import { useUserData } from 'lane-shared/hooks';

import { Chip, ChipStyle } from 'components/ads';

import ChannelAdminContext from '../ChannelAdminContext';

const listChargesQuery = gql`
  query listCharges($listChargesRequest: ListChargesRequest!) {
    accounts {
      listCharges(listChargesRequest: $listChargesRequest) {
        charges {
          id
          name
          description
          amount
          status
          externalPayerId
          currency
          createdAt
          updatedAt
          invoice {
            id
            invoiceDisplayId
            status
            invoiceDate
            dueDate
          }
        }
        pageInfo {
          perPage
          total
          start
        }
      }
    }
  }
`;

const voidChargeMutation = gql`
  mutation voidCharge($voidChargeRequest: VoidChargeRequest!) {
    accounts {
      voidCharge(voidChargeRequest: $voidChargeRequest) {
        chargeId
      }
    }
  }
`;

export function ChargesTable() {
  const { t } = useTranslation();
  const { channel } = useContext(ChannelAdminContext);
  const { user } = useUserData();

  const [voidCharge, voidChargeResponse] = useMutation(voidChargeMutation);
  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';

  function handleVoidChargeError(err: unknown) {
    const messages = getMessagesFromError(err);

    if (messages.length > 0) {
      if (
        messages.some(message =>
          message.includes(bpErrorsCodes.chargeAlreadyVoided.code)
        )
      ) {
        window.Toast.show(t(bpErrorsCodes.chargeAlreadyVoided.message));
      } else if (
        messages.some(message =>
          message.includes(bpErrorsCodes.chargeAlreadyPaid.code)
        )
      ) {
        window.Toast.show(t(bpErrorsCodes.chargeAlreadyPaid.message));
      } else {
        window.Toast.show(t(bpErrorsCodes.chargeVoidFailed.message));
      }
    }
  }

  const actions: any[] = [
    {
      label: t('abp.actions.voidCharge'),
      onClick: async (_id: number, charge: Charge) => {
        try {
          await window.Alert.confirm({
            title: t('abp.charge.voidChargeTitle'),
            message: t('abp.charge.voidChargeMessage', {
              currency: CurrencyFormatMap[currencyFromJSON(charge.currency)],
              amount: charge.amount,
              name: charge.externalPayerId,
            }),
            confirmText: t('abp.actions.voidCharge'),
          });
          window.Alert.loading({
            title: 'Updating',
          });
          // User confirmed
          await voidCharge({
            variables: {
              voidChargeRequest: {
                chargeId: charge.id,
                externalActorId: user?._id || '',
                externalActorType:
                  ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
              },
            },
          });

          if (voidChargeResponse.error) {
            throw voidChargeResponse.error;
          }

          window.Toast.show(t('abp.charges.chargeVoidedSuccessfully'));
        } catch (err) {
          handleVoidChargeError(err);
          console.error(`Action cancelled: ${err}`);
        } finally {
          window.Alert.hide();
        }
      },
    },
  ];

  const chargeColumns: Column<Charge>[] = [
    {
      key: 'status',
      header: t('abp.chargeList.status'),
      renderCell: (cell: string, row) => {
        const statusMap = getChargeStatus(cell, row);
        const chipColor = statusMap[1] as ChipStyle;

        return <Chip value={statusMap[0]} type={chipColor} size="xs" />;
      },
    },
    { key: 'externalPayerId', header: t('abp.chargeList.customer') },
    { key: 'name', header: t('abp.chargeList.name') },
    { key: 'description', header: t('abp.chargeList.description') },
    {
      key: 'amount',
      header: t('abp.chargeList.amount'),
      renderCell: (cell: number, row) => (
        <span>
          {simpleCurrencyFormatter(
            cell,
            currencyToPaymentCurrency(row.currency)
          )}
        </span>
      ),
    },
    {
      key: 'createdAt',
      header: `${t(
        'abp.chargeList.createdDate'
      )} ${getThreeLetterTimeZoneAbbreviation(timeZone)}`,
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, dates.SIMPLE_DATE, timeZone)}</span>
      ),
    },
    {
      key: 'actions',
      header: t('abp.actions'),
      renderCell: (cell: number, row: Charge) => (
        <PopupMenu
          trigger={
            <TextButton
              iconName="angle-down"
              disabled={
                getChargeStatus(row.status, row)[0] ===
                  CHARGE_STATUS.CHARGE_VOIDED ||
                getChargeStatus(row.status, row)[0] ===
                  CHARGE_STATUS.CHARGE_PAID
              }
            >
              {t('abp.actions')}
            </TextButton>
          }
          items={actions.map(({ label, onClick }) => ({
            label,
            onSelect: () => {
              onClick(cell, row);
            },
          }))}
        />
      ),
    },
  ];

  const [loadChargesList, { data, loading }] = useLazyQuery(listChargesQuery);

  const [filterParams, setFilterParams] = useQueryString<ChargeQueryString>(
    DEFAULT_FILTER_PARAMS,
    ['tab']
  );

  useEffect(() => {
    if (channel) {
      loadChargesList({
        variables: {
          listChargesRequest: {
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
            pagination: {
              start:
                ((filterParams?.page || 0) as number) *
                getPageSizeFromQueryString(filterParams?.pageSize),
              perPage: getPageSizeFromQueryString(filterParams?.pageSize),
            },
          },
        },
      });
    }
  }, [channel, loadChargesList, filterParams]);

  const totalCharges = data?.accounts?.listCharges?.pageInfo?.total;

  useEffect(() => {
    if (totalCharges) {
      setFilterParams({
        total: totalCharges,
      });
    }
  }, [data?.accounts?.listCharges?.pageInfo?.total]);

  return (
    <Table
      columns={chargeColumns}
      data={data ? data.accounts.listCharges.charges : []}
      disableSorting
      isLoading={loading}
      pagination="server"
      totalRows={Number(filterParams.total)}
      queryStringsEnabled
    />
  );
}
