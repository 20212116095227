import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { map, filter } from 'lodash';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { useFlag, useSetContextualLDAttributes } from 'lane-shared/hooks';
import routes from 'lane-shared/config/routes';
import { WorkOrderModuleSettings } from 'lane-shared/domains/workOrder';
import { channelModuleByChannelId } from 'lane-shared/graphql/modules';
import { pause } from 'lane-shared/helpers';
import { convertToUUID, convertTo62 } from 'uuid-encoding';
import { FeatureFlag } from 'constants-flags';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import {
  AttachmentImageContentTypeEnum,
  AttachmentDocumentContentTypeEnum,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';

import {
  Flex,
  Button,
  ValidationMessage,
  ErrorMessage,
  AddAttachment,
  Tooltip,
  Loading,
} from 'components';
import BreadCrumbs from 'components/lds/BreadCrumbs';
import {
  HookFormInput,
  HookFormDropdown,
  HookFormTextarea,
  HookFormToggle,
  HookFormMultiSelectDropdown,
} from 'components/reactHookForm';
import { P, H4, H5 } from 'components/typography';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { InlineDropdownOption } from '../../constants/InlineDropdownOption';
import {
  CustomQuestions,
  CustomQuestionsType,
} from '../../constants/customQuestions';

import {
  getServiceRequestByIdQuery,
  updateServiceRequestMutation,
} from 'graphql-queries';

import styles from './ServiceRequestEditForm.scss';
import { AmazonS3Buckets } from 'lane-shared/types/media';
import { useGetUnitsFieldOptions } from 'domains/workOrder/feature/components/hooks/useGetUnitsFieldOptions';
import { SingleSuiteText } from 'domains/workOrder/components/SingleSuiteText';
import { Icon } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

function ServiceRequestEdit({ channel }: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { serviceRequestId } = useParams<{ serviceRequestId: string }>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [navigateAway, setNavigateAway] = useState(true);
  const isEnabledCesLinksForServiceRequests = useFlag(
    FeatureFlag.EnableCesLinksForServiceRequests,
    false
  );

  const {
    data: serviceRequestResponse,
    refetch,
    loading: srLoading,
  } = useQuery(getServiceRequestByIdQuery, {
    variables: { serviceRequestId },
  });
  const serviceRequestData = serviceRequestResponse?.serviceRequest;
  const srCategory = serviceRequestData?.category;

  useSetContextualLDAttributes({
    activeContentOriginChannelId: serviceRequestData?.channelId?.id
      ? convertTo62(serviceRequestData.channelId.id)
      : undefined,
  });

  const { options: suiteOptions } = useGetUnitsFieldOptions(
    serviceRequestData?.channelId?.id,
    !isEnabledCesLinksForServiceRequests
  );

  const { data: moduleResp } = useQuery(channelModuleByChannelId, {
    variables: {
      channelId: serviceRequestData?.channelId?.id,
      contentType: ContentTypeEnum.WorkOrder,
    },
  });
  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;
  const settings: WorkOrderModuleSettings =
    moduleResp?.channelModuleByChannelId?.settings;
  const selectedCategory = settings?.categories?.find(
    category => category.firendlyName === srCategory
  );
  const categoryOptions: InlineDropdownOption[] = settings?.categories
    ? settings.categories
        .filter(category => category.issues.length > 0)
        .map((category: { firendlyName: string }) => ({
          label: category.firendlyName,
          value: category.firendlyName,
        }))
    : [];
  const locationOptions: InlineDropdownOption[] = settings?.locations
    ? settings.locations.map((locationOption: string) => ({
        label: locationOption,
        value: locationOption,
      }))
    : [];

  const [issueOptions, setIssueOptions] = useState<InlineDropdownOption[]>(
    selectedCategory
      ? selectedCategory.issues.map(issue => ({
          label: issue,
          value: issue,
        }))
      : []
  );

  const customQuestions: CustomQuestions[] = settings?.customQuestions
    ? settings.customQuestions.map((customQuestion: any) => {
        if (
          customQuestion?.validators &&
          customQuestion?.validators[0] &&
          customQuestion?.validators[0]?.value
        ) {
          const options: InlineDropdownOption[] =
            customQuestion.validators[0].value.map((customfield: any) => ({
              label: customfield.value,
              value: customfield.value,
            }));

          return {
            label: customQuestion.friendlyName,
            type: customQuestion.packagedType,
            options,
          };
        }

        return {
          label: customQuestion.friendlyName,
          type: customQuestion.packagedType,
        };
      })
    : [];

  let customQuestionValues = {};
  const deletedCustomQuestions: any[] = [];

  if (serviceRequestData?.customQuestions) {
    serviceRequestData?.customQuestions.forEach((question: any) => {
      customQuestionValues = {
        ...customQuestionValues,
        [question.Question]: question.Answer,
      };

      if (
        !customQuestions.some(
          (customQuestion: any) => customQuestion.label === question.Question
        )
      ) {
        deletedCustomQuestions.push({
          Question: question.Question,
          Answer: question.Answer,
        });
      }
    });
  }

  const initialFormValues: {
    category: any;
    issue: any;
    location: any;
    description: any;
    suiteIds?: { value: string }[];
    floor?: any;
    suite?: any;
  } = {
    category: serviceRequestData?.category,
    issue: serviceRequestData?.issue,
    location: serviceRequestData?.location,
    description: serviceRequestData?.description,
    ...customQuestionValues,
  };

  if (isEnabledCesLinksForServiceRequests) {
    initialFormValues.suiteIds = filter(
      serviceRequestData?.suiteIds,
      'sourceEntityId'
    ).map((suite: { sourceEntityId: string }) => ({
      value: suite?.sourceEntityId,
    }));
  } else {
    initialFormValues.floor = serviceRequestData?.floor;
    initialFormValues.suite = serviceRequestData?.suite;
  }

  const updateServiceRequest = async (fields: any) => {
    try {
      await pause();
      const customAnswers: any[] = [...deletedCustomQuestions];

      customQuestions.forEach((question: any) => {
        if (fields[question.label] !== undefined) {
          let answer = fields[question.label]?.value
            ? fields[question.label].value
            : fields[question.label];

          if (typeof answer === 'boolean') {
            answer = answer ? t`Yes` : t`No`;
          }

          customAnswers.push({
            Question: question.label,
            Answer: answer,
          });
        }

        delete fields[question.label];
      });
      serviceRequestData?.customQuestions.forEach((question: any) => {
        delete fields[question.Question];
      });
      fields.category = fields?.category?.value
        ? fields.category.value
        : serviceRequestData.category;
      fields.issue = fields?.issue?.value
        ? fields.issue.value
        : serviceRequestData.issue;
      fields.location = fields?.location?.value
        ? fields.location.value
        : serviceRequestData.location;

      if (isEnabledCesLinksForServiceRequests) {
        if (suiteOptions.length === 1) {
          fields.suiteIds = [suiteOptions[0].value];
        } else {
          fields.suiteIds =
            map(filter(fields?.suiteIds, 'value'), 'value') || [];
        }
      } else {
        fields = {
          floor: serviceRequestData.floor,
          suite: serviceRequestData.suite,
          suiteIds:
            map(
              filter(serviceRequestData?.suiteIds, 'sourceEntityId'),
              'sourceEntityId'
            ) || [],
          ...fields,
        };
      }

      await getClient().mutate({
        mutation: updateServiceRequestMutation,
        variables: {
          updateServiceRequest: {
            serviceRequestId: convertToUUID(
              serviceRequestData.serviceRequestId
            ),
            description: serviceRequestData.description,
            category: serviceRequestData.category,
            issue: serviceRequestData.issue,
            location: serviceRequestData.location,
            company: serviceRequestData.company?.id,
            assignee: serviceRequestData?.assignee?.id,
            assigneeGroups: serviceRequestData?.assigneeGroups,
            isBillable: serviceRequestData?.isBillable,
            notes: serviceRequestData.notes,
            hours: serviceRequestData.hours,
            equipment: serviceRequestData.equipment,
            customQuestions: customAnswers,
            status: serviceRequestData.status,
            ...fields,
          },
        },
      });
      refetch();
      setLoading(false);
      window.Toast.show(t('web.admin.serviceRequest.toast.success'));
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  const serviceRequestListPath =
    routes.channelAdminWorkOrdersServiceRequests.replace(':id', channel?.slug);

  const { register, handleSubmit, control, formState, setValue } = useForm<any>(
    {
      mode: 'all',
      defaultValues: initialFormValues,
    }
  );

  const onCategoryChange = (item: any) => {
    const newCategory = settings?.categories?.find(
      category => category.firendlyName === item.value
    );
    const issueOptionTemp = newCategory
      ? newCategory.issues.map(issue => ({
          label: issue,
          value: issue,
        }))
      : [];

    setValue('issue', '');
    setIssueOptions(issueOptionTemp);
  };

  const renderCustomQuestions = () => {
    return (
      <div>
        {customQuestions.map(
          (customQuestion: CustomQuestions, index: number) => (
            <div className={styles.CustomDataDescription} key={index}>
              {customQuestion.type === CustomQuestionsType.None && (
                <div className={styles.input}>
                  <P className={styles.inputHeadings}>{customQuestion.label}</P>
                  <HookFormInput
                    control={control}
                    name={customQuestion.label}
                    inputClassName={customQuestion.label}
                    testId={customQuestion.label}
                  />
                </div>
              )}
              {customQuestion.type === CustomQuestionsType.Checkboxes && (
                <div className={styles.input}>
                  <P className={styles.inputHeadings}>{customQuestion.label}</P>
                  <HookFormToggle
                    control={control}
                    {...register(customQuestion.label)}
                  />
                </div>
              )}
              {customQuestion.type === CustomQuestionsType.Dropdown && (
                <div className={styles.input}>
                  <P className={styles.inputHeadings}>{customQuestion.label}</P>
                  <HookFormDropdown
                    placeholder={t('web.admin.serviceRequest.select')}
                    items={customQuestion.options || []}
                    control={control}
                    {...register(customQuestion.label)}
                    isFullWidth
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>
    );
  };

  const formSubmission = async (data: any) => {
    await updateServiceRequest(data);
    afterSave();
  };

  const afterSave = () => {
    history.push(serviceRequestListPath);
  };

  if (!navigateAway && Object.keys(formState.errors).length > 0) {
    setNavigateAway(true);
  }

  return (
    <div className={styles.container}>
      <BreadCrumbs
        links={[
          {
            label: t('web.admin.serviceRequest.title'),
            url: serviceRequestListPath,
          },
          {
            label: `${serviceRequestData?.userFriendlyID}`,
            url: routes.channelAdminWorkOrdersServiceRequestDetails
              .replace(':id', channel?.slug)
              .replace(
                ':serviceRequestId',
                serviceRequestData?.serviceRequestId
              ),
          },
          {
            label: t('web.admin.serviceRequest.edit'),
          },
        ]}
      />
      {error ? <ErrorMessage error={error} /> : null}
      {!error && srLoading && <Loading />}
      {!srLoading && (
        <div className={styles.serviceRequestForm}>
          <H4>{t('web.admin.serviceRequest.edit')}</H4>
          <form
            onSubmit={handleSubmit(formSubmission)}
            style={{ width: '100%' }}
          >
            <div className={styles.input}>
              <P className={styles.inputHeadings}>
                {t('web.admin.serviceRequest.edit.category.title')}
                <span className={styles.required}>*</span>
              </P>
              <HookFormDropdown
                placeholder={t('web.admin.serviceRequest.select')}
                items={categoryOptions}
                control={control}
                onValueChange={onCategoryChange}
                {...register('category', { required: true })}
                isFullWidth
              />
              {formState.errors.category && (
                <ValidationMessage
                  className={styles.validationMessage}
                  errors={[t('web.admin.serviceRequest.field.required')]}
                  withoutIcon
                />
              )}
            </div>
            <div className={styles.input}>
              <P className={styles.inputHeadings}>
                {t('web.admin.serviceRequest.edit.issue.title')}
              </P>
              <HookFormDropdown
                placeholder={t('web.admin.serviceRequest.select')}
                items={issueOptions}
                control={control}
                {...register('issue')}
                isFullWidth
              />
              {formState.errors.issue && (
                <ValidationMessage
                  className={styles.validationMessage}
                  errors={[t('web.admin.serviceRequest.field.required')]}
                  withoutIcon
                />
              )}
            </div>
            <div className={styles.input}>
              <P className={styles.inputHeadings}>
                {t('web.admin.serviceRequest.edit.location.title')}
                <span className={styles.required}>*</span>
              </P>
              <HookFormDropdown
                placeholder={t('web.admin.serviceRequest.select')}
                items={locationOptions}
                control={control}
                {...register('location', { required: true })}
                isFullWidth
              />
              {formState.errors.location && (
                <ValidationMessage
                  className={styles.validationMessage}
                  errors={[t('web.admin.serviceRequest.field.required')]}
                  withoutIcon
                />
              )}
            </div>
            <div className={styles.input}>
              <P className={styles.inputHeadings}>
                {t('web.admin.serviceRequest.edit.description.title')}
              </P>
              <HookFormTextarea
                containerClassName={styles.field}
                control={control}
                {...register('description')}
                showLengthIndicator
                minRows={4}
                maxLength={500}
              />
            </div>
            {isPropertyChannel && (
              <>
                {!isEnabledCesLinksForServiceRequests && (
                  <>
                    <div className={styles.input}>
                      <P className={styles.inputHeadings}>
                        {t('web.admin.serviceRequest.edit.floor.title')}
                      </P>
                      <HookFormInput
                        control={control}
                        {...register('floor')}
                        inputClassName="floor"
                        className={styles.floorInput}
                      />
                    </div>
                    <div className={styles.input}>
                      <P className={styles.inputHeadings}>
                        {t('web.admin.serviceRequest.edit.suite.title')}
                      </P>
                      <HookFormInput
                        control={control}
                        {...register('suite')}
                        inputClassName="suite"
                        className={styles.suiteInput}
                      />
                    </div>
                  </>
                )}

                {isEnabledCesLinksForServiceRequests &&
                  suiteOptions.length > 0 && (
                    <div className={styles.input}>
                      {suiteOptions.length === 1 ? (
                        <SingleSuiteText suiteName={suiteOptions[0].label} />
                      ) : (
                        <>
                          <P className={styles.inputHeadings}>
                            {t('web.admin.serviceRequest.edit.suites.title')}
                          </P>
                          <HookFormMultiSelectDropdown
                            control={control}
                            items={suiteOptions}
                            isFullWidth
                            placeholder={t('web.admin.serviceRequest.select')}
                            {...register('suiteIds', {
                              value: initialFormValues.suiteIds,
                            })}
                          />
                        </>
                      )}
                    </div>
                  )}
              </>
            )}
            <div className={styles.input}>
              <div className={styles.inputHeadings}>
                <H5>{t`web.admin.serviceRequest.form.attachment.heading`}</H5>
              </div>
              <div className={styles.ServiceRequestAttachmentsDisclaimer}>
                <span>{t`web.admin.serviceRequest.form.attachment.disclaimer`}</span>
              </div>
              <div className={styles.fieldAttachments}>
                <AddAttachment
                  entityId={serviceRequestData?.attachmentRefId}
                  entityType={ContentTypeEnum.WorkOrder}
                  editMode={false}
                  variant={AttachmentVariantEnum.WorkOrder}
                  canAddAttachments
                  acceptedFileTypes={[
                    ...Object.values(AttachmentImageContentTypeEnum),
                    AttachmentDocumentContentTypeEnum.pdf,
                  ]}
                  s3Bucket={AmazonS3Buckets.Activate}
                />
              </div>
            </div>
            {deletedCustomQuestions.length > 0 &&
              deletedCustomQuestions.map(
                (customQuestion: any, index: number) => (
                  <Flex
                    className={styles.customQuestionDataRow}
                    width="full"
                    key={index}
                  >
                    <span>
                      {customQuestion.Question}
                      <Tooltip
                        TooltipComponent={t(
                          'web.admin.serviceRequest.customQuestions.archived.tooltip'
                        )}
                        placement="top"
                      >
                        <Icon
                          name="info-circle"
                          className={styles.infoIcon}
                          set={ICON_SET_FONTAWESOME}
                          type="far"
                        />
                      </Tooltip>
                    </span>
                    <P>{customQuestion.Answer}</P>
                  </Flex>
                )
              )}
            {customQuestions.length > 0 && renderCustomQuestions()}
            <Flex className={styles.buttonGroup}>
              <Button
                variant="outlined"
                size="large"
                testId="cancelFormButton"
                fullWidth
                onClick={() => {
                  setNavigateAway(false);
                  history.goBack();
                }}
                className={styles.formButton}
                labelClassName="cancel-button"
              >
                {t('web.admin.serviceRequest.cancel.button')}
              </Button>
              <Button
                type="submit"
                variant="activate-contained"
                size="large"
                fullWidth
                testId="submitFormButton"
                loading={loading}
                onClick={() => setNavigateAway(false)}
                className={styles.formButton}
                labelClassName="submit-button"
              >
                {t('web.admin.serviceRequest.save')}
              </Button>
            </Flex>
            {navigateAway && (
              <Prompt
                when={formState.isDirty}
                message={t(
                  'web.admin.serviceRequest.equipment.form.navigation.prompt'
                )}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
}

export default ServiceRequestEdit;
