import { checkIfChannelExistsQuery } from 'graphql-queries';
import { getClient } from 'lane-shared/apollo';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AutoSetupConfig, Template } from '../types';

export function useTemplateIdValidation({
  config,
  setError,
}: {
  config: AutoSetupConfig;
  setError: Dispatch<SetStateAction<string>>;
}): string {
  const [templateValidation, setTemplateValidation] = useState<string>('');

  useEffect(() => {
    async function checkChannelExists() {
      try {
        const { data } = await getClient().query({
          query: checkIfChannelExistsQuery,
          variables: {
            id: config.templateId,
          },
        });

        if (!data.checkIfChannelExists) {
          setTemplateValidation(
            'This channel ID is not valid. Please provide a new template channel ID'
          );
        }
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        setTemplateValidation(
          'This channel ID is not valid. Please provide a new template channel ID'
        );
      }
    }

    setTemplateValidation('');
    setError('');

    if (
      config.templateId &&
      config.templateId !== '' &&
      config.templateType !== Template.BLANK
    )
      checkChannelExists();
  }, [config.templateId, config.templateType]);

  return templateValidation;
}
