import React, { useEffect } from 'react';

import routes from 'lane-shared/config/routes';
import { useTranslation } from 'react-i18next';
import { useChannelAdminContext } from 'hooks';
import { useParams } from 'react-router-dom';
import { ButtonType } from 'components/layout/PageHeader';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';
import { ItemsTable } from 'domains/billingAndPayments/components/ItemsTable';
import { getRecurringChargeQuery } from 'lane-shared/domains/billingPayments/helpers/getRecurringCharge';
import { AdminPage, PageHeader } from 'components/layout';
import { ChipStyle } from 'components/ads';
import { getRecurringChargeStatus } from 'lane-shared/helpers/features/getRecurringChargeStatus';
import { dates } from 'lane-shared/helpers/constants';
import { H4, H5, M, S } from 'components/typography';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useLazyQuery } from '@apollo/client';
import {
  RecurringChargeActions,
  useGetActionsForRecurringCharges,
} from 'pages/portal/admin/channel/charges-invoices/helpers/useGetActionsForRecurringCharges';
import styles from './styles.scss';
import { SIMPLE_DATE } from 'lane-shared/helpers/constants/dates';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';

const InfoSection = ({ title, value }: { title: string; value: string }) => {
  return (
    <span className={styles.group}>
      <H5 className={styles.title}>{title}</H5>
      <M>{value}</M>
    </span>
  );
};

export function MembershipDetails() {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { recurringChargeId } = useParams<{ recurringChargeId: string }>();
  const [loadRecurringCharge, { data, refetch }] = useLazyQuery(
    getRecurringChargeQuery,
    {
      fetchPolicy: 'network-only',
    }
  );
  const actions = useGetActionsForRecurringCharges(
    refetch
  ) as RecurringChargeActions[];

  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';

  useEffect(() => {
    if (channel) {
      loadRecurringCharge({
        variables: {
          getRecurringChargeRequest: {
            recurrenceId: recurringChargeId,
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
          },
        },
      });
    }
  }, [recurringChargeId, channel, loadRecurringCharge]);

  if (!channel) return null;

  const breadcrumbs = [
    {
      label: t('abp.charges.breadcrumbs.subscriptions'),
      url: routes.channelAdminSubscriptions.replace(':id', channel.slug),
    },
    { label: t('abp.charges.breadcrumbs.subscriptionDetails') },
  ];

  const recurringCharge = data?.accounts.getRecurringCharge.recurringCharge;
  const charge = recurringCharge?.charge;
  const recurrence = recurringCharge?.recurrence;
  const items = charge?.items || [];
  const statusMap = getRecurringChargeStatus(
    Boolean(recurrence?.isActive),
    recurrence?.status
  );
  const currentDate = new Date();
  const daysSinceLastUpdate = recurrence?.updatedAt
    ? Math.floor(
        (currentDate.getTime() - new Date(recurrence?.updatedAt).getTime()) /
          (1000 * 60 * 60 * 24)
      )
    : null;

  const nilDate = new Date(0);
  const lastOccurrenceDate = recurrence?.lastOccurrence
    ? new Date(recurrence?.lastOccurrence)
    : nilDate;
  const expiryDate =
    nilDate >= lastOccurrenceDate
      ? t('abp.subscription.never')
      : dateFormatter(lastOccurrenceDate, SIMPLE_DATE, timeZone);

  type ActionItem = {
    label: string;
    type: ButtonType;
    onClick: () => void;
  };

  const actionButtons = actions.reduce(function (
    result,
    { label, onSelect, hidden }
  ) {
    const triggerAction = () => onSelect(null, recurringCharge);

    if (!hidden(recurringCharge)) {
      result.push({
        label,
        type: 'tertiary' as ButtonType,
        onClick: triggerAction,
      });
    }

    return result;
  }, [] as ActionItem[]);

  return (
    <AdminPage>
      {charge && recurrence && (
        <>
          <PageHeader
            header={charge?.name}
            headerLevel="h3"
            status={statusMap[0]}
            statusType={statusMap[1] as ChipStyle}
            breadcrumbs={breadcrumbs}
            actionButtons={actionButtons}
          />

          <div className={styles.membershipDetails}>
            <S variant="secondary">
              {t('abp.subscription.lastUpdatedText', {
                days: daysSinceLastUpdate,
                updater: recurrence?.updatedBy,
              })}
            </S>
            <div className={styles.sectionContainer}>
              <H4 mb={2}>
                {t('abp.subscriptions.details.sections.customerDetails')}
              </H4>
              <div className={styles.sectionTable}>
                <InfoSection
                  title={t('abp.subscriptions.details.customerName')}
                  value={charge.externalPayerId}
                />
                <InfoSection
                  title={t('abp.subscriptions.details.customerType')}
                  value={t(
                    `abp.subscriptions.details.customerType.${charge.externalPayerType}`
                  )}
                />
              </div>
            </div>
            <div className={styles.sectionContainer}>
              <H4 mb={2}>
                {t('abp.subscriptions.details.sections.subscriptionDetails')}
              </H4>
              <div className={styles.sectionTable}>
                <InfoSection
                  title={t('abp.subscriptions.details.name')}
                  value={charge.name}
                />
                <InfoSection
                  title={t('abp.subscriptions.details.billingFrequency')}
                  value={t(
                    `abp.subscription.recurrenceInterval.${recurrence.recurrenceInterval}`
                  )}
                />
                <InfoSection
                  title={t('abp.subscription.startDate')}
                  value={
                    recurrence.startDate
                      ? dateFormatter(
                          recurrence.startDate,
                          dates.LONG_MONTH_DATE_YEAR,
                          timeZone
                        )
                      : '-'
                  }
                />
                <InfoSection
                  title={t('abp.subscriptions.details.lastCharge')}
                  value={expiryDate}
                />
                <InfoSection
                  title={t('abp.subscriptions.details.customerCanCancel')}
                  value={t(
                    `abp.subscriptions.details.customerCanCancel.${recurrence.anyoneCanCancel}`
                  )}
                />
                <div className={styles.description}>
                  <InfoSection
                    title={t('abp.subscriptions.details.description')}
                    value={charge.description || '-'}
                  />
                </div>
              </div>
            </div>

            {items.length > 0 && (
              <div className={styles.sectionTableContainer}>
                <H4 mb={5} className={styles.heading}>
                  {t('abp.subscriptions.details.sections.productsAndServices')}
                </H4>
                <div className={styles.itemsTable}>
                  <ItemsTable channelId={channel?._id} charge={charge} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </AdminPage>
  );
}
