import { WhitelabeledLogo } from 'components';
import React from 'react';
import styles from './SiteHeader.scss';
import { Link } from 'react-router-dom';
import { Icon } from 'design-system-web';
import { ButtonLink } from 'components/general/ButtonLink';
import { routes } from 'lane-shared/config';
import QueryString from 'qs';
import { useTranslation } from 'react-i18next';

export type SiteHeaderComponentProps = { channelId: string | undefined };

export function SiteHeader({ channelId }: SiteHeaderComponentProps) {
  const { t } = useTranslation();

  return (
    <header className={styles.Header}>
      <Link to="/" data-test="white-label-logo">
        <div className={styles.logo}>
          <WhitelabeledLogo size={50} />
        </div>
      </Link>
      <div className={styles.headerButtonGroup}>
        {channelId ? (
          <ButtonLink
            variant="primary"
            size="large"
            startIcon={<Icon name="arrow-to-right" />}
            testId="joinButton"
            className={styles.joinMembersButton}
            to={{
              pathname: routes.identifier,
              search: QueryString.stringify({
                chid: channelId,
              }),
            }}
          >
            {t('web.public.channelProfile.button.joinMembersPortal')}
          </ButtonLink>
        ) : (
          ''
        )}
      </div>
    </header>
  );
}
