import React, { useEffect, useState } from 'react';

import { H3, S } from 'components/typography';
import { Loading } from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { routes } from 'lane-shared/config';

import { getClient } from 'lane-shared/apollo';
import { getAudiences } from 'lane-shared/graphql/query';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';
import type { Audience } from 'lane-shared/types/audiences/Audience';

import { Table } from 'design-system-web';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './Audiences.scss';

type AudiencesResponse = {
  items: { name: string; id: string }[];
  pageInfo: {
    total: number;
  };
};

export const Audiences = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [audiences, setAudiences] = useState<AudiencesResponse | undefined>();
  const { audienceTracker } = useAudienceAnalytics();
  const history = useHistory();

  useEffect(() => {
    if (audiences) {
      audienceTracker.View.Index(audiences.items as Audience[]);
    }
  }, [JSON.stringify(audiences?.items.map(audience => audience.id))]);

  const [query, goToUrl] = useQueryString({
    page: 0,
    keyword: '',
    sortBy: 'updatedAt',
    sortDirection: 'desc',
    pageSize: 10,
  });

  const [keyword, setKeyword] = useState(query.keyword as string | undefined);

  const pageSize = parseInt(query.pageSize as string, 10) || 10;

  const pageFromQuery = parseInt(query.page as string, 10);
  const pageFromRequest = audiences?.pageInfo?.total! / pageSize;

  // The search is fired when query or channel changes through useEffect,
  // if you happen to be on page 5 of the table,
  // search for something and only 1 page of results comes back,
  // you would see nothing unless you click in the pagination to got to page 1.
  // So we override page to be 0 if total/perPage is smaller then current page.
  const page =
    (pageFromRequest < pageFromQuery
      ? 0
      : parseInt(query.page as string, 10)) || 0;

  const doFetch = useDebouncedCallback(() => fetchAudiences(), 500).callback;

  const fetchAudiences = async () => {
    if (!channel?._id) {
      return;
    }

    const variables = {
      channelId: channel._id,
      pagination: {
        start: page * pageSize,
        perPage: pageSize,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
      } as Record<string, any>,
    };

    if (keyword) {
      variables.search.name = keyword;
    }

    if (query.sortBy) {
      variables.search.sortBy = { key: query.sortBy, dir: query.sortDirection };
    }

    const { data } = await getClient().query({
      fetchPolicy: 'network-only',
      query: getAudiences,
      variables,
    });

    if (data?.audiences.items) {
      setAudiences(data.audiences);
    }
  };

  useEffect(() => {
    goToUrl({ keyword, page });
  }, [keyword, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    doFetch();
  }, [query, channel]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      header: t`web.pages.portal.admin.channel.audiences.table.name`,
      key: 'name',
      renderCell: (name: string, audience: { name: string; id: string }) => (
        <Link
          className={styles.link}
          to={{
            pathname: `audiences/${audience.id}`,
            state: { from: location.pathname },
          }}
        >
          {name}
        </Link>
      ),
    },
  ];

  if (!channel || !audiences) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.Audience}>
      <div className={styles.titleWrapper}>
        <H3>{t('web.pages.portal.admin.channel.audiences.title')}</H3>
        <S mt={5} mb={5} className={styles.description}>
          {t('web.pages.portal.admin.channel.audiences.description')}
        </S>
      </div>

      <div>
        <Table
          columns={columns}
          data={audiences.items}
          totalRows={audiences?.pageInfo.total}
          hasKeywordFilter
          onKeywordFilterChange={keyword => setKeyword(keyword)}
          onPrimaryAction={() =>
            history.push(
              routes.channelAdminAudiencesNew.replace(':id', channel.slug)
            )
          }
          primaryActionLabel={t(
            'web.pages.portal.admin.channel.audiences.newButtonText'
          )}
          pagination="server"
          queryStringsEnabled
        />
      </div>
    </div>
  );
};
