import React from 'react';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from 'react-select';

import { Flex } from 'components';

import { InlineDropdownOption } from '../../constants/InlineDropdownOption';

import styles from './InlineDropdown.scss';

type InlineDropdownProps = {
  options: InlineDropdownOption[];
  value?: {
    label: string;
    value: string;
  };
  onChange: Function;
  ariaLabel: string;
};

const InlineDropdown = ({
  options,
  value,
  onChange,
  ariaLabel,
}: InlineDropdownProps) => {
  const CustomInlineDropdown = (props: any) => {
    return (
      <Flex align="center">
        <span>{props.data.label || props.data.name}</span>
      </Flex>
    );
  };

  return (
    <Select
      aria-label={ariaLabel}
      options={options}
      value={value}
      onChange={onChange}
      className={styles.CustomInlineDropdown}
      styles={{
        control: (baseStyles: any) => ({
          ...baseStyles,
          border: 'none',
          ':hover': {
            backgroundColor: '#EBEBFF',
          },
        }),
        indicatorSeparator: (styles: any) => ({
          ...styles,
          display: 'none',
        }),
        dropdownIndicator: (styles: any) => ({
          ...styles,
          display: 'none',
        }),
      }}
      components={{
        SingleValue: CustomInlineDropdown,
      }}
    />
  );
};

export default InlineDropdown;
