import React, { useState } from 'react';
import cx from 'classnames';

import { Flex, Input } from 'components';
import { useTranslation } from 'react-i18next';
import {
  ProductItem,
  ProductType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { PriceException } from '../PriceException';

import { M } from 'components/typography';

import styles from './ItemRow.scss';
import { Icon } from 'design-system-web';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { SupportedLocaleEnum } from 'localization';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import { limitValue } from 'lane-shared/domains/billingPayments/limitValue';
import { useDebouncedCallback } from 'use-debounce';

export function ItemRow({
  item,
  currency,
  locale,
  update,
  remove,
  isDraft = false,
}: {
  item?: ProductItem;
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  update?: (updatedProduct: ProductItem) => void;
  remove?: () => void;
  isDraft?: boolean;
}) {
  const { t } = useTranslation();
  const isEmpty = !item;
  const product = item?.product!;
  const [quantity, setQuantity] = useState(item?.quantity!);

  const currencyFormat = currencyFormatter({
    currency,
  });

  const productTypeLabel = () => {
    switch (product.productCategory.productType) {
      case ProductType.PRODUCT_TYPE_SERVICE_LABOUR:
        return t('abp.charges.addProduct.modal.type.service');
      case ProductType.PRODUCT_TYPE_SERVICE_MATERIALS:
        return t('abp.charges.addProduct.modal.type.product');
      default:
        return '-';
    }
  };

  let listPrice = 0;

  if (!isEmpty) {
    const priceComponents = productToPriceComponents(product);

    ({ listPrice } = getPricing({
      currency,
      locale,
      priceComponents,
    }));
  }

  const debouncedOnChange = useDebouncedCallback<
    (limitedValue: number) => void
  >((limitedValue: number) => {
    if (update) {
      update({ ...item!, quantity: limitedValue });
    }
  }, 1000).callback;

  const onUpdate = (value: string) => {
    const limitedValue = limitValue(value, 2);

    debouncedOnChange(limitedValue);
    setQuantity(limitedValue);
  };

  return (
    <div
      className={cx(styles.Grid, {
        [styles.DraftGrid]: isDraft,
      })}
    >
      <Flex direction="column" className={styles.DetailsColumn}>
        {isEmpty ? (
          <>
            <M bold>{t('punctuation.dash')}</M>
            <M>{t('punctuation.dash')}</M>
            <M bold>{t('punctuation.dash')}</M>
          </>
        ) : (
          <Flex direction="column" gap={1}>
            <M className={styles.ProductType} bold>
              {productTypeLabel()}
            </M>
            <M className={styles.ProductCategory}>
              {product.productCategory.name}
            </M>
            <M className={styles.ProductName}>{product.name}</M>
          </Flex>
        )}
      </Flex>
      <Flex direction="column" className={styles.QuantityColumn}>
        <M className={styles.ColumnHeader} mb={2}>
          {t('abp.productsServices.table.header.quantity')}
        </M>
        {isDraft ? (
          <Input
            testId="quantity-input"
            disabled={isEmpty}
            className={styles.QuantityInput}
            input={{ style: { paddingRight: '1rem' } }}
            value={quantity}
            onChange={value => onUpdate(value)}
            showClear={false}
            error={!quantity && !isEmpty ? [''] : null}
            type="number"
          />
        ) : (
          <M>{quantity}</M>
        )}
      </Flex>
      <Flex className={styles.RateColumn} direction="column" align="flex-end">
        <M className={styles.ColumnHeader} mb={2}>
          {t('abp.productsServices.table.header.rate')}
        </M>
        <M className={styles.PriceExceptionAmount}>
          {!isEmpty && item.product.originalPrice && (
            <PriceException
              rate={listPrice}
              taxValue={item.product.tax?.value}
              taxType={item.product.tax?.taxAmountType}
              originalPrice={item.product.originalPrice}
              currency={currency}
              locale={locale}
              className={styles.PriceExceptionIcon}
              testId="priceException"
            />
          )}
          {isEmpty ? t('punctuation.dash') : currencyFormat(listPrice)}
        </M>
      </Flex>
      <Flex className={styles.TotalColumn} direction="column" align="flex-end">
        <M className={styles.ColumnHeader} mb={2}>
          {t('abp.productsServices.table.header.total')}
        </M>
        <M>
          {isEmpty
            ? t('punctuation.dash')
            : currencyFormat(listPrice * quantity)}
        </M>
      </Flex>
      {isDraft && (
        <Flex
          className={styles.DeleteColumn}
          direction="column"
          justify="center"
          align="center"
        >
          <Icon
            testId="delete-icon"
            size="medium"
            name="trash-alt"
            disabled={isEmpty}
            className={isEmpty ? styles.DisabledDeleteIcon : styles.DeleteIcon}
            onClick={remove}
          />
        </Flex>
      )}
    </div>
  );
}
