import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  ChargeCodeSource,
  CreateChargeCodeRequest,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';

import { ChargeCodeDetails } from '../chargeCodes/CreateChargeCodeForm';

export async function createChargeCodeMutation(
  chargeCodeDetails: ChargeCodeDetails,
  channelId: string
) {
  const { chargeCode, glCode, description } = chargeCodeDetails;

  const createChargeCodeMutationQuery = gql`
    mutation createChargeCode(
      $createChargeCodeRequest: CreateChargeCodeRequest!
    ) {
      accounts {
        createChargeCode(createChargeCodeRequest: $createChargeCodeRequest) {
          id
        }
      }
    }
  `;

  await getClient().mutate<
    any,
    { createChargeCodeRequest: CreateChargeCodeRequest }
  >({
    mutation: createChargeCodeMutationQuery,
    variables: {
      createChargeCodeRequest: {
        chargeCode,
        glCode,
        description,
        groupType: GroupType.GroupTypeActivateChannel,
        groupId: convertToUUID(channelId),
        chargeCodeSource: ChargeCodeSource.CHARGE_CODE_SOURCE_ACTIVATE,
      },
    },
  });
}
