import { useEffect, useState } from 'react';

import {
  ChargeCode as gqlChargeCode,
  SortDirection as gqlSortDirection,
  GroupType as gqlGroupType,
} from 'graphql-query-contracts';

import { chargeCodeFilterQuery } from 'graphql-queries';
import { convertToUUID } from 'uuid-encoding';

import * as Sentry from '@sentry/browser';
import { getClient } from 'lane-shared/apollo';
import { useApolloClient } from '@apollo/client';

type ApolloClient = ReturnType<typeof getClient>;

export function useChargeCodesOptions(channelId: string | undefined) {
  const apolloClient = useApolloClient();
  const [chargeCodes, setChargeCodes] = useState<gqlChargeCode[]>([]);

  useEffect(() => {
    if (channelId) {
      fetchChargeCodesForFilters(channelId, apolloClient)
        .then(data => setChargeCodes(data))
        .catch(error => {
          Sentry.captureException('BPError - chargeCodeFilterQuery error', {
            contexts: {
              fetchChargeCode: {
                channelId,
                error,
              },
            },
          });
          console.error(error);
        });
    }
  }, [channelId]);

  return chargeCodes;
}

async function fetchChargeCodesForFilters(
  channelId: string,
  apolloClient: ApolloClient
) {
  const { data } = await apolloClient.query({
    query: chargeCodeFilterQuery,
    variables: {
      listChargeCodeRequest: {
        groupId: convertToUUID(channelId),
        groupType: gqlGroupType.GroupTypeActivateChannel,
        pagination: {
          perPage: 100,
          start: 0,
        },
        sortBy: {
          dir: gqlSortDirection.Asc,
          key: 'charge_code',
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  if (data) {
    const chargeCodes = data.accounts?.listChargeCode?.chargeCodes || [];
    const validChargeCodes: gqlChargeCode[] = chargeCodes.filter(
      (chargeCode): chargeCode is gqlChargeCode => chargeCode !== null
    );

    return validChargeCodes;
  }

  return [];
}
