import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { getScheduledContentNotifications } from 'graphql-queries';

import { useParams } from 'react-router-dom';
import { ErrorMessage } from 'components';
import { scheduledContentNotificationsColumns } from './scheduledContentNotificationsColumns';

import { Table, PageSizeType, Loading } from 'design-system-web';

export function ContentNotifications() {
  const params = useParams<{ page?: string; pageSize?: string }>();

  const [paginationState, setPaginationState] = useState({
    page: Number.isNaN(Number(params.page)) ? 1 : Number(params.page),
    pageSize: (Number.isNaN(Number(params.pageSize))
      ? 25
      : Number(params.pageSize)) as PageSizeType,
  });

  const { data, loading, error } = useQuery(getScheduledContentNotifications, {
    variables: {
      pagination: {
        after: (paginationState.page - 1) * paginationState.pageSize,
        first: paginationState.pageSize,
      },
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (
    !data?.scheduledContentNotifications.nodes ||
    data.scheduledContentNotifications.nodes.length === 0
  ) {
    return <ErrorMessage error={new Error('No data found')} />;
  }

  return (
    <div className="px-2">
      <Table
        filters={[]}
        columns={scheduledContentNotificationsColumns}
        data={data.scheduledContentNotifications.nodes}
        isSelectable
        getRowId={({ id }) => id}
        pagination="server"
        totalRows={data.scheduledContentNotifications.totalCount}
        pageSize={paginationState.pageSize}
        page={paginationState.page}
        isLoading={loading}
        onPageChange={page => {
          setPaginationState({ ...paginationState, page });

          const newSearchParams = new URLSearchParams(window.location.search);

          newSearchParams.set('page', page.toString());
        }}
        onPageSizeChange={pageSize => {
          setPaginationState({ ...paginationState, pageSize });

          const newSearchParams = new URLSearchParams(window.location.search);

          newSearchParams.set('pageSize', pageSize.toString());
        }}
        hasKeywordFilter
        showColumnVisibility
      />
    </div>
  );
}
