import { useContext } from 'react';

import { AppContext } from '../contexts';
import useFlag from './useFlag';

// NOTE: Whitelabels exist statically in `cache.json` but not referentially
// so I cannot use anything like `x.cityFoundry.instance` to capture this.
// Knowing that, if someone were to change `pursang` -> `not-pursang`
// this will no longer notice them.
const CITY_FOUNDRY = 'pursang';
const CHARTER_HALL = 'charterhall';
const COLAB_DS = 'colabds';
const CQC = 'cqcconnect';
const KUDOS = 'kudosworkplace';
const HOSTY = 'hosty';
const CENTURY_PARK = 'centuryparkapp';
const N_WACKER = 'northwacker110';

const CUSTOM_LANGUAGE_AFFECTED_WHITELABELS = [CITY_FOUNDRY];
const CONTINUE_AS_GUEST_NOT_VISIBLE_AFFECTED_WHITELABELS = [
  CHARTER_HALL,
  COLAB_DS,
  CQC,
  KUDOS,
  HOSTY,
  CENTURY_PARK,
  N_WACKER,
];

export const DEFAULT_CONTINUE_AS_GUEST_LABELS = {
  noRecognizedOptionLabel: 'onboarding.company.cannotFindCompany',
  emailLabel: 'web.login.loginEmail.emailLabel',
  connectionTitleLabel: 'web.login.loginEmail.connectToWhitelabel',
  locationPrompt: 'onboarding.location.workplaceLabel',
  locationPlaceholder: 'onboarding.location.buildingLocationPlaceholder',
} as const;

type ContinueAsGuestType = {
  isContinueAsGuestVisible: boolean;
  translationKeys: {
    [key in keyof typeof DEFAULT_CONTINUE_AS_GUEST_LABELS]: string;
  };
};

export function useContinueAsGuest(): ContinueAsGuestType {
  const { whitelabel } = useContext(AppContext);

  const continueAsGuestVisibilityEnabled = useFlag<boolean>(
    'flex.continueAsGuestVisibility',
    true
  );

  const isContinueAsGuestVisibleWhitelabel =
    !CONTINUE_AS_GUEST_NOT_VISIBLE_AFFECTED_WHITELABELS.includes(
      whitelabel.instance
    );

  const isCustomLanguageWhitelabel =
    CUSTOM_LANGUAGE_AFFECTED_WHITELABELS.includes(whitelabel.instance);

  const isContinueAsGuestVisible = Boolean(
    !continueAsGuestVisibilityEnabled || isContinueAsGuestVisibleWhitelabel
  );

  if (!isCustomLanguageWhitelabel) {
    return {
      isContinueAsGuestVisible,
      translationKeys: { ...DEFAULT_CONTINUE_AS_GUEST_LABELS },
    };
  }

  return {
    isContinueAsGuestVisible,
    translationKeys: {
      noRecognizedOptionLabel: 'onboarding.company.continueAsGuest',
      emailLabel: 'web.login.loginEmail.softEmailLabel',
      connectionTitleLabel: 'web.login.loginEmail.connectToWhitelabel',
      locationPrompt: 'onboarding.location.enterWhitelabel',
      locationPlaceholder: 'onboarding.location.locationPlaceholder',
    },
  };
}
