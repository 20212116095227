import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import { ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { LocationAvailabilityEnum } from 'lane-shared/types/LocationType';

import { H3 } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import { OnboardingBuildingInput } from './OnboardingBuildingInput';
import SignUpSubmitButton from './SignUpSubmitButton';

import styles from './Signup.scss';

import { ANALYTIC_KEYS } from 'constants-analytics';

export default function SignUpBuilding({ onNext }: any) {
  const simpleTrack = useSimpleTrack();
  const { locationHelper } = useLocation();
  const { t } = useTranslation();

  const { whitelabel } = useContext(AppContext);

  const {
    building,
    buildingSearch,
    buildingsResult,
    name,
    company,
    updateSignUp,
    companySearch,
    companyLocationsResult,
    parentCompany,
    isLocked,
  } = useSignUpContext();

  useEffect(() => {
    locationHelper.startWatching({
      showRequestModal: true,
      availability: LocationAvailabilityEnum.Foreground,
      disableLocationPrecision: whitelabel.disableLocationPrecision,
    });
  }, []);

  const handleBuildingSearchTextChange = (buildingSearch: string) =>
    updateSignUp({ buildingSearch });

  const handleBuildingSelectionChange = (building: ChannelType | null) =>
    updateSignUp({ building });

  const handleCompanySearchTextChange = (companySearch: string) =>
    updateSignUp({ companySearch });

  const handleCompanySelectionChange = (company: ChannelType | null) =>
    updateSignUp({ company });

  const buildingPrivateError: Error | null = building?.inviteOnly
    ? new Error(
        t('The location you selected is private, and can’t be accessed.')
      )
    : null;

  return (
    <div className={cx(styles.formContents, styles.centeredVertically)}>
      <div style={{ width: '100%' }}>
        <H3 bold mb={4} className={welcomeFormStyles.formTitle}>
          {t(`Welcome {{- name}},`, { name })}
          <br />
          {t('join your community.')}
        </H3>
        <ErrorMessage
          className={styles.errorMessage}
          error={buildingPrivateError}
        />
        <br />
        <OnboardingBuildingInput
          data={{
            building,
            buildingSearch,
            buildingsResult,
            company,
            companySearch,
            companyLocationsResult,
            parentCompany,
            whiteLabelName: whitelabel.name,
          }}
          config={{
            isLocked,
          }}
          handlers={{
            onBuildingSearchTextChange: handleBuildingSearchTextChange,
            onBuildingSelectionChange: handleBuildingSelectionChange,
            onCompanySearchTextChange: handleCompanySearchTextChange,
            onCompanySelectionChange: handleCompanySelectionChange,
          }}
          ContinueButton={
            <SignUpSubmitButton
              disabled={Boolean(buildingPrivateError)}
              onNext={() => {
                simpleTrack(
                  ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_BUILDING_CREATE_ACCOUNT,
                  {
                    buildingId: building?._id,
                  }
                );
                onNext();
              }}
            />
          }
        />
      </div>
    </div>
  );
}
