import React, { useMemo } from 'react';

import cx from 'classnames';
import Lottie from 'lottie-react-web';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styleMap from '../styleMap';

type BlockProps = WebBlockProps & {
  source: any;
  width: number;
  height: number;
  speed: number;
};

export default function LottieBlock({
  style,
  className,
  source,
  width,
  height,
  speed,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);

  const lottieOptions = useMemo(() => {
    let animationData = {};

    try {
      animationData = JSON.parse(source);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // bad input file
    }

    return {
      animationData,
      loop: true,
      autoPlay: true,
    };
  }, [source]);

  return (
    <div className={cx(className)} style={styleMap(false, style)} {...props}>
      <Lottie
        height={height}
        width={width}
        speed={speed}
        options={lottieOptions}
      />
    </div>
  );
}
