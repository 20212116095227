import React, { useEffect } from 'react';

import { Icon, Table } from 'design-system-web';
import { Button, AdminPage, Loading } from 'components';
import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { ListUnitsQueryResponse, listUnits } from 'lane-shared/graphql/units';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { H3, H4, M } from 'components/typography';

import { getSharedTranslationKeys } from '../utils';
import history from 'helpers/history';

import styles from './styles.scss';
import { useUnitDeleteDisabledAlert } from '../hooks/useUnitDeleteDisabledAlert';

type Props = {
  channel: ChannelType;
};

type UnitRow = {
  name: string;
  id: string;
  description: string;
  floors: {
    name: string;
  }[];
};

export const UnitsList = ({ channel }: Props) => {
  const { t } = useTranslation();
  const { listPage } = getSharedTranslationKeys(channel.experienceType);

  const { handleDeleteAlert } = useUnitDeleteDisabledAlert();

  const [loadUnitsList, { data, loading }] =
    useLazyQuery<ListUnitsQueryResponse>(listUnits);

  useEffect(() => {
    if (channel) {
      loadUnitsList({
        variables: {
          propertyId: channel._id,
        },
      });
    }
  }, [channel, loadUnitsList]);

  const unitCTAs = () => {
    const linkToNewUnit = routes.channelAdminUnitsNew.replace(
      ':id',
      channel?.slug || ''
    );

    return (
      <div className={styles.buttons}>
        <Link to={linkToNewUnit}>
          <Button testId="addUnitButton" variant="contained">
            {t(listPage.addButton)}
          </Button>
        </Link>
      </div>
    );
  };

  const emptyState = () => {
    return (
      <div className={styles.emptyStateBorder}>
        <div className={styles.emptyState}>
          <Icon className={styles.icon} name="building" size="large" />
          <div>
            <H4 className={styles.headerPadding}>
              {t(listPage.emptyStateHeader)}
            </H4>
            <M>{t(listPage.emptyStateSubtext)}</M>
          </div>
          {unitCTAs()}
        </div>
      </div>
    );
  };

  const rowActions = [
    {
      label: t('web.pages.portal.admin.units.list.columns.actions.viewDetails'),
      onClick: (row: UnitRow) => {
        const url = routes.channelAdminUnitDetails
          .replace(':id', channel?.slug || '')
          .replace(':unitId', row.id);

        history.push(url);
      },
    },
    {
      label: t('web.pages.portal.admin.units.list.columns.actions.delete'),
      onClick: async () => {
        handleDeleteAlert();
      },
    },
  ];

  const keywordFilter = (row: UnitRow, keyword: string) => {
    const searchableString = [
      row.name,
      ...row.floors.map(f => f.name),
      row.description,
    ]
      .join(' ')
      .toLowerCase();

    return searchableString.includes(keyword.toLowerCase());
  };

  const unitColumns = [
    {
      key: 'name',
      header: t(listPage.columns.name),
      renderCell: (cell: string | undefined, row: any) => {
        const url = routes.channelAdminUnitDetails
          .replace(':id', channel?.slug || '')
          .replace(':unitId', row.id);

        return (
          <Link style={{ textDecoration: 'underline' }} to={url}>
            {cell}
          </Link>
        );
      },
      renderCSV: (row: UnitRow) => row.name,
    },
    {
      key: 'floors',
      header: t('web.pages.portal.admin.units.list.columns.floors'),
      renderCell: (floors: Array<any>[]) => {
        const floorNames = floors.map((f: any) => f.name);

        return <span>{floorNames.join(', ') || '--'}</span>;
      },
      renderCSV: (row: UnitRow) =>
        row.floors.map(floor => floor.name).join(','),
    },
    {
      key: 'description',
      header: t('web.pages.portal.admin.units.list.columns.description'),
      renderCell: (cell: string | undefined) => <span>{cell || '--'}</span>,
      renderCSV: (row: UnitRow) => row.description,
    },
  ];

  const handleExportToCSV = (units: UnitRow[]) => {
    const csvData = [];
    const headers = unitColumns.map(column => column.header);

    csvData.push(headers);

    units.forEach(unit => {
      const rowData: any[] = [];

      unitColumns.map(column => {
        const value = column.renderCSV(unit);

        rowData.push(value);
      });

      csvData.push(rowData);
    });

    const csv = Papa.unparse(csvData);
    const filename = `${listPage.exportFileName}-${Date.now()}.csv`;

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  const exportAll = () => {
    const units = data?.property.units || [];

    handleExportToCSV(units);
  };

  const exportCurrentPage = (currentPageData: UnitRow[] = []) => {
    handleExportToCSV(currentPageData);
  };

  const exportOptions = [
    {
      label: t('web.pages.portal.admin.units.list.export.currentPage'),
      onClick: exportCurrentPage,
    },
    {
      label: t('web.pages.portal.admin.units.list.export.all'),
      onClick: exportAll,
    },
  ];

  const showTable = data && data.property?.units?.length > 0;

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.Units}>
        <div className={styles.header}>
          <H3>{t(listPage.header)}</H3>
          {unitCTAs()}
        </div>

        {!showTable && !loading && emptyState()}
        {!showTable && loading && <Loading />}

        {showTable && (
          <Table
            columns={unitColumns}
            data={data.property.units}
            pagination="client"
            hasKeywordFilter
            customKeywordFilterFn={keywordFilter}
            exportOptions={exportOptions}
            rowActions={rowActions}
            isLoading={loading}
          />
        )}
      </div>
    </AdminPage>
  );
};
