import { getClient } from 'lane-shared/apollo';
import {
  CreateRecurringChargeRequest,
  ExternalEntityType,
  RecurrenceType,
  ExternalActorType,
  AddProductToChargeDetails,
  ChargeDetails,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';

import { createRecurringChargeMutationQuery } from './chargesInvoiceQueries';
import { convertProductsToQueryFormat } from './createChargeMutation';

export interface CreateNewChargeDetails extends ChargeDetails {
  channelId: string;
  chargeCreatorUserId: string;
  anyoneCanCancel: boolean;
}

export async function createRecurringChargeMutation(
  chargeDetails: CreateNewChargeDetails,
  products: AddProductToChargeDetails[]
) {
  const {
    invoicee: { _id, type: invoiceeType },
    name,
    description,
    amount,
    currency,
    frequency,
    startDate,
    endDate,
    totalCounts,
    channelId,
    chargeCreatorUserId,
    paymentDueDays,
    anyoneCanCancel,
    invoiceType,
  } = chargeDetails;

  const productItems = convertProductsToQueryFormat(products, channelId);

  await getClient().mutate<
    any,
    { createRecurringChargeRequest: CreateRecurringChargeRequest }
  >({
    mutation: createRecurringChargeMutationQuery,
    variables: {
      createRecurringChargeRequest: {
        charge: {
          name,
          description,
          amount: Number(amount),
          currency,
          groupId: convertToUUID(channelId),
          groupType: GroupType.GroupTypeActivateChannel,
          externalEntityId: '',
          externalEntityType:
            ExternalEntityType.EXTERNAL_ENTITY_TYPE_ACTIVATE_UCI,
          externalActorId: convertToUUID(chargeCreatorUserId),
          externalActorType:
            ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
          externalPayerId: convertToUUID(_id),
          externalPayerType: invoiceeType,
          invoiceType,
          metadata: '',
          invoiceDueInDays: paymentDueDays,
          items: productItems,
        },
        recurrenceType: RecurrenceType.RECURRENCE_TYPE_CHARGES,
        recurrenceInterval: frequency,
        startDate: new Date(startDate.toUTCString()),
        totalCounts,
        endDate,
        anyoneCanCancel,
      },
    },
  });
}
