import React from 'react';
import { useParams, useLocation, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'components/layout/PageHeader';
import { Icon } from 'design-system-web';
import ErrorMessage from 'components/general/ErrorMessage';
import { Loading } from 'design-system-web/components/Loading/Loading';
import {
  Invoice,
  ExternalPayerType,
} from 'lane-shared/domains/billingPayments/types';
import { getInvoiceStatus } from 'lane-shared/helpers/features/getInvoiceStatus';
import { PageHeader } from 'components/layout';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';
import { useUserBillingAndPaymentContext } from 'lane-shared/domains/billingPayments/hooks/useUserBillingAndPaymentContext';
import { useInvoiceDetails } from 'lane-shared/domains/billingPayments/hooks/useInvoiceDetails';
import { ChipStyle } from 'components/ads';
import { ChargeList } from './ChargeList';
import { Info } from './Info';
import { InvoiceTotal } from './InvoiceTotal';
import styles from './styles.scss';
import { routes } from 'lane-shared/config';
import { useBillingAndPaymentDownloadInvoiceEnabled } from 'lane-shared/hooks/useBillingAndPaymentDownloadInvoiceEnabled';
import { convertTo62 } from 'uuid-encoding';
import { useDownloadFile } from '../../../../hooks/useDownloadFile';

export function InvoiceDetails() {
  const { t } = useTranslation();
  const { downloadFileFromUrl } = useDownloadFile();

  const isDownloadInvoiceEnabled = useBillingAndPaymentDownloadInvoiceEnabled();
  const location = useLocation<{ invoice: Invoice }>();

  const groupId =
    location.state?.invoice.groupId ||
    new URLSearchParams(location.search).get('groupId');
  const { invoiceDisplayId } = useParams<{ invoiceDisplayId: string }>();

  const { isInvoiceAutopayEnabled } = useUserBillingAndPaymentContext();

  const {
    loading: invoiceDetailsLoading,
    error: invoiceDetailsError,
    invoiceDetails,
  } = useInvoiceDetails({
    groupId: groupId || undefined,
    invoiceDisplayId,
  });

  const { invoiceDisplayName } = useBillingPaymentSettingsForChannel({
    channelId: groupId || undefined,
  });

  const invoice = invoiceDetails?.invoice;
  const transaction = invoiceDetails?.transaction;
  const charges = invoiceDetails?.charges || [];
  const displayId = invoice?.displayId || '';
  const isInvoiceTypeUser =
    invoice?.externalPayerType ===
    ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER;

  const showAutopay = isInvoiceAutopayEnabled && isInvoiceTypeUser;

  const statusMap = invoice
    ? getInvoiceStatus(invoice.status, invoice, showAutopay)
    : ['', ''];
  const isPaid = statusMap[0] === 'abp.invoiceList.status.paid';
  const headerLabel = `${invoiceDisplayName} ${displayId}`;
  const buttonType = 'secondary' as ButtonType;

  const showDownloadInvoiceButton = isDownloadInvoiceEnabled;

  const handleClick = async () => {
    if (!invoice) return;

    window.Alert.loading({
      title: `${t('abp.actions.downloadPdf.preparingYourFile')}`,
      disableCloseOnBackgroundClick: true,
    });

    try {
      const url = `${generatePath(routes.downloadInvoicePdf, {
        channelId: convertTo62(invoice.groupId),
        invoiceDisplayId: invoice.displayId,
      })}`;

      await downloadFileFromUrl({ url });
    } catch {
      await window.Toast.show(t('abp.actions.downloadPdf.error'));
    }

    setTimeout(() => window.Alert.hide(), 500);
  };

  const actionButtons =
    showDownloadInvoiceButton && invoice
      ? [
          {
            label: t('abp.actions.downloadPdf'),
            type: buttonType,
            onClick: handleClick,
            startIcon: <Icon name="arrow-to-bottom" />,
          },
        ]
      : [];

  return (
    <span>
      {invoiceDetailsLoading && (
        <div>
          <Loading />
        </div>
      )}

      {invoiceDetailsError && (
        <div>
          <ErrorMessage error={t('abp.errors.invoice.requestFailed')} />
        </div>
      )}

      {invoice && (
        <>
          <PageHeader
            externalPadding={[0, 0]}
            header={headerLabel}
            headerLevel="h3"
            status={statusMap[0]}
            statusType={statusMap[1] as ChipStyle}
            actionButtons={actionButtons}
          />
          <div className={styles.divider} />
          <Info invoice={invoice} isPaid={isPaid} transaction={transaction} />
          <ChargeList invoice={invoice} charges={charges} />
          <InvoiceTotal invoice={invoice} charges={charges} />
        </>
      )}
    </span>
  );
}
