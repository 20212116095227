import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { hasPermission } from 'lane-shared/helpers';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'constants-flags';
import { UserType } from 'lane-shared/types/User';

import PreventiveMaintenanceHome from '../pages/preventiveMaintenance-home';
import { ScheduleCreatePage } from '../schedule/pages/schedule-create';
import ScheduleDetailsPage from '../schedule/pages/schedule-details';
import { ScheduleEditPage } from '../schedule/pages/schedule-edit';
import { TaskDetailsPage } from '../task/pages/task-details';
import { PreventiveMaintenanceSettings } from '../pages/settings/settings';

export enum PreventiveMaintenancePageName {
  PreventiveMaintenanceHome = 'preventiveMaintenanceHome',
  ScheduleCreate = 'scheduleCreate',
  ScheduleDetails = 'scheduleDetails',
  ScheduleEdit = 'scheduleEdit',
  TaskDetails = 'taskDetails',
  Settings = 'settings',
}

type preventiveMaintenancePage = {
  key: PreventiveMaintenancePageName;
  Page: React.FC<{
    channel: ChannelType;
    hasAnyPermission: (permissions: string[]) => Boolean;
  }>;
  exact?: boolean;
  path: string;
};

export const preventiveMaintenancePages: Record<
  string,
  preventiveMaintenancePage
> = {
  [PreventiveMaintenancePageName.PreventiveMaintenanceHome]: {
    key: PreventiveMaintenancePageName.PreventiveMaintenanceHome,
    Page: PreventiveMaintenanceHome,
    exact: true,
    path: routes.channelAdminWorkOrdersPM,
  },
  [PreventiveMaintenancePageName.ScheduleCreate]: {
    key: PreventiveMaintenancePageName.ScheduleCreate,
    Page: ScheduleCreatePage,
    exact: true,
    path: routes.channelAdminWorkOrdersPMScheduleCreate,
  },
  [PreventiveMaintenancePageName.ScheduleDetails]: {
    key: PreventiveMaintenancePageName.ScheduleDetails,
    Page: ScheduleDetailsPage,
    exact: true,
    path: routes.channelAdminWorkOrdersPMScheduleDetails,
  },
  [PreventiveMaintenancePageName.ScheduleEdit]: {
    key: PreventiveMaintenancePageName.ScheduleEdit,
    Page: ScheduleEditPage,
    exact: false,
    path: routes.channelAdminWorkOrdersPMScheduleEdit,
  },
  [PreventiveMaintenancePageName.TaskDetails]: {
    key: PreventiveMaintenancePageName.TaskDetails,
    Page: TaskDetailsPage,
    exact: true,
    path: routes.channelAdminWorkOrdersPMTaskDetails,
  },
  [PreventiveMaintenancePageName.Settings]: {
    key: PreventiveMaintenancePageName.Settings,
    Page: PreventiveMaintenanceSettings,
    exact: true,
    path: routes.channelAdminWorkOrdersPMTaskSettings,
  },
};

export function PreventiveMaintenanceRoutes({
  urlPath,
  channel,
  user,
}: {
  urlPath: string;
  channel: any;
  user: UserType;
}): JSX.Element | null {
  const workOrdersPreventiveMaintenanceFlag = useFlag(
    FeatureFlag.WorkOrdersPreventiveMaintenance,
    false
  );

  if (!workOrdersPreventiveMaintenanceFlag) return null;

  const hasAnyPermission = (permissions: string[]): Boolean => {
    return (
      user.isSuperUser ||
      (channel?._id && hasPermission(user.roles, permissions, channel?._id))
    );
  };

  return (
    <Switch>
      {Object.entries(preventiveMaintenancePages).map(
        ([key, { Page, path, exact }]) => {
          return (
            <Route
              key={key}
              exact={exact}
              path={`${urlPath}/${path.replace('/l/channel/:id/admin/', '')}`}
            >
              <Page channel={channel} hasAnyPermission={hasAnyPermission} />
            </Route>
          );
        }
      )}
    </Switch>
  );
}
