import React from 'react';
import { useTranslation } from 'react-i18next';

import { Person } from 'lane-shared/types/Person';
import { LaneType } from 'common-types';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import history from 'helpers/history';
import { routes } from 'lane-shared/config';
import { Table } from 'design-system-web';
import { H4 } from 'components/typography';
import styles from './styles.scss';

type Props = {
  residents: Person[];
};

type Row = {
  id: string;
  name: string;
  email: string;
  userId: LaneType.UUID | undefined;
  activity: Date | undefined;
  joinedOn: Date | undefined;
};

export const ResidentsList = ({ residents }: Props) => {
  const { t } = useTranslation();

  const data: Row[] =
    residents &&
    residents.map(
      (resident: {
        id: string;
        name: string;
        email: string;
        userId: LaneType.UUID | undefined;
        user?: {
          name: string;
          _created: Date | undefined;
          lastSeen: Date;
          profile: {
            email: string;
          };
        };
      }) => {
        return {
          id: resident.id,
          name: resident.user?.name || resident.name,
          email: resident.user?.profile.email || resident.email,
          userId: resident.userId,
          activity: resident.user?.lastSeen,
          joinedOn: resident.user?._created,
        };
      }
    );

  const columns = [
    {
      key: 'name',
      header: t('web.pages.portal.admin.units.details.residents.table.name'),
      type: 'text',
      renderCell: (name: string) => <span>{name || '-'}</span>,
    },
    {
      key: 'email',
      header: t('web.pages.portal.admin.units.details.residents.table.email'),
      type: 'text',
      renderCell: (email: string) => <span>{email || '-'}</span>,
    },
    {
      key: 'activity',
      header: t(
        'web.pages.portal.admin.units.details.residents.table.activity'
      ),
      type: 'text',
      renderCell: (activity: string) => (
        <span>{(activity && fromNow(activity)) || '-'}</span>
      ),
    },
    {
      key: 'joinedOn',
      header: t(
        'web.pages.portal.admin.units.details.residents.table.joinedOn'
      ),
      type: 'date',
      renderCell: (joinedOn: string) => (
        <span>{simpleDate(joinedOn) || '-'}</span>
      ),
    },
  ];

  const rowActions = [
    {
      label: t(
        'web.pages.portal.admin.units.details.residents.table.actions.viewProfile'
      ),
      isDisabled: (resident: Row) => !resident.userId,
      onClick: (resident: Row) => {
        if (typeof resident.userId === 'undefined') {
          return;
        }

        const url = routes.portalManagementUserEdit.replace(
          ':userId',
          resident.userId
        );

        history.push(url);
      },
    },
    {
      label: t(
        'web.pages.portal.admin.units.details.residents.table.actions.removeUser'
      ),
      onClick: () => {}, // Remove user
    },
  ];

  return (
    <div className={styles.residents}>
      <div className={styles.header}>
        <H4>{t('web.pages.portal.admin.units.details.residents.header')}</H4>
      </div>
      <div className={styles.body}>
        <Table
          columns={columns}
          data={data}
          pagination="client"
          rowActions={rowActions}
          emptyMessage={t(
            'web.pages.portal.admin.units.details.residents.table.emptyMessage'
          )}
        />
      </div>
    </div>
  );
};
