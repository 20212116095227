import React, { useContext, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';

import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'localization';

import { Button } from 'design-system-web';

import { ContentRendererContext } from 'lane-shared/contexts';
import {
  useContentWaitlistActions,
  useQuantityAllotments,
  useWaitlistEnrollmentsByContent,
} from 'lane-shared/hooks/contentState';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import explodeFeatures from 'lane-shared/helpers/features/explodeFeatures';

import { WebBlockProps } from '../WebBlockProps';
import { ANALYTIC_KEYS } from 'constants-analytics';

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = Object.freeze({
  joinWaitlist: 'shared.content.feature.reservable.waitlist.join',
  leaveWaitlist: 'shared.content.feature.reservable.waitlist.leave',
  joinWaitlistContentId:
    'shared.content.feature.reservable.waitlist.join.contentid.required',
  joinWaitlistSuccess:
    'shared.content.feature.reservable.waitlist.join.success',
  leaveWaitlistSuccess:
    'shared.content.feature.reservable.waitlist.leave.success',
  leaveWaitlistEnrolledId:
    'shared.content.feature.reservable.waitlist.leave.enrolledId.required',
  booked: 'shared.content.feature.reservable.timeSlots.booked',
});

export function withQuantity<T extends WebBlockProps>(Component: React.FC<T>) {
  function WithQuantityFeature({ ...props }: T) {
    const simpleTrack = useSimpleTrack();
    const { t } = useTranslation();
    const { content, loading: isContentRendererLoading } = useContext(
      ContentRendererContext
    );

    const {
      enrollmentId,
      getWaitlistEnrollmentForUser,
      isLoading: isContentWaitlistEnrollmentLoading,
    } = useWaitlistEnrollmentsByContent(content?._id);

    const {
      joinWaitlist,
      leaveWaitlist,
      isLoading: isContentWaitlistActionLoading,
    } = useContentWaitlistActions();

    const {
      fetchAllotments,
      hasUserRemainingAllotmentsToClaim,
      hasUserExistingBookings,
    } = useQuantityAllotments(content?._id);

    useEffect(() => {
      if (content) {
        fetchAllotments();
      }
    }, [content?._id]);

    const { quantityFeature } = explodeFeatures(content?.features);

    const handleClick = async () => {
      if (enrollmentId) {
        try {
          simpleTrack(ANALYTIC_KEYS.ANALYTIC_CONTENT_QUANTITY_WAITLIST_LEAVE);

          await leaveWaitlist(enrollmentId, content._id);
          await getWaitlistEnrollmentForUser();

          window.Toast.show(t(TRANSLATION_KEYS.leaveWaitlistSuccess));
        } catch (e) {
          window.Toast.show(e.message);
        }

        return;
      }

      const isEvent = Boolean(content?.startDate && content?.endDate);
      const timeSlot = {
        startDate: isEvent ? content?.startDate : content?.publishedAt,
        endDate: isEvent ? content?.endDate : content?.unpublishDate,
      };

      try {
        if (!content?._id) {
          throw new Error(t(TRANSLATION_KEYS.joinWaitlistContentId));
        }

        simpleTrack(ANALYTIC_KEYS.ANALYTIC_CONTENT_QUANTITY_WAITLIST_JOIN);

        await joinWaitlist(content._id, timeSlot);
        await getWaitlistEnrollmentForUser();

        window.Toast.show(t(TRANSLATION_KEYS.joinWaitlistSuccess));
      } catch (e) {
        window.Toast.show(e.message);
      }
    };

    const isLoading =
      isContentWaitlistEnrollmentLoading ||
      isContentWaitlistActionLoading ||
      isContentRendererLoading;

    if (quantityFeature && isLoading) {
      return (
        <Skeleton
          width="100%"
          variant="rounded"
          style={{ height: '40px', borderRadius: '1.5rem' }}
        />
      );
    }

    const isWaitlistEnabled = quantityFeature && quantityFeature?.useWaitlist;

    if (isWaitlistEnabled && !hasUserRemainingAllotmentsToClaim) {
      if (hasUserExistingBookings) {
        return (
          <Button fullWidth variant="secondary" size="large" disabled>
            {t(TRANSLATION_KEYS.booked)}
          </Button>
        );
      }

      return (
        <Button
          onClick={handleClick}
          fullWidth
          variant="secondary"
          size="large"
        >
          {enrollmentId
            ? t(TRANSLATION_KEYS.leaveWaitlist)
            : t(TRANSLATION_KEYS.joinWaitlist)}
        </Button>
      );
    }

    return <Component {...props} />;
  }

  return WithQuantityFeature;
}
