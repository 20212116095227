import React, { useContext, useEffect, useState } from 'react';

import { Icon, Input } from 'design-system-web';
import { Button } from 'components';
import gql from 'graphql-tag';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { ValidationErrorContext } from 'lane-shared/contexts';
import {
  arrayReorder,
  getValidationMessages,
  pause,
} from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { parseDate } from 'lane-shared/helpers/dates';
import {
  FloorMapsGenerationStatusEnum,
  FloorMapsSettingsType,
} from 'lane-shared/helpers/integrations/FloorMaps/definition';
import FloorMap, {
  FloorMapType,
} from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';
import { byOrder } from 'lane-shared/helpers/sort';

import {
  ChannelIntegration,
  ChannelIntegrationEditorProps,
} from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';
import FloorMapFloor from 'components/integrations/FloorMaps/FloorMapFloor';
import { H4, H5, S } from 'components/typography';

import Alert, { AlertType } from '../../lds/Alert';

import styles from './FloorMaps.scss';

const generationStatusQuery = gql`
  query generationStatusQuery($channelIntegrationId: UUID!) {
    channelIntegrationFloorMapGenerationStatus(
      channelIntegrationId: $channelIntegrationId
    ) {
      _id
      status
      percentComplete
    }
  }
`;

const generateMutation = gql`
  mutation generateFloorMaps(
    $channelIntegrationId: UUID!
    $toRestart: Boolean
  ) {
    generateFloorMapChannelIntegration(
      channelIntegrationId: $channelIntegrationId
      toRestart: $toRestart
    )
  }
`;

function isRegenerationNeeded(
  channelIntegration: ChannelIntegration<FloorMapsSettingsType>
) {
  const updatedAt = parseDate(channelIntegration._updated);
  const generationStartedAt = parseDate(
    channelIntegration.settings.generationStartedAt
  );
  const generationFinishedAt = parseDate(
    channelIntegration.settings.generationFinishedAt
  );

  // Possible cases:
  // (u - updated timestamp, s - generation started timestamp, f - generation finished)
  // u > s > f -> restart - generation finished (prev), started, settings updated
  // s > u > f -> wait - generation finished (prev), settings updated, generation started
  // s > f > u -> wait - settings updated (-2 cycles), generation finished (prev cycle), generation started
  // u > f > s -> new - generation is started, finished and settings updated
  // f > u > s -> new - generation is started, settings updated, generation finished
  // f > s > u -> new - settings updated, generation started and finished
  return Boolean(
    updatedAt &&
      generationStartedAt &&
      updatedAt > generationStartedAt &&
      (!generationFinishedAt || generationStartedAt > generationFinishedAt)
  );
}

export default function FloorMaps({
  channelIntegration,
  onUpdateChannelIntegration,
  forCreate,
}: ChannelIntegrationEditorProps<FloorMapsSettingsType>) {
  const errors = useContext(ValidationErrorContext);
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [generateFloorMaps] = useMutation(generateMutation);
  const [generationProgress, setGenerationProgress] = useState<
    number | undefined
  >();

  const settings = channelIntegration?.settings;
  const floorMaps = settings?.floorMaps.sort(byOrder) || [];

  function addFloor() {
    const floors = settings?.floorMaps || [];

    onUpdateChannelIntegration({
      settings: {
        ...settings,
        floorMaps: [...floors, FloorMap.default],
      },
    });
  }

  async function removeFloor(floor: FloorMapType) {
    try {
      await window.Alert.confirm({
        title: t(`web.admin.channel.integrations.floormaps.floor.delete.title`),
        message: t(
          `web.admin.channel.integrations.floormaps.floor.delete.description`
        ),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    onUpdateChannelIntegration({
      settings: {
        ...settings,
        floorMaps: [
          ...settings.floorMaps.filter(({ _id }) => _id !== floor._id),
        ],
      },
    });
  }

  async function checkGenerationStatus() {
    let doLoop = true;

    while (doLoop) {
      try {
        const { data } = await getClient().query({
          query: generationStatusQuery,
          fetchPolicy: 'network-only',
          variables: {
            channelIntegrationId: channelIntegration?._id,
          },
        });

        const generationStatus =
          data.channelIntegrationFloorMapGenerationStatus;

        if (
          ![
            FloorMapsGenerationStatusEnum.Processing,
            FloorMapsGenerationStatusEnum.Created,
            FloorMapsGenerationStatusEnum.Restart,
          ].includes(generationStatus.status)
        ) {
          doLoop = false;
          setGenerationProgress(undefined);
        } else {
          setGenerationProgress(generationStatus.percentComplete);
        }

        await pause(5000);
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        doLoop = false;
      }
    }
  }

  async function generateHandler() {
    try {
      await window.Alert.confirm({
        title: t`web.admin.channel.integrations.floormaps.floor.generate.title`,
        message: t`web.admin.channel.integrations.floormaps.floor.generate.description`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    setIsGenerating(true);

    try {
      const toRestart = isRegenerationNeeded(channelIntegration);
      const { data } = await generateFloorMaps({
        variables: {
          channelIntegrationId: channelIntegration?._id,
          toRestart,
        },
      });

      if (!data.generateFloorMapChannelIntegration) {
        await window.Alert.alert({
          title: t`web.admin.channel.integrations.floormaps.floor.generate.loading.title`,
          message: t`web.admin.channel.integrations.floormaps.floor.generate.loading.description`,
        });
      } else {
        window.Toast.show(<p>Generating!</p>);
        checkGenerationStatus();
      }
    } catch (error) {
      await window.Alert.alert({
        title: t`web.admin.channel.integrations.floormaps.floor.generate.error.title`,
        message: t`web.admin.channel.integrations.floormaps.floor.generate.error.description`,
        error,
      });
    } finally {
      setIsGenerating(false);
    }
  }

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    arrayReorder(
      settings.floorMaps,
      result.source.index,
      result.destination.index
    );

    onUpdateChannelIntegration({
      settings: {
        ...settings,
        floorMaps: [...settings.floorMaps],
      },
    });
  }

  function updateFloor(update: Partial<FloorMapType>) {
    const ix = settings.floorMaps.findIndex(({ _id }) => _id === update._id);

    settings.floorMaps[ix] = { ...settings.floorMaps[ix], ...update };

    onUpdateChannelIntegration({
      settings: {
        ...settings,
        floorMaps: [...settings.floorMaps],
      },
    });
  }

  useEffect(() => {
    floorMaps.forEach((_, i) => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ValidationErrorContextType' is n... Remove this comment to see the full error message
      if (getValidationMessages(errors, `floorMaps[${i}]`)?.length) {
        setExpandedIndex(i);
      }
    });
  }, [errors]);

  useEffect(() => {
    if (generationProgress === undefined) {
      window.Alert.hide();

      return;
    }

    window.Alert.loading({
      title: t`web.admin.channel.integrations.floormaps.floor.generate.loading`,
      children: (
        <progress
          style={{ width: '100%' }}
          value={generationProgress}
          max={100}
        />
      ),
    });
  }, [generationProgress]);

  if (!settings) {
    return null;
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ValidationErrorContextType' is n... Remove this comment to see the full error message
  const nameErrors = getValidationMessages(errors, 'name');

  return (
    <div className={styles.FloorMaps}>
      <div className={styles.menu}>
        <div className={styles.header}>
          <H4 mb={2}>{t('web.admin.channel.integrations.floormaps.title')}</H4>

          {!forCreate && (
            <Button
              loading={isGenerating}
              variant="contained"
              onClick={generateHandler}
              className={styles.generateButton}
            >
              {t('web.admin.channel.integrations.floormaps.generate')}
            </Button>
          )}
        </div>

        {nameErrors &&
          nameErrors.map(error => (
            <Alert key={error} type={AlertType.error} fullWidth>
              {t(error)}
            </Alert>
          ))}

        <S className={styles.subTitle}>
          {t('web.admin.channel.integrations.floormaps.subtitle')}
        </S>
      </div>

      <Input
        label={t('web.admin.channel.integrations.floormaps.title.add')}
        placeholder={t(
          'web.admin.channel.integrations.floormaps.title.placeholder'
        )}
        onChange={name =>
          onUpdateChannelIntegration({ settings: { ...settings, name } })
        }
        value={channelIntegration.settings.name}
      />

      {!forCreate && (
        <>
          <hr className={styles.divider} />

          <H5 mb={4}>
            {t('web.admin.channel.integrations.floormaps.floors.title')}
          </H5>

          {!floorMaps.length && (
            <Button
              variant="outlined-light"
              className={styles.addButton}
              onClick={addFloor}
              labelClassName={styles.addButtonLabel}
            >
              <Icon
                name="plus"
                type="far"
                className={styles.addButtonIcon}
                set={ICON_SET_FONTAWESOME}
              />{' '}
              {t('web.admin.channel.integrations.floormaps.floors.add')}
            </Button>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="options">
              {provided => (
                <ul className={styles.floorMaps} ref={provided.innerRef}>
                  {floorMaps.map((floor: FloorMapType, ix) => (
                    <Draggable
                      key={floor._id}
                      draggableId={floor._id}
                      index={ix}
                    >
                      {(provided, snapshot) => (
                        <li
                          key={floor._id}
                          ref={provided.innerRef}
                          className={styles.listItem}
                          data-is-dragging={snapshot.isDragging}
                          {...provided.draggableProps}
                        >
                          <FloorMapFloor
                            className={styles.floorMap}
                            channelIntegration={channelIntegration}
                            floor={floor}
                            errors={getValidationMessages(
                              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ValidationErrorContextType' is n... Remove this comment to see the full error message
                              errors,
                              `floorMaps[${ix}]`
                            )}
                            isExpanded={expandedIndex === ix}
                            onExpandToggle={() =>
                              setExpandedIndex(ix === expandedIndex ? null : ix)
                            }
                            dragHandleProps={provided.dragHandleProps!}
                            onRemoveFloor={removeFloor}
                            onUpdateFloor={updateFloor}
                          />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}

      {!!floorMaps.length && (
        <button className={styles.alternativeAltButton} onClick={addFloor}>
          {t('web.admin.channel.integrations.floormaps.floors.empty.add')}
        </button>
      )}
    </div>
  );
}
