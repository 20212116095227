import { useContext } from 'react';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { useFlag } from 'lane-shared/hooks';
import { tenantReports, propertyReports } from '../insights/constants';
import { FeatureFlag } from 'constants-flags';
import ChannelAdminContext from '../ChannelAdminContext';

export function useChannelInsightReports() {
  const { channel } = useContext(ChannelAdminContext);

  const tenantAnalyticReportsFlag = useFlag(
    FeatureFlag.AceTenantAnalyticReports,
    false
  );

  const isTenantChannel = channel?.type !== ChannelTypeEnum.Property;

  const availableReports =
    tenantAnalyticReportsFlag && isTenantChannel
      ? tenantReports
      : propertyReports;

  return availableReports;
}
