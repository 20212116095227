import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { imageUrl } from 'lane-shared/helpers/formatters';
import useOnboardingV2Enabled from 'lane-shared/hooks/useOnboardingV2Enabled';

import WhitelabeledLogo from 'components/general/WhitelabeledLogo';
import { M, H3 } from 'components/typography';

import buildingPurple from 'static/img/building-purple.svg';

import styles from './WelcomeTemplate.scss';
import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';

type Props = {
  children: React.ReactNode;
  portalSize?: 'large' | 'small'; // checks if component should take 1/3 or 2/3 ratio.
  slide?: {
    image?: string;
    title: string;
    subTitle: string;
  };
};

export default function WelcomeTemplate({
  portalSize = 'large',
  slide,
  children,
}: Props) {
  const { t } = useTranslation();
  const isOnboardingV2 = useOnboardingV2Enabled();
  const whitelabel = useWhitelabelByIndexHTML();

  const isCustomBackgroundImage = Boolean(whitelabel?.profile?.backgroundImage);
  const image =
    imageUrl(whitelabel?.profile?.backgroundImage) ||
    slide?.image ||
    buildingPurple;

  return (
    <div className={styles.WelcomePage}>
      <div
        className={cx(
          styles.WelcomeBody,
          isCustomBackgroundImage && styles.fullSizeImage
        )}
        style={
          isCustomBackgroundImage ? { backgroundImage: `url(${image})` } : {}
        }
      >
        <Link to="/">
          <WhitelabeledLogo />
        </Link>
        {!isCustomBackgroundImage && (
          <div className={styles.welcomeSlide}>
            <img src={image} alt="office building" />
            {!isOnboardingV2 && slide?.title && slide?.subTitle && (
              <div>
                <H3 mb={6}>{t(slide.title)}</H3>
                <M variant="secondary">{t(slide.subTitle)}</M>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={cx(
          styles.WelcomeComponent,
          portalSize === 'small' && styles.minor
        )}
      >
        {children}
      </div>
    </div>
  );
}
