import React from 'react';

import { Icon } from 'design-system-web';
import { useIsAdminView, useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { EntityTypeEnum, useEntityInvoiceInfo } from 'react-hooks';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';
import { useBillingAndPaymentEuComplianceEnabled } from 'lane-shared/domains/billingPayments/hooks/useBillingAndPaymentEuComplianceEnabled';
import {
  currencyFromJSON,
  Invoice,
  ExternalPayerType,
  Transaction,
} from 'lane-shared/domains/billingPayments/types';
import { CurrencyFormatMap } from 'lane-shared/domains/billingPayments/constants';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';

import { dates } from 'lane-shared/helpers/constants';
import {
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_SOLID,
} from 'lane-shared/helpers/constants/icons';
import {
  dateFormatter,
  currencyFormatter,
} from 'lane-shared/helpers/formatters';

import { Button } from 'components/general';

import { H3, H4, H5, M } from 'components/typography';

import styles from './styles.scss';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import { EMPTY_ADDRESS } from 'lane-shared/types/constants';

const InfoSection = ({ title, value }: { title: string; value: string }) => {
  return (
    <span className={styles.group}>
      <H5 className={styles.title}>{title}</H5>
      <M>{value}</M>
    </span>
  );
};

export function Info({
  invoice,
  isPaid,
  transaction,
}: {
  invoice: Invoice;
  isPaid: boolean;
  transaction?: Transaction | null;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const isEuComplianceEnabled = useBillingAndPaymentEuComplianceEnabled();
  const { channel } = useChannelAdminContext();

  const [isAdminView] = useIsAdminView();

  const { taxDetails } = useBillingPaymentSettingsForChannel({
    channelId: invoice.groupId,
  });

  const companyPayer =
    invoice.externalPayerType ===
    ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY;
  const entityType = companyPayer
    ? EntityTypeEnum.COMPANY
    : EntityTypeEnum.USER;
  const { info: companyInfo } = useEntityInvoiceInfo(
    invoice.groupId,
    EntityTypeEnum.COMPANY
  );
  const { info: payerInfo } = useEntityInvoiceInfo(
    invoice.externalPayerId,
    entityType
  );

  const currencyCode = CurrencyFormatMap[currencyFromJSON(invoice.currency)];
  const formatCurrency = currencyFormatter({
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  });
  const businessNumberDetails = `${taxDetails?.businessTaxName}: ${taxDetails?.businessTaxNumber}`;
  const taxNumberDetails = `${taxDetails?.taxDisplayName}: ${taxDetails?.taxNumber}`;
  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';
  const transactionBillingAddress = addressFormatter(
    {
      ...EMPTY_ADDRESS,
      city: transaction?.billingAddress?.city || '',
      state: transaction?.billingAddress?.state || '',
      country: transaction?.billingAddress?.country || '',
    },
    ['city', 'state', 'country']
  );

  const companyAddress = addressFormatter(companyInfo.address, [
    'city',
    'state',
    'country',
  ]);

  const companyPayerAddress = addressFormatter(payerInfo.address, [
    'city',
    'state',
    'country',
  ]);

  return (
    <div className={styles.invoiceDetailInfoSection}>
      <H4>{t('abp.invoice.details.invoiceDetails')}</H4>
      <div className={styles.invoiceDetailsSection}>
        <div>
          <InfoSection
            title={t('abp.invoice.details.id')}
            value={invoice.displayId}
          />
          <InfoSection
            title={t('abp.invoice.details.dateIssued')}
            value={
              invoice.invoiceDate
                ? dateFormatter(
                    invoice.invoiceDate,
                    dates.LONG_MONTH_DATE_YEAR,
                    timeZone
                  )
                : '-'
            }
          />
          <InfoSection
            title={t('abp.invoice.details.serviceMonth')}
            value={
              invoice.invoiceDate
                ? dateFormatter(
                    invoice.invoiceDate,
                    dates.LONG_MONTH_YEAR,
                    timeZone
                  )
                : '-'
            }
          />
          <span className={styles.group}>
            <H5 className={styles.title}>
              {t('abp.invoice.details.billFrom')}
            </H5>
            <M>{companyInfo.name}</M>
            <div className={styles.address}>
              {companyInfo.address.street1 && (
                <M>{companyInfo.address.street1}</M>
              )}
              {companyInfo.address.street2 && (
                <M>{companyInfo.address.street2}</M>
              )}
              <M>{companyAddress}</M>
              <M>{companyInfo.address.code}</M>
              {taxDetails?.businessTaxName && taxDetails?.businessTaxNumber && (
                <M>{businessNumberDetails}</M>
              )}
              {isEuComplianceEnabled &&
                taxDetails?.taxDisplayName &&
                taxDetails?.taxNumber && <M>{taxNumberDetails}</M>}
            </div>
          </span>
        </div>
        <div>
          <InfoSection
            title={t('abp.invoice.details.amountDue')}
            value={`${currencyCode} ${formatCurrency(invoice.amount)}`}
          />
          <InfoSection
            title={t('abp.invoice.details.dueDate')}
            value={
              invoice.dueDate
                ? dateFormatter(
                    invoice.dueDate,
                    dates.LONG_MONTH_DATE_YEAR,
                    timeZone
                  )
                : '-'
            }
          />
          <span className={styles.group}>
            <H5 className={styles.title}>{t('abp.invoice.details.billTo')}</H5>
            <M>{payerInfo.name}</M>
            {companyPayer ? (
              <div className={styles.address}>
                <M>{companyPayerAddress}</M>
                <M>{payerInfo.address.code}</M>
              </div>
            ) : (
              <M>{payerInfo.email}</M>
            )}
            {transaction && isEuComplianceEnabled && (
              <>
                {transaction.billingAddress?.addressLine1 && (
                  <M>{transaction.billingAddress.addressLine1}</M>
                )}
                {transaction.billingAddress?.addressLine2 && (
                  <M>{transaction.billingAddress.addressLine2}</M>
                )}
                {transactionBillingAddress && (
                  <M>{transactionBillingAddress}</M>
                )}
                {transaction?.billingAddress?.postalCode && (
                  <M>{transaction.billingAddress.postalCode}</M>
                )}
                {transaction.companyName && <M>{transaction.companyName}</M>}
                {transaction.taxId && <M>{transaction.taxId}</M>}
              </>
            )}
          </span>
        </div>
        <div className={styles.paymentSection}>
          {isPaid ? (
            <>
              <Icon
                name="check-circle"
                className={styles.paidIcon}
                set={ICON_SET_FONTAWESOME}
                type={FONT_AWESOME_SOLID}
                size="large"
              />
              <M className={styles.status}>
                {t('abp.invoiceList.status.paid')}
              </M>
            </>
          ) : (
            <M>{t('abp.invoice.details.amountDue')}</M>
          )}
          <span className={styles.currency}>
            <H3>{`${currencyCode} ${formatCurrency(invoice.amount)}`}</H3>
          </span>
          {!isPaid && !isAdminView && (
            <Button
              className={styles.button}
              variant="contained"
              onClick={() => {
                history.push(routes.meInvoicePayment, { invoice });
              }}
            >
              {t('abp.invoice.details.payInvoice')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
