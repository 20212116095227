import { Workflow, WorkflowTypeEnum } from 'constants-workflows';
import { useWorkflows as useWorkflowsV1 } from 'lane-shared/hooks';
import { useWorkflows as useWorkflowsV2 } from './useWorkflowsV2';
import { RestoreDefaultWorkflowsParams } from 'lane-shared/hooks/useWorkflows';

// TODO: TS-9660 remove this hook and use useWorkflowsV2 directly
export const useWorkflows = (
  channelId?: string,
  type?: WorkflowTypeEnum,
  crudStrategyV2Enabled: boolean = false
) => {
  const {
    loading: _loading,
    workflows: _workflows,
    addWorkflow: _addWorkflow,
    saveWorkflows: _saveWorkflows,
    editWorkflow: _editWorkflow,
    removeWorkflow: _removeWorkflow,
    cloneWorkflow: _cloneWorkflow,
    restoreDefaultWorkflows: _restoreDefaultWorkflows,
  } = useWorkflowsV1(channelId, type);
  const {
    loading,
    workflows,
    addWorkflow,
    saveWorkflow,
    editWorkflow,
    deleteWorkflow,
    cloneWorkflow,
    restoreDefaultWorkflows,
    reorderWorkflows,
  } = useWorkflowsV2(channelId, type);

  const handleAddWorkflow = (workflow: Workflow) => {
    if (crudStrategyV2Enabled) {
      return addWorkflow(workflow);
    }

    return _addWorkflow(workflow);
  };

  const handleSaveWorkflows = (
    workflows: Workflow[],
    workflowToSave?: Workflow
  ) => {
    if (crudStrategyV2Enabled) {
      if (!workflowToSave) {
        throw new Error(
          'parameter "workflowToSave" is required when using CRUD strategy V2'
        );
      }

      return saveWorkflow(workflowToSave);
    }

    return _saveWorkflows(workflows);
  };

  const handleEditWorkflow = (index: number, workflow: Workflow) => {
    if (crudStrategyV2Enabled) {
      return editWorkflow(workflow);
    }

    return _editWorkflow(index, workflow);
  };

  const handleRemoveWorkflow = (workflowId: string) => {
    if (crudStrategyV2Enabled) {
      return deleteWorkflow(workflowId);
    }

    return _removeWorkflow(workflowId);
  };

  const handleCloneWorkflow = (workflowIndex: string, userId: string) => {
    if (crudStrategyV2Enabled) {
      return cloneWorkflow(workflowIndex, userId);
    }

    return _cloneWorkflow(workflowIndex, userId);
  };

  const handleRestoreDefaultWorkflows = (
    params: RestoreDefaultWorkflowsParams
  ) => {
    if (crudStrategyV2Enabled) {
      return restoreDefaultWorkflows(params);
    }

    return _restoreDefaultWorkflows(params);
  };

  const handleReorderWorkflows = (workflows: Workflow[]) => {
    if (crudStrategyV2Enabled) {
      return reorderWorkflows(workflows);
    }

    return _saveWorkflows(workflows);
  };

  return {
    loading: crudStrategyV2Enabled ? loading : _loading,
    workflows: crudStrategyV2Enabled ? workflows : _workflows,

    addWorkflow: handleAddWorkflow,
    saveWorkflows: handleSaveWorkflows,
    editWorkflow: handleEditWorkflow,
    removeWorkflow: handleRemoveWorkflow,
    cloneWorkflow: handleCloneWorkflow,
    restoreDefaultWorkflows: handleRestoreDefaultWorkflows,
    reorderWorkflows: handleReorderWorkflows,
  };
};
