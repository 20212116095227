import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ChannelAdminContext from 'pages/portal/admin/channel/ChannelAdminContext';
import { UserDataContext } from 'lane-shared/contexts';

import { H3 } from 'design-system-web';
import { AdminPage, TabStrip } from 'components';
import { TabItem } from 'components/general/TabStrip';
import { BreadCrumbs } from 'components/lds';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import routes from 'lane-shared/config/routes';
import { NotificationsTab } from './tabs/notifications/NotificationsTab';

import styles from './settings.scss';

enum Tabs {
  Notifications = 'notifications',
}

export function PreventiveMaintenanceSettings() {
  const { t } = useTranslation();

  const { channel } = useContext(ChannelAdminContext);
  const { user } = useContext(UserDataContext);

  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Notifications);

  const isConfigurableNotificationsEnabled = useFlag(
    FeatureFlag.WorkOrdersConfigurableNotifications,
    false
  );

  const tabs = [];

  if (isConfigurableNotificationsEnabled) {
    tabs.push({
      value: Tabs.Notifications,
      label: `web.admin.serviceRequest.settings.tabs.notifications`,
    });
  }

  const handleTabChange = (tab: TabItem) => {
    setSelectedTab(tab.value as Tabs);
  };

  const renderTabs = () => {
    if (!channel || !user) {
      return null;
    }

    switch (selectedTab) {
      case Tabs.Notifications:
        return <NotificationsTab channel={channel} user={user} />;
      default:
        return null;
    }
  };

  if (!channel || !user) {
    return null;
  }

  return (
    <AdminPage>
      <BreadCrumbs
        links={[
          {
            label: t`web.admin.workOrder.preventiveMaintenance.title`,
            url: routes.channelAdminWorkOrdersPM.replace(':id', channel._id),
          },
          {
            label: t`web.admin.workOrder.preventiveMaintenance.taskSettings`,
          },
        ]}
      />
      <H3
        mt={6}
      >{t`web.admin.workOrder.preventiveMaintenance.taskSettings`}</H3>
      <TabStrip
        tabs={tabs}
        selected={{ value: Tabs.Notifications }}
        onSelectTab={handleTabChange}
        className={styles.tabs}
      />
      {renderTabs()}
    </AdminPage>
  );
}
