import React from 'react';

import { Page, View, Text, Svg, Line, Path } from '@react-pdf/renderer';
import {
  ServiceRequestAction,
  ServiceRequestAuditLog,
  TaskAction,
  TaskAuditLog,
} from 'graphql-query-contracts';
import { styles } from './ActivityLogPDF.styles';
import { dateFormatter, simpleDate } from 'lane-shared/helpers/formatters';
import {
  LONG_MONTH_DATE_YEAR,
  SHORT_TIME_WITH_TZ,
} from 'lane-shared/helpers/constants/dates';

type Props = {
  userFriendlyID: string;
  createdAt: Date;
  createdBy: string;
  auditLogs?: ServiceRequestAuditLog[] | TaskAuditLog[];
  labels: { [key: string]: string };
  t: (val: string, opts?: any) => string;
};

const MAX_CHANGE_LENGTH = 20;

const ARROW = (
  <Svg height={10} width={10} viewBox="-24 0 12 36">
    <Path
      transform="rotate(90)"
      strokeWidth={1}
      stroke="#72777E"
      fill="#72777E"
      d="M27.66,15.61,18,6,8.34,15.61A1,1,0,1,0,9.75,17L17,9.81V28.94a1,1,0,1,0,2,0V9.81L26.25,17a1,1,0,0,0,1.41-1.42Z"
    />
  </Svg>
);

const renderActivityLogChange = (
  action: ServiceRequestAction | TaskAction,
  key: string
) => {
  if (!action) return null;

  if (action.field === 'equipment') {
    const equipment = action.currentState?.split(',');

    if (!equipment) return null;

    return (
      <View key={key} style={styles.list}>
        {equipment.map(item => (
          <View key={item} style={styles.listItem}>
            <Text style={{ marginRight: 6 }}>•</Text>
            <Text style={styles.changeText}>{item}</Text>
          </View>
        ))}
      </View>
    );
  }

  if (
    (action.previousState && action.previousState.length > MAX_CHANGE_LENGTH) ||
    (action.currentState && action.currentState.length > MAX_CHANGE_LENGTH)
  ) {
    return (
      <View key={key} wrap>
        <Text style={styles.changeText}>{action.previousState}</Text>
        {ARROW}
        <Text style={styles.changeText}>{action.currentState}</Text>
      </View>
    );
  }

  return (
    <View key={key} style={styles.change}>
      <Text style={styles.changeText}>{action.previousState}</Text>
      {ARROW}
      <Text style={styles.changeText}>{action.currentState}</Text>
    </View>
  );
};

const renderActivityLogAction = (
  action: ServiceRequestAction | TaskAction,
  key: string
) => {
  return (
    <View style={styles.actionItem} key={key}>
      <Text>{action.title}</Text>
      {renderActivityLogChange(action, `${action.field}${action.title}`)}
    </View>
  );
};

const renderActivityLogRow = (
  auditLog: ServiceRequestAuditLog | TaskAuditLog,
  labels: { [key: string]: string },
  key: string
) => {
  return (
    <View key={key} wrap>
      <View style={styles.actionRow}>
        <View style={styles.nameCell}>
          <Text style={styles.name}>
            {auditLog.userName ? auditLog.userName : labels.automatic}
          </Text>
        </View>
        <View style={styles.actionCell}>
          {auditLog.actions?.map(action =>
            renderActivityLogAction(action, `${action.field}${action.title}`)
          )}
        </View>
        <View style={styles.dateCell}>
          <Text>{dateFormatter(auditLog.date, LONG_MONTH_DATE_YEAR)}</Text>
          <Text style={styles.time}>
            {dateFormatter(auditLog.date, SHORT_TIME_WITH_TZ)}
          </Text>
        </View>
      </View>
      <Svg height="2" width="530" style={styles.line}>
        <Line
          x1="1100"
          y1="0"
          x2="0"
          y2="0"
          strokeWidth={1}
          stroke="rgb(128,128,128)"
        />
      </Svg>
    </View>
  );
};

export const ActivityLogPDF = ({
  userFriendlyID,
  createdAt,
  createdBy,
  auditLogs,
  labels,
  t,
}: Props) => {
  if (!auditLogs) return null;

  return (
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={styles.header}>{userFriendlyID}</Text>
        <Text style={styles.subheadings}>
          <Text>
            {labels.created} {simpleDate(createdAt)} {labels.by} {createdBy}
          </Text>
        </Text>
        <Svg height="2" width="530" style={styles.line}>
          <Line
            x1="1100"
            y1="0"
            x2="0"
            y2="0"
            strokeWidth={1}
            stroke="rgb(128,128,128)"
          />
        </Svg>
        <Text style={styles.activtyText}>{labels.activity}</Text>
      </View>
      {auditLogs
        .slice()
        .reverse()
        .map(auditLog => renderActivityLogRow(auditLog, labels, auditLog.date))}
      {labels.pageCounter ? (
        <View style={styles.pageCounter} fixed>
          <Text>
            {new Date().toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            })}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              t(`${labels.pageCounter}`, {pageNumber, totalPages})
            }
          />
        </View>
      ): (
        <Text
          fixed
          style={styles.pageNumber}
          render={({ pageNumber }) => `${labels.pageNumber} ${pageNumber}`}
        />
      )}
    </Page>
  );
};
