import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { AttachmentResponse } from 'lane-shared/types/attachment';

import { Button, AttachmentThumbnail, FileInput, Flex } from 'components';
import { M } from 'components/typography';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import styles from './index.scss';

export type SelectFileReturnType = ((
  files: FileReturnType[]
) => Promise<void>) &
  (() => null);
export type AttachmentData =
  | AttachmentResponse
  | { file: File; thumbnailSignedUrl: string };

interface IPMAttachmentsProps {
  attachmentFiles: AttachmentData[];
  onAddAttachments: SelectFileReturnType;
  onRemoveAttachment: (index: string) => void;
}

export function PMAttachments({
  attachmentFiles,
  onAddAttachments,
  onRemoveAttachment,
}: IPMAttachmentsProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" className={styles.attachments} gap={4}>
      <FileInput
        accept="*/*"
        type={FileReturnTypeEnum.File}
        onFilesSelected={onAddAttachments}
        enableMultiUpload
      >
        {/* TODO: this should not be recreating the styles of the AddAttachment component */}
        <Button
          style={{ width: '50%' }}
          className={styles.buttonAttachments}
          fullWidth={false}
        >
          <Flex>
            <Icon
              className={styles.emptyImageIcon}
              set={ICON_SET_FONTAWESOME}
              name="paperclip"
              type="fal"
            />
            <M className={styles.buttonAttachmentsLabel}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.schedule.attachments.fileUploadTitle'
              )}
            </M>
          </Flex>
        </Button>
      </FileInput>
      <Flex className={styles.attachmentThumbnail} gap={4}>
        {attachmentFiles.map((attachment, index) => (
          <AttachmentThumbnail
            key={index}
            attachment={{
              ...(attachment as AttachmentResponse),
              id: String(index),
            }}
            deleteAttachmentHandler={onRemoveAttachment}
          />
        ))}
      </Flex>
    </Flex>
  );
}
