import React, { useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';

import { Table, getPageSizeFromQueryString } from 'design-system-web';
import { useQueryString } from 'hooks';
import ChannelAdminContext from '../ChannelAdminContext';
import { listRecurringChargesForChannelsQuery } from './helpers/chargesInvoiceQueries';
import { useGetRecurringChargesTableColumns } from './helpers/useGetRecurringChargesTableColumns';
import {
  useGetRecurringChargesTableFilters,
  RecurringChargeQueryString,
  DEFAULT_FILTER_PARAMS,
} from './helpers/useGetRecurringChargesTableFilters';

import styles from './styles.scss';

export function RecurringChargesTable() {
  const { channel } = useContext(ChannelAdminContext);
  const [loadRecurringChargesList, loadRecurringChargesMetadata] = useLazyQuery(
    listRecurringChargesForChannelsQuery
  );
  const recurringChargesTableColumns = useGetRecurringChargesTableColumns(
    loadRecurringChargesMetadata.refetch!
  );
  const [queryParams, setQueryParams] =
    useQueryString<RecurringChargeQueryString>(DEFAULT_FILTER_PARAMS);
  const totalRows =
    loadRecurringChargesMetadata?.data?.accounts
      ?.listRecurringChargesForChannels?.pageInfo?.total || 0;

  useEffect(() => {
    if (channel) {
      loadRecurringChargesList({
        variables: {
          listRecurringChargesForChannelsRequest: {
            recurringChargesFilters: {
              groupIds: [convertToUUID(channel._id)],
              groupType: GroupType.GroupTypeActivateChannel,
              statuses: queryParams?.status
                ? queryParams.status.split(',')
                : [],
            },
            pagination: {
              start:
                ((queryParams?.page || 0) as number) *
                getPageSizeFromQueryString(queryParams?.pageSize),
              perPage: getPageSizeFromQueryString(queryParams?.pageSize),
            },
            ...(queryParams?.sortBy && queryParams?.sortDirection
              ? {
                  sortBy: {
                    key: queryParams.sortBy,
                    dir: queryParams.sortDirection,
                  },
                }
              : {}),
          },
        },
      });
    }
  }, [
    channel,
    loadRecurringChargesList,
    queryParams.page,
    queryParams.pageSize,
    queryParams.status,
    queryParams.sortBy,
    queryParams.sortDirection,
  ]);

  useEffect(() => {
    if (totalRows) {
      setQueryParams({
        total: totalRows,
      });
    }
  }, [totalRows]);

  return (
    <div className={styles.recurringChargesTable}>
      <Table
        columns={recurringChargesTableColumns}
        data={
          loadRecurringChargesMetadata.data
            ? loadRecurringChargesMetadata.data.accounts
                .listRecurringChargesForChannels.recurringCharges
            : []
        }
        isLoading={loadRecurringChargesMetadata.loading}
        pagination="server"
        queryStringsEnabled
        totalRows={Number(queryParams.total)}
        filters={useGetRecurringChargesTableFilters()}
      />
    </div>
  );
}
