import React, { useContext } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { ProfileFragment } from 'lane-shared/graphql/fragments';
import { byName } from 'lane-shared/helpers/sort';
import { collapseMemberChannels } from 'lane-shared/helpers/user';
import { UserType } from 'lane-shared/types/User';

import UserChannelGroupRoleListView from 'components/lane/UserChannelGroupRoleListView';
import { H3 } from 'components/typography';

import { PortalManagementUserEditType } from '../types';

import styles from './PortalManagementUserMemberships.scss';
import { PrimaryLocation } from 'pages/portal/admin/channel/member/components/PrimaryLocation';

const membershipQuery = gql`
  ${ProfileFragment}

  query getMembership($id: UUID!) {
    memberGroupRoles(_id: $id) {
      _id
      _created
      name
      description
      isPublic
      expires
      groupRole {
        _id
        name
        isPublic
        channel {
          _id
          name
          slug
          type
          isPublic
          isSub
          parent {
            _id
          }
          profile {
            ...ProfileFragment
          }
        }
      }
    }
  }
`;

export default function PortalManagementUserMemberships({
  user,
}: {
  user: PortalManagementUserEditType;
}) {
  const { user: adminUser } = useContext(UserDataContext);
  const { data, refetch } = useQuery(membershipQuery, {
    client: getClient(),
    fetchPolicy: 'network-only',
    skip: !user._id,
    variables: {
      id: user._id,
    },
  });

  const channels = collapseMemberChannels(data?.memberGroupRoles || []).sort(
    byName
  );

  return (
    <div className={styles.PortalManagementUserMemberships}>
      <H3>Team Memberships</H3>
      <div className={styles.primaryLocation}>
        <PrimaryLocation
          data={{
            userId: user._id,
            primaryLocation: {
              _id: (user as unknown as UserType).primaryLocation?._id,
            },
            channels,
          }}
        />
      </div>
      {channels.map(channel => (
        <UserChannelGroupRoleListView
          key={channel._id}
          channel={channel}
          user={user as unknown as UserType}
          adminUser={adminUser}
          refetch={refetch}
        />
      ))}
    </div>
  );
}
