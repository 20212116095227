import {
  FilterType,
  CustomFilterType,
  NativeFilterTypes,
} from 'design-system-web';
import {
  RECURRING_CHARGE_STATUS,
  RecurrenceStatus,
} from 'lane-shared/domains/billingPayments/types';
import { useTranslation } from 'react-i18next';

const PER_PAGE = 100;

export const DEFAULT_FILTER_PARAMS = {
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
};

export type RecurringChargeQueryString = {
  status: string;
  page?: string;
  pageSize?: string;
  total?: string;
  sortBy?: string;
  sortDirection?: string;
};

export const useGetRecurringChargesTableFilters = (): (
  | FilterType
  | CustomFilterType<any>
)[] => {
  const { t } = useTranslation();

  const tableFilters: (FilterType | CustomFilterType<any>)[] = [
    {
      key: 'status',
      label: t('abp.subscription.filters.status'),
      type: NativeFilterTypes.Multiselect,
      options: getFilterableStatuses(),
      isPromoted: true,
    },
  ];

  return tableFilters;
};

const getFilterableStatuses = (): { label: string; value: string }[] => {
  const statuses = [
    {
      label: RECURRING_CHARGE_STATUS.ACTIVE,
      value: RecurrenceStatus.Active,
    },
    {
      label: RECURRING_CHARGE_STATUS.CANCELLED,
      value: RecurrenceStatus.Cancelled,
    },
    {
      label: RECURRING_CHARGE_STATUS.EXPIRED,
      value: RecurrenceStatus.Expired,
    },
  ];

  return statuses;
};
