import React from 'react';

import { Icon } from 'design-system-web';
import { Chip } from 'components';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import formatDateRangeDay from 'lane-shared/helpers/formatters/dateRange/day';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import {
  SHOPIFY_BURST_MAX_RANGE_SIZE,
  SHOPIFY_BURST_MIN_RANGE_SIZE,
} from 'lane-shared/types/integrations/ShopifyBurst';

import DropdownContextMenu from 'components/general/DropdownContextMenu';

import BurstDateSelector from './BurstDateSelector';

interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

interface Props {
  timeZone: string;
  startDate?: string;
  endDate?: string;
  onChange: (dateRange: DateRange) => void;
  unavailableDateRanges: DateRangeType[];
  minDate: Date;
  maxDate: Date;
  className?: string;
}

export default function BurstAvailableDateFilter({
  timeZone,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
  className,
  unavailableDateRanges,
}: Props) {
  const { i18n, t } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title={
        <Chip
          className={className}
          onClick={() => setOpen(!isOpen)}
          label={
            startDate && endDate
              ? formatDateRangeDay({
                  _start: startDate,
                  _end: endDate,
                  timeZone,
                  i18n,
                })
              : t('Date')
          }
          selected={Boolean(endDate)}
          deleteIcon={
            <Icon
              name="angle-down"
              set={ICON_SET_FONTAWESOME}
              onClick={() => setOpen(!isOpen)}
            />
          }
          selectedIcon={null}
        />
      }
    >
      <BurstDateSelector
        timeZone={timeZone}
        startDate={startDate}
        endDate={endDate}
        minRangeSize={SHOPIFY_BURST_MIN_RANGE_SIZE}
        maxRangeSize={SHOPIFY_BURST_MAX_RANGE_SIZE}
        unavailableDateRanges={unavailableDateRanges}
        maxDate={maxDate}
        minDate={minDate}
        isCalendarOpened={isOpen}
        onChange={interval => {
          onChange(interval);
          setOpen(false);
        }}
      />
    </DropdownContextMenu>
  );
}
