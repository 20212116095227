import React, { useState } from 'react';

import gql from 'graphql-tag';

import { LaneType } from 'common-types';
import { getAdminClient } from 'lane-shared/apollo';
import { pause } from 'lane-shared/helpers';

import Button from 'components/general/Button';
import { UserSelectorButton } from 'components/lane';
import { Modal } from 'components/lds';
import { H4 } from 'components/typography';

import { PortalManagementUserEditType } from '../types';

import styles from '../styles.scss';

const mergeUsersMutation = gql`
  mutation mergeUsers($destinationUserId: UUID!, $userId: UUID!) {
    mergeUsers(destinationUserId: $destinationUserId, userId: $userId)
  }
`;

type MergeUserButtonProps = {
  user: PortalManagementUserEditType;
};

export const MergeUserButton = ({
  user,
}: MergeUserButtonProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [isMergeOpen, setIsMergeOpen] = useState(false);
  const [
    selectedMergeUser,
    setSelectedMergeUser,
  ] = useState<LaneType.User | null>(null);

  function mergeUserHandler() {
    setLoading(true);

    const mergeUsers = async () => {
      const mergedUserIsSet = (
        user: LaneType.User | null
      ): user is LaneType.User => Boolean(user);

      if (!user || !mergedUserIsSet(selectedMergeUser)) {
        return;
      }

      try {
        await window.Alert.confirm({
          title: 'Merging users.',
          message:
            'Are you sure you want to merge these users together? This cannot be undone.',
          confirmText: 'Yes, merge',
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
        return;
      }

      try {
        await window.Alert.confirm({
          title: 'Merging users.',
          message: 'Seriously. This cannot be undone.',
          confirmText: 'Yes, merge',
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
        return;
      }

      try {
        await pause(1000);
        await getAdminClient().mutate({
          refetchQueries: ['getUser', 'getMembership'],
          mutation: mergeUsersMutation,
          variables: {
            destinationUserId: user._id,
            userId: selectedMergeUser._id,
          },
        });
      } catch (err) {
        window.Alert.alert({
          title: 'Failed',
          error: err,
        });
      }

      setIsMergeOpen(false);
      setLoading(false);
    };

    mergeUsers();
  }

  return (
    <>
      <Button loading={loading} onClick={() => setIsMergeOpen(true)}>
        Merge
      </Button>
      <Modal
        className={styles.window}
        isOpen={isMergeOpen}
        title="Merge Users"
        onClose={() => setIsMergeOpen(false)}
        actions={
          <>
            <Button
              variant="contained"
              loading={loading}
              onClick={() => setIsMergeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedMergeUser?._id}
              loading={loading}
              variant="contained"
              onClick={mergeUserHandler}
            >
              Merge User
            </Button>
          </>
        }
      >
        <H4 mb={4}>Select a user to merge into {user?.name}</H4>
        <UserSelectorButton
          includeWorkplaceMember
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          userId={selectedMergeUser?._id}
          onUserSelected={user => setSelectedMergeUser(user)}
        />
      </Modal>
    </>
  );
};
