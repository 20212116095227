import React from 'react';

import { Checkbox } from 'components';
import { useTranslation } from 'react-i18next';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { XS } from 'components/typography';

import styles from './index.scss';

const TRANSLATION_KEYS = {
  defaultLabel: 'shared.signup.form.checkboxLabel.default',
  complianceLabel: 'shared.signup.form.checkboxLabel.compliance',
  description: 'shared.signup.form.checkboxDescription',
};

interface Props {
  emailOptIn: boolean;
  onChange: () => void;
  whitelabel: WhiteLabelType;
}

export default function EmailOptIn({
  emailOptIn,
  onChange,
  whitelabel,
}: Props) {
  const { t } = useTranslation();
  const enableComplianceContact = useFlag(
    FeatureFlag.EnableComplianceContact,
    false
  );

  const complianceWithoutContact = t(TRANSLATION_KEYS.defaultLabel);
  const complianceWithContact = t(TRANSLATION_KEYS.complianceLabel, {
    complianceContact: whitelabel?.complianceContact,
  });

  const checkBoxLabel =
    enableComplianceContact && whitelabel?.complianceContact
      ? complianceWithContact
      : complianceWithoutContact;

  return (
    <>
      <Checkbox<boolean>
        className={styles.checkBox}
        name="emailOptIn"
        testId="emailOptInCheckbox"
        text={checkBoxLabel}
        selected={emailOptIn}
        onChange={onChange}
        value={emailOptIn}
        labelStyles={{
          fontSize: '0.75rem',
          fontWeight: 500,
          marginLeft: '10px',
          paddingBottom: '4px',
        }}
      />
      <div className={styles.description}>
        <XS>{t(TRANSLATION_KEYS.description)}</XS>
      </div>
    </>
  );
}
