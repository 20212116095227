import { useChannelAdminContext } from 'hooks';
import { BulkAction, RowSelectionState } from 'design-system-web';
import { useDownloadFile } from '../../../../../../hooks/useDownloadFile';
import { generatePath } from 'react-router-dom';
import { routes } from 'lane-shared/config';
import { useTranslation } from 'react-i18next';

export function useGetBulkActionsForInvoices(): BulkAction[] {
  type InvoiceBulkDownloadRequest = {
    invoiceIds: string[];
  };

  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { downloadBulkFilesFromUrl } =
    useDownloadFile<InvoiceBulkDownloadRequest>();
  const INVOICE_SELECTION_LIMIT = 50;

  return [
    {
      label: 'abp.bulkActions.downloadInvoices',
      onClick: async (rowSelection: RowSelectionState) => {
        const selectedInvoiceIds = Object.entries(rowSelection)
          .filter(([_, isSelected]) => isSelected)
          .map(([invoiceId]) => invoiceId);

        if (selectedInvoiceIds.length > INVOICE_SELECTION_LIMIT) {
          await window.Toast.show(
            t('abp.bulkActions.downloadInvoices.limitExceeded', {
              invoiceSelectionLimit: INVOICE_SELECTION_LIMIT,
              selectedInvoiceCount: selectedInvoiceIds.length,
            }),
            5000
          );
          return;
        }

        window.Alert.loading({
          title: `${t('abp.bulkActions.downloadInvoices.preparingYourZipFile')}`,
          disableCloseOnBackgroundClick: true,
        });

        try {
          const url = `${generatePath(routes.bulkDownloadInvoicePdfs, {
            channelId: channel?._id || '',
          })}`;
          await downloadBulkFilesFromUrl({
            url,
            body: { invoiceIds: selectedInvoiceIds },
          });
        } catch {
          await window.Toast.show(t('abp.bulkActions.downloadInvoices.error'));
        }

        setTimeout(() => {
          window.Alert.hide();
        }, 500);
      },
    },
  ];
}
