import React, { useState, useEffect, useContext } from 'react';

import { ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';

import { UserDataContext } from 'lane-shared/contexts';
import {
  Invoice,
  ExternalPayerType,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';

import { PageSizeType, Table } from 'design-system-web';
import ChannelAdminContext from 'pages/portal/admin/channel/ChannelAdminContext';

import Loading from 'components/general/Loading';

import { InvoiceCardView } from '../InvoiceCardView';
import {
  ListInvoicesCompanyQueryResult,
  ListInvoicesUserQueryResult,
  listInvoicesByCompaniesQuery,
  listInvoicesByUsersQuery,
} from './invoiceListQueries';
import { useGetPaymentHistoryColumns } from './useGetPaymentHistoryColumns';

import styles from './styles.scss';
import { useUserBillingAndPaymentContext } from 'lane-shared/domains/billingPayments/hooks';

const TYPE_STATUS_MAP: { [key: string]: string[] } = {
  paid: ['PAID'],
  unpaid: ['INVOICED', 'OVERDUE', 'PAYMENT_FAILED'],
};

enum EMPTY_STATE_MAP {
  paid = 'abp.routes.accountInvoices.emptyPaidInvoices',
  unpaid = 'abp.routes.accountInvoices.emptyUnpaidInvoices',
}

export type InvoiceListProps = {
  type: 'company' | 'user';
  subType: 'paid' | 'unpaid';
  renderType: 'card' | 'table';
};

type QueryDataResult =
  | ListInvoicesCompanyQueryResult
  | ListInvoicesUserQueryResult
  | undefined;

function getInvoices(data: QueryDataResult, type: InvoiceListProps['type']) {
  return type === 'user'
    ? (data as ListInvoicesUserQueryResult)?.accounts?.listInvoicesByUsers
        ?.invoices
    : (data as ListInvoicesCompanyQueryResult)?.accounts
        ?.listInvoicesByCompanies?.invoices;
}

function getPageInfo(data: QueryDataResult, type: InvoiceListProps['type']) {
  return type === 'user'
    ? (data as ListInvoicesUserQueryResult)?.accounts?.listInvoicesByUsers
        ?.pageInfo
    : (data as ListInvoicesCompanyQueryResult)?.accounts
        ?.listInvoicesByCompanies?.pageInfo;
}

export function InvoiceList({
  type,
  subType,
  renderType = 'card',
}: InvoiceListProps) {
  const { t } = useTranslation();
  const { channel } = useContext(ChannelAdminContext);
  const { user } = useContext(UserDataContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);

  const invoiceTableColumns = useGetPaymentHistoryColumns();

  const listInvoicesQuery =
    type === 'user' ? listInvoicesByUsersQuery : listInvoicesByCompaniesQuery;

  const { isInvoiceAutopayEnabled } = useUserBillingAndPaymentContext();

  const showAutopay = isInvoiceAutopayEnabled && type === 'user';

  const [loadInvoices, { data, loading, error: listInvoicesQueryError }] =
    useLazyQuery<QueryDataResult>(listInvoicesQuery);

  useEffect(() => {
    loadInvoices({
      variables: {
        listInvoicesByPayersRequest: {
          externalPayerIds: [user?._id],
          externalPayerType:
            ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER,
          groupType: GroupType.GroupTypeActivateChannel,
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
          filter: {
            statuses: TYPE_STATUS_MAP[subType],
          },
        },
      },
    });
  }, [
    user?._id,
    loadInvoices,
    subType,
    channel,
    page,
    pageSize,
    isInvoiceAutopayEnabled,
    type,
  ]);

  const invoices = getInvoices(data, type);
  const pageInfo = getPageInfo(data, type);

  return (
    <>
      {loading && <Loading />}
      {listInvoicesQueryError && (
        <ErrorMessage error={t(listInvoicesQueryError.message)} />
      )}
      {invoices?.length === 0 && (
        <div className={styles.emptyState}>{t(EMPTY_STATE_MAP[subType])}</div>
      )}
      <div className={styles.invoiceCardContainer}>
        {invoices?.length &&
          renderType === 'card' &&
          invoices.map((invoice: Invoice) => (
            <InvoiceCardView
              key={invoice.id}
              invoice={invoice}
              showAutopay={showAutopay}
            />
          ))}
      </div>
      {invoices?.length && renderType === 'table' && (
        <Table
          columns={invoiceTableColumns}
          data={invoices}
          pagination="server"
          totalRows={pageInfo?.total}
          page={page}
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
        />
      )}
    </>
  );
}
