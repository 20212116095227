import React, { useContext, useRef } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { LaneType } from 'common-types';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import {
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED,
  INTERACTION_OPEN,
} from 'constants-interactions';
import { getFriendlyInteractionDate } from 'lane-shared/helpers/content';
import { longDateTimeZone, shortAddress } from 'lane-shared/helpers/formatters';
import {
  formatEssensysAccountUrl,
  formatEssensysContactUrl,
  formatEssensysProductUrl,
} from 'lane-shared/helpers/integrations/Essensys';
import isShopifyBurstInteraction from 'lane-shared/helpers/integrations/ShopifyBurst/isShopifyBurstInteraction';
import {
  useUpdateInteraction,
  usePublicUserQuery,
  useChannelProfileQuery,
  useInteractionTimeZone,
} from 'lane-shared/hooks';
import useVisibleAndSanitizedFeatures from 'lane-shared/hooks/features/useVisibleAndSanitizedFeatures';
import useVisibleClearedFromDependenciesData from 'lane-shared/hooks/properties/useVisibleClearedFromDependenciesData';
import useVisibleClearedFromDependenciesProperties from 'lane-shared/hooks/properties/useVisibleClearedFromDependenciesProperties';
import { useAdminNotesEnabled } from 'lane-shared/hooks/useAdminNotesEnabled';
import { useUserNotesEnabled } from 'lane-shared/hooks/useUserNotesEnabled';
import { ChannelBillingDetailsType as ChannelType } from 'lane-shared/types/ChannelType';

import ObjectPropertiesRenderer from 'components/builder/properties/ObjectPropertiesRenderer';
import GuestInviteFeature from 'components/features/GuestInviteFeature';
import InfoRow from 'components/features/InfoRow';
import MenuOrderCart from 'components/features/Menu/MenuOrderCart';
import PaymentFeaturePlacePayData from 'components/features/PaymentFeaturePlacePayData';
import PaymentFeatureQuote from 'components/features/PaymentFeatureQuote';
import PaymentFeatureStripeData from 'components/features/PaymentFeatureStripeData';
import ReservableInput from 'components/features/ReservableInput';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import BurstAdminUCIEditView from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/BurstAdminUCIEditView';
import BurstReadOnlyView from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/BurstReadOnlyView';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import UserCircleListView from 'components/lane/UserCircleListView';
import { H4, H5 } from 'components/typography';

import { VisitorManagementReceipt } from '../../domains/visitorManagement/components';
import StatusDropdown from './StatusDropdown';
import { AdminNotes } from './components/AdminNotes';
import {
  AttachmentContext,
  AttachmentDispatchContext,
} from 'contexts/AttachmentContext';
import { useAttachmentDelete } from 'hooks/useAttachmentDelete';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import useIsAdminView from 'hooks/useIsAdminView';

import styles from './UCIEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  interaction: any;
  onViewHistory?: () => void;
  mode: 'history' | 'edit';
};

export default function UCIEdit({
  className,
  style,
  interaction,
  onViewHistory,
  mode = 'edit',
}: Props) {
  const isMountedRef = useRef(true);
  const { t } = useTranslation();
  const [isAdminView, channelSlug] = useIsAdminView();
  const { user } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();
  const isAdminNotesEnabled = useAdminNotesEnabled();
  const isUserNotesEnabled = useUserNotesEnabled();

  const { user: interactionUser } = usePublicUserQuery({
    userId: interaction?.user?._id,
  });

  const { filesSelectedHandler } = useAttachmentUpload({});
  const { deleteAttachmentHandler } = useAttachmentDelete({
    fetchAttachments: async () => {},
  });

  const contentDraftAttachmentsWeb = useContext(AttachmentContext);
  const attachmentDispatcher = useContext(AttachmentDispatchContext);

  const {
    editingInteraction,
    setInteractionUpdate,
    hasChanged,
    updateInteraction,
    loading,
    resetChanges,
  } = useUpdateInteraction({
    interaction,
    filesSelectedHandler,
    deleteAttachmentHandler,
    contentDraftAttachmentsWeb,
    attachmentDispatcher,
  });

  const {
    useCompanyPermissionsFeature,
    reservableFeature,
    submitOnBehalfOfFeature,
    guestInviteFeature,
    paymentFeature,
    essensysProductFeature,
    menuFeature,
    quantityFeature,
    statusesFeature,
    visitorManagementFeature,
    cancelableFeature,
  } = useVisibleAndSanitizedFeatures(interaction?.contentData);

  const sanitizedProperties = useVisibleClearedFromDependenciesProperties({
    data: editingInteraction?.data,
    content: interaction?.contentData,
  });

  const sanitizedInteractionData = useVisibleClearedFromDependenciesData({
    data: editingInteraction?.data,
    content: interaction?.contentData,
  });

  const timeZone = useInteractionTimeZone(interaction);

  const companyData = useChannelProfileQuery({
    channelId: interaction?.features?.UseCompanyPermissions?.company?._id,
  });

  async function onSaveInteraction() {
    if (interaction.status !== editingInteraction.status) {
      const isCancelling = editingInteraction.status === INTERACTION_CANCELLED;

      try {
        const statuses = {
          oldStatus: interaction.status,
          newStatus: editingInteraction.status,
        };

        if (isCancelling && paymentFeature) {
          // warn this will issue a refund.
          await window.Alert.confirm({
            title: t(`Cancel and issue a Refund`),
            message: t(
              `You are Cancelling this, this will issue a full Refund.  Are you sure?`
            ),
            confirmText: t('Yes, Cancel and Refund'),
            cancelText: t('No'),
          });
        } else if (isCancelling) {
          await window.Alert.confirm({
            title: t(`Cancelling`, statuses),
            message: t(
              `You are cancelling this, this will reverse the transaction. Are you sure?`,
              statuses
            ),
            confirmText: t('Yes, Cancel'),
            cancelText: t('No'),
          });
        } else {
          await window.Alert.confirm({
            title: t(`Changing to {{newStatus}}`, statuses),
            message: t(
              `You are changing the status from {{oldStatus}} to {{newStatus}}.  Are you sure?`,
              statuses
            ),
            confirmText: t('Change Status'),
            cancelText: t('Cancel'),
          });
        }
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
        return;
      }
    }

    updateInteractionAndShowToast();
  }

  const updateInteractionAndShowToast = async () => {
    try {
      await updateInteraction();
      const updateSuccessfulMessage = `${t('shared.common.updateSuccessful')}!`;

      window.Toast.show(<p>{updateSuccessfulMessage}</p>);
    } catch (error) {
      window.Alert.alert({
        title: 'Updating this interaction failed…',
        error,
      });
    }
  };

  async function onCloseInteraction() {
    const status = ![editingInteraction?.status, interaction.status].includes(
      INTERACTION_CLOSED
    )
      ? INTERACTION_CLOSED
      : INTERACTION_OPEN;

    setInteractionUpdate({ status });
  }

  function renderUserLink(
    user: { _id: LaneType.UUID; name: string } | undefined
  ) {
    return (
      <Link
        to={routes.channelAdminTeamMember
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          .replace(':id', channelSlug)
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          .replace(':userId', user?._id)}
      >
        {user?.name}
      </Link>
    );
  }

  const hasDates = Boolean(interaction?.startDate);

  const reservableInputDisabled =
    Boolean(paymentFeature) && Boolean(reservableFeature);

  const showAdminNotes =
    isAdminView && isAdminNotesEnabled && !visitorManagementFeature;

  const isUserNotesAvailable =
    isUserNotesEnabled &&
    reservableFeature &&
    interaction?.features.Reservable?.userNotes;

  const debouncedHandleAdminNoteChange = useDebouncedCallback(
    (reservation: any) => {
      if (!isMountedRef.current) {
        setInteractionUpdate({
          features: {
            Reservable: {
              ...editingInteraction.features.Reservable,
              reservation,
            },
          },
        });
      } else {
        isMountedRef.current = false;
      }
    },
    200
  ).callback;

  const interationChannel: ChannelType = {
    channel: {
      _id: interaction?.channel?._id,
      name: interaction?.channel?.name,
      address: interaction?.channel?.address,
    },
  };

  return (
    <section className={cx(styles.UCIEdit, className)} style={style}>
      {mode === 'edit' && (
        <ControlMenu>
          <hr />
          <Button onClick={onViewHistory} disabled={loading}>
            {t('shared.common.history')}
          </Button>
          <Button onClick={resetChanges} disabled={!hasChanged || loading}>
            {t('shared.common.undo')}
          </Button>
          {(!!statusesFeature || !!cancelableFeature) && (
            <Button onClick={onCloseInteraction}>
              {![editingInteraction?.status, interaction?.status].includes(
                INTERACTION_CLOSED
              )
                ? t('Close')
                : t('Re-open')}
            </Button>
          )}
          <Button
            variant="contained"
            loading={loading}
            disabled={!hasChanged}
            onClick={onSaveInteraction}
          >
            {t('shared.common.save')}
          </Button>
        </ControlMenu>
      )}

      <div className={cx(styles.group, styles.row)}>
        <div className={cx(styles.column)}>
          <H4 mt={2}>{t('shared.common.member')}</H4>
          <Link
            to={routes.channelAdminTeamMember
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              .replace(':id', channelSlug)
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              .replace(':userId', interactionUser?._id)}
          >
            <UserCircleListView
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'PublicUserType | undefined' is not assignabl... Remove this comment to see the full error message
              user={interactionUser}
              className={styles.userListView}
            />
          </Link>
        </div>

        {useCompanyPermissionsFeature && (
          <div className={cx(styles.column)}>
            <H4 mt={2}>{t('shared.common.company')}</H4>
            <ChannelCircleListView
              className={styles.channelListView}
              channel={companyData?.channel}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'AddressType | undefined' is not ... Remove this comment to see the full error message
              description={shortAddress(companyData?.channel?.address)}
            />
          </div>
        )}

        {submitOnBehalfOfFeature &&
          interaction?.user?._id !== interaction?._createdBy?._id && (
            <div className={styles.column}>
              <H5 mt={2}>{t('shared.common.submittedBy')}</H5>
              <Link
                to={routes.channelAdminTeamMember
                  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                  .replace(':id', channelSlug)
                  .replace(':userId', interaction?._createdBy?._id)}
              >
                <UserCircleListView
                  user={interaction?._createdBy}
                  className={styles.userListView}
                />
              </Link>
            </div>
          )}
      </div>

      <div className={styles.group}>
        <div className={styles.row}>
          {(!!statusesFeature || !!cancelableFeature) && (
            <div className={styles.column}>
              <H4 mt={2}>{t('shared.common.status')}</H4>
              <StatusDropdown
                interaction={editingInteraction ?? interaction}
                disabled={mode === 'history'}
              />
            </div>
          )}

          <div className={cx(styles.column, styles.property)}>
            <H5>{t('shared.common.id')}</H5>
            <span className={styles.id}>{interaction?._id}</span>
          </div>
        </div>

        <div className={styles.row}>
          <div className={cx(styles.property, styles.column)}>
            <H5>{t('shared.common.created')}</H5>
            <span>
              {longDateTimeZone(interaction?._created, timeZone)} by{' '}
              {renderUserLink(interaction?._createdBy)}
            </span>
          </div>
          <div className={cx(styles.property, styles.column)}>
            <H5>{t('shared.common.updated')}</H5>
            <span>
              {longDateTimeZone(interaction?._updated, timeZone)} by{' '}
              {renderUserLink(interaction?._updatedBy)}
            </span>
          </div>
        </div>

        {hasDates && (
          <div className={styles.row}>
            <div className={cx(styles.property, styles.column)}>
              <H5>{t('shared.common.dateAndTime')}</H5>
              <span>
                {getFriendlyInteractionDate(
                  interaction,
                  timeZone,
                  t,
                  user?.locale
                )}
              </span>
            </div>
          </div>
        )}

        {quantityFeature && (
          <div className={styles.row}>
            <div className={cx(styles.property, styles.column)}>
              <label>{t('shared.common.quantity')}</label>
              <div className={styles.quantity}>
                {interaction.features.Quantity.quantity}
              </div>
            </div>
          </div>
        )}
      </div>

      {interaction && (
        <ObjectPropertiesRenderer
          disabled={loading || mode !== 'edit'}
          className={styles.group}
          properties={sanitizedProperties}
          data={sanitizedInteractionData}
          creatorId={interaction.contentData?._createdBy?._id}
          ownerId={interaction.user?._id}
          sourceId={interaction.channel?._id}
          user={user}
          channel={channel}
          onPropertyValueChanged={(key, value) => {
            setInteractionUpdate({
              data: {
                [key]: value,
              },
            });
          }}
        />
      )}

      {reservableFeature && (
        <div className={styles.group}>
          <H4 mt={2}>{t('shared.common.reservation')}</H4>
          <ReservableInput
            disabled={loading || mode !== 'edit' || reservableInputDisabled}
            content={interaction.contentData}
            value={editingInteraction.features.Reservable.reservation}
            onInput={debouncedHandleAdminNoteChange}
            existingValue={{
              startDate: new Date(
                interaction.features.Reservable.reservation.startDate
              ),
              endDate: new Date(
                interaction.features.Reservable.reservation.endDate
              ),
            }}
            forAdmin
          />

          {isUserNotesAvailable ? (
            <div className={styles.userNotes}>
              <p>
                <b>{reservableFeature?.userNotes?.labelText}</b>
              </p>
              <div>{interaction.features.Reservable?.userNotes}</div>
            </div>
          ) : null}
        </div>
      )}

      {showAdminNotes && (
        <div className={styles.group} data-test="admin-notes">
          <AdminNotes
            note={interaction?.adminNote}
            onSubmit={updateInteractionAndShowToast}
            onChange={(note: string) =>
              setInteractionUpdate({ adminNote: note })
            }
            onCancel={() =>
              setInteractionUpdate({ adminNote: interaction.adminNote })
            }
          />
        </div>
      )}

      {guestInviteFeature && (
        <div className={styles.group}>
          <GuestInviteFeature
            value={editingInteraction.features.GuestInvite.guests}
            showLaneUsers
            onChange={guests =>
              setInteractionUpdate({
                features: {
                  GuestInvite: {
                    ...editingInteraction.features.GuestInvite,
                    guests,
                  },
                },
              })
            }
          />
        </div>
      )}

      {visitorManagementFeature && (
        <div className={styles.group}>
          <VisitorManagementReceipt
            interaction={interaction}
            properties={visitorManagementFeature}
            displayScheduleAsRow
            adminUciEdit
          />
        </div>
      )}

      {menuFeature && (
        <div className={styles.group}>
          <MenuOrderCart
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: boolean; title: string; order: a... Remove this comment to see the full error message
            disabled={loading || mode !== 'edit'}
            title="Order Info"
            order={interaction.features.Menu.order}
            menuFeature={menuFeature!}
            paymentFeature={paymentFeature}
            hasClearCart={false}
            showSupport={false}
          />
        </div>
      )}

      {paymentFeature && (
        <div className={styles.group}>
          <H4 mt={2}>{t('shared.common.paymentInfo')}</H4>
          <PaymentFeatureQuote
            quote={interaction.features.Payment.quote}
            channel={interationChannel}
          />

          <hr />

          {essensysProductFeature && (
            <>
              <InfoRow label="Essensys Contact Id" isId>
                <a
                  href={formatEssensysContactUrl(
                    interaction.features.EssensysProduct.contactId
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {interaction.features.EssensysProduct.contactId}
                </a>
              </InfoRow>
              <InfoRow label="Essensys Account Id" isId>
                <a
                  href={formatEssensysAccountUrl(
                    interaction.features.EssensysProduct.accountId
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {interaction.features.EssensysProduct.accountId}
                </a>
              </InfoRow>
              <InfoRow label="Essensys Product Id" isId>
                <a
                  href={formatEssensysProductUrl(
                    essensysProductFeature.productId
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {essensysProductFeature.productId}
                </a>
              </InfoRow>
              <InfoRow label="Essensys Booking Id" isId>
                {interaction.features.EssensysProduct.bookingId}
              </InfoRow>
              <InfoRow label="Essensys Status" isId>
                {interaction.features.EssensysProduct.status}
              </InfoRow>
            </>
          )}

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'style' is missing in type '{ payment: an... Remove this comment to see the full error message */}
          <PaymentFeatureStripeData payment={interaction.features.Payment} />

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'style' is missing in type '{ payment: an... Remove this comment to see the full error message */}
          <PaymentFeaturePlacePayData payment={interaction.features.Payment} />
        </div>
      )}

      {isShopifyBurstInteraction(interaction) && (
        <div className={styles.group}>
          {mode === 'edit' && (
            <BurstAdminUCIEditView
              burstInteraction={interaction}
              interactionUser={interactionUser!}
            />
          )}
          {mode === 'history' && (
            <>
              <H4 mt={2}>{t('shared.common.burstDetails')}</H4>
              {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'onDataUpdated' is missing in type '{ bur... Remove this comment to see the full error message */}
              <BurstReadOnlyView burstData={interaction?.data?._ShopifyBurst} />
            </>
          )}
        </div>
      )}
    </section>
  );
}
