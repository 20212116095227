export type LookerEmbedOptions = {
  embedURL: string;
  channelList: string[];
  permissions: string[];
  models: string[];
  lookerGroupIDs: number[];
  forceLogout: boolean;
  userTimezone: string;
};

export enum LookerReportEnum {
  Content = 'content',
  User = 'user',
  Portfolio = 'portfolio',
  Reservation = 'reservation',
  UserSearch = 'user_search',
  PostAnalytics = 'post_analytics',
  CompanyAnalytics = 'company_analytics',
  WorkOrdersAnalytics = 'work_orders_analytics',
  UserEngagementAnalytics = 'user_engagement_analytics',
  AdminDashboardAnalytics = 'admin_dashboard_analytics',
  TenantSentimentAnalytics = 'tenant_sentiment_analytics',
  AccessAnalytics = 'access_analytics',
  EventAnalytics = 'event_analytics',
  VisitorManagementAnalytics = 'visitor_management_analytics',
}

// Note: it is better to keep these values hardcoded rather then move to SSM Shared Config due to simplicity of local debugging
export const reportUrlMappingForVtsAccountDev = {
  [LookerReportEnum.Content]: '/embed/dashboards/2606',
  [LookerReportEnum.User]: '/embed/dashboards/2601',
  [LookerReportEnum.Portfolio]: '/embed/dashboards/2605',
  [LookerReportEnum.Reservation]: '/embed/dashboards/2602',
  [LookerReportEnum.UserSearch]: '/embed/dashboards/2610',
  [LookerReportEnum.PostAnalytics]: '/embed/dashboards/2603',
  [LookerReportEnum.CompanyAnalytics]: '/embed/dashboards/2604',
  [LookerReportEnum.WorkOrdersAnalytics]: '/embed/dashboards/2611',
  [LookerReportEnum.UserEngagementAnalytics]: '/embed/dashboards/2608',
  [LookerReportEnum.AdminDashboardAnalytics]: '/embed/dashboards/2609',
  [LookerReportEnum.TenantSentimentAnalytics]: '/embed/dashboards/2607',
  [LookerReportEnum.AccessAnalytics]: '/embed/dashboards/2644',
  [LookerReportEnum.EventAnalytics]: '/embed/dashboards/2674',
  [LookerReportEnum.VisitorManagementAnalytics]: '/embed/dashboards/2877',
};

export const reportUrlMappingForVtsAccountProd = {
  [LookerReportEnum.Content]: '/embed/dashboards/2716',
  [LookerReportEnum.User]: '/embed/dashboards/2713',
  [LookerReportEnum.Portfolio]: '/embed/dashboards/2715',
  [LookerReportEnum.Reservation]: '/embed/dashboards/2712',
  [LookerReportEnum.UserSearch]: '/embed/dashboards/2720',
  [LookerReportEnum.PostAnalytics]: '/embed/dashboards/2711',
  [LookerReportEnum.CompanyAnalytics]: '/embed/dashboards/2714',
  [LookerReportEnum.WorkOrdersAnalytics]: '/embed/dashboards/2721',
  [LookerReportEnum.UserEngagementAnalytics]: '/embed/dashboards/2718',
  [LookerReportEnum.AdminDashboardAnalytics]: '/embed/dashboards/2719',
  [LookerReportEnum.TenantSentimentAnalytics]: '/embed/dashboards/2717',
  [LookerReportEnum.AccessAnalytics]: '/embed/dashboards/2734',
  [LookerReportEnum.EventAnalytics]: '/embed/dashboards/2787',
  [LookerReportEnum.VisitorManagementAnalytics]: '/embed/dashboards/2908',
};

export const tenantReportUrlMappingForVtsAccountDev = {
  [LookerReportEnum.Content]: '/embed/dashboards/3006',
  [LookerReportEnum.PostAnalytics]: '/embed/dashboards/3007',
  [LookerReportEnum.WorkOrdersAnalytics]: '/embed/dashboards/3003',
  [LookerReportEnum.UserEngagementAnalytics]: '/embed/dashboards/2608',
  [LookerReportEnum.AdminDashboardAnalytics]: '/embed/dashboards/3005',
  [LookerReportEnum.AccessAnalytics]: '/embed/dashboards/3004',
  [LookerReportEnum.EventAnalytics]: '/embed/dashboards/2991',
  [LookerReportEnum.VisitorManagementAnalytics]: '/embed/dashboards/3008',
};

export const tenantReportUrlMappingForVtsAccountProd = {
  [LookerReportEnum.Content]: '/embed/dashboards/2981',
  [LookerReportEnum.PostAnalytics]: '/embed/dashboards/2984',
  [LookerReportEnum.WorkOrdersAnalytics]: '/embed/dashboards/2986',
  [LookerReportEnum.UserEngagementAnalytics]: '/embed/dashboards/2718',
  [LookerReportEnum.AdminDashboardAnalytics]: '/embed/dashboards/2980',
  [LookerReportEnum.AccessAnalytics]: '/embed/dashboards/2983',
  [LookerReportEnum.EventAnalytics]: '/embed/dashboards/2984',
  [LookerReportEnum.VisitorManagementAnalytics]: '/embed/dashboards/2985',
};
