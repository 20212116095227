import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'design-system-web';
import { Flex, Label } from 'components';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { PlusIcon } from '@viewthespace/components';
import type { RecentMeterReadingValue } from 'graphql-query-contracts';
import { getMeterReadingUnitDropDown } from 'domains/workOrder/equipment/utils/meterReadingUnitOptions';
import { useFormContext } from 'react-hook-form';
import {
  HookFormDropdown,
  HookFormInput,
  HookFormTextarea,
} from 'components/reactHookForm';

import styles from './MeterReadingInput.scss';

export interface MeterReadingInputProps {
  unit: string;
  index?: number;
  addlineBreak: boolean;
  settingName: string;
  previousValue?: RecentMeterReadingValue;
  channel?: UseChannelForAdminQueryResponse['channel'];
}

export function MeterReadingInput({
  unit,
  index,
  settingName,
  previousValue,
  addlineBreak = false,
}: MeterReadingInputProps) {
  const { t } = useTranslation();
  const { control, register } = useFormContext();

  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const structuredDataSelect = getMeterReadingUnitDropDown(unit);
  const isStructuredData = !!structuredDataSelect;

  return (
    <div className="mt-2">
      <Flex gap={2} direction="column">
        <Flex gap={2} className={styles.MeterReadingLabels}>
          <div className={styles.MeterReadingInput}>
            {isStructuredData && (
              <HookFormDropdown
                control={control}
                {...register(`meterReadings.${index}.value`)}
                testId="meter-reading-value-select-dropdown"
                label={settingName}
                items={structuredDataSelect.items}
                isRequired
                fixedLabel
                isFullWidth
              />
            )}

            {!isStructuredData && (
              <HookFormInput
                fieldName="value"
                control={control}
                {...register(`meterReadings.${index}.value`)}
                testId="meter-reading-value-input"
                label={settingName}
                fixedLabel
                ariaLabel={t(
                  'web.admin.serviceRequest.equipment.MeterReadingInput.valueFor',
                  {
                    for: settingName,
                  }
                )}
                type="number"
              />
            )}
          </div>

          <Label mt={5} style={{ marginRight: 'auto' }}>
            {unit}
          </Label>

          {!isNotesVisible && (
            <div className="ml-auto mt-auto">
              <Button
                onClick={() => setIsNotesVisible(true)}
                variant="text"
                startIcon={<PlusIcon />}
                ariaLabel={t(
                  'web.admin.serviceRequest.equipment.MeterReadingInput.addNotesTo',
                  {
                    to: settingName,
                  }
                )}
              >
                {t(
                  'web.admin.serviceRequest.equipment.MeterReadingInput.addNotes'
                )}
              </Button>
            </div>
          )}
        </Flex>

        {isNotesVisible && (
          <Flex direction="column">
            <Label mt={0} htmlFor="meter-reading-notes">
              {t('web.admin.serviceRequest.equipment.MeterReadingInput.notes')}
            </Label>
            <HookFormTextarea
              control={control}
              {...register(`meterReadings.${index}.notes`)}
              id="meter-reading-notes"
              maxLength={4000}
              showLengthIndicator
              minRows={4}
            />
          </Flex>
        )}

        <Flex
          direction="row"
          className={styles.rowContainerWithValues}
          gap={12}
        >
          {!!previousValue && (
            <Flex direction="column">
              <Label>
                {t(
                  'web.admin.serviceRequest.equipment.MeterReadingInput.previousValue'
                )}
              </Label>
              <div className="font-body-large">
                {previousValue.createdAt
                  ? t(
                      'web.admin.serviceRequest.equipment.MeterReadingInput.previousValuePhraseWithDate',
                      {
                        value: previousValue.value,
                        unit,
                        date: simpleDate(previousValue.createdAt),
                      }
                    )
                  : t(
                      'web.admin.serviceRequest.equipment.MeterReadingInput.previousValuePhrase',
                      {
                        value: previousValue.value,
                        unit,
                      }
                    )}
              </div>
            </Flex>
          )}
        </Flex>

        {addlineBreak && (
          <div className="w-full border-t border-t-border-subdued mt-2" />
        )}
      </Flex>
    </div>
  );
}
