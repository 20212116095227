import React, { useEffect, useMemo, useState } from 'react';

import {
  AdminPage,
  Button,
  Input,
  PageNavigationAlert,
  PageHeader,
  MultiselectField,
} from 'components';
import { history } from 'helpers';
import { useFormServerErrorHandler } from 'hooks';
import { useTranslation } from 'react-i18next';
import { object, number, ValidationError, string } from 'yup';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { floorErrorCodeTypes } from 'activate-errors';
import {
  createFloor,
  CreateFloorMutationResponse,
} from 'lane-shared/graphql/floors';
import { getAggregatedValidationMessage } from 'lane-shared/helpers';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'constants-flags';

import { useGetUnitsFieldOptions } from '../hooks';
import {
  FloorInputFieldKey,
  FloorInputFields,
  ServerErrorCodeToFieldMapping,
} from '../types';

import styles from './styles.scss';
import { getSharedTranslationKeys } from '../utils';
import { ALPHA_NUMERIC_REGEX } from 'lane-shared/helpers/constants';

const TOAST_DELAY = 3000;

type Props = {
  channel: Pick<ChannelType, '_id' | 'name' | 'slug' | 'experienceType'>;
};

export const CreateFloor = ({ channel }: Props) => {
  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);
  const { t } = useTranslation();
  const { createPage } = getSharedTranslationKeys(channel.experienceType);

  const [isFloorCreated, setIsFloorCreated] = useState(false);
  const [floorInputFields, setFloorInputFields] = useState<FloorInputFields>({
    index: undefined,
    name: '',
    units: [],
  });
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  const {
    options,
    error,
    loading: isFetchingUnits,
  } = useGetUnitsFieldOptions(channel._id);
  const genericServerErrorMessage = t('shared.floors.errors.generic');
  const unitsDropdownNoOptionsMessage = error
    ? genericServerErrorMessage
    : t(createPage.form.space.notFound);

  const showToast = (message: string) => {
    window.Toast.show(message);
  };

  const localFloorErrorCodeTypes = { ...floorErrorCodeTypes };

  localFloorErrorCodeTypes.floorIndexAlreadyExistsError.message = t(
    'web.admin.channel.floors.create.form.floor.validation.indexExists'
  );

  const {
    serverFormFieldErrors,
    resetServerFormFieldErrors,
    handleServerError,
  } = useFormServerErrorHandler({
    errorCodeTypes: localFloorErrorCodeTypes,
    errorCodeToFormFieldMap: ServerErrorCodeToFieldMapping,
    show: showToast,
    defaultErrorMessage: genericServerErrorMessage,
  });

  const [createFloorMutation, { loading }] =
    useMutation<CreateFloorMutationResponse>(createFloor);

  const floorInputLabel = t('web.admin.channel.floors.create.form.floor.label');
  const floorAlphanumericValidationMessage = t(
    'web.admin.channel.floors.create.form.floor.validation.alphanumeric'
  );

  const floorValidator = object().shape({
    index: number().required().label(floorInputLabel),
    name: string()
      .label(floorInputLabel)
      .trim()
      .matches(ALPHA_NUMERIC_REGEX, floorAlphanumericValidationMessage),
  });

  const isPristine = useMemo(() => {
    return (
      (floorInputFields.name?.length === 0 ||
        floorInputFields.name === undefined) &&
      (floorInputFields.index === undefined || floorInputFields.index === null)
    );
  }, [floorInputFields]);

  useEffect(() => {
    if (isFloorCreated) {
      history.push(
        routes.channelAdminFloorsListView.replace(':id', channel.slug)
      );
    }
  }, [isFloorCreated, channel.slug]);

  const handleOnChange = (
    key: FloorInputFieldKey,
    value: FloorInputFields[FloorInputFieldKey]
  ) => {
    setFloorInputFields({
      ...floorInputFields,
      [key]: value,
    });
  };

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleOnCreate = async () => {
    try {
      floorValidator.validateSync(
        { index: floorInputFields.index, name: floorInputFields.name },
        { abortEarly: false }
      );

      setValidationError(null);
      resetServerFormFieldErrors();

      const floorInput = {
        ...floorInputFields,
        index: Number(floorInputFields.index),
        units: floorInputFields.units?.map(unit => unit.value),
      };

      await createFloorMutation({
        variables: { propertyId: channel._id, floor: floorInput },
      });

      window.Toast.show(
        t('web.admin.channel.floors.create.successToast'),
        TOAST_DELAY
      );
      await sleep(TOAST_DELAY);
      setIsFloorCreated(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationError(error);
      } else {
        handleServerError(error);
      }
    }
  };

  const handleOnCancel = () => {
    const url = routes.channelAdminFloorsListView.replace(':id', channel.slug);

    history.push(url);
  };

  const getErrorMessage = (field: FloorInputFieldKey) => {
    const validationMessage = getAggregatedValidationMessage(
      validationError,
      field
    );

    if (validationMessage) {
      return [validationMessage];
    }

    // check server errors
    const errorMessages = serverFormFieldErrors[field];

    if (errorMessages) {
      return [errorMessages];
    }

    return [];
  };

  const renderCTAButtons = () => {
    return (
      <>
        <Button
          onClick={handleOnCreate}
          testId="createButton"
          variant="contained"
          disabled={isPristine}
          loading={loading}
        >
          {t('web.admin.channel.floors.create.createButton')}
        </Button>
        <Button
          onClick={handleOnCancel}
          testId="cancelButton"
          variant="outlined"
        >
          {t('web.admin.channel.floors.create.cancelButton')}
        </Button>
      </>
    );
  };

  if (!unitsFloorsEnabled) return null;

  return (
    <AdminPage className={styles.adminPage}>
      <PageNavigationAlert
        when={!isPristine && !isFloorCreated}
        testId="pageNavigationAlert"
      />
      <PageHeader
        headerLevel="h3"
        header={t('web.admin.channel.floors.create.header', {
          propertyChannelName: channel.name,
        })}
        breadcrumbs={[
          {
            label: t('web.admin.channel.floors.create.breadcrumb.floors'),
            url: routes.channelAdminFloorsListView.replace(':id', channel.slug),
          },
          {
            label: t('web.admin.channel.floors.create.breadcrumb.addFloor'),
          },
        ]}
      />
      <div className={styles.addFloorContainer}>
        <div className={styles.formContainer}>
          <Input
            fieldName="index"
            label={floorInputLabel}
            type="number"
            fixedLabel
            testId="indexInput"
            value={floorInputFields.index}
            error={getErrorMessage('index')}
            onChange={value => handleOnChange('index', value)}
            placeholder={t(
              'web.admin.channel.floors.create.form.floor.placeholder'
            )}
            isRequired
          />
          <Input
            fieldName="name"
            label={t('web.admin.channel.floors.create.form.name.label')}
            fixedLabel
            testId="nameInput"
            value={floorInputFields.name}
            error={getErrorMessage('name')}
            onChange={value => handleOnChange('name', value)}
            placeholder={t(
              'web.admin.channel.floors.create.form.name.placeholder'
            )}
          />
          <MultiselectField
            label={t(createPage.form.space.label)}
            fixedLabel
            isFullWidth
            testId="unitsDropdown"
            items={options}
            onChange={value => handleOnChange('units', value)}
            value={floorInputFields.units}
            placeholder={t(createPage.form.space.placeholder)}
            noOptionsMessage={unitsDropdownNoOptionsMessage}
            doTranslation={false}
            isLoading={isFetchingUnits}
          />
          <div className={styles.buttonContainer}>{renderCTAButtons()}</div>
        </div>
      </div>
    </AdminPage>
  );
};
