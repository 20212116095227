import React, { useState, useEffect, useMemo } from 'react';

import { Loading, DraftNotice, ErrorMessage } from 'components';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import {
  AddressFragment,
  FullContentForAdminFragment,
  MetatagFragment,
  ObjectMetatagFragment,
  FullProfileWithThemeFragment,
  PublicUserFragment,
  ThemeFragment,
} from 'lane-shared/graphql/fragments';
import { getDraftContent } from 'lane-shared/graphql/query';
import { castGraphQLObject } from 'lane-shared/helpers';
import { CONTENT_TYPES } from 'lane-shared/helpers/constants/content';
import { ContentType } from 'lane-shared/types/content/Content';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

import { DraftContent } from 'components/lane/DraftContent';
import { ContentNotificationType } from 'lane-shared/types/content/DraftContent';
import { cloneDeep } from 'lodash';

const contentQuery = gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}
  ${MetatagFragment}
  ${ObjectMetatagFragment}
  ${FullContentForAdminFragment}

  query getContentForAdmin($id: UUID!) {
    content(_id: $id) {
      ...FullContentForAdminFragment
      sectionContent {
        _id
        section {
          _id
          name
          channel {
            _id
          }
        }
      }
    }
  }
`;

type ContentTypeWithNotifications = ContentType & {
  notifications: ContentNotificationType[];
};

export default function DraftEdit({ channel }: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'draftId' does not exist on type '{}'.
  const { draftId } = useParams();
  const [draftContent, setDraftContent] = useState<ContentType | null>(null);
  const [liveContent, setLiveContent] = useState<
    ContentType | null | undefined
  >(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [liveContentLoading, setLiveContentLoading] = useState(false);
  const [draftContentLoading, setDraftContentLoading] = useState(false);

  async function fetchLiveContent() {
    setLiveContentLoading(true);

    try {
      const { data } = await getClient().query({
        query: contentQuery,
        fetchPolicy: 'no-cache',
        variables: {
          id: draftId,
        },
      });

      const content = castGraphQLObject(data?.content);

      setLiveContent(content);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setLiveContent(null);
      // this error is ok to ignore
    } finally {
      setLiveContentLoading(false);
    }
  }

  async function fetchDraftContent() {
    setDraftContentLoading(true);
    setError(null);

    try {
      const { data } = await getClient().query({
        query: getDraftContent,
        fetchPolicy: 'no-cache',
        variables: {
          id: draftId,
        },
      });

      const draftContent = castGraphQLObject(data?.draftContent);

      if (!channel?.settings.hasVisitorManagementEnabled) {
        const data = draftContent?.block?.properties?.children;

        if (data) {
          draftContent.block.properties.children = data.filter(
            (item: { for: string }) =>
              item.for !== `${FeatureNameEnum.VisitorManagement}.visitors` &&
              item.for !== `${FeatureNameEnum.VisitorManagement}.schedule`
          );
        }
      } // to remove the visitor management blocks if the setting toggle is off

      setDraftContent(draftContent);
    } catch (err) {
      setError(err as Error);
    } finally {
      setDraftContentLoading(false);
    }
  }

  async function reload() {
    setLiveContent(null);
    setDraftContent(null);
    await Promise.allSettled([fetchDraftContent(), fetchLiveContent()]);
  }

  useEffect(() => {
    if (draftId) {
      reload();
    }
  }, [draftId]);

  const draftWithUpdatedNotifications = useMemo(() => {
    if (!draftContent) {
      return null;
    }

    if (!liveContent) {
      return draftContent;
    }

    const draftContentNotifications: ContentNotificationType[] =
      (draftContent as ContentTypeWithNotifications).notifications ?? [];

    const livePublishedNotifications: ContentNotificationType[] =
      (liveContent as ContentTypeWithNotifications).notifications ?? [];

    const draftUnpublishedNotifications = draftContentNotifications.filter(
      notification =>
        !livePublishedNotifications.some(
          livePublishedNotification =>
            livePublishedNotification._id === notification._id
        )
    );

    const updatedDraftContentNotifications = [
      ...cloneDeep(draftUnpublishedNotifications),
      ...cloneDeep(livePublishedNotifications),
    ];

    if (updatedDraftContentNotifications.length === 0) {
      return draftContent;
    }

    const draftWithUpdatedNotifications = cloneDeep(
      draftContent as ContentTypeWithNotifications
    );

    draftWithUpdatedNotifications.notifications =
      updatedDraftContentNotifications;

    return draftWithUpdatedNotifications;
  }, [draftContent, liveContent]);

  if (error && !draftContent) {
    return <ErrorMessage error={error} />;
  }

  if (draftContentLoading || !draftContent) {
    return <Loading fullscreen />;
  }

  if (liveContentLoading) {
    return <Loading fullscreen />;
  }

  let Component;

  switch (draftContent!.type) {
    case CONTENT_TYPES.SCHEDULED_NOTICE:
    case CONTENT_TYPES.NOTICE:
      Component = DraftNotice;
      break;
    case CONTENT_TYPES.STATIC:
    case CONTENT_TYPES.SCHEDULED_CONTENT:
    case CONTENT_TYPES.PROMOTION:
    case CONTENT_TYPES.PERK:
    case CONTENT_TYPES.CONTENT:
    default:
      Component = DraftContent;
  }

  return (
    <Component
      draft={draftWithUpdatedNotifications!}
      contentType={draftContent!.type}
      liveContent={liveContent}
      channel={channel}
      onUndo={reload}
      onSave={reload}
      forEdit
    />
  );
}
