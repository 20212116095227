import React from 'react';

import { AdminPage, TabStrip, ControlMenu } from 'components';
import { H3 } from 'components/typography';
import { useQueryString } from 'hooks';

import { useTranslation } from 'react-i18next';
import styles from './MessagingAndMarketplace.styles.scss';
import { DefaultMessageGroups } from 'domains/messagingAndMarketplace';

enum TabsEnum {
  GROUPS = 'groups',
  APPEARANCE = 'appearance',
}

const tabs = [
  {
    label:
      'web.admin.channel.settings.messagingAndMarketplace.messageGroups.title',
    value: TabsEnum.GROUPS,
  },
];

export function MessagingAndMarketplace() {
  const { t } = useTranslation();

  const defaultTab = TabsEnum.GROUPS;

  const [query, goToUrl] = useQueryString({
    tab: defaultTab,
  });

  const selectedTab = query.tab || defaultTab;

  const selected = tabs.find(tab => tab.value === selectedTab);

  return (
    <AdminPage className={styles.MessagingAndMarketplace}>
      <H3>{t('web.admin.channel.settings.messagingAndMarketplace.title')}</H3>
      <ControlMenu className={styles.controlMenu}>
        <TabStrip
          tabs={tabs}
          selected={selected}
          onSelectTab={tab => goToUrl({ tab: tab.value })}
          className={styles.tabStrip}
        />
      </ControlMenu>

      {selectedTab === TabsEnum.GROUPS && <DefaultMessageGroups />}
    </AdminPage>
  );
}
