import React from 'react';
import { getServiceRequestAuditLogsQuery } from 'graphql-queries';
import { convertToUUID } from 'uuid-encoding';
import { ServiceRequestAuditLog } from 'graphql-query-contracts';
import { useQuery } from '@apollo/client';
import { ActivityLog } from 'domains/workOrder/activityLog/ActivityLog';
import { Loading } from 'components';
import styles from './Activity.scss';

type Props = {
  serviceRequestId: string;
  testId?: string;
};

export function ActivityTab({ serviceRequestId }: Props) {
  const { data, loading } = useQuery(getServiceRequestAuditLogsQuery, {
    variables: {
      serviceRequestId: convertToUUID(serviceRequestId),
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loading fullscreen />;

  if (!data) return null;

  const auditLogs: ServiceRequestAuditLog[] = data?.serviceRequestAuditLogs;

  return (
    <div className={styles.activityTab}>
      {auditLogs.map(auditLog => (
        <ActivityLog key={auditLog.date} auditLog={auditLog} />
      ))}
    </div>
  );
}
