 
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Equipment, type ClientPmSchedule, type StepTemplate } from 'graphql-query-contracts';

import { Dropdown, Flex, IconButton, Input, Label } from 'components';
import { Button, Checkbox, Icon, Tooltip } from 'design-system-web';

import {
  EquipmentData,
  MeterReadingOption,
  ScheduleFormValidationErrors,
} from './types';

import styles from './ScheduleEquipments.scss';

interface ScheduleEquipmentsProps {
  onScheduleFieldChange: (field: keyof ClientPmSchedule, value: string) => void;
  schedule: ClientPmSchedule | undefined;
  selectedEquipments: EquipmentData[];
  setSelectedEquipments: (equipments: any[]) => void;
  validationErrors: ScheduleFormValidationErrors | undefined;
  isEquipmentLinked: boolean;
  setIsEquipmentLinked: (value: boolean) => void;
  availableEquipment: Map<string, Equipment[]>;
  currentSteps: StepTemplate[];
  meterReadings: MeterReadingOption[];
}

const REQUIRED_IDENTIFIER = '*';

export const ScheduleEquipments = ({
  onScheduleFieldChange,
  schedule,
  selectedEquipments,
  setSelectedEquipments,
  validationErrors,
  isEquipmentLinked,
  setIsEquipmentLinked,
  availableEquipment,
  currentSteps,
  meterReadings,
}: ScheduleEquipmentsProps) => {
  const { t } = useTranslation();
  const [equipmentInUse, setEquipmentInUse] = useState<Set<string>>(new Set());
  const translate = (key: string) => {
    return t(`web.admin.workOrder.preventiveMaintenance.schedule.Form.${key}`);
  };

  const toggleEquipmentLinked = () => {
    if (!isEquipmentLinked) {
      setSelectedEquipments([]);
    }

    setIsEquipmentLinked(!isEquipmentLinked);
  };

  const handleAddEquipment = () => {
    if (selectedEquipments[selectedEquipments.length - 1]?.equipment === null) {
      return;
    }

    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments.push({ category: undefined, equipment: undefined });
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleRemoveEquipment = (index: number) => {
    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments.splice(index, 1);
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleEquipmentChange = (equipment: any, index: number) => {
    const selectedEq = selectedEquipments[index];
    const category = selectedEq?.category;

    if (!category) return;

    const equipmentOptions = availableEquipment.get(category);
    const equipmentData = equipmentOptions!.find(
      (equipmentOption: any) => equipmentOption.id === equipment.value
    );

    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments[index]!.category = equipmentData?.category;
    newSelectedEquipments[index]!.equipment = equipmentData;
    setSelectedEquipments(newSelectedEquipments);

    if (newSelectedEquipments.length > 0) {
      onScheduleFieldChange('location', undefined as any);
    }
  };

  const handleEquipmentCategoryChange = (category: any, index: number) => {
    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments[index]!.category = category.value;
    newSelectedEquipments[index]!.equipment = undefined;
    setSelectedEquipments(newSelectedEquipments);
  };

  useEffect(() => {
    const newEquipmentInUse = new Set<string>();

    currentSteps?.forEach(step => {
      if (step.type === 'meter' && step.meterReadingId) {
        const meterReading = meterReadings.find(
          reading => reading.id === step.meterReadingId
        );

        if (meterReading?.equipmentId) {
          newEquipmentInUse.add(meterReading?.equipmentId);
        }
      }
    });

    setEquipmentInUse(newEquipmentInUse);
  }, [currentSteps, meterReadings]);

  return (
    <Flex direction="column" className={styles.equipmentSection}>
      <Checkbox
        text={translate('linkEquipment')}
        testId="link-equipment"
        selected={isEquipmentLinked}
        onChange={toggleEquipmentLinked}
        value={isEquipmentLinked}
        name="link-equipment"
      />

      {/* Schedule Location */}
      {!isEquipmentLinked && (
        <Flex direction="column" className={styles.locationField}>
          <Label mt={0}>{translate('location')}
          <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
          </Label>
          <Input
            testId="schedule-location"
            value={schedule?.location}
            onChange={(value: string) => {
              const trimmedValue = value.trim();

              if (trimmedValue.length > 0 || value === '') {
                onScheduleFieldChange('location', value);
              }
            }}
            maxLength={200}
            showLengthIndicator
            disabled={isEquipmentLinked && selectedEquipments.length > 0}
            fieldName="location"
            error={validationErrors?.location}
          />
        </Flex>
      )}

      {/* Schedule Equipment */}
      {isEquipmentLinked && (
        <Flex direction="column" gap={3} className={styles.equipmentList}>
          {selectedEquipments.length > 0 && (
            <Flex className={styles.equipmentLabels}>
              <Label mt={0}>
                {translate('equipmentCategory')}
                <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
              </Label>
              <Label mt={0}>
                {translate('equipment')}
                <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
              </Label>
            </Flex>
          )}

          {/* Equipment List */}
          {selectedEquipments.map((selectedEquipment, index) => (
            <Flex key={index} gap={3} className={styles.equipmentLabels}>
              <div className={styles.equipmentDropdown}>
                <Dropdown
                  id="select-equipment-category"
                  testId="select-equipment-category"
                  value={selectedEquipment.category}
                  isFullWidth
                  doTranslation={false}
                  items={
                    Array.from(availableEquipment.keys()).map(category => ({
                      label: category,
                      value: category,
                    })) || []
                  }
                  onChange={value =>
                    handleEquipmentCategoryChange(value, index)
                  }
                  invalid={
                    isEquipmentLinked &&
                    validationErrors?.equipmentIds &&
                    !selectedEquipment.category
                  }
                  errors={
                    isEquipmentLinked &&
                    validationErrors?.equipmentIds &&
                    !selectedEquipment.category
                      ? []
                      : null
                  }
                />
              </div>
              <div className={styles.equipmentDropdown}>
                <Dropdown
                  id="select-equipment"
                  testId="select-equipment"
                  value={selectedEquipment.equipment?.id}
                  isFullWidth
                  doTranslation={false}
                  items={
                    selectedEquipment.category &&
                    availableEquipment.has(selectedEquipment.category)
                      ? availableEquipment
                          .get(selectedEquipment.category)!
                          .map(equipment => ({
                            label: equipment.name,
                            value: equipment.id,
                          }))
                      : []
                  }
                  onChange={value => handleEquipmentChange(value, index)}
                  errors={
                    isEquipmentLinked &&
                    validationErrors?.equipmentIds &&
                    !selectedEquipment.equipment
                      ? validationErrors?.equipmentIds
                      : null
                  }
                  invalid={
                    isEquipmentLinked &&
                    validationErrors?.equipmentIds &&
                    !selectedEquipment.equipment
                  }
                />
              </div>
              {equipmentInUse.has(selectedEquipment.equipment?.id || '') ? (
                <Tooltip
                  TooltipComponent={t(
                    'web.admin.workOrder.preventiveMaintenance.schedule.Form.disabledRemoveEquipment'
                  )}
                >
                  <IconButton
                    icon="trash"
                    testId="remove-option"
                    size="small"
                    onClick={() => handleRemoveEquipment(index)}
                    disabled
                  />
                </Tooltip>
              ) : (
                <IconButton
                  icon="trash"
                  testId="remove-option"
                  size="small"
                  onClick={() => handleRemoveEquipment(index)}
                />
              )}
            </Flex>
          ))}

          {/* Add Equipment */}
          <div>
            <Button
              variant="text"
              startIcon={<Icon name="plus" />}
              onClick={handleAddEquipment}
              fullWidth={false}
              size="medium"
              testId="add-equipment-button"
              className={styles.addEquipmentButton}
            >
              {translate('addEquipment')}
            </Button>
          </div>
        </Flex>
      )}
    </Flex>
  );
};
