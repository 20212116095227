import React, { useState } from 'react';

import { Button, EmptyPageView, Flex } from 'components';
import { useTranslation } from 'react-i18next';

import { MeterReadingValueType } from 'lane-shared/domains/workOrder/constants';
import { Dropdown, Icon } from 'design-system-web';
import { routes } from 'lane-shared/config';
import { getMeterReadingUnitLabel } from 'domains/workOrder/equipment/utils/meterReadingUnitOptions';

import styles from './MeterReadings.scss';
import { CreateMeterReadingModal } from '../../../form/details/CreateMeterReadingModal';
import { MeterReadingTable } from './MeterReadingTable';
import { MeterReadingGraph } from './MeterReadingGraph';
import { useMeterReadingList } from './meterReadingListContext';
import { EditMeterReadingModal } from '../../../form/details/EditMeterReadingModal';

export function MeterReadingsTab() {
  const { t } = useTranslation();

  const [isCreating, setIsCreating] = useState(false);

  const {
    selectedSetting,
    setSelectedSetting,
    visualizationType,
    allSettings,
    editingReading,
    setEditingReading,
    channel,
    equipmentId,
    isEquipmentArchived,
  } = useMeterReadingList();

  const meterReadingSettingOptions = allSettings.map(s => ({
    label:
      s.name && s.name !== '' ? s.name : t(getMeterReadingUnitLabel(s?.unit)),
    value: s.id,
  }));

  if (allSettings.length < 1) {
    return (
      <EmptyPageView
        icon="tachometer"
        title={t(
          'web.admin.serviceRequest.equipment.meterReadingValues.empty.title'
        )}
        message={t(
          'web.admin.serviceRequest.equipment.meterReadingValues.empty.body'
        )}
        primaryButton={{
          href: routes.channelAdminWorkOrdersEquipmentEdit
            .replace(':id', channel?.slug || '')
            .replace(':equipmentId', equipmentId ?? ''),
          label: t(
            'web.admin.serviceRequest.equipment.meterReadingValues.empty.editEquipment'
          ),
        }}
      />
    );
  }

  return (
    <Flex direction="column" className={styles.EquipmentDetailsPage} gap={5}>
      <Flex>
        <Dropdown
          items={meterReadingSettingOptions}
          value={selectedSetting.id}
          doTranslation={false}
          label={t(
            'web.admin.serviceRequest.equipment.meterReadingValues.meterType'
          )}
          fixedLabel
          onChange={option =>
            setSelectedSetting(
              allSettings.find(s => s.id === option.value) || allSettings[0]
            )
          }
        />
        {!isEquipmentArchived && (
          <Button
            variant="contained"
            startIcon={<Icon name="plus" />}
            onClick={() => setIsCreating(true)}
            fullWidth={false}
            size="small"
            testId="add-option"
            className="ml-auto h-4"
          >
            {t('web.admin.serviceRequest.equipment.meterReadingValues.add')}
          </Button>
        )}
      </Flex>

      <Flex direction="column" gap={5}>
        {visualizationType === 'table' && <MeterReadingTable />}
        {visualizationType === 'graph' && <MeterReadingGraph />}
      </Flex>

      {isCreating && (
        <CreateMeterReadingModal
          sourceType={MeterReadingValueType.ADHOC}
          isOpen={isCreating}
          closeModal={() => setIsCreating(false)}
        />
      )}

      {!!editingReading && (
        <EditMeterReadingModal
          meterReading={editingReading}
          sourceType={MeterReadingValueType.ADHOC}
          isOpen={!!editingReading}
          closeModal={() => setEditingReading(null)}
        />
      )}
    </Flex>
  );
}
