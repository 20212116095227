import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';
import {
  useChannelProfileQuery,
  useFlag,
  useFetchUserTenantsSuitesList,
  useSetContextualLDAttributes,
} from 'lane-shared/hooks';
import { getClient } from 'lane-shared/apollo';
import { WorkOrderModuleSettings } from 'lane-shared/domains/workOrder';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import {
  Button,
  Input,
  Dropdown,
  TextArea,
  MultiselectField,
  Loading,
} from 'components';
import { CustomQuestionInput } from 'domains/workOrder/feature/components/serviceRequestSubmissionForm/customQuestionInput';
import { FeatureFlag } from 'constants-flags';
import { Item } from 'components/form/Dropdown/Dropdown';
import { SingleSuiteText } from 'domains/workOrder/components/SingleSuiteText';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';

import { updateServiceRequestMutation } from 'graphql-queries';
import {
  CustomQuestion,
  UpdateServiceRequestDto,
} from 'graphql-query-contracts';

import styles from './MemberServiceRequestEdit.scss';
import { convertTo62 } from 'uuid-encoding';

export interface MemberServiceRequestEditProps {
  close: () => void;
  settings: WorkOrderModuleSettings;
  serviceRequest: any;
  refetch: () => void;
}

export const MemberServiceRequestEdit: React.FC<
  MemberServiceRequestEditProps
> = ({
  close,
  settings,
  serviceRequest,
  refetch,
}: MemberServiceRequestEditProps) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [newserviceRequest, setModuleData] = useState(serviceRequest);

  useSetContextualLDAttributes({
    activeContentOriginChannelId: serviceRequest?.channelId?.id
      ? convertTo62(serviceRequest.channelId.id)
      : undefined,
  });

  const isEnabledCesLinksForServiceRequests = useFlag(
    FeatureFlag.EnableCesLinksForServiceRequests,
    false
  );
  const { user } = useContext(UserDataContext);
  const { primaryChannel } = useContext(ChannelsContext);

  const tenantChannelId =
    primaryChannel?.type !== ActiveChannelTypeEnum.Property
      ? primaryChannel?._id
      : null;

  const { loading: isFetchingSuites, suiteItems: suiteOptions } =
    useFetchUserTenantsSuitesList(
      serviceRequest?.channelId?.id,
      user?._id,
      tenantChannelId
    );

  useEffect(() => {
    const serviceRequestData = { ...serviceRequest };

    serviceRequestData.suiteIds = filter(
      serviceRequestData?.suiteIds,
      'sourceEntityId'
    ).map((suite: { sourceEntityId: string }) => ({
      value: suite?.sourceEntityId,
    }));

    setModuleData(serviceRequestData);
  }, [serviceRequest, isEnabledCesLinksForServiceRequests]);

  const { category, description, floor, issue, location, suite, suiteIds } =
    newserviceRequest;

  const { channel, loading: cpLoading } = useChannelProfileQuery({
    channelId: serviceRequest?.channelId?.id,
  });

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;
  const save = async () => {
    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      if (isEnabledCesLinksForServiceRequests && suiteOptions?.length === 1) {
        newserviceRequest.suiteIds = suiteOptions || [];
      }

      const updateServiceRequestPayload: UpdateServiceRequestDto = {
        serviceRequestId: newserviceRequest.serviceRequestId,
        description: newserviceRequest.description,
        category: newserviceRequest.category,
        issue: newserviceRequest.issue,
        location: newserviceRequest.location,
        company: newserviceRequest.company?.id,
        extRefId: newserviceRequest.extRefId,
        customQuestions: newserviceRequest.customQuestions.map(
          (customQuestion: CustomQuestion) => ({
            Question: customQuestion.Question,
            Answer: customQuestion.Answer,
          })
        ),
        status: newserviceRequest.status,
        assignee: newserviceRequest.assignee?.id,
        assigneeGroups: newserviceRequest.assigneeGroups,
        suiteIds:
          newserviceRequest?.suiteIds.map(
            (suite: Item<string>) => suite.value
          ) || [],
      };

      if (!isEnabledCesLinksForServiceRequests) {
        updateServiceRequestPayload.suite = newserviceRequest.suite;
        updateServiceRequestPayload.floor = newserviceRequest.floor;
      }

      await getClient().mutate({
        mutation: updateServiceRequestMutation,
        variables: {
          updateServiceRequest: updateServiceRequestPayload,
        },
      });
      window.Toast.show(t('web.member.serviceRequest.update.success'));
      refetch();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      window.Toast.show(t('web.member.serviceRequest.update.failure'));
    } finally {
      close();
    }

    return true;
  };

  const validate = () => {
    setHasErrors(!description);
  };

  const selectedCategory = settings?.categories?.find(
    category => category.firendlyName === newserviceRequest?.category
  );
  const categoryOptions =
    settings.categories.map(categoryOption => {
      return {
        label: categoryOption.firendlyName,
        value: categoryOption.firendlyName,
      };
    }) || [];

  const issueOptions =
    selectedCategory?.issues.map(locationOption => {
      return {
        label: locationOption,
        value: locationOption,
      };
    }) || [];

  const locationOptions =
    settings.locations.map(categoryOption => {
      return {
        label: categoryOption,
        value: categoryOption,
      };
    }) || [];

  let customQuestionValues: any = {};

  if (newserviceRequest?.customQuestions) {
    newserviceRequest?.customQuestions.forEach((question: any) => {
      customQuestionValues = {
        ...customQuestionValues,
        [question.Question]: question.Answer,
      };
    });
  }

  const onCustomQuestionEdit = (customQuestion: CustomQuestion) => {
    const newCustomQuestions = newserviceRequest.customQuestions
      ? newserviceRequest.customQuestions.map((customQuestion: any) => ({
          Question: customQuestion.Question,
          Answer: customQuestion.Answer,
        }))
      : [];

    const existingAnswerIndex = newCustomQuestions?.findIndex(
      (existingCustomQuestion: any) =>
        existingCustomQuestion.Question === customQuestion.Question
    );

    if (existingAnswerIndex === -1) {
      newCustomQuestions.push({
        Question: customQuestion.Question,
        Answer: customQuestion.Answer,
      });
    } else {
      newCustomQuestions[existingAnswerIndex] = customQuestion;
    }

    setModuleData({
      ...newserviceRequest,
      customQuestions: newCustomQuestions,
    });
  };

  useEffect(() => {
    validate();
  }, [description]);

  if (cpLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.editWoForm}>
      <div className={styles.editCtaContainer}>
        <Button size="small" onClick={close}>{t`Cancel`}</Button>
        <Button
          size="small"
          onClick={save}
          variant="activate-contained"
          disabled={hasErrors}
        >
          {t`web.member.serviceRequest.submit`}
        </Button>
      </div>
      <div data-test={t`web.admin.serviceRequest.form.category`}>
        <div className={styles.label}>
          <span className={styles.label}>
            {t`web.admin.serviceRequest.form.category`}
          </span>
        </div>
        <Dropdown
          items={categoryOptions}
          value={category}
          initialValue={category}
          onChange={({ value }: any) => {
            setModuleData({ ...newserviceRequest, category: value, issue: '' });
          }}
          isFullWidth
        />
      </div>
      <div
        data-test={t`web.admin.serviceRequest.form.issue`}
        className={styles.field}
      >
        <div className={styles.label}>
          <span className={styles.label}>
            {t`web.admin.serviceRequest.form.issue`}
          </span>
        </div>
        <Dropdown
          items={issueOptions}
          value={issue}
          onChange={({ value }: any) => {
            setModuleData({ ...newserviceRequest, issue: value });
          }}
          isFullWidth
        />
      </div>

      <div data-test={t`web.admin.serviceRequest.form.Location`}>
        <div className={styles.label}>
          <span>{t`web.admin.serviceRequest.form.Location`}</span>
        </div>

        <Dropdown
          items={locationOptions}
          value={location}
          interfaceStyle="light"
          onChange={({ value }: any) => {
            setModuleData({ ...newserviceRequest, location: value });
          }}
          isFullWidth
        />
      </div>
      <TextArea
        label={t`web.admin.serviceRequest.form.description`}
        value={description}
        containerClassName={styles.field}
        onChange={(value: any) => {
          setModuleData({ ...newserviceRequest, description: value });
        }}
        showLengthIndicator
        className={styles.textArea}
        minRows={3}
        errors={!description && t`This field is required`}
        showClear={false}
      />
      {isPropertyChannel && (
        <>
          {!isEnabledCesLinksForServiceRequests && (
            <>
              <Input
                className={styles.field}
                label={t`web.admin.serviceRequest.form.floor.placeholder`}
                value={floor}
                onChange={(value: any) => {
                  setModuleData({ ...newserviceRequest, floor: value });
                }}
                showClear={false}
              />
              <Input
                className={styles.field}
                label={t`web.admin.serviceRequest.form.suite.placeholder`}
                value={suite}
                onChange={(value: any) => {
                  setModuleData({ ...newserviceRequest, suite: value });
                }}
                showClear={false}
              />
            </>
          )}
          {isEnabledCesLinksForServiceRequests &&
            suiteOptions?.length > 0 &&
            (suiteOptions?.length === 1 ? (
              <SingleSuiteText
                suiteName={suiteOptions[0].label}
                className="mb-2"
              />
            ) : (
              <MultiselectField
                label={t('web.admin.serviceRequest.form.suites.label')}
                fixedLabel
                isFullWidth
                isSearchable
                testId="suitesDropdown"
                placeholder={t(
                  'web.admin.serviceRequest.form.suites.placeholder'
                )}
                doTranslation={false}
                value={suiteIds}
                onChange={(value: Item<string>[]) => {
                  setModuleData({ ...newserviceRequest, suiteIds: value });
                }}
                items={suiteOptions}
                isLoading={isFetchingSuites}
              />
            ))}
        </>
      )}
      {settings?.customQuestions &&
        settings.customQuestions.map(
          (customQuestion: PropertyType, index: number) => (
            <CustomQuestionInput
              className={styles.field}
              value={
                customQuestion.friendlyName
                  ? customQuestionValues[customQuestion.friendlyName.toString()]
                  : ''
              }
              customQuestion={customQuestion}
              key={index}
              onAnswer={onCustomQuestionEdit}
            />
          )
        )}
    </div>
  );
};
