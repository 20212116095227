import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  TaxAmountType,
  MarkupAmountType,
  NewProductDetails,
  ExceptionDetails,
} from 'lane-shared/domains/billingPayments/types';

import { RateModifierCheckbox } from 'pages/portal/admin/channel/products-services/products/RateModifierCheckbox';
import {
  ModifierType,
  RateModifierInput,
} from 'pages/portal/admin/channel/products-services/products/RateModifierInput';

import styles from './CreateProductForm.scss';

export interface RateModifiers {
  [ModifierType.MARKUP]: boolean;
  [ModifierType.TAX]: boolean;
  [ModifierType.RATE]: boolean;
}

export function MarkupAndTaxOptions({
  productDetails,
  updateDetails,
  showRate = false,
}: {
  productDetails: NewProductDetails | ExceptionDetails;
  updateDetails: (value: any, field: string) => void;
  showRate?: boolean;
}) {
  const [rateModifiersSelected, setRateModifiersSelected] =
    useState<RateModifiers>({
      [ModifierType.MARKUP]: false,
      [ModifierType.TAX]: false,
      [ModifierType.RATE]: false,
    });

  const defaultValues = {
    [ModifierType.MARKUP]: {
      value: 0,
      type: MarkupAmountType.MarkupAmountTypePercentage,
    },
    [ModifierType.TAX]: {
      value: 0,
      type: TaxAmountType.TaxAmountTypePercentage,
    },
    [ModifierType.RATE]: 0,
  };

  useEffect(() => {
    if (
      productDetails.rate !== undefined &&
      productDetails.rate !== null &&
      Number(productDetails.rate) >= 0 &&
      !rateModifiersSelected[ModifierType.RATE]
    ) {
      setRateModifiersSelected({
        ...rateModifiersSelected,
        [ModifierType.RATE]: true,
      });
    }

    const shouldTaxModifierBeSelectedBecauseTaxDefault =
      productDetails.taxDefault &&
      productDetails.tax &&
      !rateModifiersSelected[ModifierType.TAX];

    const taxAlreadySelected =
      productDetails.tax !== undefined &&
      Number(productDetails.tax.value) >= 0 &&
      !rateModifiersSelected[ModifierType.TAX];

    if (shouldTaxModifierBeSelectedBecauseTaxDefault || taxAlreadySelected) {
      setRateModifiersSelected({
        ...rateModifiersSelected,
        [ModifierType.TAX]: true,
      });
    }

    if (
      productDetails.markup !== undefined &&
      Number(productDetails.markup.value) >= 0 &&
      !rateModifiersSelected[ModifierType.MARKUP]
    ) {
      setRateModifiersSelected({
        ...rateModifiersSelected,
        [ModifierType.MARKUP]: true,
      });
    }
  }, [productDetails, rateModifiersSelected]);

  const toggleRateModifier = (rateModifierToToggle: ModifierType) => {
    const isRateModifierOn = rateModifiersSelected[rateModifierToToggle];
    const shouldUpdateWithTaxDefaultValues =
      rateModifierToToggle === ModifierType.TAX && productDetails.taxDefault;

    // update productDetails with the correct default value before toggling checkbox value
    if (isRateModifierOn) {
      updateDetails(undefined, rateModifierToToggle);
    } else if (shouldUpdateWithTaxDefaultValues) {
      updateDetails(
        {
          value: productDetails.taxDefault!.value,
          type: productDetails.taxDefault!.type,
        },
        'tax'
      );
    } else {
      updateDetails(defaultValues[rateModifierToToggle], rateModifierToToggle);
    }

    setRateModifiersSelected({
      ...rateModifiersSelected,
      [rateModifierToToggle]: !rateModifiersSelected[rateModifierToToggle],
    });
  };

  return (
    <>
      {showRate && (
        <>
          <RateModifierCheckbox
            selected={rateModifiersSelected[ModifierType.RATE]}
            toggleRateModifier={toggleRateModifier}
            type={ModifierType.RATE}
          />
          {rateModifiersSelected[ModifierType.RATE] && (
            <div className={styles.additionalOptionGroup}>
              <RateModifierInput
                modifierValue={productDetails.rate}
                updateDetails={updateDetails}
                type={ModifierType.RATE}
              />
            </div>
          )}
        </>
      )}
      <RateModifierCheckbox
        selected={rateModifiersSelected[ModifierType.MARKUP]}
        toggleRateModifier={toggleRateModifier}
        type={ModifierType.MARKUP}
      />
      {rateModifiersSelected[ModifierType.MARKUP] && (
        <div className={cx(styles.additionalOptionGroup, styles.checkboxItem)}>
          <RateModifierInput
            modifierType={productDetails.markup?.type}
            modifierValue={productDetails.markup?.value}
            updateDetails={updateDetails}
            type={ModifierType.MARKUP}
          />
        </div>
      )}
      <RateModifierCheckbox
        selected={rateModifiersSelected[ModifierType.TAX]}
        toggleRateModifier={toggleRateModifier}
        type={ModifierType.TAX}
      />
      {rateModifiersSelected[ModifierType.TAX] && (
        <div className={cx(styles.additionalOptionGroup, styles.checkboxItem)}>
          <RateModifierInput
            modifierType={productDetails.tax?.type}
            modifierValue={productDetails.tax?.value}
            updateDetails={updateDetails}
            type={ModifierType.TAX}
          />
        </div>
      )}
    </>
  );
}
