import { useTranslation } from 'react-i18next';

// once delete functionaliy added in the CES. we can delete this hook and use useDeleteUnit.tsx
export function useUnitDeleteDisabledAlert() {
  const { t } = useTranslation();

  const handleDeleteAlert = async () => {
    try {
        await window.Alert.confirm({
          title: t('web.admin.common.deleteDisabled.title'),
          message: t('web.admin.common.deleteDisabled.message'),
          confirmText: t('web.admin.common.deleteDisabled.okButton'),
          cancelText: '',
          confirmButtonStyle:{
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
          }
        });
    } catch (err) {
        console.error(`Action cancelled: ${err}`);
    }
  };

  return { handleDeleteAlert };
}