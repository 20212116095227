import { LaneType } from 'common-types';
import { AnalyticsInterface } from '../../../types/Analytics';
import { EVENT_KEYS } from 'constants-events';

type EmitViewButtonClickedType = {
  userId: LaneType.UUID;
  channelId: LaneType.UUID;
  channelName: string;
  contentId: LaneType.UUID;
  contentName: string;
  sectionId: LaneType.UUID;
  sectionName: string;
  analytics: Pick<AnalyticsInterface, 'track'>;
};

export function emitViewButtonClicked({
  userId,
  channelId,
  channelName,
  contentId,
  contentName,
  sectionId,
  sectionName,
  analytics,
}: EmitViewButtonClickedType) {
  // FIXME: Event being sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_VIEW_BUTTON_CLICK_SECTION_CONTENT, {
    userId,
    channelId,
    channelName,
    contentId,
    contentName,
    sectionId,
    sectionName,
  });
}
