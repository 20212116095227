import React from 'react';
import styles from './index.scss';
import { Button, Flex } from 'design-system-web';
import { H5 } from 'components/typography';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { useTranslation } from 'react-i18next';
import { ChannelListItem } from '../../../../components/ChannelListItem';
import { useOnboardingAnalytics } from 'lane-shared/hooks/analytics';
import { Edge } from 'lane-shared/contexts/SignUpContext/useOnboardingBuildings';
import { convertBuildingEdgeToChannelType } from '../OnboardingBuildingInput/ChannelListItemWrapper';

const TRANSLATION_KEYS = {
  yesButton: 'shared.common.yes',
  otherLocationButton: 'shared.onboarding.building.otherLocationButton',
  preselectedPlaceholder: 'shared.onboarding.building.preselectedPlaceholder',
};

type Props = {
  onboardingState: 'loading' | 'idle' | 'error';
  preselectedBuilding: Edge;
  onContinue: (building: ChannelType) => void;
  handlers: {
    handleOtherLocationClick: () => void;
  };
};

export function OnboardingPreselectedBuilding({
  onboardingState,
  preselectedBuilding,
  onContinue,
  handlers,
}: Props) {
  const { t } = useTranslation();
  const { onboardingTracker } = useOnboardingAnalytics();

  const buildingChannel = convertBuildingEdgeToChannelType(preselectedBuilding);

  const onSubmit = () => {
    onboardingTracker.Select.Building({ buildingId: buildingChannel?._id });
    onContinue(buildingChannel);
  };

  return (
    <div className={styles.container}>
      <H5>{t(TRANSLATION_KEYS.preselectedPlaceholder)}</H5>
      <Flex align="center" className={styles.selectedChannelContainer}>
        <ChannelListItem
          className={styles.preselectedChannel}
          showName
          channel={buildingChannel}
          onClick={() => {}}
        />
      </Flex>
      <Button
        className={styles.button}
        fullWidth
        onClick={onSubmit}
        size="large"
        type="submit"
        loading={onboardingState === 'loading'}
      >
        {t(TRANSLATION_KEYS.yesButton)}
      </Button>
      <Button
        className={styles.button}
        fullWidth
        onClick={handlers.handleOtherLocationClick}
        size="large"
        variant="secondary"
        loading={onboardingState === 'loading'}
      >
        {t(TRANSLATION_KEYS.otherLocationButton)}
      </Button>
    </div>
  );
}
