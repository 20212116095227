import React, { useEffect, useState } from 'react';
import { Loading, Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { getClient } from 'lane-shared/apollo';
import { getTag } from 'lane-shared/graphql/content';
import { useParams, useHistory } from 'react-router-dom';
import { H3, S } from 'components/typography';
import { convertToUUID } from 'uuid-encoding';
import TabStrip from 'components/general/TabStrip';
import { BreadCrumbs } from 'components/lds';
import { routes } from 'lane-shared/config';
import { ContentTable } from './tabs/ContentTable';
import { SectionTable } from './tabs/SectionTable';
import type { Tag } from './types';
import { deleteTag } from 'lane-shared/graphql/mutation';
import { PopupButton, Icon } from 'design-system-web';
import type { MenuItemType } from 'design-system-web/components/PopupMenu/PopupMenu';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { useMultiLanguage } from 'lane-shared/hooks';

import styles from './Tags.scss';

enum Tabs {
  Content = 'content',
  Section = 'section',
}

export const TagShow = () => {
  const { t } = useTranslation();

  const [tag, setTag] = useState<Tag | undefined>();
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Content);
  const { tagId } = useParams<{ tagId: string }>();
  const { channel } = useChannelAdminContext();
  const history = useHistory();
  const { translate } = useMultiLanguage();

  useEffect(() => {
    fetchTagDetails();
  }, [tagId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTagDetails = async () => {
    const variables = {
      id: convertToUUID(tagId),
    };

    const { data } = await getClient().query({
      fetchPolicy: 'network-only',
      query: getTag,
      variables,
    });

    if (data?.tag) {
      const translatedTag = translate({ model: data.tag, columns: ['name'] });

      setTag(translatedTag);
    }
  };

  if (!channel || !tag) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  const tabs = [
    {
      value: Tabs.Content,
      label: `web.admin.library.tags.tabs.content`,
    },
    {
      value: Tabs.Section,
      label: 'web.admin.library.tags.tabs.section',
    },
  ];

  const computeTooltipText = (tag: Tag) => {
    let reason;

    if (tag?.contents?.length) {
      reason = t('web.admin.library.tags.contentInUse');
    }

    if (tag.isDefault) {
      reason = t('web.admin.library.tags.contentIsDefault');
    }

    return reason;
  };

  const actions = [
    {
      label: t('Delete'),
      onSelect: _event => onDelete(),
      isDisabled: !!(tag?.contents?.length > 0 || tag?.isDefault),
      tooltipText: computeTooltipText(tag),
    },
    {
      label: t('Edit'),
      onSelect: _event =>
        history.push(
          routes.channelAdminLibraryTagEdit
            .replace(':id', channel?.slug)
            .replace(':tagId', tag.id)
        ),
    },
  ] as MenuItemType[];

  const onDelete = async () => {
    try {
      await window.Alert.confirm({
        title: t('web.pages.portal.admin.channel.tags.deleteConfirmationTitle'),
        message: `${t(
          'web.pages.portal.admin.channel.tags.deleteConfirmationMessage'
        )}`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    await getClient().mutate({
      mutation: deleteTag,
      variables: { id: tagId },
    });

    window.Toast.show(
      t('web.pages.portal.admin.library.tags.show.deleteSuccsess')
    );

    history.push(
      `${routes.channelAdminLibraryTags.replace(':id', channel?.slug!)}`
    );
  };

  return (
    <Flex direction="column" justify="flex-sart" className={styles.container}>
      <BreadCrumbs
        links={[
          {
            label: t('Tags'),
            url: routes.channelAdminLibraryTags.replace(':id', channel.slug),
          },
          {
            label: tag?.name,
          },
        ]}
      />
      <Flex
        direction="row"
        justify="space-between"
        className={styles.marginTop}
      >
        <Flex justify="flex-start" align="center" direction="row">
          <H3>{tag.name}</H3>
          <div className={styles.translationChip}>
            <S>
              {t('web.pages.portal.admin.library.tags.show.translatableField')}
            </S>
            <Icon name="external-link" />
          </div>
        </Flex>
        <PopupButton items={actions} variant="secondary" size="large">
          {t('web.pages.portal.admin.library.tags.show.actionsButtonLabel')}
        </PopupButton>
      </Flex>
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab }}
        onSelectTab={tab => setSelectedTab(tab.value as Tabs)}
        fullWidth
      />
      <div className={styles.tabContentContainer}>
        {selectedTab === Tabs.Content && (
          <ContentTable channel={channel} contents={tag.contents} />
        )}
        {selectedTab === Tabs.Section && (
          <SectionTable channel={channel} sections={tag.sections} />
        )}
      </div>
    </Flex>
  );
};
