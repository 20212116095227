import { Credential, CredentialStatus } from 'graphql-query-contracts';

const PROVIDERS = {
  HID_2_2: 'HID_2_2',
  CCURE_2_8__2_9: 'CCURE_2_8__2_9',
  HID_3_0: 'HID_3_0',
  BRAXOS_1_0: 'BRAXOS_1_0',
  GENEA_2_0: 'GENEA_2_0',
  OPENPATH_1_0: 'OPENPATH_1_0',
  AMAG_9_0: 'AMAG_9_0',
  BRIVO_1_0: 'BRIVO_1_0',
  DATAWATCH_1_0: 'DATAWATCH_1_0',
  MAXXESS_6_0: 'MAXXESS_6_0',
  GENETEC_5_9: 'GENETEC_5_9',
  GALLAGHER_8_9: 'GALLAGHER_8_9',
  DSX_6_0: 'DSX_6_0',
  SALTO_SVN_1_35: 'SALTO_SVN_1_35',
};

export type AvailableCredentialActions = {
  canSuspendOrRevokeOrCancel: () => boolean;
  canResume: () => boolean;
  canSuspend: () => boolean;
  canRevoke: () => boolean;
  canCancel: () => boolean;
  isRevoked: () => boolean;
  isActive: () => boolean;
  isIdP: () => boolean;
};

export const availableCredentialActions = (
  credential: Credential & { hasConnectedCredentials?: boolean }
) => {
  function canSuspendOrRevokeOrCancel() {
    return canSuspend() || canRevoke() || canCancel();
  }

  function canResume() {
    return isIdP() && isAppleWallet() && isSuspended();
  }

  function canSuspend() {
    return isIdP() && isAppleWallet() && isActive();
  }

  function canRevoke() {
    return isIdP() && (isActive() || isFailed());
  }

  function canCancel() {
    return (
      isIdP() && isIssuing() && Boolean(credential.hasConnectedCredentials)
    );
  }

  function isIdP() {
    return [
      PROVIDERS.HID_2_2,
      PROVIDERS.HID_3_0,
      PROVIDERS.SALTO_SVN_1_35,
    ].includes(credential.provider);
  }

  function isAppleWallet() {
    if (!credential.credentialType) return false;

    return (
      credential.credentialType === 'MOBILE_APPLE_WALLET' ||
      credential.credentialType === 'WATCH_APPLE_WALLET' ||
      credential.provider === PROVIDERS.HID_3_0
    );
  }

  function isSuspended() {
    return credential.status === 'SUSPENDED';
  }

  function isActive() {
    return credential.status === 'ACTIVE';
  }

  function isRevoked() {
    return credential.status === CredentialStatus.Revoked;
  }

  function isFailed() {
    return credential.status === CredentialStatus.Failed;
  }

  function isIssuing() {
    return credential.status === CredentialStatus.Issuing;
  }

  const availableActions: AvailableCredentialActions = {
    canSuspendOrRevokeOrCancel,
    canResume,
    canSuspend,
    canRevoke,
    canCancel,
    isRevoked,
    isActive,
    isIdP,
  };

  return availableActions;
};
