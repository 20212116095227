import { ApolloError } from '@apollo/client';
import { useOnboardingCompanyQuery } from 'lane-shared/hooks';

type OnboardingCompaniesParams = {
  query?: string;
  presumedEmailDomain?: string;
  buildingId?: string | null;
  pageSize?: number;
};

export type Edge = {
  cursor: string;
  node: {
    id: string;
    name: string;
    thumbnailUrl: string | null;
    inviteOnly: boolean;
  };
};

export type OnboardingCompanyResult = {
  companies: Edge[];
  isInitialLoading: boolean;
  isFetchingMore: boolean;
  error: ApolloError | null | undefined;
  fetchMore: () => void;
  hasNextPage: boolean;
  refetch: () => void;
};

export function useOnboardingCompanies({
  query = '',
  presumedEmailDomain,
  buildingId,
  pageSize = 10, // Default page size
}: OnboardingCompaniesParams): OnboardingCompanyResult {
  const {
    companies,
    isInitialLoading,
    isFetchingMore,
    error,
    fetchMore,
    hasNextPage,
    refetch,
  } = useOnboardingCompanyQuery({
    query,
    presumedEmailDomain,
    buildingId,
    pageSize,
  });

  return {
    companies,
    isInitialLoading,
    isFetchingMore,
    error,
    fetchMore,
    hasNextPage,
    refetch,
  };
}
