import React, { FormEvent } from 'react';

import { Button, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { ANALYTIC_KEYS } from 'constants-analytics';

import {
  FormValues,
  useSignUpForm,
} from 'lane-shared/hooks/auth/useSignUpForm';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { H4 } from 'components/typography';

import EmailOptIn from '../EmailOptIn';

import styles from './index.scss';

const TRANSLATION_KEYS = {
  title: 'web.signup.form.title',
  nameLabel: 'web.signup.form.fullNameLabel',
  emailLabel: 'web.signup.form.emailLabel',
  passwordLabel: 'web.signup.form.passwordLabel',
  checkBoxLabel: 'web.signup.form.checkboxLabel',
  checkBoxText: 'web.signup.form.checkboxText',
  button: 'web.signup.form.submitButton',
};

export interface SignUpUserData {
  fullName: string;
  email: string;
  password: string;
  emailOptIn: boolean;
}

interface Props {
  email: string;
  name?: string;
  onSubmit: (data: SignUpUserData) => void;
  whitelabel: WhiteLabelType;
}

const INITIAL_VALUES: FormValues = {
  fullName: '',
  email: '',
  password: '',
  emailOptIn: false,
};

function SignUpWithEmailForm({ email, name, onSubmit, whitelabel }: Props) {
  const simpleTrack = useSimpleTrack();
  const { t } = useTranslation();
  const { fullName, password, emailOptIn, setFieldValue, validate, errors } =
    useSignUpForm({
      initialValues: {
        ...INITIAL_VALUES,
        email,
        fullName: name || '',
      },
    });

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    onSubmit({
      fullName,
      email,
      password,
      emailOptIn,
    });
  }

  return (
    <div className={styles.container}>
      <form
        className={styles.formContainer}
        onSubmit={e => {
          simpleTrack(ANALYTIC_KEYS.ANALYTIC_USER_ONBOARDING_PROFILE_CONTINUE, {
            emailOptIn,
          });
          handleSubmit(e);
        }}
      >
        <div className={styles.title}>
          <H4>{t(TRANSLATION_KEYS.title)}</H4>
        </div>
        <Input
          className={styles.input}
          onBlur={() => validate('fullName')}
          onChange={name => setFieldValue('fullName', name)}
          value={fullName}
          label={t(TRANSLATION_KEYS.nameLabel)}
          fieldName="fullName"
          error={errors && errors.fullName ? [errors.fullName] : null}
          autoFocus
        />
        <Input
          className={styles.input}
          onChange={() => {}}
          value={email}
          showClear={false}
          label={t(TRANSLATION_KEYS.emailLabel)}
          fieldName="email"
          readOnly
          icon="lock"
          iconRight
          style={{ color: 'black' }}
        />
        <Input
          className={styles.input}
          type="password"
          onBlur={() => validate('password')}
          onChange={password => setFieldValue('password', password)}
          value={password}
          label={t(TRANSLATION_KEYS.passwordLabel)}
          fieldName="password"
          error={errors && errors.password ? [errors.password] : null}
          showClear={false}
        />
        <EmailOptIn
          onChange={() => setFieldValue('emailOptIn')}
          emailOptIn={emailOptIn}
          whitelabel={whitelabel}
        />
        <Button
          color="inherit"
          disabled={!fullName || !password || !!errors}
          loading={false}
          onClick={() => {}}
          size="large"
          type="submit"
          variant="contained"
          testId="continueSignUp"
        >
          {t(TRANSLATION_KEYS.button)}
        </Button>
      </form>
    </div>
  );
}

export default SignUpWithEmailForm;
