import React from 'react';

import cx from 'classnames';

import styles from './UCITable.scss';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { Column, Sort, Table } from 'design-system-web';
import { useIsAdminView } from 'hooks';
import { routes } from 'lane-shared/config';
import { Link } from 'react-router-dom';

import { UserContentInteraction } from 'graphql-query-contracts';

type Props = {
  selectedSort: Sort['id'];
  selectedOrder: Sort['direction'];
  additionalHeaders?: Column[];
  className?: string;
  style?: React.CSSProperties;
  interactions: UserContentInteraction[];
  onSortClicked: (columnHeaderName: string) => void;
};

export function UCITable({
  className,
  style,
  interactions,
  selectedSort,
  selectedOrder,
  additionalHeaders,
  onSortClicked,
}: Props) {
  const [isAdminView, channelSlug] = useIsAdminView();

  function getInteractionLink(interaction: UserContentInteraction) {
    if (isAdminView) {
      // if we are on a channel admin page, use that slug and direct
      // to the team member page, that will have more info about this user.
      return (
        routes.channelAdminInteraction
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          .replace(':id', channelSlug)
          .replace(':interactionId', interaction._id)
      );
    }

    // other wise send them to the public user page.
    return routes.interaction.replace(':id', interaction._id);
  }

  function getUserLink(interaction: UserContentInteraction) {
    if (isAdminView) {
      // if we are on a channel admin page, use that slug and direct
      // to the team member page, that will have more info about this user.
      return (
        routes.channelAdminTeamMember
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          .replace(':id', channelSlug)
          .replace(':userId', interaction.user._id)
      );
    }

    // other wise send them to the public user page.
    return routes.user.replace(':id', interaction.user._id);
  }

  const baseTableColumns: Column<UserContentInteraction>[] = [
    {
      key: '_created',
      header: 'Created',
      renderCell: created => simpleDate(created),
    },
    {
      key: '_updated',
      header: 'Updated',
      renderCell: updated => simpleDate(updated),
    },
    {
      key: '_id',
      header: 'Id',
      renderCell: (id, interaction) => (
        <Link to={getInteractionLink(interaction)}>{id}</Link>
      ),
      disableSorting: true,
    },
    {
      key: 'status',
      header: 'Status',
    },
    {
      key: 'startDate',
      header: 'Start',
    },
    {
      key: 'endDate',
      header: 'End',
    },
    {
      key: 'contentData.name',
      header: 'Content',
      renderCell: (contentName, interaction) => (
        <Link to={routes.content.replace(':id', interaction.contentData._id)}>
          {contentName}
        </Link>
      ),
      disableSorting: true,
    },
    {
      key: 'user.profile.name',
      header: 'User',
      renderCell: (profileName, interaction) => (
        <Link to={getUserLink(interaction)}>{profileName}</Link>
      ),
      disableSorting: true,
    },
  ];

  const allTableColumns = baseTableColumns.concat(additionalHeaders || []);

  return (
    <div className={cx(styles.UCITable, className)} style={style}>
      <Table
        columns={allTableColumns}
        data={interactions}
        sorting={{
          id: selectedSort,
          direction: selectedOrder,
        }}
        onSortChange={sort => {
          onSortClicked(sort.id);
        }}
      />
    </div>
  );
}
