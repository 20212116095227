import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { UserDataContext } from 'lane-shared/contexts';
import SectionType from 'lane-shared/types/sections/SectionType';
import { Link } from 'react-router-dom';
import { Table, PageSizeType } from 'design-system-web';
import { routes } from 'lane-shared/config';

import { ChannelType } from 'lane-shared/types/ChannelType';
import { formatDateWithUserLocale } from 'helpers';

export const SectionTable = ({
  channel,
  sections,
}: {
  channel: ChannelType;
  sections: SectionType[];
}) => {
  const { t } = useTranslation();

  const { user } = useContext(UserDataContext);

  const columns = [
    {
      header: t`web.pages.portal.admin.library.tags.show.sectionTable.name`,
      key: 'name',
      renderCell: (name: string, sectionItem: SectionType) => (
        <Link
          target="_blank"
          to={{
            pathname: routes.channelAdminSectionsView
              .replace(':id', channel.slug)
              .replace(':sectionId', sectionItem._id),
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      header: t`web.pages.portal.admin.library.tags.show.sectionTable.created`,
      key: '_created',
      renderCell: (created: Date) =>
        formatDateWithUserLocale({
          date: created,
          userLocale: user?.locale,
          formattingOptions: { year: 'numeric' },
        }),
    },
    {
      header: t`web.pages.portal.admin.library.tags.show.sectionTable.updated`,
      key: '_updated',
      renderCell: (updated: Date) =>
        formatDateWithUserLocale({
          date: updated,
          userLocale: user?.locale,
          formattingOptions: { year: 'numeric' },
        }),
    },
  ];

  return (
    <Table
      columns={columns}
      defaultPageSize={10 as PageSizeType}
      hasKeywordFilter
      keywordFilterLabel={t`web.pages.portal.admin.audiences.show.contentTable.contentSearchText`}
      data={sections}
      pagination="client"
    />
  );
};
