import {
  FilterType,
  CustomFilterType,
  NativeFilterTypes,
} from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { useEffect, useRef } from 'react';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';

import { ChargeCode as gqlChargeCode } from 'graphql-query-contracts';

import {
  ExternalPayerType,
  InvoiceeInfo,
} from 'lane-shared/domains/billingPayments/types';
import { searchWorkplaceMembersAndCompanies } from '../helpers/searchWorkplaceMembersAndCompanies';
import { getCustomerNameById } from '../helpers/getCustomerNameById';
import { convertTo62 } from 'uuid-encoding';
import { useChannelAdminContext } from 'hooks';

const PER_PAGE = 50;

export const DEFAULT_BILLING_REPORTS_FILTER_PARAMS = {
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
};

export type BillingReportsQueryString = {
  page?: string;
  pageSize?: string;
  total?: string;
  chargeCode?: string;
  createdAt?: string;
  payer?: string;
};

const useCustomerSearchInput = (channelId: string | undefined) => {
  const customerSearchInputRef = useRef<(inputValue: string) => Promise<any>>(
    async () => []
  );

  useEffect(() => {
    if (channelId) {
      customerSearchInputRef.current =
        searchWorkplaceMembersAndCompanies(channelId);
    }
  }, [channelId]);

  return customerSearchInputRef.current;
};

export const useChargeReportsFilters = (
  chargeCodes: gqlChargeCode[],
  channelId: string | null
): (FilterType | CustomFilterType<any>)[] => {
  const chargeCodeOptions = (chargeCodes as gqlChargeCode[]).map(
    chargeCode => ({
      label: chargeCode.chargeCode,
      value: convertTo62(chargeCode.id),
    })
  );
  const { channel } = useChannelAdminContext();
  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';
  const customerSearchInput = useCustomerSearchInput(channelId || '');

  const loadCustomerOptions = async (inputValue: string) => {
    const customers = await customerSearchInput(inputValue);

    const dropdownOptions = customers.map((customer: InvoiceeInfo) => {
      const prefix =
        customer.type === ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY
          ? 'c-'
          : 'u-';

      return {
        label: customer.name,
        value: prefix + customer._id,
      };
    });

    return dropdownOptions;
  };

  const loadDefaultOptions = async (value: string) => {
    const customerSplit = value.split('-');
    const customerType =
      customerSplit[0] === 'c'
        ? ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY
        : ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER;

    const customer = await getCustomerNameById(customerSplit[1], customerType);

    if (customer) {
      const dropdownOptions = [
        {
          label: customer.name,
          value,
        },
      ];

      return dropdownOptions;
    }

    return [];
  };

  const { t } = useTranslation();

  const tableFilters: (FilterType | CustomFilterType<any>)[] = [
    {
      key: 'createdAt',
      label: t('abp.chargeBillingReports.createdDate'),
      type: NativeFilterTypes.DateRange,
      isPromoted: true,
      hideLabel: true,
      timeZone,
    },
    {
      key: 'chargeCode',
      label: t('abp.chargeBillingReports.chargeCode'),
      type: NativeFilterTypes.Multiselect,
      doTranslation: false,
      isPromoted: true,
      options: chargeCodeOptions,
    },
    {
      key: 'payer',
      label: t('abp.chargeBillingReports.customer'),
      type: NativeFilterTypes.Select,
      options: [],
      doTranslation: false,
      isPromoted: true,
      loadOptions: loadCustomerOptions,
      loadDefaultOptions,
      placeholder: t('abp.chargeBillingReports.searchCustomers'),
    },
  ];

  return tableFilters;
};
