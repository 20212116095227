import { CustomQuestionBuilder } from './ServiceRequestDataBuilder/customQuestionBuilder';
import { LocationBuilder } from './ServiceRequestDataBuilder/locationBuilder';
import { ServiceCategoriesBuilder } from './ServiceRequestDataBuilder/serviceCategoriesBuilder';
import { ServiceRequestBuilderContext } from './contexts/ServiceRequestBuilderContext';
import styles from './index.scss';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { H3 } from 'components/typography';
import { LaneType } from 'common-types';
import routes from 'lane-shared/config/routes';
import {
  WorkOrderModuleCategoriesSettings,
  WorkOrderModuleSettings,
} from 'lane-shared/domains/workOrder';
import { queryContentOnChannel } from 'lane-shared/graphql/channel';
import updateChannelModuleMutation from 'lane-shared/graphql/modules/updateChannelModule';
import { getDraftContent } from 'lane-shared/graphql/query';
import { castGraphQLObject } from 'lane-shared/helpers';
import { convertToUUID } from 'uuid-encoding';
import {
  useCreateDraft,
  useFlag,
  useChannelServiceRequestFeaturesContext,
} from 'lane-shared/hooks';
import {
  ActiveChannelTypeEnum,
  ChannelType,
} from 'lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { AdminPage, Button, TabStrip, ErrorMessage, Loading } from 'components';
import DraftContentTargetingEdit from 'components/lane/DraftContent/DraftContentTargetingEdit';
import DraftInfo from 'components/lane/DraftContent/DraftInfo';
import { AlertType, BreadCrumbs } from 'components/lds';
import { BlockBuilder } from 'components/renderers/BlockBuilder';
import LeaveConfirmationPrompt from 'domains/visitorManagement/components/LeaveConfirmationPrompt/LeaveConfirmationPrompt';
import history from 'helpers/history';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from 'constants-flags';
import { TabItem } from 'components/general/TabStrip';
import { NotificationsTab } from './tabs/NotificationsTab';

enum Tabs {
  ServiceCategories = 'serviceCategories',
  Locations = 'locations',
  CustomQuestions = 'customQuestions',
  Info = 'info',
  Layout = 'layout',
  Targeting = 'targeting',
  Billing = 'billing',
  Notifications = 'notifications',
}

export interface ServiceRequestBuilderProps {
  channel?: ChannelType;
  user?: UserType;
  refetchChannel?: () => void;
}

export const ServiceRequestBuilder: React.FC<ServiceRequestBuilderProps> = ({
  channel,
  user,
  refetchChannel = () => {},
}) => {
  const { t } = useTranslation();
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();

  const isInvoicingEnabled = useFlag(
    FeatureFlag.WorkOrdersServiceRequestInvoicing,
    false
  );

  const hasProductsAndServicesEnabled = useFlag(
    FeatureFlag.ProductsAndServices,
    false
  );

  const isBillingEnabled =
    channel?.settings.hasBillingPaymentsEnabled &&
    hasProductsAndServicesEnabled;

  const isConfigurableNotificationsEnabled = useFlag(
    FeatureFlag.WorkOrdersConfigurableNotifications,
    false
  );

  const [isServiceRequestDataReady, setIsServiceRequestDataReady] =
    useState<boolean>(true);
  const tabs = [
    {
      value: Tabs.ServiceCategories,
      label: t`web.admin.serviceRequest.settings.tabs.serviceConfiguration`,
    },
    {
      value: Tabs.Locations,
      label: t`web.admin.serviceRequest.settings.tabs.locations`,
    },
    {
      value: Tabs.CustomQuestions,
      label: t`web.admin.serviceRequest.settings.tabs.customQuestions`,
    },
    {
      value: Tabs.Layout,
      label: t`web.admin.serviceRequest.settings.tabs.layout`,
    },
    {
      value: Tabs.Targeting,
      label: t`web.admin.serviceRequest.settings.tabs.targeting`,
    },
    {
      value: Tabs.Info,
      label: t`web.admin.serviceRequest.settings.tabs.info`,
    },
  ];

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  if (isInvoicingEnabled && isBillingEnabled && isPropertyChannel) {
    tabs.splice(3, 0, {
      value: Tabs.Billing,
      label: t`web.admin.serviceRequest.settings.tabs.billing`,
    });
  }

  if (isConfigurableNotificationsEnabled) {
    tabs.push({
      value: Tabs.Notifications,
      label: t`web.admin.serviceRequest.settings.tabs.notifications`,
    });
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [settings, setSettings] = useState<WorkOrderModuleSettings>();
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.ServiceCategories);
  const [existingContent, setExistingContent] =
    useState<null | LaneType.Content>(null);
  const [errors, setErrors] = useState<String[] | null>(null);
  const [missingFields, setMissingFields] = useState<String[]>([]);
  const [hideSaveChages, setHideSaveChanges] = useState<boolean>(false);
  const [updateChannelModule] = useMutation(updateChannelModuleMutation);
  const [getContentOnChannel, contentResults] = useLazyQuery(
    queryContentOnChannel
  );

  useEffect(() => {
    if (
      contentResults &&
      !existingContent &&
      contentResults?.data?.contentOnChannel?.items?.length > 0
    ) {
      setExistingContent(contentResults?.data?.contentOnChannel?.items[0]);
    }
  }, [contentResults]);

  useEffect(() => {
    if (!existingContent && channel?._id) {
      getContentOnChannel({
        variables: {
          channelId: channel?._id,
          search: {
            type: {
              any: ContentTypeEnum.WorkOrder,
            },
          },
        },
      });

      const wOModule = channel?.channelModules?.find(
        module => module.name === ModuleCategoryEnum.WorkOrders
      );

      setSettings(wOModule?.settings as WorkOrderModuleSettings);
    }
  }, [channel?._id]);

  const { data: draftData, loading: dataLoading } = useQuery(getDraftContent, {
    skip: !existingContent?._id,
    variables: {
      id: existingContent?._id ? convertToUUID(existingContent._id) : undefined,
    },
  });

  const {
    content,
    validation,
    hasChanged,
    updateContent,
    updateTimeZone,
    addMetatag,
    removeMetatag,
    saveDraft,
    publishDraft,
  } = useCreateDraft({
    existingContent,
    forEdit: true,
    forCreate: false,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentTypeEnum' is not assignable to type '... Remove this comment to see the full error message
    contentType: ContentTypeEnum.WorkOrder,
    channel,
    draft: castGraphQLObject(draftData?.draftContent),
  });

  const saveAndPublish = async () => {
    setLoading(true);
    setIsPristine(true);
    setErrors(null);
    await saveDraft();
    await publishDraft();
    const workOrdersModule = channel?.channelModules?.find(
      module => module.name === ModuleCategoryEnum.WorkOrders
    );

    try {
      await updateChannelModule({
        variables: {
          channelModule: {
            ...workOrdersModule,
            __typename: undefined,
            channelId: channel?._id,
            settings,
          },
        },
      });
      await refetchChannel();
    } catch (ex: any) {
      setErrors([ex.message]);
      setIsPristine(false);
      setLoading(false);

      return;
    }

    window.Toast.show(
      t('web.admin.serviceRequest.settings.toast.successMessage')
    );
    setLoading(false);
  };

  useEffect(() => {
    if (
      (selectedTab === Tabs.ServiceCategories ||
        selectedTab === Tabs.Locations) &&
      !serviceRequestFeatures.createServiceRequest
    ) {
      setHideSaveChanges(true);
    } else {
      setHideSaveChanges(false);
    }
  }, [selectedTab, serviceRequestFeatures.createServiceRequest]);

  function onLayoutUpdated(data: any) {
    const children = data.block.properties.children;

    for (let i = 0; i < missingFields.length; i++) {
      if (children.find((block: any) => block.for === missingFields[i])) {
        const newMissingFields = [...missingFields];

        newMissingFields.splice(i, 1);
        setMissingFields(newMissingFields);
      }
    }

    updateContent(data);
    setIsPristine(false);
  }

  function onContentUpdated(data: any) {
    updateContent(data);
    setIsPristine(false);

    return data;
  }

  if (loading || dataLoading || !existingContent || !settings) {
    return <Loading fullscreen />;
  }

  const renderTabs = () => {
    if (!channel || !content || !user) {
      return;
    }

    switch (selectedTab) {
      case Tabs.ServiceCategories:
        return (
          <ServiceCategoriesBuilder
            categories={settings.categories}
            onSettingsUpdated={(
              categories: WorkOrderModuleCategoriesSettings[]
            ) => {
              setSettings({
                ...settings,
                categories,
              });
            }}
            channelId={channel?._id}
          />
        );

      case Tabs.Locations:
        return (
          <LocationBuilder
            settings={settings}
            onSettingsUpdated={(locations: string[]) => {
              setSettings({
                ...settings,
                locations,
              });
            }}
          />
        );

      case Tabs.CustomQuestions:
        return (
          <CustomQuestionBuilder
            customQuestions={settings.customQuestions || []}
            onSettingsUpdated={(customQuestions: PropertyType[]) => {
              setSettings({
                ...settings,
                customQuestions,
              });
            }}
          />
        );

      case Tabs.Info:
        return (
          <DraftInfo
            validation={validation}
            channel={channel}
            content={content}
            onContentUpdated={onContentUpdated}
            onMetatagAdded={addMetatag}
            onMetatagRemoved={removeMetatag}
            onTimeZoneUpdated={updateTimeZone}
          />
        );

      case Tabs.Layout:
        return (
          <BlockBuilder
            channel={channel}
            user={user}
            content={content}
            onContentUpdated={onLayoutUpdated}
            onDataValidation={() => {}}
          />
        );

      case Tabs.Targeting:
        return (
          <DraftContentTargetingEdit
            user={user}
            hasChanged={hasChanged}
            content={content}
            channel={channel}
            onContentUpdated={onContentUpdated}
          />
        );

      case Tabs.Notifications:
        return <NotificationsTab channel={channel} user={user} />;

      default:
        return null;
    }
  };

  const handleTabChange = (tab: TabItem) => {
    if (tab.value === Tabs.Billing && channel) {
      history.push(
        routes.channelAdminWorkOrdersServiceRequestsBillingSettings.replace(
          ':id',
          channel._id
        )
      );
    } else {
      setSelectedTab(tab.value as Tabs);
    }
  };

  return (
    <ServiceRequestBuilderContext.Provider
      value={{ isServiceRequestDataReady, setIsServiceRequestDataReady }}
    >
      <AdminPage className={styles.AdminViewContent}>
        <LeaveConfirmationPrompt
          when={!isPristine}
          navigate={path => history.push(path)}
        />
        <BreadCrumbs
          links={[
            {
              label: t('web.admin.serviceRequest.title'),
              url: channel?.slug
                ? routes.channelAdminWorkOrdersServiceRequests.replace(
                    ':id',
                    channel?.slug
                  )
                : undefined,
            },
            { label: t('web.admin.serviceRequest.settings') },
          ]}
        />
        <div className={styles.headingContainer}>
          <H3>{t`web.admin.serviceRequest.settings`}</H3>
          {!hideSaveChages && (
            <Button
              variant="contained"
              loading={loading}
              disabled={
                !isServiceRequestDataReady || selectedTab === Tabs.Notifications
              }
              onClick={saveAndPublish}
            >
              {t`web.admin.serviceRequest.save.changes.text`}
            </Button>
          )}
        </div>
        <TabStrip
          tabs={tabs}
          disabled={!isServiceRequestDataReady}
          selected={{ value: selectedTab }}
          onSelectTab={handleTabChange}
          skipLabelTranslation
          className={styles.tabs}
        />
        {errors && errors.length > 0 && <ErrorMessage error={errors} />}
        {missingFields.length > 0 && (
          <ErrorMessage
            color={AlertType.warning}
            error={missingFields.map(
              missingField =>
                `${missingField} ${t(
                  'web.admin.serviceRequest.missing.layout.error'
                )}`
            )}
          />
        )}
        {renderTabs()}
      </AdminPage>
    </ServiceRequestBuilderContext.Provider>
  );
};
