import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { usePaymentMethodFeature } from 'lane-shared/domains/billingPayments/hooks';
import { HookOptions as UsePaymentHookOptions } from 'lane-shared/domains/billingPayments/hooks/usePaymentMethodFeature';

import { PaymentOptions } from 'components/features/Payment/PaymentSummaryV2/PaymentOptions/PaymentOptions';
import { ErrorMessage } from 'components/general';

import styles from './WalletV2.scss';

const paymentOptionFunctionPlaceHolder = () => {};

// TODO: Rename to Wallet and delete the old Wallet component - TM-11004
export function WalletV2() {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const {
    savedPaymentMethods,
    savePaymentMethod,
    removePaymentMethod,
    createUser,
    paymentMethodError,
    removeAutopaymentMethod,
    invoiceAutopayment,
    saveAutopaymentMethod,
  } = usePaymentMethodFeature({ user } as UsePaymentHookOptions);

  return (
    <>
      <div className={styles.walletV2Container}>
        <PaymentOptions
          updatePaymentMethod={paymentOptionFunctionPlaceHolder}
          createUser={createUser}
          savePaymentMethod={savePaymentMethod}
          savedPaymentMethods={savedPaymentMethods}
          selectedPaymentMethod={undefined}
          removePaymentMethod={removePaymentMethod}
          removeAutopaymentMethod={removeAutopaymentMethod}
          invoiceAutopayment={invoiceAutopayment}
          isWallet
          loading={false}
          onSubmit={() => {}}
          saveAutopaymentMethod={saveAutopaymentMethod}
        />
      </div>
      {paymentMethodError && (
        <ErrorMessage error={t(paymentMethodError.message)} />
      )}
    </>
  );
}
