import styles from './index.scss';
import { H4 } from 'components/typography';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import React from 'react';
import { Trans } from 'react-i18next';

const TRANSLATION_KEYS = {
  header: 'shared.onboarding.channelSelection.header',
  headerV2: 'shared.onboarding.channelSelection.header.v2',
};

export function Header({ userName }: { userName: string }) {
  const isEnhancedOnboardingFeatureEnabled = useFlag(
    FeatureFlag.EnhancedOnboardingChannelSearch,
    false
  );

  return (
    <div className={styles.header}>
      <H4>
        <Trans
          i18nKey={
            isEnhancedOnboardingFeatureEnabled
              ? TRANSLATION_KEYS.headerV2
              : TRANSLATION_KEYS.header
          }
          components={[<br key="br" />]}
          values={{
            userName,
          }}
        />
      </H4>
    </div>
  );
}
