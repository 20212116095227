import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';
import { RecurringCharge, RecurrenceStatus } from 'graphql-query-contracts';
import { useCancelSubscription } from './helpers/useCancelSubscription';
import { Button } from 'design-system-web';

export function ChargeCardHeaderRightSlot({
  subscription,
  refetch,
}: {
  subscription: RecurringCharge;
  refetch: Function;
}) {
  const { t } = useTranslation();
  const { cancelSubscription } = useCancelSubscription({
    subscription,
    refetch,
  });

  const { isActive, anyoneCanCancel, status } = subscription.recurrence;

  const isCancellable = isActive && anyoneCanCancel;
  const isExpired =
    !isActive && (!status || status === RecurrenceStatus.Expired);
  const isCancelled = !isActive && status === RecurrenceStatus.Cancelled;

  return (
    <>
      {isCancellable && (
        <Button
          interfaceStyle="light"
          variant="secondary"
          onClick={cancelSubscription}
        >
          {t('abp.actions.cancelSubscription')}
        </Button>
      )}
      {isExpired && (
        <span className={styles.expiredHeading}>
          {t('abp.subscription.card.expired')}
        </span>
      )}
      {isCancelled && (
        <span className={styles.expiredHeading}>
          {t('abp.subscription.card.cancelled')}
        </span>
      )}
    </>
  );
}
