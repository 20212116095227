import React, { useEffect, useState } from 'react';

import { TurnOnAutopaymentModal } from './TurnOnAutopaymentModal';
import styles from './invoiceAutopayment.scss';
import { Icon } from 'design-system-web';
import { Modal } from 'components/lds';
import { M } from 'components/typography';
import {
  usePaymentMethodFeature,
  useUserBillingAndPaymentContext,
} from 'lane-shared/domains/billingPayments/hooks';
import { FONT_AWESOME_LIGHT } from 'lane-shared/helpers/constants/icons';

import { useTranslation } from 'react-i18next';
import { AutopaymentCta } from './AutopaymentCta';
import { TurnOffAutopaymentModal } from './TurnOffAutopaymentModal';
import { Elements } from '@stripe/react-stripe-js';
import { usePaymentProvider } from 'lane-shared/domains/billingPayments/hooks/usePaymentProvider';
import useUserLocale from 'lane-shared/hooks/useUserLocale';
import { ElementOptions } from 'domains/billingAndPayments/components/StripeElementOptions';

type InvoiceAutopaymentProps = {
  user: any;
};

export function InvoiceAutopayment({ user }: InvoiceAutopaymentProps) {
  const { t } = useTranslation();
  const { provider } = usePaymentProvider();
  const userLocale = useUserLocale();
  const elementOptions = ElementOptions(userLocale);

  const {
    invoiceAutopayment,
    saveAutopaymentMethod,
    savedPaymentMethods,
    removeAutopaymentMethod,
    paymentMethodError,
  } = usePaymentMethodFeature({
    user,
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isTurnOffModalOpen, setIsTurnOffModalOpen] = useState(false);

  const isAutopaymentEnabled = Boolean(
    invoiceAutopayment?.isInvoiceAutopaymentEnabled
  );

  const { updateInvoiceAutopayEnabled } = useUserBillingAndPaymentContext();

  useEffect(() => {
    updateInvoiceAutopayEnabled(isAutopaymentEnabled);
  }, [isAutopaymentEnabled, updateInvoiceAutopayEnabled]);

  const autopaymentStatus = () => {
    return `${t('abp.routes.accountInvoices.autopayment.automaticPayments')}:
      ${
        isAutopaymentEnabled
          ? t('abp.routes.accountInvoices.autopayment.on')
          : t('abp.routes.accountInvoices.autopayment.off')
      }`;
  };

  const autopaymentDetails = () => {
    if (isAutopaymentEnabled) {
      const { brand, last4, expiryMonth, expiryYear } =
        invoiceAutopayment!.paymentSource!;

      return (
        <>
          <M bold>
            {t('abp.routes.accountInvoices.autopayment.paymentMethod')}
          </M>
          {` ${brand.toUpperCase()} ****${last4} ${t(
            'abp.payment.exp'
          )} ${expiryMonth}/${expiryYear}`}
        </>
      );
    }

    return t(
      'abp.routes.accountInvoices.autopayment.automaticPaymentsDescription'
    );
  };

  useEffect(() => {
    if (paymentMethodError) {
      window.Toast.show(
        t('abp.routes.accountInvoices.autopayment.error', {
          errorMessage: paymentMethodError.message,
        }),
        30000
      );
    }
  }, [paymentMethodError]);

  return (
    <div className={styles.invoiceAutopaymentContainer}>
      <div className={styles.autopaymentInfo}>
        <Icon
          name="credit-card"
          size="large"
          type={FONT_AWESOME_LIGHT}
          className={styles.creditCardIcon}
        />
        <div className={styles.autopaymentStatus}>
          <M>{autopaymentStatus()}</M>
          <M className={isAutopaymentEnabled && styles.autopaymentCardDetails}>
            {autopaymentDetails()}
          </M>
        </div>
      </div>
      <div className={styles.autopaymentCta}>
        <AutopaymentCta
          isAutopaymentEnabled={isAutopaymentEnabled}
          setIsAddModalOpen={setIsAddModalOpen}
          setIsTurnOffModalOpen={setIsTurnOffModalOpen}
        />
      </div>
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        className={styles.addAutopaymentModal}
        disableCloseOnBackgroundClick
        testId="AddAutopaymentModal"
      >
        <Elements stripe={provider} options={elementOptions}>
          <TurnOnAutopaymentModal
            paymentSources={savedPaymentMethods}
            saveAutopaymentMethod={saveAutopaymentMethod}
            closeModal={() => setIsAddModalOpen(false)}
          />
        </Elements>
      </Modal>
      <Modal
        isOpen={isTurnOffModalOpen}
        onClose={() => setIsTurnOffModalOpen(false)}
        className={styles.turnOffAutopaymentModal}
        disableCloseOnBackgroundClick
        testId="TurnOffAutopaymentModal"
      >
        <TurnOffAutopaymentModal
          closeModal={() => setIsTurnOffModalOpen(false)}
          removeAutopayment={removeAutopaymentMethod}
          existingAutopaymentMethod={
            invoiceAutopayment?.paymentSource?.paymentMethodId || ''
          }
        />
      </Modal>
    </div>
  );
}
