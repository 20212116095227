import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components';

import { H5 } from 'components/typography';
import {
  Charge,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { Chip, ChipStyle } from 'components/ads';

import styles from './ChargeItemsTable.scss';

import { SupportedLocaleEnum } from 'localization';
import { ItemRow } from '../ItemRow/ItemRow';
import { getChargeStatus } from 'lane-shared/helpers/features';

export function ChargeItemsTable({
  charge,
  currency,
  locale,
}: {
  charge: Charge;
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
}) {
  const { t } = useTranslation();
  const { name, items, status } = charge;
  const chargeStatus = getChargeStatus(status, charge);

  return (
    <Flex className={styles.ChargeItemsTable} direction="column" gap={5}>
      <Flex justify="space-between" align="center">
        <H5>
          {t('abp.charges.chargeManager.list.chargeItem', {
            name,
          })}
        </H5>
        <Chip
          value={chargeStatus[0]}
          type={chargeStatus[1] as ChipStyle}
          size="xs"
        />
      </Flex>
      {items.map((item, index) => (
        <ItemRow
          key={item.id || index}
          item={item}
          currency={currency}
          locale={locale}
        />
      ))}
    </Flex>
  );
}
