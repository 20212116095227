import { colors } from 'lane-shared/config';
import React from 'react';

export default function IconClipboard() {
  return (
    <svg
      width={18}
      height={24}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.90625 13.4531L8.90625 10.4531C9 10.3594 9 10.2188 8.90625 10.125L8.29688 9.51562C8.20312 9.42188 8.0625 9.42188 7.96875 9.51562L5.71875 11.7188L4.78125 10.7344C4.64062 10.6406 4.5 10.6406 4.40625 10.7344L3.79688 11.3438C3.70312 11.4375 3.70312 11.5781 3.79688 11.6719L5.53125 13.4531C5.625 13.5469 5.8125 13.5469 5.90625 13.4531ZM15.75 3H12C12 1.35938 10.6406 0 9 0C7.3125 0 6 1.35938 6 3H2.25C0.984375 3 0 4.03125 0 5.25V21.75C0 23.0156 0.984375 24 2.25 24H15.75C16.9688 24 18 23.0156 18 21.75V5.25C18 4.03125 16.9688 3 15.75 3ZM9 2.25C9.375 2.25 9.75 2.625 9.75 3C9.75 3.42188 9.375 3.75 9 3.75C8.57812 3.75 8.25 3.42188 8.25 3C8.25 2.625 8.57812 2.25 9 2.25ZM15.75 21.375C15.75 21.6094 15.5625 21.75 15.375 21.75H2.625C2.39062 21.75 2.25 21.6094 2.25 21.375V5.625C2.25 5.4375 2.39062 5.25 2.625 5.25H4.5V6.75C4.5 7.17188 4.82812 7.5 5.25 7.5H12.75C13.125 7.5 13.5 7.17188 13.5 6.75V5.25H15.375C15.5625 5.25 15.75 5.4375 15.75 5.625V21.375ZM5.25 15.375C4.59375 15.375 4.125 15.8906 4.125 16.5C4.125 17.1562 4.59375 17.625 5.25 17.625C5.85938 17.625 6.375 17.1562 6.375 16.5C6.375 15.8906 5.85938 15.375 5.25 15.375ZM13.125 11.25H10.125C10.0781 11.3438 10.0312 11.4375 9.98438 11.5312L8.71875 12.75H13.125C13.3125 12.75 13.5 12.6094 13.5 12.375V11.625C13.5 11.4375 13.3125 11.25 13.125 11.25ZM13.125 15.75H7.875C7.64062 15.75 7.5 15.9375 7.5 16.125V16.875C7.5 17.1094 7.64062 17.25 7.875 17.25H13.125C13.3125 17.25 13.5 17.1094 13.5 16.875V16.125C13.5 15.9375 13.3125 15.75 13.125 15.75Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-15}
          y1={-12}
          x2="41.4236"
          y2="1.96448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset={1} stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
