import Storage from 'lane-shared/helpers/Storage';
import { authorizationHeader } from 'lane-shared/helpers/formatters';
import makeFileDownload from './makeFileDownload';

export default async function downloadBulkFiles<TBody = any>(
  url: string,
  body: TBody,
  customFilename?: string
) {
  const token = await Storage.getItem(Storage.AUTH_TOKEN);

  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: authorizationHeader(token),
    }),
    body: JSON.stringify(body),
  });

  const blob = await response.blob();
  const fileType = response.headers.get('content-type');
  const [, fileName] = (
    response.headers.get('content-disposition') || ''
  ).split('filename=');

  makeFileDownload({
    type: fileType || 'application/zip',
    name: customFilename || fileName || 'download.zip',
    blob,
  });
}
