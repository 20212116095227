export const propertyReports = {
  content: {
    label: 'web.admin.channel.insightsReports.content.label',
    description: 'web.admin.channel.insightsReports.content.description',
  },
  user: {
    label: 'web.admin.channel.insightsReports.user.label',
    description: 'web.admin.channel.insightsReports.user.description',
  },

  portfolio: {
    label: 'web.admin.channel.insightsReports.portfolio.label',
    description: 'web.admin.channel.insightsReports.portfolio.description',
  },

  reservation: {
    label: 'web.admin.channel.insightsReports.reservation.label',
    description: 'web.admin.channel.insightsReports.reservation.description',
  },

  user_search: {
    label: 'web.admin.channel.insightsReports.userSearch.label',
    description: 'web.admin.channel.insightsReports.userSearch.description',
  },
  post_analytics: {
    label: 'web.admin.channel.insightsReports.postAnalytics.label',
    description: 'web.admin.channel.insightsReports.postAnalytics.description',
  },
  company_analytics: {
    label: 'web.admin.channel.insightsReports.companyAnalytics.label',
    description:
      'web.admin.channel.insightsReports.companyAnalytics.description',
  },
  work_orders_analytics: {
    label: 'web.admin.channel.insightsReports.workOrderAnalytics.label',
    description:
      'web.admin.channel.insightsReports.workOrderAnalytics.description',
  },
  tenant_sentiment_analytics: {
    label: 'web.admin.channel.insightsReports.tenantSentimentAnalytics.label',
    description:
      'web.admin.channel.insightsReports.tenantSentimentAnalytics.description',
  },
  access_analytics: {
    label: 'web.admin.channel.insightsReports.accessAnalytics.label',
    description:
      'web.admin.channel.insightsReports.accessAnalytics.description',
  },
  event_analytics: {
    label: 'web.admin.channel.insightsReports.eventAnalytics.label',
    description: 'web.admin.channel.insightsReports.eventAnalytics.description',
  },
  visitor_management_analytics: {
    label: 'web.admin.channel.insightsReports.visitorManagement.label',
    description:
      'web.admin.channel.insightsReports.visitorManagement.description',
  },
};

export const tenantReports = {
  content: {
    label: 'web.admin.channel.insightsReports.content.label',
    description: 'web.admin.channel.insightsReports.content.description',
  },
  post_analytics: {
    label: 'web.admin.channel.insightsReports.postAnalytics.label',
    description: 'web.admin.channel.insightsReports.postAnalytics.description',
  },
  work_orders_analytics: {
    label: 'web.admin.channel.insightsReports.workOrderAnalytics.label',
    description:
      'web.admin.channel.insightsReports.workOrderAnalytics.description',
  },
  access_analytics: {
    label: 'web.admin.channel.insightsReports.accessAnalytics.label',
    description:
      'web.admin.channel.insightsReports.accessAnalytics.description',
  },
  event_analytics: {
    label: 'web.admin.channel.insightsReports.eventAnalytics.label',
    description: 'web.admin.channel.insightsReports.eventAnalytics.description',
  },
  visitor_management_analytics: {
    label: 'web.admin.channel.insightsReports.visitorManagement.label',
    description:
      'web.admin.channel.insightsReports.visitorManagement.description',
  },
};
