import { StyleSheet } from '@react-pdf/renderer';

import { spacing, colors } from 'lane-shared/config';

export const styles = StyleSheet.create({
  page: {
    maxWidth: '100vw',
    backgroundColor: 'white',
    margin: spacing[5],
    paddingBottom: 65,
  },
  line: {
    marginVertical: spacing[3],
    marginLeft: spacing[2],
  },
  header: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 12,
    marginBottom: spacing[4],
    margin: spacing[2],
  },
  subheadings: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    fontWeight: 'heavy',
    margin: spacing[2],
    color: colors.subduedText,
  },
  pageNumber: {
    fontFamily: 'Helvetica',
    position: 'absolute',
    fontSize: 12,
    bottom: 0,
    right: 0,
    padding: spacing[7],
    color: colors.neutral.grey700,
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginHorizontal: spacing[4],
    columnGap: spacing[2],
  },
  nameCell: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '25%',
    fontSize: 10,
    gap: spacing[2],
  },
  name: {
    marginTop: spacing[2],
  },
  actionCell: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '45%',
    fontSize: 10,
    gap: spacing[1],
  },
  dateCell: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '20%',
    fontSize: 10,
    gap: spacing[1],
  },
  time: {
    color: colors.subduedText,
  },
  actionItem: {
    gap: spacing[1],
  },
  change: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing[1],
  },
  changeText: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.subduedText,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    color: colors.subduedText,
  },
  activtyText: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    fontWeight: 'heavy',
    marginBottom: spacing[4],
    marginLeft: spacing[3],
  },
  pageCounter: {
    position: 'absolute',
    fontSize: 10,
    fontStyle: 'italic',
    bottom: 40,
    left: 0,
    right: 50,
    textAlign: 'right',
    color: 'grey',
  }
});
