import React from 'react';
import { Icon } from 'design-system-web';
import { H5, M } from 'components/typography';
import styles from './styles.scss';
import { RecurringCharge } from 'graphql-query-contracts';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { getUserLocale } from 'helpers';
import {
  Currency,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { ChargeCardHeaderRightSlot } from './ChargeCardHeaderRightSlot';

export function ChargeCardHeader({
  subscription,
  refetch,
}: {
  subscription: RecurringCharge;
  refetch: Function;
}) {
  const { t } = useTranslation();

  const { recurrenceInterval } = subscription.recurrence;
  const { description, amount, name, currency } = subscription.charge;

  const currencyFormat = currencyFormatter({
    currency: currencyToPaymentCurrency(Currency[currency]),
    locale: getUserLocale(),
  });
  const currencyAmount = currencyFormat(amount);

  return (
    <>
      <span className={styles.heading}>
        <Icon
          name="RefreshCw"
          set="Feather"
          style={{ fontSize: 20, marginBottom: 10, marginRight: 16 }}
        />
        <H5>{name}</H5>
      </span>
      <span className={styles.testing}>
        <span className={styles.amountHeading}>
          <span className={styles.displayAmount}>
            <H5>{currencyAmount}</H5>
            <H5 className={styles.timeFrame}>
              {t(`abp.subscription.timeFrame.${recurrenceInterval}`)}
            </H5>
          </span>
          <M>{description}</M>
        </span>
        <ChargeCardHeaderRightSlot
          subscription={subscription}
          refetch={refetch}
        />
      </span>
    </>
  );
}
