import React from 'react';

import { useTranslation } from 'react-i18next';

import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import LoginSSOButtons from 'components/authV2/LoginSSOButtons';
import Input from 'components/form/Input';
import VariantIconButton from 'components/general/variant/VariantIconButton';
import { H4 } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import { AuthToken } from 'typings';

import styles from './LoginWithSSO.scss';

const MESSAGES = {
  title: 'Welcome back!',
  emailLabel: 'Email',
  continueWithEmail: 'Continue with email',
};

export default function LoginWithSSOForm({
  userEmail,
  onError,
  onSuccess,
  onManualLogin,
  whitelabel,
  oAuthConfig,
}: {
  userEmail: string;
  onError: (err: Error | null) => void;
  onSuccess: (signInData: AuthToken) => void;
  onManualLogin: () => void;
  whitelabel: WhiteLabelType;
  oAuthConfig?: OAuthConfigType[];
}) {
  const { t } = useTranslation();

  return (
    <div>
      <H4 className={styles.title} mb={4}>
        {t(MESSAGES.title)}
      </H4>
      <div className={styles.formContainer}>
        <div className="input-container">
          <Input
            value={userEmail}
            showClear={false}
            label={t(MESSAGES.emailLabel)}
            fieldName="email"
            icon="lock"
            iconRight
            style={{ color: 'black' }}
            onChange={() => {
              // Not expecting onchange to be run
            }}
            readOnly
          />
        </div>
      </div>
      <div className={styles.formContainer}>
        <LoginSSOButtons
          mode="LogIn"
          onSuccess={onSuccess}
          onError={onError}
          showSeparator={false}
          oAuthConfig={oAuthConfig}
        />
      </div>
      <VariantIconButton
        className={welcomeFormStyles.actionButton}
        label={t(MESSAGES.continueWithEmail)}
        onClick={onManualLogin}
        color={whitelabel.profile.backgroundColor}
      />
    </div>
  );
}
