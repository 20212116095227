import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Flex } from 'components';
import { Modal } from 'components/lds';

import styles from './PDFExportDetailsModal.scss';
import { ServiceRequestDetailsPDFExportRenderer } from '../export/ServiceRequestDetailsPDF';

const PDFExportDetailsModal = ({
  channel,
  PDFExportModalOpen,
  setPDFExportModalOpen,
  serviceRequestData,
}: any) => {
  const { t } = useTranslation();
  const closeModal = () => {
    setPDFExportModalOpen(false);
  };

  return (
    <Flex className={styles.PDFExportDetailsModal}>
      <Modal
        isOpen={PDFExportModalOpen}
        onClose={closeModal}
        size="small"
        title={t('web.admin.serviceRequest.exportSelection')}
        testId="pdfExportModal"
      >
        <Flex className={styles.PDFExportDetailsModalRows}>
          <Button
            variant="outlined"
            size="small"
            testId="cancelPDFButton"
            onClick={closeModal}
            style={{
              marginRight: 5,
              borderRadius: 8,
              width: 120,
              marginTop: 20,
            }}
          >
            {t('web.admin.serviceRequest.cancel')}
          </Button>
          <ServiceRequestDetailsPDFExportRenderer
            channel={channel}
            serviceRequestData={serviceRequestData}
            onExport={closeModal}
          />
        </Flex>
      </Modal>
    </Flex>
  );
};

export default PDFExportDetailsModal;
