import { ChannelExperienceTypeEnum } from 'lane-shared/types/ChannelType';

type SharedTranslationKeys = {
  listPage: {
    header: string;
    addButton: string;
    emptyStateHeader: string;
    emptyStateSubtext: string;
    exportFileName: string;
    columns: {
      name: string;
    };
  };
  deleteModal: {
    title: string;
    message: string;
    successToast: string;
  };
  createPage: {
    header: string;
    breadcrumbs: {
      back: string;
      current: string;
    };
    form: {
      name: {
        label: string;
        placeholder: string;
        minValidation: string;
        alphanumericValidation: string;
      };
    };
    successToast: string;
  };
  detailsPage: {
    header: string;
    breadcrumbs: {
      back: string;
      current: string;
    };
    name: {
      label: string;
    };
    deleteButton: string;
  };
  editPage: {
    successToast: string;
  };
};

/**
 * Returns an object that includes the translation keys common to both Units and Suites.
 * This allows for the dynamic rendering of text tailored to either units or suites, based on the type of channel experience.
 * @param experienceType
 * @returns SharedTranslationKeys
 */
export const getSharedTranslationKeys = (
  experienceType: ChannelExperienceTypeEnum | null | undefined
) => {
  const mfExperienceKeys: SharedTranslationKeys = {
    listPage: {
      header: 'web.pages.portal.admin.units.list.header',
      addButton: 'web.pages.portal.admin.units.list.addUnitButton',
      emptyStateHeader: 'web.pages.portal.admin.units.list.noUnits.header',
      emptyStateSubtext: 'web.pages.portal.admin.units.list.noUnits.subtext',
      exportFileName: 'units',
      columns: {
        name: 'web.pages.portal.admin.units.list.columns.unit',
      },
    },
    deleteModal: {
      title: 'web.pages.portal.admin.units.list.delete.title',
      message: 'web.pages.portal.admin.units.list.delete.message',
      successToast: 'web.pages.portal.admin.units.list.delete.successToast',
    },
    createPage: {
      header: 'web.admin.channel.units.create.header',
      breadcrumbs: {
        back: 'web.admin.channel.units.create.breadcrumb.units',
        current: 'web.admin.channel.units.create.breadcrumb.addUnit',
      },
      form: {
        name: {
          label: 'web.admin.channel.units.create.form.unit.label',
          placeholder: 'web.admin.channel.units.create.form.unit.placeholder',
          minValidation:
            'web.admin.channel.units.create.form.unit.validation.min',
          alphanumericValidation:
            'web.admin.channel.suites.create.form.suite.validation.alphanumeric',
        },
      },
      successToast: 'web.admin.channel.units.create.successToast',
    },
    detailsPage: {
      header: 'web.pages.portal.admin.units.details.header',
      breadcrumbs: {
        back: 'web.pages.portal.admin.units.details.breadCrumbs.back',
        current: 'web.pages.portal.admin.units.details.breadCrumbs.currentPage',
      },
      name: {
        label: 'web.pages.portal.admin.units.details.unitNumber',
      },
      deleteButton: 'web.pages.portal.admin.units.details.deleteUnit',
    },
    editPage: {
      successToast: 'web.pages.portal.admin.units.details.edit.successToast',
    },
  };

  const officeExperienceKeys: SharedTranslationKeys = {
    listPage: {
      header: 'web.pages.portal.admin.suites.list.header',
      addButton: 'web.pages.portal.admin.suites.list.addSuiteButton',
      emptyStateHeader: 'web.pages.portal.admin.suites.list.noSuites.header',
      emptyStateSubtext: 'web.pages.portal.admin.suites.list.noSuites.subtext',
      exportFileName: 'suites',
      columns: {
        name: 'web.pages.portal.admin.suites.list.columns.suite',
      },
    },
    deleteModal: {
      title: 'web.pages.portal.admin.suites.list.delete.title',
      message: 'web.pages.portal.admin.suites.list.delete.message',
      successToast: 'web.pages.portal.admin.suites.list.delete.successToast',
    },
    createPage: {
      header: 'web.admin.channel.suites.create.header',
      breadcrumbs: {
        back: 'web.admin.channel.suites.create.breadcrumb.suites',
        current: 'web.admin.channel.suites.create.breadcrumb.addSuite',
      },
      form: {
        name: {
          label: 'web.admin.channel.suites.create.form.suite.label',
          placeholder: 'web.admin.channel.suites.create.form.suite.placeholder',
          minValidation:
            'web.admin.channel.suites.create.form.suite.validation.min',
          alphanumericValidation:
            'web.admin.channel.suites.create.form.suite.validation.alphanumeric',
        },
      },
      successToast: 'web.admin.channel.suites.create.successToast',
    },
    detailsPage: {
      header: 'web.pages.portal.admin.suites.details.header',
      breadcrumbs: {
        back: 'web.pages.portal.admin.suites.details.breadCrumbs.back',
        current:
          'web.pages.portal.admin.suites.details.breadCrumbs.currentPage',
      },
      name: {
        label: 'web.pages.portal.admin.suites.details.suiteNumber',
      },
      deleteButton: 'web.pages.portal.admin.suites.details.deleteSuite',
    },
    editPage: {
      successToast: 'web.pages.portal.admin.suites.details.edit.successToast',
    },
  };

  return experienceType === ChannelExperienceTypeEnum.office
    ? officeExperienceKeys
    : mfExperienceKeys;
};
