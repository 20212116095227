import { gql } from '@apollo/client';

export const listRecurringChargesForPayersQuery = gql`
  query listRecurringChargesForPayers(
    $listRecurringChargesForPayersRequest: ListRecurringChargesRequest!
  ) {
    accounts {
      listRecurringChargesForPayers(
        listRecurringChargesForPayersRequest: $listRecurringChargesForPayersRequest
      ) {
        recurringCharges {
          recurrence {
            recurrenceId
            isActive
            recurrenceInterval
            startDate
            nextOccurrence
            lastOccurrence
            anyoneCanCancel
            status
          }
          charge {
            name
            description
            amount
            externalPayerId
            currency
            groupId
          }
        }
      }
    }
  }
`;

export const cancelRecurringChargeMutation = gql`
  mutation cancelRecurringCharge(
    $cancelRecurringChargeRequest: CancelRecurringChargeRequest!
  ) {
    accounts {
      cancelRecurringCharge(
        cancelRecurringChargeRequest: $cancelRecurringChargeRequest
      ) {
        recurrenceId
      }
    }
  }
`;
