import useFlag from './useFlag';

const FLAG = 'ace.theme.block-theme-use-theme-from-theme-context';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2025-03-31T18:20:10+0530.
 */
export function useThemeFromContextForBlockTheme() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
