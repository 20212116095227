import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentPage, Loading, Flex } from 'components';
import { ButtonLink } from 'components/general/ButtonLink';
import { routes } from 'lane-shared/config';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { H5 } from 'components/typography';
import { PDFPreview } from 'components/lane/PDFPreview';
import { AttachmentProvider } from 'contexts/AttachmentContext';

import type { ChannelType } from 'lane-shared/types/ChannelType';
import type { ContentType } from 'lane-shared/types/content/Content';

import styles from './AdminViewContent.scss';
import { useFlag, useMultiLanguage } from 'lane-shared/hooks';
import { LanguagePreviewSelector } from 'components/general/LanguagePreviewSelector';
import { FeatureFlag } from 'constants-flags';
import { cloneDeep } from 'lodash';

export const InfoTab = ({
  content,
  channel,
}: {
  content: ContentType;
  channel: ChannelType;
}) => {
  let contentForPreview: ContentType | undefined;
  const { t } = useTranslation();

  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );
  const shouldDisplayLanguagePreviewSelector = Boolean(
    isMLSLanguagePreviewSelectorEnabled &&
      channel?.settings?.multiLanguageEnabled
  );
  const { translate } = useMultiLanguage();

  const channelDefaultLanguage = channel?.settings?.multiLanguageEnabled
    ? (channel?.settings?.channelLanguages?.primary as string)
    : '';
  const [previewLanguage, setPreviewLanguage] = useState<string>(
    channelDefaultLanguage
  );

  useEffect(() => {
    setPreviewLanguage(channelDefaultLanguage);
  }, [channelDefaultLanguage]);

  if (shouldDisplayLanguagePreviewSelector && previewLanguage) {
    contentForPreview = translate({
      model: cloneDeep(content),
      previewLanguage,
    });
  }

  const showPreview =
    ![ContentTypeEnum.Notice, ContentTypeEnum.ScheduledContent].includes(
      content?.type
    ) &&
    !content?.externalUrl &&
    !content?.deepLink;

  function onInteractionCreated() {
    window.Toast.show(
      <p>
        {t('web.pages.portal.admin.channel.post.InfoTab.interactionSubmitted')}
      </p>
    );
  }

  const destinationUrl =
    content?.signedExternalUrl || content?.externalUrl?.url;

  return (
    <section className={styles.preview}>
      {content?.externalUrl && (
        <div className={styles.previewWrapper}>
          {content?.signedExternalUrl ? (
            <ButtonLink
              size="large"
              to={routes.channelAdminLibraryDocumentsEdit
                .replace(':id', channel?.slug)
                .replace(':documentId', content?.externalUrl?.mediaId!)}
              target="_blank"
              as="a"
              testId="viewInDocumentLibrary"
              variant="secondary"
              className={styles.showButton}
            >
              {t(
                'web.pages.portal.admin.channel.post.InfoTab.openInlibraryButtonText'
              )}
            </ButtonLink>
          ) : (
            <Flex direction="column" mb={5}>
              <H5 mb={2}>
                {t(
                  'web.pages.portal.admin.channel.post.InfoTab.externalContentTitle'
                )}
              </H5>
              <a href={destinationUrl} target="_blank" rel="noreferrer">
                {destinationUrl}
              </a>
            </Flex>
          )}

          <PDFPreview
            media={{ url: destinationUrl }}
            error=""
            loading={<Loading />}
          />
        </div>
      )}

      {content?.deepLink && (
        <div>
          <H5 mb={2}>Deep Link</H5>
          <a
            href={content.deepLink.deepLinkUrl}
            target="_blank"
            rel="noreferrer"
          >
            {content.deepLink.deepLinkUrl ?? ''}
          </a>
          <H5 mb={2}>
            {t('web.pages.portal.admin.channel.post.InfoTab.appStoreLinkTitle')}
          </H5>
          <a
            href={content.deepLink.appStoreUrl}
            target="_blank"
            rel="noreferrer"
          >
            {content.deepLink.appStoreUrl ?? ''}
          </a>
          <H5 mb={2}>
            {t(
              'web.pages.portal.admin.channel.post.InfoTab.playStoreLinkTitle'
            )}
          </H5>
          <a
            href={content.deepLink.googlePlayStoreUrl}
            target="_blank"
            rel="noreferrer"
          >
            {content.deepLink.googlePlayStoreUrl ?? ''}
          </a>
        </div>
      )}

      {showPreview && (
        <AttachmentProvider>
          {shouldDisplayLanguagePreviewSelector && (
            <Flex direction="row" justify="center" m={[5, 0, 5, 0]}>
              <LanguagePreviewSelector
                previewLanguage={previewLanguage}
                setPreviewLanguage={setPreviewLanguage}
              />
            </Flex>
          )}
          <ContentPage
            className={styles.content}
            content={contentForPreview ?? content}
            onInteractionCreated={onInteractionCreated}
            forAdmin
            previewLanguage={previewLanguage}
          />
        </AttachmentProvider>
      )}
    </section>
  );
};
