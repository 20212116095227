import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { Button } from 'components/general';

import { ExceptionsTable } from '../exceptions/ExceptionsTable';

import styles from '../styles.scss';

type Props = {
  formRoute?: string;
  canManage?: boolean;
};

export function ExceptionsTab({ formRoute, canManage }: Props) {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const history = useHistory();

  const handleCreateException = () => {
    history.push(
      formRoute ?? routes.channelAdminCreateExceptionForm.replace(':id', id)
    );
  };

  return (
    <>
      <div className={styles.heading}>
        {canManage && (
          <Button
            testId="newException"
            variant="contained"
            onClick={handleCreateException}
          >
            {t('abp.productsServices.exceptions.button')}
          </Button>
        )}
      </div>
      <ExceptionsTable canManage={canManage} />
    </>
  );
}
