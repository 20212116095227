import React from 'react';

import { useTranslation } from 'react-i18next';

import { RecurringCharge } from 'graphql-query-contracts';

import { SIMPLE_DATE } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';

import { Text } from 'components/typography';

import styles from './styles.scss';
import { ChargeCardHeader } from './ChargeCardHeader';

export function ChargeCard({
  recurringCharge,
  refetch,
}: {
  recurringCharge: RecurringCharge;
  refetch: Function;
}) {
  const { t } = useTranslation();
  const { isActive, lastOccurrence, nextOccurrence, startDate } =
    recurringCharge.recurrence;
  const { groupId, externalPayerId } = recurringCharge.charge;

  const nilDate = new Date(0);
  const lastOccurrenceDate = lastOccurrence
    ? new Date(lastOccurrence)
    : nilDate;
  const nextOccurrenceDate = nextOccurrence
    ? new Date(nextOccurrence)
    : nilDate;

  return (
    <div className={styles.recurringChargeCard}>
      <ChargeCardHeader subscription={recurringCharge} refetch={refetch} />
      <span className={styles.info}>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.from')}
          </Text>
          {groupId}
        </span>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.to')}
          </Text>
          {externalPayerId}
        </span>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.starts')}
          </Text>
          {dateFormatter(startDate, SIMPLE_DATE)}
        </span>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.expires')}
          </Text>
          {nilDate >= lastOccurrenceDate
            ? t('abp.subscription.never')
            : dateFormatter(lastOccurrenceDate, SIMPLE_DATE)}
        </span>
        {isActive ? (
          <span className={styles.infoGroup}>
            <Text className={styles.infoGroupHeading}>
              {t('abp.subscription.card.next')}
            </Text>
            {dateFormatter(nextOccurrenceDate, SIMPLE_DATE)}
          </span>
        ) : (
          <span className={styles.infoGroup} />
        )}
      </span>
    </div>
  );
}
