export function limitValue(value: string, limit: number): number {
  const sanitizedValue = value.replace(/[^0-9.]/g, '');
  const decimalIndex = sanitizedValue.indexOf('.');
  const limitedValue =
    decimalIndex === -1
      ? sanitizedValue
      : sanitizedValue.slice(0, decimalIndex + limit + 1);

  return parseFloat(limitedValue);
}
