import React, { useState } from 'react';
import { ErrorMessage, RadioGroup, TextArea, Input } from 'components';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { routes } from 'lane-shared/config';
import { ProductType } from 'lane-shared/domains/billingPayments/types';

import { Button } from 'components/general';
import { AdminPage, PageHeader } from 'components/layout';

import { createCategoryMutation } from '../helpers/createCategoryMutation';

import styles from '../styles.scss';

export interface CategoryDetails {
  productType: ProductType;
  name: string;
  description: string;
}

type Props = {
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
  className?: string;
};

export function CreateCategoryForm({ breadcrumbs, className }: Props) {
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const { t } = useTranslation();
  const channel_slug = channel?.slug || '';
  const productServicesLink = routes.channelAdminProductsServices.replace(
    ':id',
    channel_slug
  );

  const [error, setError] = useState<Error | undefined>(undefined);
  const [newCategoryDetails, setNewCategoryDetails] = useState<CategoryDetails>(
    {
      productType: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      name: '',
      description: '',
    }
  );

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setNewCategoryDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const isSaveButtonDisabled = (): boolean => {
    const { name, productType } = newCategoryDetails;

    return !(name && productType);
  };

  const handleSaveCategory = async () => {
    try {
      await createCategoryMutation(
        {
          ...newCategoryDetails,
        },
        channel?._id || ''
      );
      history.goBack();
      window.Toast.show(
        t('abp.productsServices.createCategory.createSuccessfull')
      );
    } catch (err) {
      console.error(err);
      setError(err as Error);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const productTypeOptions = [
    {
      id: ProductType.PRODUCT_TYPE_SERVICE_MATERIALS,
      label: 'abp.productsServices.createCategory.productServiceType.materials',
      description:
        'abp.productsServices.createCategory.productServiceType.materialDescription',
    },
    {
      id: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      label: 'abp.productsServices.createCategory.productServiceType.labour',
      description:
        'abp.productsServices.createCategory.productServiceType.labourDescription',
    },
  ];

  const defaultBreadcrumbs = [
    {
      label: t('adp.productsServices.breadcrumb'),
      url: productServicesLink,
    },
    { label: t('adp.productsServices.createCategory.breadcrumb') },
  ];

  return (
    <AdminPage className={className}>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.productsServices.createCategory.heading')}
        headerLevel="h3"
        breadcrumbs={breadcrumbs ?? defaultBreadcrumbs}
        actionButtons={[
          {
            label: t('abp.productsServices.createCategory.cancel'),
            type: 'secondary',
            onClick: handleCancel,
          },
          {
            label: t('abp.productsServices.createCategory.save'),
            type: 'primary',
            onClick: handleSaveCategory,
            disabled: isSaveButtonDisabled(),
          },
        ]}
      />
      <div className={styles.createCategoryForm}>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t('abp.productsServices.createCategory.type')}
          </label>

          <div className={styles.fieldset}>
            <RadioGroup
              key="type"
              name="productType"
              selected={newCategoryDetails.productType}
              className={styles.radioLabel}
              schema={{
                id: 'id',
                text: 'label',
                subtext: 'description',
              }}
              items={productTypeOptions}
              onChange={(value: string) => {
                handleFieldUpdate(value, 'productType');
              }}
            />
          </div>
        </fieldset>
        <Input
          type="text"
          label={t('abp.productsServices.createCategory.name')}
          fixedLabel
          onChange={(value: string) => {
            handleFieldUpdate(value, 'name');
          }}
          fieldName="name"
          className={cx(styles.fieldset, styles.categoryName)}
          value={newCategoryDetails.name}
          isRequired
          testId="categoryName"
        />
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t('abp.productsServices.createCategory.description')}
          </label>
          <TextArea
            onChange={(value: string) => {
              handleFieldUpdate(value, 'description');
            }}
            value={newCategoryDetails.description}
            maxLength={200}
            showLengthIndicator
            minRows={4}
          />
        </fieldset>
      </div>
      <div className={styles.formButtons}>
        <Button
          className={styles.button}
          variant="contained"
          disabled={isSaveButtonDisabled()}
          onClick={handleSaveCategory}
        >
          {t('abp.productsServices.createCategory.save')}
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          onClick={handleCancel}
        >
          {t('abp.productsServices.createCategory.cancel')}
        </Button>
      </div>
      {error && <ErrorMessage error={error} />}
    </AdminPage>
  );
}
