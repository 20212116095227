import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { WorkOrderModuleCategoriesSettings } from 'lane-shared/domains/workOrder';
import { AdminUser } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { safeConvertToUUID } from 'uuid-encoding';

import { Flex, RadioGroup } from 'components';
import { P } from 'components/typography';
import AssignedMemberDropdown from 'pages/portal/admin/channel/service-requests/details/components/AssignedMemberDropdown';
import { StaffTeamsMultiSelectDropdown } from 'pages/portal/admin/channel/service-requests/details/components/StaffTeamsMultiSelectDropdown';

import styles from './CategoryAssignment.scss';

type Props = {
  category: WorkOrderModuleCategoriesSettings;
  assignableTeams: AdminUser[];
  assignableUsers: AdminUser[];
  setCategoryTeams: (assigneeGroups: string[]) => void;
  setCategoryAssignee: (assignee: string) => void;
};

export const CategoryAssignment = ({
  category,
  setCategoryTeams,
  setCategoryAssignee,
  assignableTeams,
  assignableUsers,
}: Props) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<number>(3);
  const [teams, setTeams] = useState<any>([]);
  const [assignee, setAssignedMember] = useState({ value: '' });
  const options = [
    { _id: 1, text: t`web.admin.serviceRequest.setting.autoAssignment.teams` },
    {
      _id: 2,
      text: t`web.admin.serviceRequest.setting.autoAssignment.assignee`,
    },
    { _id: 3, text: t`web.admin.serviceRequest.setting.autoAssignment.none` },
  ];

  useEffect(() => {
    if (
      category.assigneeGroups &&
      category.assigneeGroups.length &&
      assignableTeams.length &&
      !teams.length
    ) {
      const oldTeams = filterTeamsData();

      setTeams(oldTeams);
      setSelectedValue(1);
    }

    if (category.assignee && assignableUsers.length && assignee.value === '') {
      const assigneedMember: any = assignableUsers?.find(
        (user: any) => safeConvertToUUID(user?.value) === category.assignee
      );

      setAssignedMember(assigneedMember);
      setSelectedValue(2);
    }
  }, [assignableTeams, assignableUsers]);

  useEffect(() => {
    if (selectedValue === 3) {
      if (teams.length) {
        setCategoryTeams([]);
      } else if (assignee.value !== '') {
        setCategoryAssignee('');
      }
    }
  }, [selectedValue]);

  const filterTeamsData = () => {
    return assignableTeams.filter(team =>
      category?.assigneeGroups?.find(
        (item: string) => safeConvertToUUID(item) === team.value
      )
    );
  };

  const onTeamSelectionChange = (newTeams: any[]) => {
    if (!assignableTeams.length) {
      return;
    }

    if (newTeams && newTeams.length) {
      const selectedOptions = assignableTeams.filter(item =>
        newTeams.find((option: any) => item.value === option.value)
      );

      setTeams(selectedOptions);
      const assigneeGroups = selectedOptions.length
        ? selectedOptions.map((user: { value: string }) =>
            safeConvertToUUID(user?.value)
          )
        : [];

      setCategoryTeams(assigneeGroups);
    } else {
      setTeams([]);
      setCategoryTeams([]);
    }
  };

  const onAssigneeSelectionChange = (value: any) => {
    const assignee = safeConvertToUUID(value?.value);

    setCategoryAssignee(assignee);
    setAssignedMember(value);
  };

  return (
    <Flex className={styles.assignmentComponent}>
      <Flex m={5}>
        <P
          className={styles.heading}
        >{t`web.admin.serviceRequest.setting.autoAssignment.header.text`}</P>
      </Flex>
      <Flex>
        <RadioGroup
          className={styles.radioGroup}
          key="assignment"
          name={`assignment-${category.firendlyName}`}
          schema={{
            id: '_id',
            text: 'text',
          }}
          selected={selectedValue}
          items={options}
          onChange={value => setSelectedValue(Number(value))}
        />
      </Flex>
      {selectedValue === 1 && (
        <Flex className={styles.dropdowns}>
          <StaffTeamsMultiSelectDropdown
            items={assignableTeams}
            isSearchable={false}
            value={teams}
            onChange={onTeamSelectionChange}
            placeholder={t`web.admin.serviceRequest.select.teams`}
          />
        </Flex>
      )}
      {selectedValue === 2 && (
        <Flex className={styles.dropdowns}>
          <AssignedMemberDropdown
            users={assignableUsers}
            setAssignedMember={onAssigneeSelectionChange}
            assignedMember={assignee}
            displayedLabel={false}
          />
        </Flex>
      )}
    </Flex>
  );
};
