import React, { useState, useEffect, useContext, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { useQuery } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { updateUserContentInteraction } from 'lane-shared/graphql/mutation';
import { getProxyClickCompanies } from 'lane-shared/graphql/proxyclick';
import { getChannelIntegration } from 'lane-shared/graphql/query';
import { getUserLogins } from 'lane-shared/graphql/user';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import { createUserContentInteraction } from 'lane-shared/helpers/content';
import { parseDateTime } from 'lane-shared/helpers/dates';
import {
  validationShape,
  validationShapeBNY,
  proxyclickDataBNY,
  isTokenExpired,
} from 'lane-shared/helpers/integrations/ProxyClick';
import {
  ProxyClickCustomField,
  ProxyClickVisitor,
} from 'lane-shared/helpers/integrations/ProxyClick/types';
import { UserLoginTypeEnum } from 'lane-shared/types/UserLogin';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import DatePickerButton from 'components/form/DatePickers/DatePickerButton';
import TimePicker from 'components/form/DatePickers/TimePicker';
import ValidatedDropdown from 'components/form/ValidatedDropdown';
import ValidatedInput from 'components/form/ValidatedInput';
import Button from 'components/general/Button';

import history from '../../../../helpers/history';
import getClampedByStartDate from './helpers/getClampedByStartDate';

import styles from './MeetingForm.scss';

const HOURS_IN_FUTURE = 2;

type Meeting = {
  startAt: Date;
  endAt: Date;
  hostId: string;
  companyId: string;
  title: string;
  description: string;
  visitors: Array<LaneType.ProxyClickVisitor>;
};

function constructDefaultMeeting(): Meeting {
  return {
    startAt: DateTime.local()
      .startOf('hour')
      .plus({ hours: HOURS_IN_FUTURE })
      .toJSDate(),
    endAt: DateTime.local()
      .startOf('hour')
      .plus({ hours: HOURS_IN_FUTURE + 1 })
      .toJSDate(),
    hostId: '',
    companyId: '',
    title: '',
    description: '',
    visitors: [{ ...ProxyClickVisitor.default, id: uuid() }],
  };
}

function MeetingForm({ companies, interaction, content }: any) {
  const { user } = useContext(UserDataContext);
  const { primaryId } = useContext(ChannelsContext);

  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState<
    Array<ProxyClickCustomField>
  >([]);

  const proxyClickLogin = user?.logins.find(
    l =>
      l.type === UserLoginTypeEnum.OAuth &&
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      l.provider === IntegrationProviderEnum.ProxyClick
  );

  const {
    data: userLogins,
    loading: userLoginLoading,
  } = useQuery(getUserLogins, { fetchPolicy: 'cache-and-network' });
  let proxyClickExpiryToken;

  if (!userLoginLoading) {
    proxyClickExpiryToken = userLogins?.me?.user?.logins.find(
      (l: any) =>
        l.type === UserLoginTypeEnum.OAuth &&
        l.provider === IntegrationProviderEnum.ProxyClick
    );
  }

  const { data, loading } = useQuery(getChannelIntegration, {
    variables: { id: content?.integration?._id },
    fetchPolicy: 'cache-and-network',
  });

  const timeZone = useMemo(
    () =>
      content?.geo
        ? getTimeZoneByGeoLocation({
            latitude: content?.geo[1],
            longitude: content?.geo[0],
          })
        : undefined,
    [content?._id]
  );

  const existingMeeting =
    interaction?.data?.[`_${IntegrationProviderEnum.ProxyClick}`];

  const isUpdating = !!existingMeeting;

  if (existingMeeting) {
    existingMeeting.hostId = proxyClickLogin?.key;
    existingMeeting.companyId = existingMeeting.host.originalCompany.id;
    existingMeeting.startAt = new Date(existingMeeting.startAt);
    existingMeeting.endAt = new Date(existingMeeting.endAt);
  }

  const [meeting, setMeeting] = useState<Meeting>(
    existingMeeting || {
      ...constructDefaultMeeting(),
      hostId: proxyClickLogin && proxyClickLogin.key,
    }
  );

  useEffect(() => {
    if (parseDateTime(meeting.endAt)! < parseDateTime(meeting.startAt)!) {
      updateMeeting({
        // @ts-expect-error ts-migrate(2740) FIXME: Type 'DateTime' is missing the following propertie... Remove this comment to see the full error message
        endAt: parseDateTime(meeting.endAt)!.plus({ hours: 1 }),
      });
    }
  }, [meeting.endAt, meeting.startAt]);

  useEffect(() => {
    // default to the users first company
    if (!meeting.companyId && companies.length > 0) {
      updateMeeting({
        companyId: companies[0]._id,
      });
    }
  }, [companies]);

  useEffect(() => {
    if (!loading && data?.channelIntegration?.settings?.hasCustomFields) {
      // deep copy
      const customFieldsDefinition = proxyclickDataBNY.map(field => ({
        ...field,
      }));

      // update fields if there was an existing meeting
      if (existingMeeting) {
        if (existingMeeting.visitors[0].customFields) {
          existingMeeting.visitors[0].customFields.forEach((field: any) => {
            const item = customFieldsDefinition.find(
              customField => customField.proxyclickId === field.id
            );

            if (item) {
              item.value = field.value;
            }
          });
        }

        if (existingMeeting.customFields) {
          existingMeeting.customFields.forEach((field: any) => {
            const item = customFieldsDefinition.find(
              customField => customField.proxyclickId === field.id
            );

            if (item) {
              item.value = field.value;
            }
          });
        }
      }

      setCustomFieldsData(customFieldsDefinition);
      setShowCustomFields(true);
    }
  }, [loading]);

  function validate() {
    try {
      yup.object().shape(validationShape).validateSync(meeting);

      return true;
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return false;
    }
  }

  async function createNewVisit() {
    setHasAttemptedSubmit(true);

    if (!validate()) {
      return;
    }

    setSubmitLoading(true);

    const meetingData = {
      ...meeting,
      startAt: DateTime.fromJSDate(meeting.startAt).toISO(),
      endAt: DateTime.fromJSDate(meeting.endAt).toISO(),
      visitors: meeting.visitors.map(visitor => ({
        ...visitor,
        id: undefined,
      })),
      customFields: customFieldsData
        .filter(value => !value.attachToVisitor)
        .map(field => ({
          id: field.proxyclickId,
          value: field.value,
        })),
    };

    try {
      await createUserContentInteraction({
        refetchQueries: commonInteractionQueries,
        content,
        interaction: {
          data: {
            [`_${IntegrationProviderEnum.ProxyClick}`]: meetingData,
          },
        },
        meChannelId: primaryId,
      });

      setSubmitLoading(false);

      // TODO: better solution (in case comes to the page by pasting url in browser)
      history.goBack();

      await window.Alert.alert({
        title: t('Meeting Created'),
        message: t(
          'Congrats! Your meeting was successfully created. An email invite was sent out to your guest.'
        ),
      });
    } catch (err) {
      console.error(err);

      await window.Alert.alert({
        title: t('Something went wrong'),
        error: err,
      });
    }

    setSubmitLoading(false);
  }

  function updateCustomField(value: any, i: any) {
    const newArray = [...customFieldsData];

    newArray[i].value = value;
    setCustomFieldsData(newArray);

    if (customFieldsData[i].attachToVisitor) {
      for (
        let visitorId = 0;
        visitorId < meeting.visitors.length;
        visitorId++
      ) {
        (meeting.visitors[visitorId] as any).customFields = [
          {
            id: customFieldsData[i].proxyclickId,
            value,
          },
        ];
      }

      updateMeeting({
        ...meeting,
      });
    }
  }

  function updateVisitor(props: any, i: any) {
    meeting.visitors[i] = {
      ...meeting.visitors[i],
      ...props,
    };
    updateMeeting({
      ...meeting,
    });
  }

  function updateMeeting(props: Partial<Meeting>) {
    const startAt = props.startAt || meeting.startAt;
    const endAt = props.endAt || meeting.endAt;

    const clampedEndAt = getClampedByStartDate(endAt, startAt);

    // TODO: Product may contribute some more correct correctional logic after clamping is done
    // ex. 15 minute start to end buffer.

    setMeeting({
      ...meeting,
      ...props,
      endAt: clampedEndAt,
    });

    validate();
  }

  function setEndTimeToStartDate(startAt: any) {
    const endDateTime = parseDateTime(meeting.endAt)!;

    return parseDateTime(startAt)!
      .set({ hour: endDateTime.hour, minute: endDateTime.minute })
      .toJSDate();
  }

  async function updateVisit() {
    setHasAttemptedSubmit(true);

    if (!validate()) {
      return;
    }

    setSubmitLoading(true);

    try {
      await getClient().mutate({
        mutation: updateUserContentInteraction,
        refetchQueries: commonInteractionQueries,
        variables: {
          interaction: {
            _id: interaction._id,
            data: {
              [`_${IntegrationProviderEnum.ProxyClick}`]: {
                title: meeting.title,
                description: meeting.description,
                companyId: meeting.companyId,
                hostId: meeting.hostId,
                visitors: meeting.visitors,
                startAt: DateTime.fromJSDate(meeting.startAt).toISO(),
                endAt: DateTime.fromJSDate(meeting.endAt).toISO(),
                id: (meeting as any).id,
                customFields: customFieldsData
                  .filter(value => !value.attachToVisitor)
                  .map(field => ({
                    id: field.proxyclickId,
                    value: field.value,
                  })),
              },
            },
          },
          meChannelId: primaryId,
        },
      });
      setSubmitLoading(false);

      // TODO: better solution (in case comes to the page by pasting url in browser)
      history.goBack();
    } catch (err) {
      await window.Alert.alert({
        title: t('Something went wrong'),
        error: err,
      });
      // setShowErrorModal(true); //TODO: error
      setSubmitLoading(false);
    }
  }

  if (proxyClickExpiryToken && isTokenExpired(proxyClickExpiryToken)) {
    return (
      <Redirect
        to={{
          pathname: routes.contentIntegrationLogin.replace(':id', content._id),
        }}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h3 className={styles.title}>Create New Visit</h3>
        <div className={styles.form}>
          <section className={styles.company}>
            <p>{t('Company')}</p>
            <ValidatedDropdown
              items={companies.map((company: any) => ({
                label: company.name,
                value: company._id,
              }))}
              value={meeting.companyId}
              onValueChange={companyId => updateMeeting({ companyId })}
              validation={validationShape.companyId}
              isPristine={!hasAttemptedSubmit}
            />
          </section>
          <p>{t('Visitor Information')}</p>
          {meeting.visitors.map((visitor, i) => (
            <div key={visitor.id}>
              <ValidatedInput
                onChange={firstName => updateVisitor({ firstName }, i)}
                placeholder={t('First Name')}
                value={visitor.firstName}
                validation={ProxyClickVisitor.shape.firstName}
                isPristine={!hasAttemptedSubmit}
              />
              <ValidatedInput
                onChange={lastName => updateVisitor({ lastName }, i)}
                placeholder={t('Last Name')}
                value={visitor.lastName}
                validation={ProxyClickVisitor.shape.lastName}
                isPristine={!hasAttemptedSubmit}
              />
              <ValidatedInput
                onChange={companyName => updateVisitor({ companyName }, i)}
                placeholder={t('Company')}
                value={visitor.companyName}
                validation={ProxyClickVisitor.shape.companyName}
                isPristine={!hasAttemptedSubmit}
              />
              <ValidatedInput
                onChange={email => updateVisitor({ email }, i)}
                placeholder={t('Email')}
                value={visitor.email}
                validation={ProxyClickVisitor.shape.email}
                isPristine={!hasAttemptedSubmit}
              />
            </div>
          ))}

          <section className={styles.options}>
            <p>{t('Visit Schedule')}</p>
            <DatePickerButton
              buttonClassName={styles.datePicker}
              timeZone={timeZone}
              value={meeting.startAt}
              onSubmit={(startAt: any) =>
                updateMeeting({
                  startAt,
                  endAt: setEndTimeToStartDate(startAt),
                })
              }
            />
            <div className={styles.times}>
              <div className={styles.group}>
                <p>{t('Start Time')}</p>
                <TimePicker
                  className={styles.timePicker}
                  value={meeting.startAt}
                  timeZone={timeZone}
                  onChange={(startAt: any) =>
                    updateMeeting({
                      startAt,
                    })
                  }
                />
              </div>
              <div className={styles.group}>
                <p>{t('End Time')}</p>
                <TimePicker
                  className={styles.timePicker}
                  value={meeting.endAt}
                  timeZone={timeZone}
                  onChange={(endAt: any) =>
                    updateMeeting({
                      endAt,
                    })
                  }
                />
              </div>
            </div>
          </section>

          <section>
            <p>{t('Visit Information')}</p>
            <ValidatedInput
              onChange={title => updateMeeting({ title })}
              placeholder={t('Title')}
              value={meeting.title}
              validation={validationShape.title}
              isPristine={!hasAttemptedSubmit}
            />
            <ValidatedInput
              onChange={description => updateMeeting({ description })}
              placeholder={t('Description')}
              value={meeting.description}
              validation={validationShape.description}
              isPristine={!hasAttemptedSubmit}
            />
            {showCustomFields &&
              customFieldsData.map((field, i) => (
                <div key={field.proxyclickId}>
                  <p>{t(field.label)}</p>
                  <ValidatedDropdown
                    items={field.options.map(({ value }) => ({
                      label: value,
                      value,
                    }))}
                    value={field.value}
                    onValueChange={customValue =>
                      updateCustomField(customValue, i)
                    }
                    validation={validationShapeBNY[field.shapeRef]}
                    isPristine={!hasAttemptedSubmit}
                  />
                </div>
              ))}
          </section>

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => (isUpdating ? updateVisit() : createNewVisit())}
              loading={submitLoading}
              className={styles.updateButton}
            >
              {isUpdating ? t('Update Meeting') : t('Invite Visitor')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default graphql(getProxyClickCompanies, {
  props: ({ data }) => {
    if (!data || !(data as any).proxyclickCompanies) {
      return {
        companies: [],
      };
    }

    return {
      companies: (data as any).proxyclickCompanies || [],
    };
  },
})(MeetingForm);
