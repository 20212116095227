import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { getAdminClient } from 'lane-shared/apollo';
import { longDateNoTime, shortTime } from 'lane-shared/helpers/formatters';
import { H3 } from 'components/typography';
import { PortalManagementUserEditType } from '../types';
import { userStatusUpdateAuditsQuery } from 'graphql-queries';
import styles from './PortalManagementUserHistory.scss';
import { Pagination } from 'design-system-web';

const PER_PAGE = 50;

export function PortalManagementUserHistory({
  user,
}: {
  user: PortalManagementUserEditType;
}) {
  const [page, setPage] = useState(0);

  const {
    data,
    loading,
    refetch: refetchUserAudits,
  } = useQuery(userStatusUpdateAuditsQuery, {
    client: getAdminClient(),
    fetchPolicy: 'network-only',
    variables: {
      userId: user?._id,
      pagination: { start: page * PER_PAGE, perPage: PER_PAGE },
    },
  });

  useEffect(() => {
    refetchUserAudits({
      userId: user?._id,
      pagination: {
        start: page * PER_PAGE,
        perPage: PER_PAGE,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const audits = data?.userStatusUpdateAudits?.items ?? [];
  const totalRows = data?.userStatusUpdateAudits?.pageInfo?.total || 0;

  return (
    <div className={styles.PortalManagementUserHistory}>
      <H3>History</H3>
      <Pagination
        loading={loading}
        perPage={PER_PAGE}
        page={page}
        onPage={page => setPage(page)}
        total={totalRows}
      />
      <table className={styles.history}>
        <tbody>
          {audits.map(audit => (
            <tr key={audit.id}>
              <td>
                {audit?.source === 'SCIM' ? 'SCIM' : audit?.sourceUser?.name}
              </td>
              <td>
                <span className={styles.heading}>Status Updated</span>
                {audit?.oldData?.status} &rarr; {audit?.newData?.status}
              </td>
              <td>
                <span className={styles.heading}>
                  {longDateNoTime(audit?.created, user?.lastTimeZone)}
                </span>
                {shortTime(audit?.created, user?.lastTimeZone)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
