import { LaneType } from 'common-types';
import { AnalyticsInterface } from '../../../types/Analytics';
import { EVENT_KEYS } from 'constants-events';

type EmitReorderContentType = {
  userId: LaneType.UUID;
  channelId: LaneType.UUID;
  channelName?: string;
  contentId: LaneType.UUID;
  contentName?: string;
  sectionId: LaneType.UUID;
  sectionName?: string;
  oldPosition?: number;
  newPosition?: number;
  analytics: Pick<AnalyticsInterface, 'track'>;
};

export function emitReorderContent({
  userId,
  channelId,
  channelName,
  contentId,
  contentName,
  sectionId,
  sectionName,
  oldPosition,
  newPosition,
  analytics,
}: EmitReorderContentType) {
  // FIXME: Event sent as analytic
  // @ts-ignore
  analytics.track(EVENT_KEYS.EVENT_REORDER_SECTION_CONTENT, {
    userId,
    channelId,
    channelName,
    contentId,
    contentName,
    sectionId,
    sectionName,
    oldPosition,
    newPosition,
  });
}
