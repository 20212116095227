 
 
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, IconButton } from 'components';
import { H4, H5 } from 'components/typography';
import { Button, Icon, Tooltip } from 'design-system-web';

import { MeterReadingOption } from './types';
import styles from './StepSelector.scss';

interface StepSelectorProps {
  meterReadings: MeterReadingOption[];
  onSelect: (type: string) => void;
  onCancel: () => void;
}

export const StepSelector = ({
  meterReadings,
  onSelect,
  onCancel,
}: StepSelectorProps) => {
  const { t } = useTranslation();
  const [meterReadingEnabled, setMeterReadingEnabled] = useState(false);

  // eslint-disable-next-line react/no-unstable-nested-components
  const MeterReadingSelector = () => {
    return (
      <Button
        testId="meter-step"
        variant="text"
        className={
          meterReadingEnabled
            ? styles.stepSelectorButton
            : styles.disabledStepSelectorButton
        }
        onClick={() => onSelect('meter')}
        disabled={!meterReadings.length}
      >
        <Flex direction="column" className={styles.stepOption}>
          <Icon name="tachometer" size="medium" className={styles.stepIcon} />
          <H5>{t('web.admin.workOrder.steps.meter.label')}</H5>
          <span className={styles.stepDescription}>
            {t('web.admin.workOrder.steps.meter.description')}
          </span>
        </Flex>
      </Button>
    );
  };

  useEffect(() => {
    setMeterReadingEnabled(!!meterReadings.length);
  }, [meterReadings]);

  return (
    <Flex direction="column" className={styles.stepSelector}>
      <Flex direction="row" className={styles.stepSelectorHeader}>
        <H4>{t('web.admin.workOrder.steps.selector.header')}</H4>
        <IconButton
          icon="times"
          testId="cancel-step-selector"
          size="small"
          onClick={onCancel}
          className={styles.cancelStepButton}
        />
      </Flex>
      <Flex direction="row" className={styles.stepSelectorBody}>
        <Flex direction="column" className={styles.stepSelectorCol}>
          {/* Instruction Step */}
          <Button
            testId="instruction-step"
            variant="text"
            className={styles.stepSelectorButton}
            onClick={() => onSelect('instruction')}
          >
            <Flex direction="column" className={styles.stepOption}>
              <Icon
                name="info-circle"
                size="medium"
                className={styles.stepIcon}
              />
              <H5>{t('web.admin.workOrder.steps.instruction.label')}</H5>
              <span className={styles.stepDescription}>
                {t('web.admin.workOrder.steps.instruction.description')}
              </span>
            </Flex>
          </Button>

          {/* Multi-Choice Step */}
          <Button
            testId="choice-step"
            variant="text"
            className={styles.stepSelectorButton}
            onClick={() => onSelect('choice')}
          >
            <Flex direction="column" className={styles.stepOption}>
              <Icon name="list-ul" size="medium" className={styles.stepIcon} />
              <H5>{t('web.admin.workOrder.steps.choice.label')}</H5>
              <span className={styles.stepDescription}>
                {t('web.admin.workOrder.steps.choice.description')}
              </span>
            </Flex>
          </Button>
        </Flex>

        <Flex direction="column" className={styles.stepSelectorCol}>
          {/* Acknowledge Step */}
          <Button
            testId="acknowledge-step"
            variant="text"
            className={styles.stepSelectorButton}
            onClick={() => onSelect('acknowledge')}
          >
            <Flex direction="column" className={styles.stepOption}>
              <Icon
                name="check-circle"
                size="medium"
                className={styles.stepIcon}
              />
              <H5>{t('web.admin.workOrder.steps.acknowledge.label')}</H5>
              <span className={styles.stepDescription}>
                {t('web.admin.workOrder.steps.acknowledge.description')}
              </span>
            </Flex>
          </Button>

          {/* Meter Reading Step */}
          {!meterReadingEnabled ? (
            <Tooltip
              TooltipComponent={t(
                'web.admin.workOrder.steps.meterReadingDisabledText'
              )}
            >
              <MeterReadingSelector />
            </Tooltip>
          ) : (
            <MeterReadingSelector />
          )}
        </Flex>

        <Flex direction="column" className={styles.stepSelectorCol}>
          {/* Response Step */}
          <Button
            testId="response-step"
            variant="text"
            className={styles.stepSelectorButton}
            onClick={() => onSelect('response')}
          >
            <Flex direction="column" className={styles.stepOption}>
              <Icon
                name="comment-dots"
                size="medium"
                className={styles.stepIcon}
              />
              <H5>{t('web.admin.workOrder.steps.response.label')}</H5>
              <span className={styles.stepDescription}>
                {t('web.admin.workOrder.steps.response.description')}
              </span>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
