import React, { useState, useContext } from 'react';

import {
  UserProfileSettings,
  ProfileLoginSettings,
  AddLoginEmail,
  LanguagePicker,
} from 'components';
import { graphql } from 'lane-shared';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import i18n from 'localization';

import AccountButtons from './AccountButtons';

import styles from './Settings.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

export default function Settings() {
  const { t } = useTranslation();
  const { user, refetch } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const [addLogin, setAddLogin] = useState(false);
  const isUpdatedLanguageSettingsEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportUserLanguageSettings,
    false
  );

  async function changeUserLocale(locale: any) {
    setLoading(true);
    await i18n.changeLanguage(locale);

    try {
      await getClient().mutate({
        mutation: graphql.user.updateUser,
        variables: {
          user: {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            _id: user._id,
            locale,
          },
        },
      });

      await refetch();
    } catch (err) {
      window.Alert.alert({
        title: t('Something went wrong'),
        error: err,
      });
    }

    setLoading(false);
  }

  return (
    <div className={styles.ProfileSettings}>
      <div className={styles.profile}>
        <UserProfileSettings user={user} />
      </div>
      <div className={styles.loginManagement}>
        <ProfileLoginSettings
          user={user}
          onAddLogin={() => setAddLogin(true)}
        />
        {!isUpdatedLanguageSettingsEnabled && (
          <LanguagePicker
            className={styles.languagePicker}
            disabled={loading}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            value={user?.locale}
            onChange={changeUserLocale}
          />
        )}
        <AccountButtons />
      </div>
      <AddLoginEmail onClose={() => setAddLogin(false)} isOpen={addLogin} />
    </div>
  );
}
