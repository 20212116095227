import { convertStringsToDates } from 'design-system-web';

import { ExternalPayerType as gqlPayerType } from 'graphql-query-contracts';

import { convertToUUID } from 'uuid-encoding';
import { BillingReportsQueryString } from '../hooks';

export function buildChargeReportFilters(
  filterParams: BillingReportsQueryString
) {
  const payerFilter = [];

  if (filterParams?.payer) {
    const prefix = filterParams?.payer.split('-');

    if (prefix[0] === 'c') {
      payerFilter.push({
        type: gqlPayerType.ExternalPayerTypeActivateCompany,
        ids: [convertToUUID(prefix[1])],
      });
    } else {
      payerFilter.push({
        type: gqlPayerType.ExternalPayerTypeActivateUser,
        ids: [convertToUUID(prefix[1])],
      });
    }
  }

  const chargeCodes = filterParams?.chargeCode?.split(',');
  const chargeCodesConverted: string[] = [];

  if (chargeCodes && chargeCodes.length > 0) {
    chargeCodes.map(chargeCode => {
      if (chargeCode !== '') {
        chargeCodesConverted.push(convertToUUID(chargeCode));
      }
    });
  }

  return {
    chargeCodes: filterParams?.chargeCode ? chargeCodesConverted : [],
    payers: payerFilter,
    ...(filterParams?.createdAt
      ? {
          createdDateRange: {
            startDate: convertStringsToDates(filterParams?.createdAt)
              ?.startDate,
            endDate: convertStringsToDates(filterParams?.createdAt)?.endDate,
          },
        }
      : {}),
  };
}
