import React, { useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { ChannelsContext } from 'lane-shared/contexts';
import { getPinnedContent } from 'lane-shared/helpers/channel';
import { BLOCK_CONTENT_QUERY } from 'lane-shared/helpers/constants/blocks';
import useSortFn from 'lane-shared/hooks/useSortFn';
import {
  useMultiLanguage,
  useFlag,
  useMultiChannelContent,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import {
  SortByEnum,
  SortDirectionEnum,
  SortPropertyType,
} from 'lane-shared/types/blocks/Sort';

import ContentLink from 'components/lane/ContentLink';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps, {
  EditModeProps,
} from 'components/renderers/v5/primitives/useEditModeProps';

import ContentCard from '../../../cards/ContentCard';
import CaughtUpMessage from '../../../general/CaughtUpMessage';
import EmptyState from '../../../general/EmptyState';
import ContentPin from '../../../lane/ContentPin';
import UserContentInteractionCard from '../../../lane/UserContentInteractionCard';
import ListHeader from './components/ListHeader';

import styles from './ContentListBlock.scss';
import { cloneDeep } from 'lodash';

type BlockProps = WebBlockProps & {
  contentType: 'Things Coming Up' | 'Today Feed' | 'Pinned Content' | 'Notices';
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  perPage: number;
  flexDirection: 'row' | 'column';
  sort: SortPropertyType;
};

function Wrapper({ className, style, children, ...props }: EditModeProps) {
  return (
    <div
      className={cx(styles.ContentListBlock, className)}
      style={style}
      {...props}
    >
      {children}
      <div className={styles.wrapper} />
    </div>
  );
}

export default function ContentListBlock({
  style,
  className,
  contentType,
  header,
  autoHide,
  showViewAll,
  perPage,
  flexDirection,
  sort,
  theme,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { t } = useTranslation();
  const { translate } = useMultiLanguage();
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  const {
    interactions: initialInteractions,
    notices: initialNotices,
    focusOnChannels,
    primaryId,
  } = useContext(ChannelsContext);

  const initialContents = useMultiChannelContent({
    channelIds: (focusOnChannels || []).map(channel => channel._id),
  });

  let contents = initialContents;
  let interactions = initialInteractions;
  let notices = initialNotices;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    contents = translate({
      model: cloneDeep(contents),
      columns: ['name', 'description', 'subtitle'],
      previewLanguage: props['data-preview-language'],
    });

    interactions = translate({
      model: cloneDeep(interactions),
      columns: [
        'contentData.name',
        'contentData.description',
        'contentData.subtitle',
      ],
      previewLanguage: props['data-preview-language'],
    });

    notices = translate({
      model: cloneDeep(notices),
      columns: ['name', 'description', 'subtitle'],
      previewLanguage: props['data-preview-language'],
    });
  }

  let sortBy: SortByEnum | SortByEnum[] = sort?.sortBy;

  if (
    [BLOCK_CONTENT_QUERY.TODAY_FEED, BLOCK_CONTENT_QUERY.NOTICES].includes(
      contentType
    )
  ) {
    if (sortBy === SortByEnum.StartDate) {
      sortBy = [SortByEnum.StartDate, SortByEnum.LiveDate];
    } else {
      sortBy = [SortByEnum.EndDate, SortByEnum.UnpublishDate];
    }
  }

  const { sortFn } = useSortFn({
    sortBy: sortBy || SortByEnum.EndDate,
    sortDirection: sort?.sortDirection || SortDirectionEnum.Asc,
  });

  function renderThingsComingUp() {
    const filteredInteractions = interactions.sort(sortFn).slice(0, perPage);

    if (
      !props['data-is-edit-mode'] &&
      autoHide &&
      filteredInteractions.length === 0
    ) {
      return null;
    }

    return (
      <Wrapper className={className} style={style} {...props}>
        <ListHeader
          theme={theme}
          header={header}
          totalItems={interactions.length}
          currentItems={filteredInteractions.length}
          link={routes.meRecentActivity}
          viewAll={showViewAll}
        />
        {filteredInteractions.length === 0 && (
          <EmptyState>
            {t(
              'web.admin.channel.content.layout.editor.components.contentListBlock.emptyList'
            )}
          </EmptyState>
        )}
        {filteredInteractions.length > 0 && (
          <ul className={styles[flexDirection]}>
            {filteredInteractions.map((interaction: any) => (
              <Link
                key={interaction._id}
                to={routes.interaction.replace(':id', interaction._id)}
                data-test={`test-${interaction._id}`}
              >
                <UserContentInteractionCard
                  className={styles.interactionCard}
                  interaction={interaction}
                />
              </Link>
            ))}
          </ul>
        )}
      </Wrapper>
    );
  }

  function renderNotices() {
    if (!props['data-is-edit-mode'] && autoHide && notices.length === 0) {
      return null;
    }

    const filteredNotices = notices.sort(sortFn).slice(0, perPage);

    return (
      <Wrapper className={className} style={style} {...props}>
        {header ? (
          <ListHeader
            theme={theme}
            header={header}
            totalItems={notices.length}
            currentItems={filteredNotices.length}
            link={routes.channelNotices.replace(':id', primaryId)}
            viewAll={showViewAll}
          />
        ) : null}
        {notices.length === 0 ? (
          <CaughtUpMessage />
        ) : (
          <ul className={styles[flexDirection]}>
            {filteredNotices.map((content: any) => (
              <Link
                key={content._id}
                to={routes.content.replace(':id', content._id)}
              >
                <ContentCard className={styles.ContentCard} content={content} />
              </Link>
            ))}
          </ul>
        )}
      </Wrapper>
    );
  }

  function renderTodayFeed() {
    if (!props['data-is-edit-mode'] && autoHide && contents.length === 0) {
      return null;
    }

    const filteredContents = contents.sort(sortFn).slice(0, perPage);

    return (
      <Wrapper className={className} style={style} {...props}>
        <ListHeader
          theme={theme}
          header={header}
          totalItems={contents.length}
          currentItems={filteredContents.length}
          link={routes.channelContent.replace(':id', primaryId)}
          viewAll={showViewAll}
        />
        {contents.length === 0 ? (
          <CaughtUpMessage />
        ) : (
          <ul className={styles[flexDirection]}>
            {filteredContents.map((content: any) => (
              <ContentLink content={content} key={content._id}>
                <ContentCard className={styles.ContentCard} content={content} />
              </ContentLink>
            ))}
          </ul>
        )}
      </Wrapper>
    );
  }

  function renderPinnedContent() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ChannelType[]' is not assignable... Remove this comment to see the full error message
    const allPinnedContent = getPinnedContent(focusOnChannels).filter(pin =>
      (pin.integration?.integration as any)?.platforms.includes(
        PlatformEnum.Web
      )
    );

    const pinnedContent = allPinnedContent.slice(0, perPage);

    if (!props['data-is-edit-mode'] && autoHide && pinnedContent.length === 0) {
      return null;
    }

    return (
      <Wrapper className={className} style={style} {...props}>
        <ListHeader
          theme={theme}
          header={header}
          totalItems={allPinnedContent.length}
          currentItems={pinnedContent.length}
          // todo: need a route for all pins
          link={routes.me}
          viewAll={showViewAll}
        />
        {pinnedContent.length === 0 ? (
          <EmptyState>
            {t(
              'web.admin.channel.content.layout.editor.components.contentListBlock.emptyPins'
            )}
          </EmptyState>
        ) : (
          <ul>
            {pinnedContent.map(pin => (
              <ContentLink content={pin} key={pin._id}>
                <ContentPin className={styles.contentPin} content={pin} />
              </ContentLink>
            ))}
          </ul>
        )}
      </Wrapper>
    );
  }

  switch (contentType) {
    case BLOCK_CONTENT_QUERY.THINGS_COMING_UP:
      return renderThingsComingUp();
    case BLOCK_CONTENT_QUERY.PINNED_CONTENT:
      return renderPinnedContent();
    case BLOCK_CONTENT_QUERY.NOTICES:
      return renderNotices();
    case BLOCK_CONTENT_QUERY.TODAY_FEED:
    default:
      return renderTodayFeed();
  }
}
