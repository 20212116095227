import { createContext } from 'react';
import { LaneType } from 'common-types';

export interface AuthUserContextInterface {
  // the current logged in user id (shared between Auth0 and Activate)
  userId: LaneType.UUID | null;
}

const defaultAuthUserContext: AuthUserContextInterface = {
  userId: null,
};

export const AuthUserContext = createContext<AuthUserContextInterface>(
  defaultAuthUserContext
);
