import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, PageSizeType, P } from 'design-system-web';
import { useChannelAdminContext } from 'hooks';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { useProductExceptionsListQuery } from '../helpers/useProductExceptionsListQuery';
import { useGetExceptionsTableColumns } from '../helpers/useGetExceptionsTableColumns';
import { Exception } from 'graphql-query-contracts/src/__generated__/graphql';
import { deleteProductExceptionMutation } from 'lane-shared/domains/billingPayments/queries';

import styles from './styles.scss';

type Props = {
  canManage?: boolean;
};

export function ExceptionsTable({ canManage }: Props) {
  const isEditProductEnabled = useFlag(FeatureFlag.EditProducts, false);
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [page, setPage] = useState(0);
  const { data: queryResponse, refetch } = useProductExceptionsListQuery({
    page,
    pageSize,
  });

  const onDelete = async (exception: Exception) => {
    const description = (
      <div className={styles.alertModalDescription}>
        <P className={styles.paragraph}>
          {t(
            'abp.productServices.priceException.deleteException.descriptionOne'
          )}
        </P>
        <P>
          {t(
            'abp.productServices.priceException.deleteException.descriptionTwo',
            {
              type: exception.productCategory?.productType
                ? t(
                    `abp.productsServices.productType.plural.${exception.productCategory?.productType}`
                  )
                : '',
            }
          )}
        </P>
      </div>
    );

    try {
      await window.Alert.confirm({
        title: t('abp.productServices.priceException.deleteException.title', {
          customer: exception.payer.id,
        }),
        message: description,
        confirmText: t('abp.productsServices.priceException.action.delete'),
        cancelText: t('abp.productsServices.priceException.action.cancel'),
        confirmButtonStyle: {
          backgroundColor: '#C7200A',
          borderColor: '#C7200A',
          color: 'white',
        },
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      if (channel?._id) {
        await deleteProductExceptionMutation(exception.id, channel._id);

        window.Toast.show(
          t('abp.productsServices.priceException.delete.success')
        );

        refetch();
      }
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      window.Toast.show(t('abp.productsServices.priceException.delete.error'));
    }
  };

  const exceptionsTableColumns = useGetExceptionsTableColumns(
    channel?._id,
    onDelete,
    isEditProductEnabled && canManage
  );

  return (
    <Table
      columns={exceptionsTableColumns}
      data={queryResponse?.listProductExceptions.exceptions || []}
      disableSorting
      isLoading={false}
      pagination="server"
      totalRows={queryResponse?.listProductExceptions.pageInfo?.total}
      pageSize={pageSize}
      page={page}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
    />
  );
}
