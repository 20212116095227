import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  ChargePartial,
  Invoice,
} from 'lane-shared/domains/billingPayments/types';

import { ChargeCard } from 'domains/billingAndPayments/components/InvoiceDetails/ChargeCard';

import { H4 } from 'components/typography';

import styles from './styles.scss';

export function ChargeList({
  invoice,
  charges,
}: {
  invoice: Invoice;
  charges: ChargePartial[];
}) {
  const { t } = useTranslation();

  return (
    <div>
      <H4 className={styles.SectionHeader}>
        {t('abp.invoice.details.productsAndServices')}
      </H4>
      {charges.map((charge: ChargePartial) => (
        <ChargeCard
          channelId={invoice.groupId}
          charge={charge}
          key={charge.id}
        />
      ))}
    </div>
  );
}
