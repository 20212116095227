import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { Loading } from 'design-system-web';

import { Document, usePDF } from '@react-pdf/renderer';
import { ServiceRequestDetailsList as ServiceRequestDetailsListPDFExport } from './components/ServiceRequestDetailsList';
import { ChargesListPDFExport } from 'domains/billingAndPayments/components/ChargeManager/ChargesListPDFExport/ChargesListPDFExport';
import { ActivityLogPDF } from 'domains/workOrder/activityLog/export/ActivityLogPDF';
import {
  CHARGE_STATUS,
  ExternalEntityType,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { convertToUUID } from 'uuid-encoding';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import {
  useBillingPaymentSettingsForChannel,
  useListChargesQuery,
} from 'lane-shared/domains/billingPayments/hooks';
import { getServiceRequestStatusLabel } from 'lane-shared/domains/workOrder/helpers';
import useUserLocale from 'hooks/useUserLocale';

import styles from './ServiceRequestDetailsPDF.scss';
import { useQuery } from '@apollo/client';
import { getServiceRequestAuditLogsQuery } from 'graphql-queries';

export const ServiceRequestDetailsPDFExportRenderer = ({
  channel,
  serviceRequestData,
  onExport,
}: any) => {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const isAuditLogsEnabled = useFlag(FeatureFlag.WorkOrdersAuditLogs, false);
  const { currency } = useBillingPaymentSettingsForChannel({
    channelId: channel?._id,
  });
  const paymentCurrency = currencyToPaymentCurrency(currency);
  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;
  const { charges, loading } = useListChargesQuery({
    channelId: channel._id,
    externalEntityType: ExternalEntityType.EXTERNAL_ENTITY_TYPE_WORKORDER,
    externalEntityId: convertToUUID(serviceRequestData.serviceRequestId),
  });

  const { data: activityLogData, loading: auditLogLoading } = useQuery(
    getServiceRequestAuditLogsQuery,
    {
      variables: {
        serviceRequestId: convertToUUID(serviceRequestData.serviceRequestId),
      },
      fetchPolicy: 'network-only',
    }
  );

  const { charges: draftCharges, loading: draftLoading } = useListChargesQuery({
    channelId: channel._id,
    externalEntityType: ExternalEntityType.EXTERNAL_ENTITY_TYPE_WORKORDER,
    externalEntityId: convertToUUID(serviceRequestData.serviceRequestId),
    isDraft: true,
  });

  const draftCharge = draftCharges.length > 0 ? draftCharges[0] : undefined;

  const translatedLabels = {
    title: t`web.admin.serviceRequest.details.title`,
    id: t`web.admin.serviceRequest.pdfId.text`,
    createdOn: t`web.admin.serviceRequest.createdOn`,
    status: t`web.admin.serviceRequest.status`,
    assignedTo: t`web.admin.serviceRequest.assignedTo`,
    submittedBy: t`web.admin.serviceRequest.submittedBy`,
    company: t`web.admin.serviceRequest.company`,
    floor: t`web.admin.serviceRequest.floor`,
    suite: t`web.admin.serviceRequest.suite`,
    phone: t`web.admin.serviceRequest.phone`,
    issue: t`web.admin.serviceRequest.issue`,
    category: t`web.admin.serviceRequest.category`,
    location: t`web.admin.serviceRequest.location`,
    description: t`web.admin.serviceRequest.description`,
    pageNumber: t`web.admin.serviceRequest.pdfPage.text`,
    billable: t`web.admin.serviceRequest.billable`,
    isBillable: t`web.admin.serviceRequest.billable.yes`,
    isNotBillable: t`web.admin.serviceRequest.billable.no`,
    notes: t`web.admin.serviceRequest.notes`,
    hours: t`web.admin.serviceRequest.hours`,
    draftTitle: t`abp.charges.chargeManager.pdf.draftTitle`,
    unsubmitted: t`abp.charges.chargeManager.pdf.unsubmitted`,
    chargeTitle: t`abp.charges.chargeManager.pdf.title`,
    chargeName: t`abp.charges.chargeManager.pdf.chargeName`,
    chargeStatus: t`abp.charges.chargeManager.pdf.status`,
    serviceCategory: t`abp.charges.chargeManager.pdf.service`,
    productCategory: t`abp.charges.chargeManager.pdf.product`,
    quantity: t`abp.charges.chargeManager.pdf.quantity`,
    rate: t`abp.charges.chargeManager.pdf.rate`,
    summary: t`abp.charges.chargeManager.pdf.summary`,
    subtotal: t`abp.charges.chargeManager.pdf.subtotal`,
    tax: t`abp.charges.chargeManager.pdf.tax`,
    total: t`abp.charges.chargeManager.pdf.total`,
    priceException: t`abp.productServices.priceException.pdf.label`,
    priceExceptionRate: t`abp.productServices.priceException.pdf.rate.label`,
    priceExceptionTax: t`abp.productServices.priceException.pdf.tax.label`,
    created: t`web.admin.serviceRequest.activity.created`,
    by: t`web.admin.serviceRequest.activity.by`,
    activity: t`web.admin.serviceRequest.activity`,
    automatic: t`web.admin.serviceRequest.activity.automatic`,
  };

  const translatedBillingStatuses: Record<string, string> = {};

  Object.values(CHARGE_STATUS).forEach(value => {
    translatedBillingStatuses[value] = t(value);
  });

  const PDF = (
    <Document>
      <ServiceRequestDetailsListPDFExport
        serviceRequestData={serviceRequestData}
        status={getServiceRequestStatusLabel(serviceRequestData?.status)}
        labels={translatedLabels}
        isPropertyChannel={isPropertyChannel}
      />
      <ChargesListPDFExport
        charges={charges}
        draftCharge={draftCharge}
        labels={translatedLabels}
        billingStatusLabels={translatedBillingStatuses}
        currency={paymentCurrency}
        locale={locale}
      />
      {isAuditLogsEnabled && (
        <ActivityLogPDF
          userFriendlyID={serviceRequestData?.userFriendlyID}
          createdAt={serviceRequestData?.createdAt}
          createdBy={serviceRequestData?.createdBy?.name}
          auditLogs={activityLogData?.serviceRequestAuditLogs}
          labels={translatedLabels}
          t={t}
        />
      )}
    </Document>
  );
  const [instance, update] = usePDF({ document: PDF });

  useEffect(() => {
    if (!loading && !draftLoading) {
      update();
    }
  }, [loading, draftLoading, draftCharges, auditLogLoading]);

  const pdfLoading =
    loading || instance.loading || draftLoading || auditLogLoading;

  return (
    <a
      href={instance.url!}
      download={`${serviceRequestData?.userFriendlyID}.pdf`}
      aria-disabled={pdfLoading}
    >
      <Button
        variant="contained"
        size="small"
        testId="pdfDownloadButton"
        style={{
          marginRight: 5,
          borderRadius: 8,
          width: 120,
          marginTop: 20,
        }}
        onClick={() => setTimeout(onExport)}
        disabled={pdfLoading}
      >
        {pdfLoading ? (
          <Loading className={styles.PDFLoadingSpinner} />
        ) : (
          t('web.admin.serviceRequest.export')
        )}
      </Button>
    </a>
  );
};
