import React from 'react';
import cx from 'classnames';

import { useChannelAdminContext, useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { routes } from 'lane-shared/config';

import { TabStrip } from 'components/general';
import { AdminPage, PageHeader } from 'components/layout';
import { ButtonType } from 'components/layout/PageHeader';

import { ChargesTable } from './ChargesTable';
import { BillingReportsTable } from './BillingReportsTable';

import styles from './styles.scss';

enum ChargesTabs {
  Charges = 'charges',
  BillingReports = 'billingReports',
}

export function Charges() {
  const { t } = useTranslation();
  const buttonType = 'primary' as ButtonType;
  const history = useHistory();
  const { channel } = useChannelAdminContext();

  const chargeBillingReportsFlag = useFlag(
    FeatureFlag.ChargeBillingReports,
    false
  );
  const tabs = [
    {
      value: ChargesTabs.Charges,
      label: 'abp.charges.charges',
    },
  ];

  if (chargeBillingReportsFlag) {
    tabs.push({
      value: ChargesTabs.BillingReports,
      label: 'abp.charges.billingReports',
    });
  }

  const [selectedTab, setSelectedTab] = useQueryString<{
    tab: ChargesTabs;
  }>({
    tab: ChargesTabs.Charges,
  });

  const handleCreateCharge = () => {
    const channel_slug = channel?.slug || '';

    history.push(
      routes.channelAdminCreateChargeForm.replace(':id', channel_slug)
    );
  };

  const actionButton = {
    label: t('abp.charges.createCharge'),
    type: buttonType,
    onClick: handleCreateCharge,
  };

  const tabComponents = {
    [ChargesTabs.Charges]: <ChargesTable />,
    [ChargesTabs.BillingReports]: <BillingReportsTable />,
  };

  return (
    <AdminPage>
      <PageHeader
        header={t('abp.charges.charges')}
        headerLevel="h3"
        actionButtons={[actionButton]}
        externalPadding={[0, 0]}
      />
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab.tab }}
        onSelectTab={tab => setSelectedTab({ tab: tab.value as ChargesTabs })}
        className={cx(styles.chargesTabStrip)}
      />
      {tabComponents[selectedTab.tab]}
    </AdminPage>
  );
}
