import React, { useEffect, useState } from 'react';

import { getClient } from 'lane-shared/apollo';
import { getLookerEmbedURL } from 'lane-shared/graphql/looker';

import { Loading } from 'components/general';

import { LookerReportType } from 'graphql-query-contracts';

import styles from './LookerIframeWidget.scss';

type Params = {
  title: string;
  channelId?: string;
  channelName?: string;
  contentName?: string;
  reportType: LookerReportType;
  // Iframe viewport height is tricky as you can not know how much space to allocate before iFrame is loaded
  // simple solution is to pass height property from parent
  style?: {
    iFrameHeight: string;
  };
};

const defaultIframeHeight = '60em';

export function LookerIframeWidget({
  title,
  channelId,
  channelName,
  contentName,
  reportType,
  style,
}: Params) {
  const [embedURL, setURL] = useState('');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    async function getEmbedURL() {
      if (!channelId || !channelName) {
        setURL('');

        return;
      }

      try {
        const { data } = await getClient().query({
          query: getLookerEmbedURL,
          variables: {
            selectedReport: reportType,
            channelId,
            channelName,
            contentName,
            timezone,
          },
          fetchPolicy: 'network-only',
        });

        setURL(data.me.lookerEmbedURL);
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        setURL('');
      }
    }

    getEmbedURL();
  }, [channelId, channelName, contentName, reportType]);

  return embedURL === '' ? (
    <Loading />
  ) : (
    <iframe
      id="embed-iframe"
      className={styles.LookerIframeContainer}
      style={{
        height: style?.iFrameHeight ?? defaultIframeHeight,
      }}
      src={embedURL}
      title={title}
      frameBorder="0"
    />
  );
}
