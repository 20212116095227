import React from 'react';

import { View, Text } from '@react-pdf/renderer';

import {
  meterReadingNotificationTrigger,
  getNotificationTriggerLabel,
} from 'domains/workOrder/equipment/utils/meterReadingNotificationTrigger';

import { styles } from '../EquipmentPDFStyle';
import { MeterReadingSetting } from 'graphql-query-contracts';

type EquipmentPDFProps = {
  meterReadingSettingData?: MeterReadingSetting | null;
  textData: any;
  t: (val: string, opts?: any) => string;
};

export const MeterReadingExport = ({
  meterReadingSettingData,
  textData,
  t,
}: EquipmentPDFProps) => {
  return (
    <View style={styles.meterReadingContainer}>
      <View style={styles.fieldContainer2}>
        <Text style={styles.subheadings}>{textData.meterReadingName}:</Text>
        <Text style={styles.subHeadingValues}>
          {meterReadingSettingData?.name || ' '}
        </Text>
      </View>

      <View style={styles.fieldContainer2}>
        <Text style={styles.subheadings}>{textData.meterReadingUnit}:</Text>
        <Text style={styles.subHeadingValues}>
          {meterReadingSettingData?.unit || ' '}
        </Text>
      </View>

      <View style={styles.fieldContainer2}>
        <Text style={styles.subheadings}>
          {textData.meterReadingEnableNotification}
        </Text>
        <Text style={styles.subHeadingValues}>
          {meterReadingSettingData?.enableNotification || ' '}
        </Text>
      </View>

      <View style={styles.twoFieldRow}>
        <View style={styles.twoFieldContainer2}>
          <Text style={styles.subheadings}>
            {textData.meterReadingNotificationTrigger}
          </Text>
          <Text style={styles.subHeadingValues}>
            {meterReadingSettingData?.notificationTrigger
              ? t(
                  getNotificationTriggerLabel(
                    meterReadingSettingData?.notificationTrigger
                  )
                )
              : ' '}
          </Text>
        </View>
        <View style={styles.twoFieldContainer2}>
          <Text style={styles.subheadings}>
            {textData.meterReadingBoundValue}
          </Text>
          <Text style={styles.subHeadingValues}>
            {meterReadingSettingData?.notificationTrigger ===
              meterReadingNotificationTrigger.LESS_THAN ||
            meterReadingSettingData?.notificationTrigger ===
              meterReadingNotificationTrigger.OUTSIDE_RANGE_OF
              ? meterReadingSettingData?.lowerBound
              : ''}
            {meterReadingSettingData?.notificationTrigger ===
            meterReadingNotificationTrigger.OUTSIDE_RANGE_OF
              ? ' - '
              : ''}
            {meterReadingSettingData?.notificationTrigger ===
              meterReadingNotificationTrigger.GREATER_THAN ||
            meterReadingSettingData?.notificationTrigger ===
              meterReadingNotificationTrigger.OUTSIDE_RANGE_OF
              ? meterReadingSettingData?.upperBound
              : ' '}
          </Text>
        </View>
      </View>

      <View style={styles.fieldContainer2}>
        <Text style={styles.subheadings}>
          {textData.meterReadingNotificationTypes}
        </Text>
        <Text style={styles.subHeadingValues}>
          {meterReadingSettingData?.notificationTypes?.includes('push')
            ? t(
                'web.admin.serviceRequest.equipment.MeterReading.notificationType.push'
              )
            : ' '}
          {meterReadingSettingData?.notificationTypes &&
          meterReadingSettingData?.notificationTypes.length > 1
            ? ' & '
            : ' '}
          {meterReadingSettingData?.notificationTypes?.includes('email')
            ? t(
                'web.admin.serviceRequest.equipment.MeterReading.notificationType.email'
              )
            : ' '}
        </Text>
      </View>
    </View>
  );
};
