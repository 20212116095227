import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { safeConvertToUUID } from 'uuid-encoding';
import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';

export const useFetchRelationships = (
  channelId: string | undefined,
  tenantId: string
) => {
  const [fetchRelationships, { data, error }] = useLazyQuery(
    queryChannelsByRelationship,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (channelId) {
      fetchRelationships({
        variables: {
          search: {
            relatedTo: { _id: safeConvertToUUID(channelId) },
            channel: {
              _id: safeConvertToUUID(tenantId),
            },
          },
          skipPagination: true,
        },
      });
    }
  }, [channelId, tenantId, fetchRelationships]);

  return {
    data,
    error,
  };
};
