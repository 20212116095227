import React, { useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { convertToUUID } from 'uuid-encoding';

import {
  Table,
  getPageSizeFromQueryString,
  convertStringsToDates,
} from 'design-system-web';

import ChannelAdminContext from '../ChannelAdminContext';
import {
  InvoiceQueryString,
  useGetInvoiceTableFilters,
  DEFAULT_FILTER_PARAMS,
} from './helpers/useGetInvoiceTableFilters';
import { listInvoicesQuery } from './helpers/chargesInvoiceQueries';
import { useGetInvoiceTableColumns } from './helpers/useGetInvoiceTableColumns';
import { useQueryString } from 'hooks';
import { ListInvoicesRequest, GroupType } from 'graphql-query-contracts';
import { useTranslation } from 'react-i18next';
import { exportCSV } from './helpers/exportCSV';
import { useGetActionsForInvoices } from './helpers/useGetActionsForInvoices';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { getThreeLetterTimeZoneAbbreviation } from 'lane-shared/helpers/formatters';
import { useGetBulkActionsForInvoices } from './helpers/useGetBulkActionsForInvoices';

export function InvoicesTable() {
  const { channel } = useContext(ChannelAdminContext);
  const { t } = useTranslation();
  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';
  const threeLetterAbbrev = getThreeLetterTimeZoneAbbreviation(timeZone);
  const invoiceTableColumns = useGetInvoiceTableColumns(
    timeZone,
    threeLetterAbbrev
  );
  const invoiceTableColumnsToExport = useGetInvoiceTableColumns(timeZone);
  const [loadInvoicesList, loadInvoicesMetadata] =
    useLazyQuery(listInvoicesQuery);

  const [filterParams, setFilterParams] = useQueryString<InvoiceQueryString>(
    DEFAULT_FILTER_PARAMS
  );

  const totalInvoices =
    loadInvoicesMetadata?.data?.accounts?.listInvoices?.pageInfo?.total;

  const invoiceActions = useGetActionsForInvoices();
  const columnsToExport = invoiceActions.length
    ? invoiceTableColumnsToExport.slice(0, -1)
    : invoiceTableColumnsToExport;

  const exportOptions = [
    {
      label: t('abp.invoice.exportPage'),
      onClick: () => {
        exportCSV(
          loadInvoicesMetadata?.data?.accounts?.listInvoices?.invoices,
          columnsToExport,
          `invoices-${new Date().toISOString()}.csv`
        );
      },
    },
  ];

  const bulkActions = useGetBulkActionsForInvoices();

  useEffect(() => {
    if (channel) {
      loadInvoicesList({
        variables: {
          listInvoicesRequest: {
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
            pagination: {
              start:
                ((filterParams?.page || 0) as number) *
                getPageSizeFromQueryString(filterParams?.pageSize),
              perPage: getPageSizeFromQueryString(filterParams?.pageSize),
            },
            filter: {
              statuses: filterParams?.status
                ? filterParams.status.split(',')
                : [],
              ...(filterParams?.dueDate
                ? {
                    dueDate: {
                      startDate: convertStringsToDates(filterParams?.dueDate)
                        ?.startDate,
                      endDate: convertStringsToDates(filterParams?.dueDate)
                        ?.endDate,
                    },
                  }
                : {}),
              ...(filterParams?.invoiceDate
                ? {
                    invoicedDate: {
                      startDate: convertStringsToDates(
                        filterParams?.invoiceDate
                      )?.startDate,
                      endDate: convertStringsToDates(filterParams?.invoiceDate)
                        ?.endDate,
                    },
                  }
                : {}),
            },
          } as ListInvoicesRequest,
        },
      });
    }
  }, [
    channel?._id,
    loadInvoicesList,
    filterParams.page,
    filterParams.pageSize,
    filterParams.status,
    filterParams.dueDate,
    filterParams.invoiceDate,
  ]);

  useEffect(() => {
    if (totalInvoices) {
      setFilterParams({
        total: totalInvoices,
      });
    }
  }, [loadInvoicesMetadata?.data?.accounts?.listInvoices?.pageInfo]);

  return (
    <Table
      columns={invoiceTableColumns}
      data={
        loadInvoicesMetadata.data
          ? loadInvoicesMetadata.data.accounts.listInvoices.invoices
          : []
      }
      disableSorting
      isLoading={loadInvoicesMetadata.loading}
      pagination="server"
      totalRows={Number(filterParams.total)}
      queryStringsEnabled
      filters={useGetInvoiceTableFilters()}
      hasKeywordFilter={false}
      exportOptions={exportOptions}
      bulkActions={bulkActions}
      isSelectable={true}
      getRowId={row => row.displayId}
    />
  );
}
