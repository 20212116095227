import React from 'react';

import { LaneType } from 'common-types';
import { SelectChannelTemplateId } from './SelectChannelTemplateId';
import { CesAutoSetupOptions } from './sources/ces-auto-setup/CesAutoSetupOptions';
import { RiseMigrationFeatures } from './sources/rise-migration-features/RiseMigrationFeatures';
import {
  DataSourceEnum,
  RiseMigrationOptionsType,
  SelectChannelTemplateIdOptionsType,
  CesOptionsType,
} from './types';

export type WizardStepTwoParams = {
  selectedDataSource: DataSourceEnum;
  parentChannelId?: LaneType.UUID;
  riseMigrationOptions: RiseMigrationOptionsType;
  selectChannelTemplateIdOptions: SelectChannelTemplateIdOptionsType;
  cesOptions: CesOptionsType;
};

export function WizardStepTwo(params: WizardStepTwoParams) {
  const {
    selectedDataSource,
    parentChannelId,
    riseMigrationOptions,
    selectChannelTemplateIdOptions,
    cesOptions,
  } = params;

  const {
    config,
    setConfig,
    templateValidation,
    cbreOnboardingMode,
    setCbreOnboardingMode,
  } = selectChannelTemplateIdOptions;

  if (selectedDataSource === DataSourceEnum.RISE) {
    return (
      <RiseMigrationFeatures
        {...{ ...riseMigrationOptions, parentChannelId }}
      />
    );
  }

  if (selectedDataSource === DataSourceEnum.CES) {
    return (
      <CesAutoSetupOptions
        onCloningTemplateChange={v => setConfig({ ...config, templateId: v })}
        cloningChannelTemplateId={config.templateId}
        templateType={config.templateType}
        onTemplateTypeChange={t => setConfig({ ...config, templateType: t })}
        {...cesOptions}
      />
    );
  }

  // Selected DataSource is sFTP
  return (
    <SelectChannelTemplateId
      onChange={v => setConfig({ ...config, templateId: v })}
      currentValue={config.templateId}
      templateType={config.templateType}
      onTemplateTypeChange={t => setConfig({ ...config, templateType: t })}
      validationError={templateValidation}
      cbreOnboardingMode={cbreOnboardingMode}
      setCbreOnboardingMode={setCbreOnboardingMode}
    />
  );
}
