import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import {
  PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
  PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
  PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
} from 'lane-shared/helpers/constants/permissions';
import hasPermission from 'lane-shared/helpers/hasPermission';

import { AdminPage, Button, ControlMenu, Flex, TabStrip } from 'components';
import { UrlSearchParamsWithPages } from 'components/lds/table/types';
import { useQueryString } from 'hooks';

import { H3 } from 'components/typography';

import ScheduleListComponent from '../../schedule/components/ScheduleList';
import TaskList from '../../task/components/TaskList';
import StaffTaskList from '../../task/components/TaskList/StaffTaskList';

import styles from './index.scss';
import { FeatureFlag } from 'constants-flags';
import { useFlag } from 'lane-shared/hooks';

type PreventiveMaintenanceUrlSearchParams<TabType = string> =
  UrlSearchParamsWithPages & {
    tab: TabType;
  };

enum PreventiveMaintenanceTabs {
  Tasks = 'tasks',
  Schedules = 'schedules',
}

export enum StaffPreventiveMaintenanceTabs {
  AssignedToMe = 'assignedToMe',
  Unassigned = 'unassigned',
  Active = 'active',
  Complete = 'complete',
}

export interface TabsMap {}

const getTabsMap = (
  hasAccessToSchedules: boolean,
  hasViewAllAccess: boolean
): TabsMap =>
  Object.freeze({
    ...(hasViewAllAccess
      ? {
          tasks: {
            value: PreventiveMaintenanceTabs.Tasks,
            label: 'web.admin.workOrder.preventiveMaintenance.taskTabHeading',
            Component: TaskList,
          },
        }
      : {
          assignedToMe: {
            value: StaffPreventiveMaintenanceTabs.AssignedToMe,
            label:
              'web.admin.workOrder.preventiveMaintenance.assignedToMeTabHeading',
            Component: StaffTaskList,
          },
          unassigned: {
            label:
              'web.admin.workOrder.preventiveMaintenance.unassignedTabHeading',
            value: StaffPreventiveMaintenanceTabs.Unassigned,
            Component: StaffTaskList,
          },
          active: {
            label: 'web.admin.workOrder.preventiveMaintenance.activeTabHeading',
            value: StaffPreventiveMaintenanceTabs.Active,
            Component: StaffTaskList,
          },
          complete: {
            label: 'web.admin.workOrder.preventiveMaintenance.closedTabHeading',
            value: StaffPreventiveMaintenanceTabs.Complete,
            Component: StaffTaskList,
          },
        }),
    ...(hasAccessToSchedules
      ? {
          schedules: {
            label:
              'web.admin.workOrder.preventiveMaintenance.scheduleTabHeading',
            value: PreventiveMaintenanceTabs.Schedules,
            Component: ScheduleListComponent,
          },
        }
      : {}),
  });

const getTabComponent = (
  selectedTab: string,
  tabMap: any
): (({
  channel,
  searchParams,
  updateSearchParams,
}: {
  channel: any;
  searchParams: PreventiveMaintenanceUrlSearchParams;
  updateSearchParams: (prop: any) => void;
}) => JSX.Element | null) => tabMap[selectedTab].Component;

function PreventiveMaintenanceHomePage({ channel }: any) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const viewAllTaskUser =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_TASK_VIEW],
      channel?._id,
      false
    );

  const canCreateSchedule =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE],
      channel?._id,
      false
    );
  const hasAccessToSchedules =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW],
      channel?._id,
      false
    );

  const isConfigurableNotificationsEnabled = useFlag(
    FeatureFlag.WorkOrdersConfigurableNotifications,
    false
  );

  const [query, goToUrl] = useQueryString<PreventiveMaintenanceUrlSearchParams>(
    {
      tab: viewAllTaskUser
        ? PreventiveMaintenanceTabs.Tasks
        : StaffPreventiveMaintenanceTabs.AssignedToMe,
    }
  );

  function renderPageForAdmin() {
    const CurrentTab = getTabComponent(
      query.tab || PreventiveMaintenanceTabs.Tasks,
      getTabsMap(hasAccessToSchedules, viewAllTaskUser)
    );

    return (
      <AdminPage>
        <div className={styles.HeadingContainer}>
          <H3>{t`web.admin.workOrder.preventiveMaintenance.title`}</H3>
          <Flex gap={2}>
            {canCreateSchedule && isConfigurableNotificationsEnabled ? (
              <Link
                to={routes.channelAdminWorkOrdersPMTaskSettings.replace(
                  ':id',
                  channel?.slug
                )}
              >
                <Button variant="outlined">{t`web.admin.workOrder.preventiveMaintenance.taskSettings`}</Button>
              </Link>
            ) : null}
            {canCreateSchedule ? (
              <Link
                to={routes.channelAdminWorkOrdersPMScheduleCreate.replace(
                  ':id',
                  channel?.slug
                )}
              >
                <Button variant="contained">{t`web.admin.workOrder.preventiveMaintenance.addSchedule`}</Button>
              </Link>
            ) : null}
          </Flex>
        </div>
        <ControlMenu>
          <TabStrip
            tabs={Object.values(
              getTabsMap(hasAccessToSchedules, viewAllTaskUser)
            )}
            selected={{ value: query.tab }}
            onSelectTab={tab => goToUrl({ tab: tab.value })}
            className={styles.preventiveMaintenanceTabs}
          />
        </ControlMenu>
        <CurrentTab
          channel={channel}
          searchParams={query}
          updateSearchParams={goToUrl}
        />
      </AdminPage>
    );
  }

  function renderPageForStaff() {
    const CurrentTab = getTabComponent(
      query.tab || StaffPreventiveMaintenanceTabs.AssignedToMe,
      getTabsMap(hasAccessToSchedules, viewAllTaskUser)
    );

    return (
      <AdminPage>
        <div className={styles.HeadingContainer}>
          <H3>{t`web.admin.workOrder.preventiveMaintenance.title`}</H3>
        </div>
        <ControlMenu>
          <TabStrip
            tabs={Object.values(
              getTabsMap(hasAccessToSchedules, viewAllTaskUser)
            )}
            selected={{ value: query.tab }}
            onSelectTab={tab => goToUrl({ tab: tab.value })}
            className={styles.preventiveMaintenanceTabs}
          />
        </ControlMenu>
        <CurrentTab
          channel={channel}
          searchParams={query}
          updateSearchParams={goToUrl}
        />
      </AdminPage>
    );
  }

  return viewAllTaskUser ? renderPageForAdmin() : renderPageForStaff();
}

export default PreventiveMaintenanceHomePage;
