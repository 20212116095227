import ExcelJS from 'exceljs';
import { chunk } from 'lodash';
import { HYPERCARE_MAPPINGS } from './constants';

const EQUIPMENTS_LINK_LENGTH = 19;

export const transformData = (
  str: string,
  index: number,
  transforms: string[]
) => {
  let newStr = str;

  transforms.forEach(transform => {
    switch (transform) {
      case 'date': {
        const parsedDate = new Date(str);

        newStr = !Number.isNaN(parsedDate.getTime())
          ? parsedDate.toISOString()
          : str;
        break;
      }

      case 'hours_to_number': {
        const validDate = new Date(str);

        if (Number.isNaN(validDate.getTime())) {
          newStr = '';
          break;
        }

        const hours = (str as any).getUTCHours();
        const minutes = (str as any).getUTCMinutes();

        newStr = (hours + minutes / 60).toString();
        break;
      }

      case 'index': {
        newStr = String(index);
        break;
      }

      case 'name': {
        newStr = str.length
          ? str.split(',').reverse().join(' ')
          : (str as any)?.result || '';
        break;
      }

      case 'status': {
        newStr = str.toLowerCase() === 'yes' ? 'Active' : 'Inactive';
        break;
      }

      case 'repeats': {
        if (str?.includes('1 Day(s)') || str?.includes('1 D')) {
          newStr = 'daily';
        } else if (str?.includes('1 Week(s)') || str?.includes('1 W')) {
          newStr = 'weekly';
        } else if (str?.includes('2 Week(s)') || str?.includes('2 W')) {
          newStr = 'bi-weekly';
        } else if (str?.includes('1 Month(s)') || str?.includes('1 M')) {
          newStr = 'monthly';
        } else if (str?.includes('2 Month(s)') || str?.includes('2 M')) {
          newStr = 'bi-monthly';
        } else if (str?.includes('3 Month(s)') || str?.includes('3 M')) {
          newStr = 'quarterly';
        } else if (str?.includes('6 Month(s)') || str?.includes('6 M')) {
          newStr = 'semi-annually';
        } else if (str?.includes('12 Month(s)') || str?.includes('12 M')) {
          newStr = 'annually';
        } else {
          newStr = str;
        }

        break;
      }

      case 'date_range_start': {
        const dateRange = str.split('/');
        const parsedDate = new Date(dateRange?.[0]);

        newStr = !Number.isNaN(parsedDate.getTime())
          ? parsedDate.toISOString()
          : '';
        break;
      }

      case 'date_range_end': {
        const dateRange = str.split('/');
        const parsedDate = new Date(dateRange?.[1]);

        newStr = !Number.isNaN(parsedDate.getTime())
          ? parsedDate.toISOString()
          : '';
        break;
      }

      default:
        break;
    }
  });

  return newStr;
};

export const addDropdowns = (workbook: ExcelJS.Workbook) => {
  const equipmentSheet = workbook.getWorksheet('Equipment list');
  const scheduleSheet = workbook.getWorksheet('PM Schedules list');
  const taskSheet = workbook.getWorksheet('TasksImports');
  const srSheet = workbook.getWorksheet('ServiceRequestImports');
  const userSheet = workbook.getWorksheet('UserImports');

  if (equipmentSheet) {
    addCategoryDropdown(equipmentSheet);
    addEqStatusDropdown(equipmentSheet);
  }

  if (scheduleSheet) {
    addRecurrenceDropdown(scheduleSheet);
    addScheduleUserDropdowns(scheduleSheet, workbook);
  }

  if (taskSheet) {
    addRecurrenceDropdown(taskSheet);
    addTaskStatusDropdown(taskSheet);
    addTaskUserDropdowns(taskSheet, workbook);
  }

  if (srSheet) {
    addSRStatusDropdown(srSheet);
    addSRUserDropdowns(srSheet, workbook);
  }

  if (userSheet) {
    addMarketingDropdown(userSheet);
    addUserStatusDropdown(userSheet);
  }
};

export const populateSheets = (workbook: ExcelJS.Workbook) => {
  populateUserSheet(workbook);
  populateBillingSheets(workbook);
  populateScheduleEquipmentLinkedSheet(workbook);
};

const addCategoryDropdown = (equipmentSheet: ExcelJS.Worksheet) => {
  const categoryColumn = equipmentSheet?.getColumn(3);
  const categoryOptions =
    HYPERCARE_MAPPINGS.PMSetupEquipSched.category.dropdownOptions;

  if (categoryOptions) {
    const { sheetName, start, end } = categoryOptions;
    const formulaRange = `${sheetName}!${start}:${end}`;

    categoryColumn?.eachCell((cell, index) => {
      if (index > 1) {
        cell.dataValidation = {
          type: 'list',
          formulae: [formulaRange],
          errorStyle: 'error',
        };
      }
    });

    equipmentSheet.addConditionalFormatting({
      ref: 'C2:C1000',
      rules: [
        {
          priority: 1,
          type: 'expression',
          formulae: [`=AND(C2<>"", ISNA(MATCH(C2, Reference!$B$2:$B$105, 0)))`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFFF0000' },
            },
          },
        },
      ],
    });
  }
};

const addEqStatusDropdown = (equipmentSheet: ExcelJS.Worksheet) => {
  const statusColumn = equipmentSheet?.getColumn(4);

  statusColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: ['Reference!$C$2:$C$3'],
      };
    }
  });

  equipmentSheet.addConditionalFormatting({
    ref: 'D2:D1000',
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [`=AND(D2<>"", ISNA(MATCH(D2, Reference!$C$2:$C$3, 0)))`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const addRecurrenceDropdown = (scheduleSheet: ExcelJS.Worksheet) => {
  const recurrenceColumn = scheduleSheet?.getColumn(6);

  recurrenceColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: ['Reference!$D$2:$D$9'],
      };
    }
  });

  scheduleSheet.addConditionalFormatting({
    ref: 'F2:F1000',
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [`=AND(F2<>"", ISNA(MATCH(F2, Reference!$D$2:$D$9, 0)))`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const addScheduleUserDropdowns = (
  scheduleSheet: ExcelJS.Worksheet,
  workbook: ExcelJS.Workbook
) => {
  const userColumnIndices = [4, 13, 15];

  userColumnIndices.forEach(columnIndex => {
    const column = scheduleSheet.getColumn(columnIndex);
    const letter = String.fromCharCode(64 + columnIndex);

    addUserDropdown(letter, column, scheduleSheet, workbook);
  });
};

const addTaskStatusDropdown = (taskSheet: ExcelJS.Worksheet) => {
  const statusColumn = taskSheet?.getColumn(15);

  statusColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: ['Reference!$E$2:$E$10'],
      };
    }
  });

  taskSheet.addConditionalFormatting({
    ref: 'O2:O1000',
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [`=AND(O2<>"", ISNA(MATCH(O2, Reference!$E$2:$E$10, 0)))`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const addTaskUserDropdowns = (
  taskSheet: ExcelJS.Worksheet,
  workbook: ExcelJS.Workbook
) => {
  const userColumnIndices = [5, 9, 12];

  userColumnIndices.forEach(columnIndex => {
    const column = taskSheet.getColumn(columnIndex);
    const letter = String.fromCharCode(64 + columnIndex);

    addUserDropdown(letter, column, taskSheet, workbook);
  });
};

const addSRStatusDropdown = (srSheet: ExcelJS.Worksheet) => {
  const statusColumn = srSheet?.getColumn(11);

  statusColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: ['Reference!$F$2:$F$10'],
      };
    }
  });

  srSheet.addConditionalFormatting({
    ref: 'K2:K1000',
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [`=AND(K2<>"", ISNA(MATCH(K2, Reference!$F$2:$F$10, 0)))`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const addSRUserDropdowns = (
  srSheet: ExcelJS.Worksheet,
  workbook: ExcelJS.Workbook
) => {
  const userColumnIndices = [9, 10, 13];

  userColumnIndices.forEach(columnIndex => {
    const column = srSheet.getColumn(columnIndex);
    const letter = String.fromCharCode(64 + columnIndex);

    addUserDropdown(letter, column, srSheet, workbook);
  });
};

const addUserDropdown = (
  letter: string,
  column: ExcelJS.Column,
  sheet: ExcelJS.Worksheet,
  workbook: ExcelJS.Workbook
) => {
  const sourceSheet = workbook.getWorksheet('Users');
  const dropdownFormula = 'Users!$B$2:$B$1048576';
  const dropdownValues: string[] = [];

  sourceSheet?.eachRow((row, rowIndex) => {
    const cell = row.getCell('B');

    if (rowIndex > 1 && cell.value) {
      dropdownValues.push(`"${cell.text}"`);
    }
  });

  column.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: [dropdownFormula],
      };
    }
  });

  sheet.addConditionalFormatting({
    ref: `${letter}2:${letter}1000`,
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [
          `=AND(${letter}2<>"", ISNA(MATCH(${letter}2, ${dropdownFormula}, 0)))`,
        ],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const addMarketingDropdown = (userSheet: ExcelJS.Worksheet) => {
  const marketingColumn = userSheet?.getColumn(5);

  marketingColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: ['Reference!$G$2:$G$3'],
      };
    }
  });

  userSheet.addConditionalFormatting({
    ref: 'D2:D1000',
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [`=AND(D2<>"", ISNA(MATCH(D2, Reference!$G$2:$G$3, 0)))`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const addUserStatusDropdown = (userSheet: ExcelJS.Worksheet) => {
  const statusColumn = userSheet?.getColumn(6);

  statusColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: ['Reference!$H$2:$H$3'],
      };
    }
  });

  userSheet.addConditionalFormatting({
    ref: 'E2:E1000',
    rules: [
      {
        priority: 1,
        type: 'expression',
        formulae: [`=AND(E2<>"", ISNA(MATCH(E2, Reference!$H$2:$H$3, 0)))`],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFFF0000' },
          },
        },
      },
    ],
  });
};

const populateUserSheet = (workbook: ExcelJS.Workbook) => {
  const sourceSheet = workbook.getWorksheet('(OPT)Pools List');
  const userSheet = workbook.getWorksheet('Users');
  const emailSet = new Set<string>();
  let rowIndex = 2;

  if (!sourceSheet || !userSheet) return;

  sourceSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const fullName = row.getCell('C').value as string;
    const email = row.getCell('E').value as string;
    const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').slice(2, 27);

    if (!email || emailSet.has(email)) return;

    rowIndex++;
    emailSet.add(email);
    userSheet.getCell(`A${rowIndex}`).value = transformData(
      fullName,
      rowNumber,
      ['name']
    );
    userSheet.getCell(`B${rowIndex}`).value = email;
    columns.forEach(colLetter => {
      userSheet.getCell(`${colLetter}${rowIndex}`).value = '';
    });
  });
};

const populateBillingSheets = (workbook: ExcelJS.Workbook) => {
  addChargeCodes(workbook);
  addCategories(workbook);
  addProducts(workbook);
};

const populateScheduleEquipmentLinkedSheet = (workbook: ExcelJS.Workbook) => {
  const equipmentSchduleListSheet = workbook.getWorksheet('PMSetupEquipSched');
  const scheduleListSheet = workbook.getWorksheet('PM Schedules list');
  const scheduleEquipmentLinkSheet = workbook.getWorksheet(
    'ScheduleEquipment Linking'
  );

  if (
    !equipmentSchduleListSheet ||
    !scheduleListSheet ||
    !scheduleEquipmentLinkSheet
  )
    return;

  const scheduleIdEquipmentNameMap: { [key: string]: string } = {};

  equipmentSchduleListSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const scheduleId = row.getCell('Q').value as string;
    const equipmentName = row.getCell('H').value as string;

    if (!equipmentName || !scheduleId) return;

    scheduleIdEquipmentNameMap[scheduleId] = equipmentName;
  });

  const scheduleTitlesMap: {
    [key: string]: {
      scheduleIds: Set<string>;
      equipmentNames: Set<string>;
    };
  } = {};

  scheduleListSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const scheduleTitle = row.getCell('B').value as string;
    const scheduleId = row.getCell('A').value as string;

    if (!scheduleTitle || !scheduleId) return;

    if (!scheduleTitlesMap[scheduleTitle]) {
      scheduleTitlesMap[scheduleTitle] = {
        scheduleIds: new Set(),
        equipmentNames: new Set(),
      };
    }

    scheduleTitlesMap[scheduleTitle].scheduleIds.add(scheduleId);

    if (scheduleIdEquipmentNameMap[scheduleId]) {
      scheduleTitlesMap[scheduleTitle].equipmentNames.add(
        scheduleIdEquipmentNameMap[scheduleId]
      );
    }
  });

  const rowData: string[][] = [];

  Object.entries(scheduleTitlesMap).forEach(([scheduleTitle, details]) => {
    const equipmentNames = Array.from(details.equipmentNames);

    if (equipmentNames.length > EQUIPMENTS_LINK_LENGTH) {
      const equipmentChunks = chunk(equipmentNames, EQUIPMENTS_LINK_LENGTH);

      equipmentChunks.forEach(chunk => {
        rowData.push([scheduleTitle, ...chunk]);
      });
    } else {
      rowData.push([scheduleTitle, ...equipmentNames]);
    }
  });

  rowData.forEach((row, index) => {
    scheduleEquipmentLinkSheet.getRow(index + 2).values = row;
  });
};

const addChargeCodes = (workbook: ExcelJS.Workbook) => {
  const serviceSchedulesSheet = workbook.getWorksheet('(opt)ServiceSchedules');
  const billingChargeCodesSheet = workbook.getWorksheet('Billing Charge Codes');

  if (!serviceSchedulesSheet || !billingChargeCodesSheet) {
    return;
  }

  const chargeCodesSet = new Set<string>();

  serviceSchedulesSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const chargeCode1 = row.getCell('G').value;

    if (chargeCode1 && chargeCode1 !== '-') {
      chargeCodesSet.add(chargeCode1.toString());
    }

    const chargeCode2 = row.getCell('J').value;

    if (chargeCode2 && chargeCode2 !== '-') {
      chargeCodesSet.add(chargeCode2.toString());
    }
  });

  let rowIndex = 2;

  chargeCodesSet.forEach(chargeCode => {
    billingChargeCodesSheet.getCell(`A${rowIndex}`).value = chargeCode;
    rowIndex++;
  });
};

const addCategories = (workbook: ExcelJS.Workbook) => {
  const serviceSchedulesSheet = workbook.getWorksheet('(opt)ServiceSchedules');
  const billingCategoriesSheet = workbook.getWorksheet('Billing Categories');

  if (!serviceSchedulesSheet || !billingCategoriesSheet) {
    return;
  }

  let rowIndex = 2;
  const uniqueProducts = new Set<string>();
  const uniqueServices = new Set<string>();

  serviceSchedulesSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const labor = row.getCell('F').value;
    const categoryName = row.getCell('C').value;
    const value = categoryName?.toString();

    if (typeof labor === 'number' && labor > 0 && value) {
      if (!uniqueServices.has(value)) {
        uniqueServices.add(value);
        billingCategoriesSheet.getCell(`A${rowIndex}`).value = categoryName;
        billingCategoriesSheet.getCell(`B${rowIndex}`).value = 'Service';
        rowIndex++;
      }
    }

    const material = row.getCell('I').value;

    if (typeof material === 'number' && material > 0 && value) {
      if (!uniqueProducts.has(value)) {
        uniqueProducts.add(value);
        billingCategoriesSheet.getCell(`A${rowIndex}`).value = value;
        billingCategoriesSheet.getCell(`B${rowIndex}`).value = 'Product';
        rowIndex++;
      }
    }
  });
};

const addProducts = (workbook: ExcelJS.Workbook) => {
  const serviceSchedulesSheet = workbook.getWorksheet('(opt)ServiceSchedules');
  const billingProductsSheet = workbook.getWorksheet(
    'Billing Products & Services'
  );

  if (!serviceSchedulesSheet || !billingProductsSheet) {
    return;
  }

  let rowIndex = 2;

  const addProductOrService = (
    productName: any,
    categoryName: any,
    amount: any,
    code: any,
    type: 'Service' | 'Product',
    markup: any,
    tax: any
  ) => {
    billingProductsSheet.getCell(`A${rowIndex}`).value = productName;
    billingProductsSheet.getCell(`D${rowIndex}`).value = categoryName;
    billingProductsSheet.getCell(`C${rowIndex}`).value = type;
    billingProductsSheet.getCell(`F${rowIndex}`).value = amount;

    if (code !== '-') {
      billingProductsSheet.getCell(`E${rowIndex}`).value = code;
    }

    billingProductsSheet.getCell(`G${rowIndex}`).value = 'Fixed';
    billingProductsSheet.getCell(`H${rowIndex}`).value = markup || 0;
    billingProductsSheet.getCell(`I${rowIndex}`).value = 'Fixed';
    billingProductsSheet.getCell(`J${rowIndex}`).value = tax || 0;

    rowIndex++;
  };

  serviceSchedulesSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const productName = row.getCell('E').value;
    const categoryName = row.getCell('C').value;
    const labor = row.getCell('F').value;
    const material = row.getCell('I').value;
    const laborCode = row.getCell('G').value;
    const materialCode = row.getCell('J').value;
    const markup = row.getCell('L').value;
    const tax = row.getCell('N').value;

    if (productName) {
      if (typeof labor === 'number' && labor > 0) {
        addProductOrService(
          productName,
          categoryName,
          labor,
          laborCode,
          'Service',
          markup,
          tax
        );
      }

      if (typeof material === 'number' && material > 0) {
        addProductOrService(
          productName,
          categoryName,
          material,
          materialCode,
          'Product',
          markup,
          tax
        );
      }
    }
  });
};
