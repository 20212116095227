import { Header } from '../components/Header';
import { OnboardingCompanyInput } from './OnboardingCompanyInput';
import styles from './index.scss';
import { Button } from 'design-system-web';
import { ErrorMessage } from 'components';
import { H5, H6 } from 'components/typography';
import { useOnboardingCompanies } from 'lane-shared/contexts/SignUpContext/useOnboardingCompanies';
import {
  SignUpUnsupportedLocationError,
  SignUpPrivateLocationError,
  SignUpFullAccessError,
} from 'activate-errors';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { LocationAvailabilityEnum } from 'lane-shared/types/LocationType';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation as useRouterLocation } from 'react-router-dom';
import { useOnboardingAnalytics } from 'lane-shared/hooks/analytics';
import QueryString from 'qs';
import { useOnboardingBuildings } from 'lane-shared/contexts/SignUpContext/useOnboardingBuildings';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';

type Props = {
  buildingFromContext: ChannelType | null;
  userName: string;
  whitelabel: WhiteLabelType;
  onboardingState: 'loading' | 'idle' | 'error';
  errorMessage?: string;
  onComplete: (
    company: ChannelType,
    building: ChannelType,
    userName: string
  ) => void;
  onClear: () => void;
  invitedChannel?: ChannelType;
  presumedEmailDomain?: string;
};

const TRANSLATION_KEYS = {
  header: 'web.onboarding.building.header',
  button: 'shared.onboarding.building.createAccountButton',
  companySearchTitle: 'shared.onboarding.company.name.search.title',
  companyTitle: 'shared.onboarding.company.title',
};

export const OnboardingCompany = ({
  userName,
  whitelabel,
  onboardingState,
  errorMessage,
  onComplete,
  onClear,
  invitedChannel,
  buildingFromContext,
  presumedEmailDomain,
}: Props) => {
  const { onboardingTracker } = useOnboardingAnalytics();
  const [parentCompanySearch, setParentCompanySearch] = useState('');
  const [companyLocationSearch, setCompanyLocationSearch] = useState('');
  const [buildingFromState, setBuilding] = useState<ChannelType | null>(null);
  const [company, setCompany] = useState<ChannelType | null>(null);
  const [parentCompany, setParentCompany] = useState<ChannelType | null>(null);
  const [selectionError, setSelectionError] = useState<Error | null>(null);

  const location = useRouterLocation();

  const { locationHelper } = useLocation();

  const {
    isContinueAsGuestVisible,
    translationKeys: { noRecognizedOptionLabel },
  } = useContinueAsGuest();
  const { t } = useTranslation();

  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();

  const building =
    isWorkplaceOnboardingEnabled && buildingFromContext
      ? buildingFromContext
      : buildingFromState;

  const invitedChannelId = new URLSearchParams(location.search).get(
    'channelId'
  );

  const isInvitedChannelCompany =
    invitedChannel?.type === ChannelTypeEnum.Company;
  const isInvitedChannelBuilding =
    invitedChannel?.type === ChannelTypeEnum.Property;

  const onboardingCompaniesResult = useOnboardingCompanies({
    query: parentCompanySearch,
    buildingId: isInvitedChannelBuilding ? invitedChannelId : null,
    presumedEmailDomain,
  });

  const onboardingBuildingsResult = useOnboardingBuildings({
    query: companyLocationSearch,
    channelId: parentCompany ? parentCompany?._id : null,
    skip: !parentCompany?._id && !building?._id,
  });

  if (
    invitedChannel &&
    parentCompany !== invitedChannel &&
    isInvitedChannelCompany
  ) {
    setParentCompany(invitedChannel);
  }

  useEffect(() => {
    locationHelper.startWatching({
      showRequestModal: true,
      availability: LocationAvailabilityEnum.Foreground,
      disableLocationPrecision: whitelabel.disableLocationPrecision,
    });
  }, []);

  useEffect(() => {
    if (
      !parentCompany ||
      !onboardingBuildingsResult.called ||
      onboardingBuildingsResult.isInitialLoading
    ) {
      return;
    }
    const timeout = setTimeout(() => {
      const locations = onboardingBuildingsResult.buildings;

      if (locations.length === 0) {
        setSelectionError(new SignUpUnsupportedLocationError());
      } else if (locations.every(l => l.node.inviteOnly)) {
        setSelectionError(new SignUpPrivateLocationError());
      } else if (parentCompany.inviteOnly) {
        setSelectionError(new SignUpFullAccessError());
      } else {
        setSelectionError(null);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [
    parentCompany,
    onboardingBuildingsResult.called,
    onboardingBuildingsResult.isInitialLoading,
    onboardingBuildingsResult.buildings.length,
  ]);

  const handleCompanyLocationSearchTextChange = (
    companyLocationSearch: string
  ) => setCompanyLocationSearch(companyLocationSearch);

  const clearSelectionError = () => {
    setSelectionError(null);
    onClear();
  };

  const handleCompanyBuildingSelectionChange = (
    selectedCompany: ChannelType | null,
    selectedBuilding: ChannelType | null
  ) => {
    setCompany(selectedCompany);
    setBuilding(selectedBuilding);

    if (selectedBuilding) {
      onboardingTracker.Select.Building({
        buildingId: selectedBuilding?._id,
      });
    }

    if (selectedCompany === null && selectedBuilding === null) {
      clearSelectionError();
      onboardingTracker.Remove.Building({ buildingId: building?._id! });
    }
  };

  const handleParentCompanySearchTextChange = (parentCompanySearch: string) =>
    setParentCompanySearch(parentCompanySearch);

  const handleParentCompanySelectionChange = (parentCompany: ChannelType) => {
    onboardingTracker.Select.Company({ companyId: parentCompany?._id! });
    setParentCompany(parentCompany);

    if (isInvitedChannelBuilding) {
      handleCompanyBuildingSelectionChange(parentCompany, invitedChannel!);
    }
  };

  const handleSelectionReset = () => {
    setParentCompany(null);
    setCompany(null);
    setBuilding(null);
    clearSelectionError();
    onboardingTracker.Remove.Company({ companyId: parentCompany?._id! });
    onboardingTracker.Remove.Building({ buildingId: building?._id! });
  };

  const handleCreateAccount = () => {
    if (company && building) {
      onComplete(company, building, userName);
    }
  };

  return (
    <div className={styles.container}>
      <div style={{ width: '100%' }}>
        <div className={styles.innerContainer}>
          <Header userName={userName} />
          <H5 mb={2} className={styles.title}>
            {parentCompany
              ? t(TRANSLATION_KEYS.companyTitle)
              : t(TRANSLATION_KEYS.companySearchTitle)}
          </H5>
        </div>
        <div>
          {selectionError && <ErrorMessage error={selectionError} />}
          {errorMessage && <ErrorMessage error={errorMessage} />}
          <OnboardingCompanyInput
            data={{
              signupError: selectionError,
              building,
              parentCompanySearch,
              onboardingCompaniesResult,
              companyLocationSearch,
              parentCompany,
              onboardingBuildingsResult,
            }}
            handlers={{
              resetSelection: handleSelectionReset,
              onParentCompanySearchTextChange:
                handleParentCompanySearchTextChange,
              onParentCompanySelectionChange:
                handleParentCompanySelectionChange,
              onCompanyLocationSearchTextChange:
                handleCompanyLocationSearchTextChange,
              onCompanyBuildingSelectionChange:
                handleCompanyBuildingSelectionChange,
            }}
          />
        </div>
        {company && (
          <Button
            loading={onboardingState === 'loading'}
            className={styles.button}
            fullWidth
            onClick={handleCreateAccount}
            size="large"
            type="submit"
            disabled={
              !building?._id &&
              Boolean(onboardingBuildingsResult.isSignUpBlocked)
            }
          >
            {t(TRANSLATION_KEYS.button)}
          </Button>
        )}
      </div>
      {!parentCompany && isContinueAsGuestVisible && (
        <Link
          to={{
            pathname: './building',
            ...(invitedChannelId && {
              search: QueryString.stringify({
                channelId: invitedChannelId,
              }),
            }),
          }}
        >
          <H6 className={styles.footerLink}>{t(noRecognizedOptionLabel)}</H6>
        </Link>
      )}
    </div>
  );
};
