import React from 'react';
// import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';

import { OnboardingBuilding } from 'components/onboarding/Onboarding/OnboardingSteps/OnboardingBuilding';

export function OnboardingTestingBuilding() {
  // const whitelabel = useWhitelabelByIndexHTML();

  return (
    <OnboardingBuilding
      onboardingState="loading"
      errorMessage="onboarding completion error"
      userName="onboarding tester"
      // whitelabel={whitelabel}
      onComplete={() => {}}
      onContinue={() => {}}
      onClear={() => {}}
    />
  );
}

export default OnboardingTestingBuilding;
