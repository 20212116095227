import React from 'react';

import styles from './PageNotFound.scss';
import { WhitelabeledLogo } from 'components';
import { H4, M } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from 'components/general/ButtonLink';
import { routes } from 'lane-shared/config';
import { TranslationKeys } from 'localization';

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = {
  title: 'web.components.layout.PageNotFound.title',
  subTextOne: 'web.components.layout.PageNotFound.subtext.one',
  subTextTwo: 'web.components.layout.PageNotFound.subtext.two',
  login: 'Log in',
};

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <section className={styles.PageElements}>
        <div className={styles.logo} data-test="pageNotFoundLogo">
          <WhitelabeledLogo size={50} />
        </div>
        <H4 mb={5} className={styles.title}>
          {t(TRANSLATION_KEYS.title)}
        </H4>
        <M mb={5} className={styles.subTextOne}>
          {t(TRANSLATION_KEYS.subTextOne)}
        </M>
        <M mb={5} className={styles.subTextTwo}>
          {t(TRANSLATION_KEYS.subTextTwo)}
        </M>
        <ButtonLink
          variant="primary"
          size="large"
          className={styles.ctaButton}
          to={routes.login}
        >
          {t(TRANSLATION_KEYS.login)}
        </ButtonLink>
      </section>
    </div>
  );
};
