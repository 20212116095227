import React from 'react';
import { Flex } from 'components';

import styles from './Equipment.scss';
import { PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT } from 'lane-shared/helpers/constants/permissions';
import { hasPermission } from 'lane-shared/helpers';

import { EntityEquipments } from 'domains/workOrder/equipment/components/EntityEquipments';

export function EquipmentTab({
  channel,
  user,
  serviceRequestData,
  serviceRequestFeatures,
  onServiceRequestUpdated,
  isEquipmentEnabled,
}: any) {
  return (
    <div className={styles.EquipmentTab}>
      {serviceRequestFeatures.updateServiceRequest && (
        <Flex className={styles.ServiceRequestData} direction="column">
          <EntityEquipments
            channel={channel}
            entityData={serviceRequestData}
            onEntityUpdated={onServiceRequestUpdated}
            allowEditing={
              user?.isSuperUser ||
              hasPermission(
                user?.roles,
                [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT],
                channel?._id,
                false
              )
            }
            user={user}
            isEquipmentEnabled={isEquipmentEnabled}
          />
        </Flex>
      )}
    </div>
  );
}
