import React from 'react';

import { Link } from 'react-router-dom';

import { Column } from 'design-system-web';
import { GetScheduledContentNotificationsQuery } from 'graphql-query-contracts';

import routes from 'lane-shared/config/routes';

type ArrayElement<T> = T extends (infer U)[] ? U : never;
type TData = ArrayElement<
  GetScheduledContentNotificationsQuery['scheduledContentNotifications']['nodes']
>;

export const scheduledContentNotificationsColumns: Column<TData>[] = [
  {
    header: 'Content Notification ID',
    disableSorting: true,
    key: 'id',
    renderCell: (_, data) => {
      return data.id;
    },
    type: 'text',
  },
  {
    header: 'Content',
    disableSorting: true,
    key: 'contentId',
    renderCell: (_, data) => {
      return (
        <Link
          to={routes.channelAdminContent
            .replace(/:id/, data.channelSlug)
            .replace(/:contentId/, data.contentId)}
        >
          {data.contentName}
        </Link>
      );
    },
    type: 'text',
  },
  {
    header: 'Reach',
    disableSorting: true,
    key: 'reach',
    renderCell: (_, data) => {
      return data.reach;
    },
    type: 'text',
  },
  {
    header: 'Scheduled Date',
    disableSorting: true,
    key: 'scheduledDate',
    renderCell: (_, data) => {
      return new Date(data.sendAt).toLocaleString();
    },
    type: 'text',
  },
];
