import React, { useContext, useEffect, useState } from 'react';

import { Checkbox, CircleListView, Label } from 'components';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { getClient } from 'lane-shared/apollo';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { buildUserChannelHierarchy } from 'lane-shared/helpers/user';
import { useUserEventSubscriptions } from 'lane-shared/hooks';
import { useUserChannelSettings } from 'lane-shared/hooks/useUserChannelSettings';
import { NotificationDeliveryTypeEnum } from 'lane-shared/types/NotificationDeliveryTypeEnum';
import {
  getDisplayName,
  getFocusOnChannels,
  getJoinedDateStringFromGroupRoles,
  pause,
} from 'lane-shared/helpers';
import history from 'helpers/history';
import { routes } from 'lane-shared/config';
import { RadioGroup } from 'components/form/';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { AdminPage } from 'components/layout';
import { M, Button, Icon } from 'design-system-web';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import styles from './UserChannelSettingsV2.scss';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { removeUserFromChannel } from 'lane-shared/graphql/channel/removeUserFromChannel';
import { switchChannel as switchChannelQuery } from 'lane-shared/graphql/query';
import { PopupModal } from './PopupModal';
import { UserGroupRoleType } from 'lane-shared/types/UserGroupRole';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';

type ChannelSettingsV2Props = {
  loading: boolean;
  channel: ChannelType;
  eventSubscriptions: any;
  onUpdateEventSubscriptions: any;
  primaryChannel?: ChannelType | null;
  isPrimary?: boolean;
  userId: string | undefined;
  getUpdatedChannels: (
    channelId?: boolean,
    switchToChannel?: boolean,
    removedWorkplaceId?: string
  ) => Promise<void>;
  userDataRefech: () => Promise<void>;
};

function ChannelSettingsV2({
  loading,
  channel,
  eventSubscriptions,
  onUpdateEventSubscriptions,
  primaryChannel,
  isPrimary,
  userId,
  getUpdatedChannels,
  userDataRefech,
}: ChannelSettingsV2Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { hasMethod, updateDelivery } = useUserChannelSettings({
    channel,
    eventSubscriptions,
    onUpdateEventSubscriptions,
  });
  const [leaveWorkplaceModalOpen, setLeaveWorkplaceModalOpen] = useState(false);
  const name = getDisplayName(channel);

  if (primaryChannel && channel._id === primaryChannel?._id) {
    return null;
  }

  async function leaveWorkplace() {
    setIsLoading(true);

    await pause();
    await getClient().mutate({
      refetchQueries: ['GetEventSubscriptionSettings'],
      mutation: removeUserFromChannel,
      variables: { channelId: channel._id, userId },
    });
    setIsLoading(false);
    userDataRefech();

    getUpdatedChannels(true, true, channel._id);
    setLeaveWorkplaceModalOpen(false);
  }

  const leaveWorkplaceContent = t(
    'web.pages.portal.settings.userChannelsettingsV2.leaveWorkplace.modalContent',
    { channelName: name }
  );

  return (
    <div
      key={channel._id}
      className={cx(styles.channelCard, !isPrimary && styles.cardBorder)}
    >
      <CircleListView
        image={imageUrl(channel?.profile?.image)}
        logo={imageUrl(channel?.profile?.logo)}
        name={name}
        className={styles.circleListView}
      >
        <M
          className={
            isPrimary ? styles.channelNamePrimary : styles.channelNameSecondary
          }
        >
          {name}
        </M>
        <M className={styles.channelJoinedDate} variant="secondary">
          {t(
            'web.pages.portal.settings.userChannelsettingsV2.workplaceJoinDate',
            { date: getJoinedDateStringFromGroupRoles(channel?.roles) }
          )}
        </M>
      </CircleListView>
      <div className={styles.notifications}>
        <Label h2>
          {t(
            'web.pages.portal.settings.userChannelsettingsV2.notificationText'
          )}
        </Label>

        <ul className={styles.eventSubscriptions}>
          <Checkbox
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; disabled: any; className... Remove this comment to see the full error message
            position="left"
            disabled={loading}
            className={styles.checkbox}
            name={NotificationDeliveryTypeEnum.Email}
            text={t('Email')}
            selected={hasMethod[NotificationDeliveryTypeEnum.Email]}
            onChange={() => updateDelivery(NotificationDeliveryTypeEnum.Email)}
            testId="emailNotificationCheckbox"
          />
          <Checkbox
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; disabled: any; className... Remove this comment to see the full error message
            position="left"
            disabled={loading}
            className={styles.checkbox}
            name={NotificationDeliveryTypeEnum.Push}
            text={t('Push')}
            selected={hasMethod[NotificationDeliveryTypeEnum.Push]}
            onChange={() => updateDelivery(NotificationDeliveryTypeEnum.Push)}
            testId="pushNotificationCheckbox"
          />
          <Checkbox
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; disabled: any; className... Remove this comment to see the full error message
            position="left"
            disabled={loading}
            className={styles.checkbox}
            name={NotificationDeliveryTypeEnum.SMS}
            text={t('SMS')}
            selected={hasMethod[NotificationDeliveryTypeEnum.SMS]}
            onChange={() => updateDelivery(NotificationDeliveryTypeEnum.SMS)}
            testId="smsNotificationCheckbox"
          />
        </ul>
      </div>
      <Button
        loading={isLoading}
        onClick={() => setLeaveWorkplaceModalOpen(true)}
        className={styles.leaveWorkplaceBtn}
        variant="secondary"
        size="large"
      >
        {t(
          'web.pages.portal.settings.userChannelsettingsV2.leaveWorkplaceText'
        )}
      </Button>
      <PopupModal
        isModalOpen={leaveWorkplaceModalOpen}
        modalTitle={t(
          'web.pages.portal.settings.userChannelsettingsV2.leaveWorkplace.modalTitle',
          { channelName: name }
        )}
        setModalClose={setLeaveWorkplaceModalOpen}
        t={t}
        loading={isLoading}
        onConfirm={leaveWorkplace}
      >
        {leaveWorkplaceContent}
      </PopupModal>
    </div>
  );
}

export function UserChannelSettingsV2() {
  const { t } = useTranslation();
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();
  const { user, refetch: userDataRefech } = useContext(UserDataContext);
  const {
    focusOnChannels,
    primaryChannel,
    switchChannel,
    refetch: channelDataRefetch,
  } = useContext(ChannelsContext);
  const [modal, setModalValue] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number>(1);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [focusOnChannelsArr, setFocusOnChannelsArr] = useState<ChannelType[]>(
    []
  );

  const schema = {
    id: '_id',
    text: 'channel',
  };

  const { updating, eventSubscriptions, updateEventSubscriptionsDelivery } =
    useUserEventSubscriptions({
      userId: user?._id,
    });

  const parents = buildUserChannelHierarchy({
    roles: user?.roles,
    makeFlat: false,
    channels:
      focusOnChannelsArr.length > 0 ? focusOnChannelsArr : focusOnChannels,
  });

  const result = [] as ChannelType[];
  const allChannelsArr = getFocusOnChannels(parents, result);

  const filteredUserChannelsIds = user?.roles
    .filter(
      (role: UserGroupRoleType) =>
        role.groupRole.channel?._id !== primaryChannel?._id
    )
    .map((role: UserGroupRoleType) => role.groupRole.channel?._id);

  const filteredFocusChannelsIds = allChannelsArr
    .filter((item: ChannelType) => item._id !== primaryChannel?._id)
    .map((item: ChannelType) => item._id);

  const currentChannel = allChannelsArr.find(
    (channel: any) => channel._id === primaryChannel?._id
  );

  const eventsArr = [] as any;
  const channelsToMap = selectedValue === 1 ? allChannelsArr : [currentChannel];
  const channelIds = channelsToMap.map((item: any) => item!._id);

  eventSubscriptions.filter((item: any) => {
    if (channelIds.includes(item.channel._id)) {
      eventsArr.push({ ...item, delivery: [] });

      return { ...item, delivery: [] };
    }
  });

  function updateSubscriptionFunction(): void {
    setModalValue(false);
    window.Toast.show(
      t(
        'web.pages.portal.settings.userChannelsettingsV2.muteNotification.sucess.message',
        { length: channelIds.length }
      ),
      6000
    );
    updateEventSubscriptionsDelivery(eventsArr);
  }

  async function onUpdateEventSubscriptions(eventSubscriptions: any) {
    await updateEventSubscriptionsDelivery(eventSubscriptions);
  }

  useEffect(() => {
    channelDataRefetch();
    userDataRefech();
    getUpdatedChannels();
  }, []);

  async function getUpdatedChannels(
    useSecondaryChannel = false,
    switchToChannel = false,
    removedWorkplaceId = ''
  ) {
    const availableFilteredFocusChannelsIds = filteredFocusChannelsIds.filter(
      channelId => channelId !== removedWorkplaceId
    );
    const availableFilteredUserChannelsIds = filteredUserChannelsIds?.filter(
      channelId => channelId !== removedWorkplaceId
    );

    const secondaryChannelId =
      availableFilteredFocusChannelsIds[0] ||
      (availableFilteredUserChannelsIds?.[0] ?? '');

    if (primaryChannel?._id && secondaryChannelId) {
      const { data } = await getClient().query({
        fetchPolicy: 'network-only',
        query: switchChannelQuery,
        variables: {
          channelId: useSecondaryChannel
            ? secondaryChannelId
            : primaryChannel?._id,
        },
      });

      setFocusOnChannelsArr(data.me.switchChannel.focusOnChannels);
    }

    if (switchToChannel && secondaryChannelId) {
      switchChannel(secondaryChannelId);
    }

    if (removedWorkplaceId && !secondaryChannelId) {
      if (isWorkplaceOnboardingEnabled) {
        history.push(`${routes.onboarding}/building`);
      } else {
        history.push(`${routes.onboarding}/company`);
      }
    }
  }

  const muteNotificationModalChildren = () => {
    const radioGroupItems = [
      ...(allChannelsArr.length > 1
        ? [
            {
              _id: 1,
              channel: t(
                'web.pages.portal.settings.userChannelsettingsV2.muteNotification.modal.option1',
                { channelsCount: allChannelsArr.length }
              ),
            },
          ]
        : []),
      {
        _id: 2,
        channel: t(
          'web.pages.portal.settings.userChannelsettingsV2.muteNotification.modal.option2',
          { channelName: getDisplayName(currentChannel) }
        ),
      },
    ];

    return (
      <div>
        <M>
          {t(
            'web.pages.portal.settings.userChannelsettingsV2.muteNotification.modalText'
          )}
        </M>

        <RadioGroup
          className={styles.radioDivContainer}
          key="muteNotifications"
          name="muteNotifications"
          selected={selectedValue}
          schema={schema}
          items={radioGroupItems}
          onChange={value => {
            setSelectedValue(Number(value));
          }}
        />
      </div>
    );
  };

  return (
    <AdminPage className={styles.UserChannelSettingsV2}>
      <div className={styles.headerDiv}>
        <Label h1 className={styles.header}>
          {t('web.pages.portal.settings.userChannelsettingsV2.adminTitle')}
        </Label>
      </div>

      <div className={styles.currentChannel}>
        {currentChannel && (
          <ChannelSettingsV2
            channel={currentChannel}
            loading={updating}
            eventSubscriptions={eventSubscriptions}
            onUpdateEventSubscriptions={onUpdateEventSubscriptions}
            isPrimary
            userId={user?._id}
            getUpdatedChannels={getUpdatedChannels}
            userDataRefech={userDataRefech}
          />
        )}
      </div>
      {allChannelsArr.length > 1 && (
        <div
          className={styles.relatedWorkspaces}
          onClick={() => setIsCollapsed(!isCollapsed)}
          role="button"
          tabIndex={0}
        >
          <Label h2>
            {t(
              'web.pages.portal.settings.userChannelsettingsV2.relatedWorkplacesText'
            )}
          </Label>
          <div>
            {isCollapsed ? (
              <Icon
                name="angle-down"
                set={ICON_SET_FONTAWESOME}
                className={styles.icon}
              />
            ) : (
              <Icon
                name="angle-up"
                set={ICON_SET_FONTAWESOME}
                className={styles.icon}
              />
            )}
          </div>
        </div>
      )}
      {!isCollapsed &&
        allChannelsArr.map((channel: ChannelType, index: number) => {
          return (
            <div key={index}>
              <ChannelSettingsV2
                key={channel._id}
                channel={channel}
                loading={updating}
                eventSubscriptions={eventSubscriptions}
                onUpdateEventSubscriptions={onUpdateEventSubscriptions}
                primaryChannel={primaryChannel}
                userId={user?._id}
                getUpdatedChannels={getUpdatedChannels}
                userDataRefech={userDataRefech}
              />
            </div>
          );
        })}

      <div className={styles.unsubcribeNotification}>
        <Button
          testId="Button-UnsubscribeFromAllChannels"
          interfaceStyle="light"
          variant="secondary"
          size="large"
          className={styles.unsubscribeBtn}
          onClick={() => {
            setModalValue(true);
          }}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"def... Remove this comment to see the full error message
          color="secondary"
        >
          {t(
            'web.pages.portal.settings.userChannelsettingsV2.muteNotificationsBtn'
          )}
        </Button>
      </div>

      <PopupModal
        isModalOpen={modal}
        modalTitle={t(
          'web.pages.portal.settings.userChannelsettingsV2.muteNotification.modalTitle'
        )}
        setModalClose={setModalValue}
        t={t}
        loading={updating}
        onConfirm={updateSubscriptionFunction}
      >
        {muteNotificationModalChildren()}
      </PopupModal>
    </AdminPage>
  );
}
