import React, { useEffect, useState } from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Table, PageSizeType, Sort } from 'design-system-web';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { useGetProductsServicesTableColumns } from '../helpers/useGetProductsServicesTableColumns';
import { useProductsListQuery } from '../helpers/useProductsListQuery';
import { Product } from 'lane-shared/domains/billingPayments/types';
import { ProductType } from 'graphql-query-contracts';
import { deleteProductMutation } from 'lane-shared/domains/billingPayments/queries/deleteProductMutation';

type Props = {
  canManage?: boolean;
};

const defaultProductTypes = [
  ProductType.ProductTypeServiceLabour,
  ProductType.ProductTypeServiceMaterials,
];

const defaultSortBy = {
  id: 'name',
  direction: 'asc',
};

export function ProductsServicesTable({ canManage }: Props) {
  const { t } = useTranslation();
  const isEditProductEnabled = useFlag(FeatureFlag.EditProducts, false);

  const { channel } = useChannelAdminContext();
  const { products, pageInfo, getProducts, isLoading } = useProductsListQuery();

  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [page, setPage] = useState(0);

  useEffect(() => {
    async function fetchProducts(channelId: string, parentChannelId?: string) {
      const product = {
        types: defaultProductTypes,
        categoryId: '',
      };

      await getProducts(channelId, parentChannelId, product, {
        page: 0,
        pageSize: 50,
        sort: defaultSortBy,
      });
    }

    if (channel?._id) {
      fetchProducts(channel._id, channel?.parent?._id);
    }
  }, [channel?._id]);

  async function handleSortChange(sort: Sort) {
    if (channel?._id) {
      await getProducts(
        channel._id,
        channel?.parent?._id,
        {
          types: defaultProductTypes,
          categoryId: '',
        },
        {
          page,
          pageSize,
          sort,
        }
      );
    }
  }

  async function handlePageSizeChange(updatedPageSize: PageSizeType) {
    setPageSize(updatedPageSize);

    if (channel?._id) {
      await getProducts(
        channel._id,
        channel?.parent?._id,
        {
          types: defaultProductTypes,
          categoryId: '',
        },
        {
          page,
          pageSize: updatedPageSize,
          sort: defaultSortBy,
        }
      );
    }
  }

  async function handleSetPage(updatedPage: number) {
    setPage(updatedPage);

    if (channel?._id) {
      await getProducts(
        channel._id,
        channel?.parent?._id,
        {
          types: defaultProductTypes,
          categoryId: '',
        },
        {
          page: updatedPage,
          pageSize,
          sort: defaultSortBy,
        }
      );
    }
  }

  const onDelete = async (product: Product) => {
    try {
      await window.Alert.confirm({
        title: t('abp.productsServices.deleteProduct.title', {
          product: product.name,
        }),
        message: t('abp.productsServices.deleteProduct.description'),
        confirmText: t('abp.productsServices.deleteProduct.action.delete'),
        cancelText: t('abp.productsServices.deleteProduct.action.cancel'),
        confirmButtonStyle: {
          backgroundColor: '#C7200A',
          borderColor: '#C7200A',
          color: 'white',
        },
      });
    } catch {
      // user cancelled
      return;
    }

    try {
      if (channel?._id) {
        await deleteProductMutation(product.id, channel._id);

        window.Toast.show(
          t('abp.productsServices.deleteProduct.delete.success')
        );

        await getProducts(
          channel._id,
          channel?.parent?._id,
          {
            types: defaultProductTypes,
            categoryId: '',
          },
          {
            page,
            pageSize,
            sort: defaultSortBy,
          }
        );
      }
    } catch {
      window.Toast.show(t('abp.productsServices.deleteProduct.delete.error'));
    }
  };

  const productsServicesTableColumns = useGetProductsServicesTableColumns(
    channel?._id,
    onDelete,
    isEditProductEnabled && canManage
  );

  const tableData = (products || []) as unknown as Product[];

  return (
    <Table
      columns={productsServicesTableColumns}
      data={tableData}
      isLoading={isLoading}
      pagination="server"
      totalRows={pageInfo?.total}
      pageSize={pageSize}
      page={page}
      onPageChange={handleSetPage}
      onPageSizeChange={handlePageSizeChange}
      onSortChange={handleSortChange}
    />
  );
}
