import { useContext, useEffect } from 'react';
import { SourceLocations } from 'pages/portal/admin/channel/children/auto-setup-wizard/types';
import { checkIfSourceLocationsAreValid } from 'pages/portal/admin/channel/children/auto-setup-wizard/helpers';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { AnalyticsContext } from 'lane-shared/contexts';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';

export function useTrackSourceLocationSelected({
  isSecondStep,
  sourceLocations,
  channel,
}: {
  isSecondStep: boolean;
  sourceLocations: SourceLocations;
  channel: UseChannelForAdminQueryResponse;
}): void {
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    if (
      isSecondStep && // only save them once user clicks "next" not to save every keystroke
      checkIfSourceLocationsAreValid(sourceLocations) &&
      channel?.channel
    ) {
      analytics.track(
        ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_LOCATION_SELECTED,
        {
          channelName: channel.channel.name,
          path: sourceLocations.map(each => each.value).join(','),
        }
      );
    }
  }, [sourceLocations, analytics, channel, isSecondStep]);
}
