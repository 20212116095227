import React, { useState } from 'react';

import { Dropdown } from 'components';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';

import { searchWorkplaceMembersAndCompanies } from 'pages/portal/admin/channel/charges-invoices/helpers/searchWorkplaceMembersAndCompanies';
import { InvoiceeInfo } from 'lane-shared/domains/billingPayments/types';

import { Item } from 'components/form/Dropdown/Dropdown';

import styles from './styles.scss';

const SEARCH_INPUT_PLACEHOLDER = 'abp.charges.searchForInvoicee';

export function InvoiceeSearchBar({
  handleUpdateInvoicee,
  label,
  placeholder,
}: {
  handleUpdateInvoicee: (invoicee: InvoiceeInfo) => void;
  label?: string;
  placeholder?: string;
}) {
  const { t } = useTranslation();
  const channelContext = useChannelAdminContext();
  const [filteredInvoicees, setFilteredInvoicees] = useState<InvoiceeInfo[]>(
    []
  );
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    Item<string> | undefined
  >(undefined);

  const filterInvoiceesBySearchInput = searchWorkplaceMembersAndCompanies(
    channelContext.channel?._id || ''
  );

  const getDropDownOptions = async (inputValue: string) => {
    const workplaceMembersAndCompaniesInvoicees =
      await filterInvoiceesBySearchInput(inputValue);

    setFilteredInvoicees(workplaceMembersAndCompaniesInvoicees);

    const dropdownOptions = invoiceesToDropdownOptions(
      workplaceMembersAndCompaniesInvoicees
    );

    return dropdownOptions;
  };

  const handleSelectInvoicee = (dropdownValue: Item<string>): void => {
    function findInvoiceeInFilteredOrInitial() {
      return filteredInvoicees.find(
        invoicee => invoicee._id === dropdownValue.value
      );
    }

    const invoicee = findInvoiceeInFilteredOrInitial();

    if (!invoicee) {
      console.error(`Could not find invoicee with id ${dropdownValue.value}`);

      return;
    }

    handleUpdateInvoicee(invoicee);
    setSelectedDropdownValue(dropdownValue);
  };

  return (
    <div className={styles.searchBarWrapper}>
      <Dropdown
        loadOptions={getDropDownOptions}
        onChange={handleSelectInvoicee}
        placeholder={placeholder ?? t(SEARCH_INPUT_PLACEHOLDER)}
        doTranslation={false}
        value={selectedDropdownValue}
        fixedLabel
        label={label || 'Customer'}
        isRequired
        testId="InvoiceeSearchBar"
        isFullWidth
      />
    </div>
  );
}

export function invoiceesToDropdownOptions(
  invoicees: InvoiceeInfo[]
): Item<string>[] {
  return invoicees.map(invoicee => {
    return {
      label: invoicee.name,
      value: invoicee._id,
    };
  });
}
