import React from 'react';

import { PopupMenu, TextButton, Column } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import {
  RecurringCharge,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import { getRecurringChargeStatus } from 'lane-shared/helpers/features/getRecurringChargeStatus';
import { useGetActionsForRecurringCharges } from './useGetActionsForRecurringCharges';
import {
  simpleCurrencyFormatter,
  dateFormatter,
  getThreeLetterTimeZoneAbbreviation,
} from 'lane-shared/helpers/formatters';
import { useUserData } from 'lane-shared/hooks';
import { useChannelAdminContext } from 'hooks';
import { Chip, ChipStyle } from 'components/ads';
import { canAdminCancelMembership } from './canAdminCancelMembership';

export interface RecurringChargesActions {
  label: string;
  onSelect: Function;
  isDisabled?: boolean;
}

export function useGetRecurringChargesTableColumns(
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>
): Column<RecurringCharge>[] {
  const { t } = useTranslation();
  const { user } = useUserData();
  const { channel } = useChannelAdminContext();
  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';
  const actions = useGetActionsForRecurringCharges(
    refetch
  ) as RecurringChargesActions[];

  function isDisabled(label: string, recurringCharge: RecurringCharge) {
    const isCancelButton = label === t('abp.actions.cancelSubscription');

    // Only need to disable the cancel button for now
    if (isCancelButton) {
      const isCancelDisabled = !canAdminCancelMembership(
        Boolean(recurringCharge?.recurrence?.isActive),
        recurringCharge,
        user
      );

      return isCancelDisabled;
    }

    return false;
  }

  const recurringChargesColumns: Column<RecurringCharge>[] = [
    {
      key: 'status',
      header: t('abp.subscription.status'),
      renderCell: renderStatusCell,
    },
    {
      key: 'charge.name',
      header: t('abp.subscriptions.table.subscriptionName'),
      disableSorting: true,
    },
    {
      key: 'charge.externalPayerId',
      header: t('abp.subscription.customer'),
      disableSorting: true,
    },
    {
      key: 'recurrence.recurrenceInterval',
      header: t('abp.subscription.frequency'),
      renderCell: (cell: string) => (
        <span>{t(`abp.subscription.recurrenceInterval.${cell}`)}</span>
      ),
      disableSorting: true,
    },
    {
      key: 'recurrence.startDate',
      header: `${t(
        'abp.subscription.firstCharge'
      )} ${getThreeLetterTimeZoneAbbreviation(timeZone)}`,
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, dates.SIMPLE_DATE, timeZone)}</span>
      ),
      disableSorting: true,
    },
    {
      key: 'recurrence.nextOccurrence',
      header: `${t(
        'abp.subscription.nextCharge'
      )} ${getThreeLetterTimeZoneAbbreviation(timeZone)}`,
      renderCell: (cell: string, row: RecurringCharge) => {
        if (!row.recurrence!.isActive) return '-';

        return <span>{dateFormatter(cell, dates.SIMPLE_DATE, timeZone)}</span>;
      },
      disableSorting: true,
    },
    {
      key: 'recurrence.lastOccurrence',
      header: `${t(
        'abp.subscription.lastCharge'
      )} ${getThreeLetterTimeZoneAbbreviation(timeZone)}`,
      renderCell: (cell: string) => {
        const nilDate = new Date(0);
        const parsedDate = new Date(cell);

        if (nilDate > parsedDate) return t('abp.subscription.never');

        return <span>{dateFormatter(cell, dates.SIMPLE_DATE, timeZone)}</span>;
      },
      disableSorting: true,
    },
    {
      key: 'charge.amount',
      header: t('abp.subscription.amount'),
      renderCell: renderAmountCell,
      disableSorting: true,
    },
    {
      key: 'actions',
      header: t('abp.actions'),
      renderCell: (cell: boolean, row: RecurringCharge) => {
        return (
          <PopupMenu
            trigger={
              <TextButton
                iconName="angle-down"
                disabled={!row.recurrence!.isActive}
              >
                {t('abp.actions')}
              </TextButton>
            }
            items={actions.map(({ label, onSelect }) => ({
              label,
              onSelect: () => {
                onSelect(cell, row);
              },
              isDisabled: isDisabled(label, row),
            }))}
          />
        );
      },
      disableSorting: true,
    },
  ];

  return recurringChargesColumns;
}

function renderStatusCell(_: any, row: RecurringCharge) {
  const status = row.recurrence!.status;
  const isActive = row.recurrence!.isActive;
  const statusMap = getRecurringChargeStatus(isActive, status);
  const chipColor = statusMap[1] as ChipStyle;

  return <Chip value={statusMap[0]} type={chipColor} size="xs" />;
}

function renderAmountCell(cell: number, recurringCharge: RecurringCharge) {
  return (
    <span>
      {simpleCurrencyFormatter(
        cell,
        currencyToPaymentCurrency(recurringCharge.charge!.currency)
      )}
    </span>
  );
}
