import { useContext, useEffect } from 'react';
import LaunchDarklyContext from '../contexts/LaunchDarklyContext';
import { RootLevelFlagsContext } from '../contexts/RootLevelFlagContext/RootLevelFlagsContextProvider';
import { FeatureFlag } from 'constants-flags';

export function RootLevelFlagsSynchronizer() {
  const { flags = {} } = useContext(LaunchDarklyContext);
  const { setRootLevelFlags } = useContext(RootLevelFlagsContext);

  const accessToken = flags[FeatureFlag.Auth0AccessToken];
  const consolidateStorage =
    flags[FeatureFlag.Auth0ConsolidateAuthTokenStorage];
  const refreshToken = flags[FeatureFlag.Auth0RefreshToken];

  useEffect(() => {
    setRootLevelFlags({
      useAuth0AccessToken: accessToken,
      useAuth0ConsolidateAuthTokenStorage: consolidateStorage,
      useAuth0RefreshToken: refreshToken,
    });
  }, [accessToken, consolidateStorage, refreshToken, setRootLevelFlags]);

  return null;
}
