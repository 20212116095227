import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ChargeListItem.scss';
import {
  Charge,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { SupportedLocaleEnum } from 'localization';

import { Flex, IconButton } from 'components';
import { ChargeItemsTable } from '../../ChargeItemsTable/ChargeItemsTable';
import { ChargeSummary } from '../../ChargeSummary/ChargeSummary';
import { checkChargeVoidable } from 'lane-shared/domains/billingPayments/hooks/helpers/checkChargeVoidable';
import { ConfirmationModal } from 'components/general/ConfirmationModal';
import { useBillingPaymentsPermissions } from 'lane-shared/domains/billingPayments/hooks';

export function ChargeListItem({
  charge,
  currency,
  locale,
  voidCharge,
}: {
  charge: Charge;
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  voidCharge: () => void;
}) {
  const { t } = useTranslation();

  const [voidChargeModalOpen, setVoidChargeModalOpen] = useState(false);
  const isChargeVoidable = checkChargeVoidable(charge);

  const { canVoidCharges } = useBillingPaymentsPermissions();

  const handleVoidCharge = async () => {
    voidCharge();
    setVoidChargeModalOpen(false);
    await window.Toast.show(t('abp.charges.chargeManager.draft.voidToast'));
  };

  return (
    <Flex className={styles.Charge} gap={5}>
      <ChargeItemsTable charge={charge} locale={locale} currency={currency} />
      <ChargeSummary items={charge.items} locale={locale} currency={currency}>
        {canVoidCharges && isChargeVoidable ? (
          <Flex width="full">
            <IconButton
              className={styles.VoidChargeButton}
              size="large"
              onClick={() => setVoidChargeModalOpen(true)}
              text={t('abp.charges.chargeManager.draftCharge.voidChargeButton')}
              testId={`void-product-button-${charge.name}`}
            />
          </Flex>
        ) : undefined}
      </ChargeSummary>
      <ConfirmationModal
        isOpen={voidChargeModalOpen}
        onClose={() => setVoidChargeModalOpen(false)}
        handleConfirm={handleVoidCharge}
        title={t('abp.charges.chargeManager.draftCharge.voidModal.title')}
        description={t(
          'abp.charges.chargeManager.draftCharge.voidModal.description'
        )}
        cancelText={t(
          'abp.charges.chargeManager.draftCharge.voidModal.cancelButton'
        )}
        confirmText={t(
          'abp.charges.chargeManager.draftCharge.voidModal.confirmButton'
        )}
        testId="void-charge-modal"
      />
    </Flex>
  );
}
