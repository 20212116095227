/* eslint-disable no-console */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExcelJS from 'exceljs';

import { routes } from 'lane-shared/config';
import { isProbablyUUID, safeConvertToUUID } from 'uuid-encoding';

import { Button, H5, M, TextArea } from 'design-system-web';
import { FileInput, Toggle } from 'components';
import { AdminPage, Flex, PageHeader } from 'components/layout';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import {
  SUPER_CONVERTER_VERSION,
  AMF_VERSION,
  EQUIPMENT_STATUSES,
  HYPERCARE_ID_HEADERS,
  HYPERCARE_IMPORTS,
  HYPERCARE_MAPPINGS,
  HYPERCARE_SOURCES,
  IMPORT_RULINGS,
  RECURRENCE_TYPES,
  SR_STATUSES,
  TASK_STATUSES,
} from './constants';
import excelFile from './fixtures/HypercareTemplate.xlsx';
import { Mapping, RowData, ImportError } from './types';
import { addDropdowns, populateSheets, transformData } from './utils';
import styles from './styles.scss';

type Props = {
  channel: any;
};

export const SuperConverter = ({ channel }: Props) => {
  const { t } = useTranslation();
  const [source, setSource] = useState<any>({});
  const [destination, setDestination] = useState<string>('');
  const [downloadActive, setDownloadActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validateSheets, setValidateSheets] = useState<boolean>(false);
  const [importErrors, setImportErrors] = useState<ImportError[]>([]);
  const [filterSheets, setFilterSheets] = useState<boolean>(false);
  const [buildingFilter, setBuildingFilter] = useState<string>('');
  const [rawId, setRawId] = useState<string>('');
  const [downloadLink, _setDownloadLink] = useState<HTMLAnchorElement>(
    document.createElement('a')
  );

  const pageHeaderProps = {
    header: t('web.admin.importer.workorders.list.superConverter'),
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.admin.importer.workorders.breadcrumb.data.import'),
        url: routes.channelAdminDataImportListView.replace(
          ':id',
          channel?.slug
        ),
      },
      {
        label: t('web.admin.importer.workorders.list.superConverter'),
      },
    ],
  };

  const handleUpload = async (
    file: FileReturnType,
    name: string
  ): Promise<void> => {
    setLoading(true);

    try {
      await buildWorkbook(file);

      setDestination(name);
    } catch (error) {
      console.log(error);
      await window.Toast.show(
        t('web.admin.importer.workorders.list.superConverter.conversionFailure')
      );

      return;
    }

    setLoading(false);
  };

  const handleDownload = async () => {
    downloadLink.download = `Converted ${destination}`;
    downloadLink.click();
  };

  const buildWorkbook = async (file: FileReturnType) => {
    const wb = new ExcelJS.Workbook();
    const arrayBuffer = await (file as File).arrayBuffer();
    const templateWorkbook = new ExcelJS.Workbook();
    const templateSource = await fetch(excelFile);
    const templateBuffer = await templateSource.arrayBuffer();

    await wb.xlsx.load(arrayBuffer);
    await templateWorkbook.xlsx.load(templateBuffer);

    // Check for correct version of AMF workbook
    if (!versionCheck(wb)) {
      await window.Toast.show(
        t('web.admin.importer.workorders.list.superConverter.invalidVersion')
      );

      return;
    }

    // Convert and transform each sheet
    wb.eachSheet(worksheet => {
      const sheetName = worksheet.name.replace(/[\s'"]/g, '');

      if (validHypercareSheet(sheetName)) {
        convert(
          worksheet,
          HYPERCARE_IMPORTS[sheetName],
          HYPERCARE_MAPPINGS[sheetName]
        );
      }
    });

    // Append original sheets to new workbook
    wb.eachSheet(oldSheet => {
      const newName =
        oldSheet.name === 'Instructions' ? 'AFM Instructions' : oldSheet.name;

      if (templateWorkbook.getWorksheet(newName)) {
        templateWorkbook.removeWorksheet(newName);
        appendWorksheet(
          oldSheet,
          templateWorkbook,
          newName,
          oldSheet.state === 'hidden'
        );
      } else {
        appendWorksheet(oldSheet, templateWorkbook, newName, true);
      }
    });

    // Remove duplicate template sheets
    templateWorkbook.removeWorksheet('PM Schedules list');
    templateWorkbook.removeWorksheet('Equipment list');
    templateWorkbook.removeWorksheet('TasksImports');
    templateWorkbook.removeWorksheet('ServiceRequestImports');

    // Append transformed sheets to new workbook
    Object.keys(source).forEach(name => {
      addJsonToWorkbook(templateWorkbook, source[name], name);
    });

    // Post-build data processing
    populateSheets(templateWorkbook);
    addDropdowns(templateWorkbook);
    recordErrors(templateWorkbook);
    addVersioning(templateWorkbook);

    // Write new workbook into a file for download
    const buffer = await templateWorkbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    downloadLink.href = URL.createObjectURL(blob);

    setDownloadActive(true);
    await window.Toast.show(
      t('web.admin.importer.workorders.list.superConverter.conversionSuccess')
    );
  };

  const convertSheetToRowData = (worksheet: ExcelJS.Worksheet): RowData[] => {
    const rowData: RowData[] = [];
    const rows = worksheet.getSheetValues();

    const headerRow = (rows.at(1) as any) || [];
    const valueRows = (rows.slice(2) as any) || [];

    valueRows
      .filter((row: any) => row.length > 0)
      .forEach((row: any) => {
        const data: RowData = {};

        headerRow.forEach((header: any, index: any) => {
          data[header] = row[index];
        });

        rowData.push(data);
      });

    return rowData;
  };

  const validHypercareSheet = (name: string): boolean => {
    let retVal: boolean = false;

    Object.keys(HYPERCARE_IMPORTS).forEach(sheet => {
      if (name.includes(sheet) && !name.includes('Converted')) {
        retVal = true;
      }
    });

    return retVal;
  };

  const convert = (sheetData: any, sheetName: string, mapping: Mapping) => {
    const convertedData: RowData[] = [];
    const newSource = source;
    const rawSource = convertSheetToRowData(sheetData);
    const processedIds = new Set();
    const errors: ImportError[] = importErrors;
    let rowCount = 2;

    rawSource.forEach((row, index) => {
      const convertedRow: RowData = {};
      const sourceRow = row as any;
      const rowId = sourceRow[HYPERCARE_ID_HEADERS[sheetName]];
      const buildingName = sourceRow.Building;

      if (processedIds.has(rowId) || emptySourceRow(sourceRow)) {
        return;
      }

      if (filterSheets && buildingName !== buildingFilter) {
        return;
      }

      Object.keys(mapping).forEach(key => {
        const convertedField = mapping[key];

        if (convertedField.keys.length > 0) {
          convertedField.keys.forEach(field => {
            if (sourceRow[field]) {
              convertedRow[key] = transformData(
                sourceRow[field],
                index + 1,
                convertedField.transforms || []
              );
            } else {
              convertedRow[key] = '';
            }
          });
        } else if (convertedField.default) {
          convertedRow[key] = convertedField.default;
        } else {
          convertedRow[key] = transformData(
            '',
            index + 1,
            convertedField.transforms || []
          );
        }
      });

      if (validateSheets) {
        validateImportRow(convertedRow, rowCount, sheetName, errors);
      }

      convertedData.push(convertedRow);
      processedIds.add(rowId);
      rowCount++;
    });

    newSource[sheetName] = convertedData;
    setSource(newSource);
    setImportErrors(errors);
  };

  const emptySourceRow = (row: any): boolean => {
    return Object.entries(row).every(([key, value]) => {
      if (key === 'Assignee') {
        return true;
      }

      return value === undefined || value === null || value === '';
    });
  };

  const addJsonToWorkbook = (
    workbook: ExcelJS.Workbook,
    data: any[],
    sheetName: string
  ): void => {
    if (data.length === 0) {
      return;
    }

    const worksheet = workbook.addWorksheet(sheetName);
    const mapping = HYPERCARE_SOURCES[sheetName];
    const headers = Object.keys(data[0]);
    const headerNames = headers.map(header => mapping[header].header || header);
    const headerRow = worksheet.addRow(headerNames);

    headerRow.eachCell(cell => {
      cell.font = {
        name: 'Arial',
        size: 10,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF38761D' },
      };
    });

    data.forEach(item => {
      const row = headers.map(header => item[header]);

      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column: any) => {
      const values = column.values.filter(
        (value: any) => value !== null && value !== undefined && value !== ''
      );
      const maxLength =
        values.length > 0
          ? Math.max(
              ...values.map((value: any) =>
                value ? value.toString().length : 0
              )
            )
          : 0;

      column.width = Math.max(maxLength + 3, 16);
    });

    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: worksheet.rowCount, column: worksheet.columnCount },
    };
  };

  const appendWorksheet = (
    oldSheet: ExcelJS.Worksheet,
    templateWorkbook: ExcelJS.Workbook,
    newName: string,
    hidden: boolean = false
  ): void => {
    const newSheet = templateWorkbook.addWorksheet(newName);

    oldSheet.eachRow((row, rowNumber) => {
      const newRow = newSheet.getRow(rowNumber);

      newRow.values = row.values;
      newRow.height = row.height;
      row.eachCell((cell, colNumber) => {
        const newCell = newRow.getCell(colNumber);

        if (cell.style) {
          newCell.style = { ...cell.style };
        }

        if (cell.style?.font) {
          newCell.style.font = { ...cell.style.font };
        }
      });
    });

    oldSheet.columns?.forEach((col, colIndex) => {
      const newCol = newSheet.getColumn(colIndex + 1);

      newCol.width = col.width;
      newCol.style = col.style || {};
    });

    if (hidden) {
      newSheet.state = 'hidden';
    }
  };

  const validateImportRow = (
    row: RowData,
    rowCount: number,
    sheetName: string,
    errors: ImportError[]
  ): void => {
    const ruling = IMPORT_RULINGS[sheetName];

    Object.keys(row).forEach((key, index) => {
      const rules = ruling[key];
      const columnLetter = String.fromCharCode(65 + index);

      if (!rules || rules.length === 0) return;

      rules.forEach(rule => {
        if (rule === 'required' && !row[key]) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Missing required field: ${key}`,
          });
        }

        if (rule === 'positive' && row[key] && Number(row[key]) < 1) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a positive number: ${row[key]}`,
          });
        }

        if (rule === 'datetime' && row[key] && invalidISODate(row[key])) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a datetime string in ISO-8601 format: ${row[key]}`,
          });
        }

        if (
          rule === 'boolean' &&
          row[key] &&
          !['true', 'false'].includes(row[key].toLowerCase())
        ) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a boolean (true, false): ${row[key]}`,
          });
        }

        if (rule === 'uuid' && row[key] && !isProbablyUUID(row[key])) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a valid UUID: ${row[key]}`,
          });
        }

        if (
          rule === 'recurrence' &&
          row[key] &&
          !RECURRENCE_TYPES.includes(row[key])
        ) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a valid recurrence type (${RECURRENCE_TYPES.join(', ')}): ${row[key]}`,
          });
        }

        if (
          rule === 'active' &&
          row[key] &&
          !EQUIPMENT_STATUSES.includes(row[key])
        ) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a valid equipment status (${EQUIPMENT_STATUSES.join(', ')}): ${row[key]}`,
          });
        }

        if (
          rule === 'task_status' &&
          row[key] &&
          !TASK_STATUSES.includes(row[key])
        ) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a valid task status (${TASK_STATUSES.join(', ')}): ${row[key]}`,
          });
        }

        if (
          rule === 'sr_status' &&
          row[key] &&
          !SR_STATUSES.includes(row[key])
        ) {
          errors.push({
            sheet: sheetName,
            row: rowCount.toString(),
            column: `${columnLetter}: ${key}`,
            error: `Value must be a valid service request status (${SR_STATUSES.join(', ')}): ${row[key]}`,
          });
        }
      });
    });
  };

  const invalidISODate = (str: string): boolean => {
    const date = new Date(str);

    return Number.isNaN(date.getTime()) || str !== date.toISOString();
  };

  const recordErrors = (workbook: ExcelJS.Workbook): void => {
    const errorSheet = workbook.getWorksheet('Errors');

    if (importErrors.length === 0) {
      return;
    }

    errorSheet!.state = 'visible';
    importErrors.forEach((error, index) => {
      if (errorSheet?.actualRowCount === index + 3) {
        errorSheet.addRow(['', '', '', '']);
      }

      const sheetCell = errorSheet!.getCell(`A${index + 3}`);
      const columnCell = errorSheet!.getCell(`B${index + 3}`);
      const rowCell = errorSheet!.getCell(`C${index + 3}`);
      const errorCell = errorSheet!.getCell(`D${index + 3}`);

      sheetCell.value = error.sheet;
      columnCell.value = error.column;
      rowCell.value = error.row;
      errorCell.value = error.error;
    });
  };

  const addVersioning = (workbook: ExcelJS.Workbook): void => {
    const versionSheet = workbook.getWorksheet('Instructions');
    const versionCell = versionSheet?.getCell('B1');

    if (versionCell) {
      versionCell.value = SUPER_CONVERTER_VERSION;
    }

    Object.keys(HYPERCARE_SOURCES).forEach(sheetName => {
      const sheet = workbook.getWorksheet(sheetName);

      if (sheet) {
        sheet.getCell('A1').name = SUPER_CONVERTER_VERSION;
      }
    });
  };

  const versionCheck = (workbook: ExcelJS.Workbook): boolean => {
    const versionSheet = workbook.getWorksheet('Instructions');
    const versionCell = versionSheet?.getCell('C1');

    if (versionCell) {
      return versionCell.value === AMF_VERSION;
    }

    return false;
  };

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.pageHeader}>
        <PageHeader {...pageHeaderProps} />

        <Flex className={styles.bodyWrapper} direction="column" gap={5}>
          {/* Description */}
          <M variant="secondary" className={styles.description}>
            {t('web.admin.importer.workorders.list.superConverter.description')}
          </M>

          {/* Options */}
          <Flex direction="row" gap={3}>
            <Toggle
              value={validateSheets}
              onChange={setValidateSheets}
              text={t(
                'web.admin.importer.workorders.list.superConverter.enableValidation'
              )}
              description={t(
                'web.admin.importer.workorders.list.superConverter.enableValidation.description'
              )}
            />
          </Flex>
          <Flex direction="row" gap={3}>
            <Toggle
              value={filterSheets}
              onChange={setFilterSheets}
              text={t(
                'web.admin.importer.workorders.list.superConverter.enableFiltering'
              )}
              description={t(
                'web.admin.importer.workorders.list.superConverter.enableFiltering.description'
              )}
            />
            <TextArea
              className={styles.textArea}
              value={buildingFilter}
              onChange={setBuildingFilter}
            />
          </Flex>

          {/* Upload Button */}
          <Flex direction="row" gap={3}>
            <FileInput
              accept="*/*"
              type={FileReturnTypeEnum.File}
              onFileSelected={handleUpload}
            >
              <Button
                className={styles.convertButton}
                variant="secondary"
                onClick={() => {}}
                type="submit"
              >
                {t('web.admin.sidebar.dataImport.converter.upload')}
              </Button>
            </FileInput>
            <M variant="secondary">{destination}</M>
          </Flex>

          {/* Download Button */}
          <Button
            className={styles.convertButton}
            variant="primary"
            onClick={handleDownload}
            type="submit"
            disabled={!downloadActive}
          >
            {loading
              ? t(
                  'web.admin.importer.workorders.list.superConverter.conversionLoading'
                )
              : t('web.admin.sidebar.dataImport.converter.download')}
          </Button>

          {/* Tools */}
          <Flex className={styles.bottomSection}>
            <H5>
              {t(
                'web.admin.importer.workorders.list.superConverter.additionalTools'
              )}
            </H5>
          </Flex>
          <Flex className={styles.tools} direction="row" gap={3}>
            <M variant="secondary">
              {t(
                'web.admin.importer.workorders.list.superConverter.additionalTools.uuidConverter'
              )}
            </M>
            <TextArea value={rawId} onChange={setRawId} />
            <TextArea
              value={isProbablyUUID(rawId) ? safeConvertToUUID(rawId) : ''}
              onChange={() => {}}
            />
          </Flex>
        </Flex>
      </div>
    </AdminPage>
  );
};
