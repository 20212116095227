import React, { ReactNode, useMemo, useState } from 'react';
import { AuthUserContext } from '.';

type Props = {
  children: ReactNode;
};

export const AuthUserContextProvider: React.FC<Props> = ({ children }) => {
  const [
    userId
  ] = useState<string | null>(null);

  const contextValue = useMemo(
    () => ({
      userId
    }),
    [userId]
  );

  return (
    <AuthUserContext.Provider value={contextValue}>
      {children}
    </AuthUserContext.Provider>
  );
};
