import React, { useState, useMemo, useEffect } from 'react';
import {
  NativeFilterTypes,
  Column,
  FilterType,
  Table,
  QueryString,
  convertStringsToDates,
  getPageSizeFromQueryString,
  Icon,
  Tooltip,
} from 'design-system-web';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery, useLazyQuery } from '@apollo/client';

import {
  useFlag,
  useChannelServiceRequestFeaturesContext,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import {
  AdminUser,
  useAssignableMembers,
} from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { queryChannelsByPermission } from 'lane-shared/graphql/channel';
import { hasPermission, getTimeSince } from 'lane-shared/helpers';
import {
  PERMISSION_WORK_ORDERS_MODULE_ACCESS,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
} from 'lane-shared/helpers/constants/permissions';
import { WORK_ORDER_SERVICE_REQUEST_TABLE_STATE } from 'helpers/constants/localstorageKey';
import { convertTo62, convertToUUID, safeConvertToUUID } from 'uuid-encoding';
import {
  ChannelType,
  ActiveChannelTypeEnum,
} from 'lane-shared/types/ChannelType';
import { getServiceRequestStatusLabel } from 'lane-shared/domains/workOrder/helpers';
import { Button, ControlMenu, Flex, TabStrip } from 'components';
import { EmptyPageView } from 'components/layout';
import { exportCSV } from 'domains/workOrder/helpers/exportCSV';
import { useQueryString, usePersistedParams } from 'hooks';

import { IdNamePair, Equipment } from 'graphql-query-contracts';

import {
  adminSearchServiceRequestsQuery,
  getServiceRequestFilterAttributesQuery,
  getAllEquipmentQuery,
  getServiceRequestContentId,
} from 'graphql-queries';

import { ServiceRequestStatusEnum } from 'domains/workOrder/serviceRequest/components/types/serviceRequestStatus';
import { TabItem } from 'components/general/TabStrip';
import { H3 } from 'components/typography';

import ServiceRequestStatusDropdown from './details/components/StatusDropdown';

import styles from './styles.scss';
import { DangerousTranslate } from 'components/DangerousTranslate';

const PER_PAGE = 50;
const TOASTER_SECONDS = 5000;
const statusFilter = 'status';
const statusChangedBetweenFilter = 'status_changed_between';

const DEFAULT_SEARCH_PARAMS = {
  // table params
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
  sortBy: 'created_at',
  sortDirection: 'desc',
  // filters
  assignee: '',
  assignee_groups: '',
  category: '',
  company: '',
  created_at: '',
  equipment: '',
  isBillable: '',
  issue: '',
  keyword: '',
  location: '',
  status: '',
  isEscalated: '',
  status_changed_between: '',
};

export const ACTIVE_SERVICE_REQUEST_STATUSES = [
  ServiceRequestStatusEnum.Created,
  ServiceRequestStatusEnum.Accepted,
  ServiceRequestStatusEnum.InProgress,
  ServiceRequestStatusEnum.OnHold,
  ServiceRequestStatusEnum.Complete,
];

export const INACTIVE_SERVICE_REQUEST_STATUSES = [
  ServiceRequestStatusEnum.Cancelled,
  ServiceRequestStatusEnum.Declined,
  ServiceRequestStatusEnum.Closed,
];

type ServiceRequestQueryString = {
  keyword: string;
  total: number;
  company: string;
  category: string;
  status: string;
  issue: string;
  location: string;
  assignee: string;
  created_at: string;
  status_changed_between: string;
} & QueryString;

enum StaffServiceRequestListTabsEnum {
  ActiveAssigned = 'activeAssigned',
  Unassigned = 'unassigned',
  Active = 'active',
  Closed = 'closed',
}

export function ServiceRequests({ channel, user }: any) {
  const { t } = useTranslation();
  const dataImporterFlag = useFlag(FeatureFlag.DataImporter, false);
  const onlyDisplayKnownFilters = useFlag(
    FeatureFlag.WorkOrdersOnlyDisplayKnownFilters,
    false
  );
  const showEscalationFilter = useFlag(
    FeatureFlag.WorkOrdersDisplayEscalationFilter,
    false
  );

  const statusChangedBetweenFilterFlag = useFlag(
    FeatureFlag.ServiceRequestStatusChangedBetweenFilter,
    false
  );

  const tableCacheKey = 'serviceRequests.serviceRequestsTable';

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();

  const storedTableState = window.localStorage.getItem(
    WORK_ORDER_SERVICE_REQUEST_TABLE_STATE
  );
  const initialTableParams = storedTableState
    ? JSON.parse(storedTableState)
    : DEFAULT_SEARCH_PARAMS;

  const [searchParams, setSearchParams] =
    useQueryString<ServiceRequestQueryString>(initialTableParams);

  const tabs: TabItem[] = [
    {
      label: 'web.admin.serviceRequest.assigned',
      value: StaffServiceRequestListTabsEnum.ActiveAssigned,
    },
    {
      label: 'web.admin.serviceRequest.unassignedTabHeading',
      value: StaffServiceRequestListTabsEnum.Unassigned,
    },
    {
      label: 'web.admin.serviceRequest.active',
      value: StaffServiceRequestListTabsEnum.Active,
    },
    {
      label: 'web.admin.serviceRequest.closed',
      value: StaffServiceRequestListTabsEnum.Closed,
    },
  ];

  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]);

  const [fetchEquipment, { data: eqData, loading: equipmentLoading }] =
    useLazyQuery(getAllEquipmentQuery, {
      onCompleted: () => {
        if (eqData?.getAllEquipment) {
          setEquipments(eqData.getAllEquipment);
        }
      },
    });

  const userRole = user?.roles.filter(
    (role: {
      groupRole: {
        channel: { _id: any };
        permissions: string | string[];
      };
    }) =>
      role?.groupRole?.channel?._id === channel?._id &&
      role.groupRole.permissions.includes(
        PERMISSION_WORK_ORDERS_MODULE_ACCESS
      ) &&
      role.groupRole.permissions.includes(
        PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM
      )
  );
  let assignableGroupIds: string[] = [];

  if (userRole.length) {
    assignableGroupIds = userRole.map((role: { groupRole: { _id: string } }) =>
      safeConvertToUUID(role?.groupRole?._id)
    );
  }

  const subChannelsResult = useQuery(queryChannelsByPermission, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        // isSub: true,
        parent: {
          _id: channel?._id,
        },
        permissions: [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW],
      },
    },
  });

  const viewAllRequestsUser =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW],
      channel?._id
    );

  const canCreateServiceRequest =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE],
      channel?._id,
      false
    );
  const { data: serviceRequestContentData, loading: workOrderLoading } =
    useQuery(getServiceRequestContentId, {
      variables: {
        channelId: channel?._id,
      },
    });

  const serviceRequestContentId =
    serviceRequestContentData?.serviceRequestContentId;
  const [assignableUsers, __, assignableTeams] = useAssignableMembers(
    channel?._id,
    assignableGroupIds
  );

  // get the data from query
  const contentId = serviceRequestContentId
    ? convertToUUID(serviceRequestContentId)
    : undefined;

  const setAssigneeFilter = () => {
    if (viewAllRequestsUser) {
      return [];
    }

    if (selectedTab.value === StaffServiceRequestListTabsEnum.ActiveAssigned) {
      return convertToUUID(user._id);
    }

    if (selectedTab.value === StaffServiceRequestListTabsEnum.Unassigned) {
      return 'unassigned';
    }

    if (
      selectedTab.value === StaffServiceRequestListTabsEnum.Active ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Closed
    ) {
      return [];
    }

    return [];
  };

  const setAssigneeGroupsFilter = () => {
    if (viewAllRequestsUser) {
      return [];
    }

    if (selectedTab.value === StaffServiceRequestListTabsEnum.ActiveAssigned) {
      return [];
    }

    if (selectedTab.value === StaffServiceRequestListTabsEnum.Unassigned) {
      return assignableGroupIds;
    }

    if (
      selectedTab.value === StaffServiceRequestListTabsEnum.Active ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Closed
    ) {
      return [...assignableGroupIds, 'unassigned'];
    }

    return [];
  };

  const setStatusFilter = () => {
    if (viewAllRequestsUser) {
      return [];
    }

    if (
      selectedTab.value === StaffServiceRequestListTabsEnum.ActiveAssigned ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Unassigned ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Active
    ) {
      return ACTIVE_SERVICE_REQUEST_STATUSES;
    }

    if (selectedTab.value === StaffServiceRequestListTabsEnum.Closed) {
      return INACTIVE_SERVICE_REQUEST_STATUSES;
    }

    return [];
  };

  let subChannelIds: string[] = [];

  const isCrossProperty = channel?.settings?.hasWorkOrderCrossPropertyEnabled;

  if (isCrossProperty) {
    subChannelIds =
      subChannelsResult?.data?.channelsByPermission?.items?.map(
        (subChannel: ChannelType) => subChannel?._id
      ) || [];
  }

  const getQueryChannels = () => {
    if (!channel?._id) return [];

    return searchParams?.channel
      ? searchParams.channel.split(',')
      : [channel?._id, ...subChannelIds];
  };

  const { data: filterData } = useQuery(
    getServiceRequestFilterAttributesQuery,
    {
      variables: {
        channelIds: getQueryChannels(),
      },
    }
  );

  const getTransitionStatusFilter = (transitionParam: string) => {
    const [transitionStatus, transitionStartDate, transitionEndDate] =
      transitionParam.split('_');

    if (transitionStatus && (transitionStartDate || transitionEndDate)) {
      return {
        transitionStatus,
        transitionStartDate: transitionStartDate || undefined,
        transitionEndDate: transitionEndDate || undefined,
      };
    }

    return {};
  };

  const queryVariables = {
    contentId,
    // if a channel id is not present, we both the current channel and sub channel ids will be null and as a result
    // we will be sending a null array which causes a client error
    channelIds: getQueryChannels(),
    search: {
      ...(searchParams.sortBy
        ? {
            sortBy: {
              key: searchParams.sortBy,
              dir: searchParams.sortDirection,
            },
          }
        : {}),
      ...(searchParams.keyword
        ? {
            search: {
              type: 'like',
              value: searchParams.keyword,
            },
          }
        : {}),
    },
    pagination: {
      start:
        ((searchParams?.page || 0) as number) *
        getPageSizeFromQueryString(searchParams?.pageSize),
      perPage: getPageSizeFromQueryString(searchParams?.pageSize),
    },
    filter: {
      companies: searchParams?.company ? searchParams?.company.split(',') : [],
      categories: searchParams?.category
        ? searchParams?.category.split(',')
        : [],
      statuses: searchParams?.status ? searchParams?.status.split(',') : [],
      issues: searchParams?.issue ? searchParams?.issue.split(',') : [],
      locations: searchParams?.location
        ? searchParams?.location.split(',')
        : [],
      assignees: searchParams?.assignee
        ? searchParams.assignee
            .split(',')
            .map(v => (v !== 'unassigned' ? safeConvertToUUID(v) : v))
        : [],
      assigneeGroups: searchParams?.assignee_groups
        ? searchParams.assignee_groups
            .split(',')
            .map(v => (v !== 'unassigned' ? safeConvertToUUID(v) : v))
        : [],
      equipments: searchParams?.equipment
        ? searchParams?.equipment.split(',')
        : [],
      ...(searchParams?.isBillable && {
        isBillable: searchParams?.isBillable === 'true',
      }),
      ...(searchParams?.isEscalated &&
        showEscalationFilter && {
          isEscalated: searchParams?.isEscalated === 'true',
        }),
      ...(searchParams?.created_at
        ? {
            createDateStart: convertStringsToDates(searchParams?.created_at)
              ?.startDate,
            createDateEnd: convertStringsToDates(searchParams?.created_at)
              ?.endDate,
          }
        : {}),
      ...(searchParams?.status_changed_between
        ? getTransitionStatusFilter(searchParams?.status_changed_between)
        : {}),
    },
    preFilter: {
      currentUser: convertToUUID(user?._id),
      statuses: setStatusFilter(),
      assignees: setAssigneeFilter(),
      assigneeGroups: setAssigneeGroupsFilter(),
    },
  };

  usePersistedParams({
    searchParams,
    initialTableParams,
    tableStorageVariable: WORK_ORDER_SERVICE_REQUEST_TABLE_STATE,
  });

  const {
    data,
    refetch,
    loading: serviceRequestLoading,
  } = useQuery(adminSearchServiceRequestsQuery, {
    variables: queryVariables,
  });

  const isLoading =
    serviceRequestLoading || workOrderLoading || equipmentLoading;

  const serviceRequests =
    data?.adminSearchServiceRequests?.serviceRequests || [];

  const customQuestionColumns: Set<string> = new Set<string>();

  if (serviceRequests.length > 0) {
    for (const sr of serviceRequests) {
      if (sr.customQuestions) {
        sr.customQuestions.forEach((item: any) =>
          customQuestionColumns.add(item.Question)
        );
      }
    }
  }

  const totalServiceRequests =
    data?.adminSearchServiceRequests?.pageInfo?.total;

  const { equipmentMap } = useMemo(() => {
    const equipmentMap = new Map<string, Equipment>();

    if (
      !data?.adminSearchServiceRequests?.serviceRequests ||
      equipments.length === 0
    )
      return { equipmentMap };

    const { serviceRequests } = data.adminSearchServiceRequests;

    for (let i = 0; i < serviceRequests.length; i++) {
      const { equipment } = serviceRequests[i];

      if (!equipment || equipment.length === 0) {
        continue;
      }

      equipment.forEach((eqId: string) => {
        if (equipmentMap.has(eqId)) return;

        const equipment = equipments.find((e: Equipment) => e.id === eqId);

        if (!equipment) return;

        equipmentMap.set(eqId, equipment);
      });
    }

    return { equipmentMap };
  }, [data?.adminSearchServiceRequests?.serviceRequests, equipments]);

  useEffect(() => {
    if (totalServiceRequests) {
      setSearchParams({ total: totalServiceRequests });
    }

    if (
      !viewAllRequestsUser &&
      selectedTab.value === StaffServiceRequestListTabsEnum.Unassigned
    ) {
      setSearchParams({ total: serviceRequests?.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.adminSearchServiceRequests?.pageInfo]);

  useEffect(() => {
    if (channel?._id) {
      fetchEquipment({
        variables: {
          channelId: channel._id,
          includeArchived: true,
        },
      });
    }
  }, [channel?._id, fetchEquipment]);

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  let columns: Column<any>[] = [
    {
      key: 'user_friendly_id',
      header: t('web.admin.serviceRequest.id'),
      renderCell: (user_friendly_id: string, row: any) => (
        <Link
          to={`/l/channel/${channel?.slug}/admin/work-orders/service-requests/${row.id}/view`}
        >
          {user_friendly_id}
        </Link>
      ),
      renderForCSV: (user_friendly_id: string) => user_friendly_id,
      disableVisibilityToggle: true,
    },
    {
      key: 'status',
      disableSorting: true,
      header: t('web.admin.serviceRequest.status'),
      type: 'text',
      renderCell: serviceRequest => (
        <ServiceRequestStatusDropdown
          serviceRequestData={serviceRequest}
          isServiceRequestList
          refetch={refetch}
          disableEdit={
            !serviceRequestFeatures.updateServiceRequest ||
            !(
              user?.isSuperUser ||
              hasPermission(
                user?.roles,
                [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS],
                channel?._id,
                false
              )
            )
          }
        />
      ),
      renderForCSV: (status: any) => status?.status,
    },
    {
      key: 'violation_time',
      disableSorting: true,
      header: t('web.admin.serviceRequest.escalated'),
      type: 'text',
      renderCell: (violation_time: string) =>
        violation_time && (
          <div className={styles.escalatedCell}>
            <Tooltip
              TooltipComponent={
                <span>{t('web.admin.serviceRequest.escalated.info')}</span>
              }
            >
              <Icon
                name="exclamation-triangle"
                set="FontAwesome"
                type="fas"
                size="medium"
              />
            </Tooltip>
            {getTimeSince(violation_time)}
          </div>
        ),
      renderForCSV: (violation_time: string) => getTimeSince(violation_time),
    },
    isPropertyChannel
      ? {
          key: 'company',
          header: t('web.admin.serviceRequest.company'),
          disableSorting: true,
          type: 'text',
        }
      : {
          key: 'created_by',
          header: t('web.admin.serviceRequest.createdBy'),
          disableSorting: true,
          type: 'text',
        },
    {
      key: 'category',
      header: t('web.admin.serviceRequest.category'),
      type: 'text',
    },
    {
      key: 'issue',
      header: t('web.admin.serviceRequest.issue'),
      type: 'text',
    },
    {
      key: 'assignee',
      header: t('web.admin.serviceRequest.assignee'),
      disableSorting: true,
      type: 'text',
    },
    {
      key: 'assignee_groups',
      header: t('web.admin.serviceRequest.assigneeGroups'),
      disableSorting: true,
      renderCell: (teamIds: string[]) => {
        const assignedTeamNames: string[] = [];

        teamIds.forEach(teamId => {
          const t = assignableTeams.find(
            team => convertToUUID(team.value) === convertToUUID(teamId)
          );

          if (!t) return;

          assignedTeamNames.push(t?.label);
        });

        return assignedTeamNames.join(', ');
      },
    },
    ...(isPropertyChannel
      ? ([
          {
            key: 'floor',
            disableSorting: true,
            header: t('web.admin.serviceRequest.floor'),
            type: 'text',
          },
          {
            key: 'suite',
            disableSorting: true,
            header: t('web.admin.serviceRequest.suite'),
            type: 'text',
          },
        ] as Column<any>[])
      : []),
    {
      key: 'location',
      header: t('web.admin.serviceRequest.location'),
      type: 'text',
    },
    {
      key: 'description',
      header: t('web.admin.serviceRequest.description'),
      renderCell: (description: string) => (
        <div className={styles.descriptionCell}>{description}</div>
      ),
      renderForCSV: (description: string) => description,
    },
    {
      header: t`web.admin.serviceRequest.equipment`,
      key: 'equipment',
      disableSorting: true,
      renderCell: (equipment: string[]) => {
        const equipmentsOnSchedule: string[] = [];

        equipment.forEach((eqId: string) => {
          const equipmentObject = equipmentMap.get(eqId);

          if (!equipmentObject) return;

          equipmentsOnSchedule.push(equipmentObject.name);
        });

        return equipmentsOnSchedule.join(', ');
      },
    },
    {
      key: 'created_at',
      header: t('web.admin.serviceRequest.createdAt'),
      type: 'date',
    },
    ...(isPropertyChannel
      ? ([
          {
            key: 'created_by',
            header: t('web.admin.serviceRequest.createdBy'),
            disableSorting: true,
            type: 'text',
          },
        ] as Column<any>[])
      : []),
    ...(isPropertyChannel
      ? ([
          {
            key: 'is_billable',
            header: t('web.admin.serviceRequest.billable'),
            type: 'text',
            renderCell: isBillable =>
              isBillable === 'true' ? (
                <p>{t`web.admin.serviceRequest.billable.yes`}</p>
              ) : (
                ''
              ),
            renderForCSV: (isBillable: string) =>
              isBillable === 'true'
                ? t`web.admin.serviceRequest.billable.yes`
                : '',
          },
        ] as Column<any>[])
      : []),
  ];

  // add custom questions
  customQuestionColumns.forEach(customQuestionColumn => {
    columns.push({
      key: customQuestionColumn,
      header: customQuestionColumn,
      type: 'text',
      disableSorting: true,
      renderCell: (customQuestionColumn: string) => {
        return (
          <div
            style={{
              width: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {customQuestionColumn}
          </div>
        );
      },
    });
  });

  const exportOptions = [
    {
      label: t('web.admin.serviceRequest.exportPage'),
      onClick: exportCurrentPageAsCSV,
      loading: exportLoading,
    },
    {
      label: t('web.admin.serviceRequest.exportAll'),
      onClick: exportAllAsCSV,
      loading: exportLoading,
    },
  ];

  if (isCrossProperty) {
    columns.splice(1, 0, {
      key: 'channel',
      header: t('web.admin.serviceRequest.property'),
      type: 'text',
      disableSorting: true,
    });
  }

  if (!channel?.settings?.hasWorkOrderEquipmentEnabled) {
    columns = columns.filter(column => column.key !== 'equipment');
  }

  const tableRows = useMemo(() => {
    return parseServiceRequests(serviceRequests);
  }, [serviceRequests]);

  function parseServiceRequests(serviceRequests: any[]) {
    return serviceRequests.map((serviceRequest: any) => {
      const row: { [key: string]: any } = {
        id: serviceRequest.serviceRequestId,
        company: serviceRequest.company?.name,
        status: serviceRequest,
        category: serviceRequest.category,
        issue: serviceRequest.issue,
        description: serviceRequest.description,
        floor: serviceRequest.floor,
        suite: serviceRequest.suite,
        location: serviceRequest.location,
        assignee: serviceRequest.assignee?.name,
        created_at: serviceRequest.createdAt,
        created_by: serviceRequest.createdBy?.name,
        updated_at: serviceRequest.updatedAt,
        updated_by: serviceRequest.updatedBy?.name,
        is_billable: serviceRequest.isBillable ? 'true' : 'false',
        user_friendly_id: serviceRequest.userFriendlyID,
        assignee_groups: serviceRequest.assigneeGroups,
        equipment: serviceRequest.equipment,
        channel: serviceRequest.channelId?.name,
        violation_time: serviceRequest.violationTime,
      };

      // add custom questions
      if (serviceRequest.customQuestions) {
        for (const customQuestion of serviceRequest.customQuestions) {
          row[customQuestion.Question] = customQuestion.Answer;
        }
      }

      return row;
    });
  }

  const getFilterableStatuses = (field = statusFilter) => {
    let loadedStatuses = [];

    if (field === statusChangedBetweenFilter) {
      loadedStatuses =
        filterData?.serviceRequestFilterAttributes?.historyStatuses || [];
    } else {
      loadedStatuses =
        filterData?.serviceRequestFilterAttributes?.statuses || [];
    }

    const availableStatuses: string[] = onlyDisplayKnownFilters
      ? loadedStatuses.filter((status: string) =>
          Object.values(ServiceRequestStatusEnum).includes(
            status as ServiceRequestStatusEnum
          )
        )
      : loadedStatuses;

    if (viewAllRequestsUser) {
      return availableStatuses.map((s: string) => ({
        label: getServiceRequestStatusLabel(s),
        value: s,
      }));
    }

    let statusesToKeep: string[] = [];

    if (
      selectedTab.value === StaffServiceRequestListTabsEnum.ActiveAssigned ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Unassigned ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Active
    ) {
      statusesToKeep = ACTIVE_SERVICE_REQUEST_STATUSES;
    }

    if (selectedTab.value === StaffServiceRequestListTabsEnum.Closed) {
      statusesToKeep = INACTIVE_SERVICE_REQUEST_STATUSES;
    }

    const statuses = availableStatuses.filter(status =>
      statusesToKeep.includes(status)
    );

    return statuses
      .filter((s: string) => s?.trim())
      .map((s: string) => ({
        label: getServiceRequestStatusLabel(s),
        value: s,
      }));
  };

  const renderTable = () => {
    let filters: FilterType[] = [
      ...(isPropertyChannel
        ? ([
            {
              key: 'company',
              label: t('web.admin.serviceRequest.company'),
              type: NativeFilterTypes.Multiselect,
              options:
                filterData?.serviceRequestFilterAttributes?.companies
                  ?.filter((c: IdNamePair) => c.name?.trim() && c.id)
                  ?.map((c: IdNamePair) => ({ label: c.name, value: c.id })) ||
                [],
            },
          ] as FilterType[])
        : []),
      {
        isPromoted: true,
        key: 'status',
        label: t('web.admin.serviceRequest.currentStatus'),
        type: NativeFilterTypes.Multiselect,
        options: getFilterableStatuses() || [],
      },
      {
        key: 'category',
        label: t('web.admin.serviceRequest.category'),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.serviceRequestFilterAttributes?.categories
            ?.filter((c: string) => c?.trim())
            ?.map((c: string) => ({ label: c, value: c })) || [],
      },
      {
        key: 'issue',
        label: t('web.admin.serviceRequest.issue'),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.serviceRequestFilterAttributes?.issues
            ?.filter((s: string) => s?.trim())
            ?.map((s: string) => ({ label: s, value: s })) || [],
      },
      {
        key: 'location',
        label: t('web.admin.serviceRequest.location'),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.serviceRequestFilterAttributes?.locations
            ?.filter((s: string) => s?.trim())
            ?.map((s: string) => ({ label: s, value: s })) || [],
      },
      {
        key: 'equipment',
        label: t('web.admin.serviceRequest.equipment'),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.serviceRequestFilterAttributes?.equipment
            ?.filter(
              (e: { name: string; id: string }) => e.name?.trim() && e.id
            )
            ?.map((e: { name: string; id: string }) => ({
              label: e.name,
              value: e.id,
            })) || [],
      },
      ...(isPropertyChannel
        ? ([
            {
              key: 'isBillable',
              label: t('web.admin.serviceRequest.billable'),
              type: NativeFilterTypes.Select,
              options: [
                {
                  label: t`web.admin.serviceRequest.billable.yes`,
                  value: 'true',
                },
                {
                  label: t`web.admin.serviceRequest.billable.no`,
                  value: 'false',
                },
              ],
            },
          ] as FilterType[])
        : []),
    ];

    if (showEscalationFilter) {
      filters.push({
        key: 'isEscalated',
        label: t('web.admin.serviceRequest.escalation'),
        type: NativeFilterTypes.Select,
        options: [
          {
            label: t`web.admin.serviceRequest.escalation.yes`,
            value: 'true',
          },
          {
            label: t`web.admin.serviceRequest.escalation.no`,
            value: 'false',
          },
        ],
      });
    }

    if (!channel?.settings?.hasWorkOrderEquipmentEnabled) {
      filters = filters.filter(f => f.key !== 'equipment');
    }

    let assignees = [];

    if (
      viewAllRequestsUser ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Active ||
      selectedTab.value === StaffServiceRequestListTabsEnum.Closed
    ) {
      if (viewAllRequestsUser) {
        assignees =
          filterData?.serviceRequestFilterAttributes?.assignees
            ?.filter((s: IdNamePair) => s.name?.trim() && s.id)
            ?.map((s: IdNamePair) => ({
              label: s.name,
              value: s.id ? convertTo62(s.id) : s.id,
            })) || [];

        assignees.unshift({
          label: t('web.admin.serviceRequest.unassigned'),
          value: 'unassigned',
        });
      } else if (
        selectedTab.value === StaffServiceRequestListTabsEnum.Active ||
        selectedTab.value === StaffServiceRequestListTabsEnum.Closed
      ) {
        assignees = assignableUsers
          ?.filter((s: AdminUser) => s.label?.trim() && s.value)
          ?.map((s: AdminUser) => ({
            label: s.label,
            value: s.value ? convertTo62(s.value) : s.value,
          }));
      }

      filters.push({
        key: 'assignee',
        label: t('web.admin.serviceRequest.assignee'),
        type: NativeFilterTypes.Multiselect,
        options: assignees,
      });
    }

    // team
    let teams: { label: string; value: string }[] = [];

    if (viewAllRequestsUser) {
      teams = assignableTeams
        ?.filter((s: AdminUser) => s.label?.trim() && s.value)
        ?.map((s: AdminUser) => ({
          label: s.label,
          value: convertTo62(s.value),
        }));
      teams.unshift({
        label: t('web.admin.serviceRequest.unassigned'),
        value: 'unassigned',
      });
    } else if (!user?.roles) {
      teams = [];
    } else {
      teams = assignableTeams?.flatMap((s: AdminUser) => {
        if (assignableGroupIds.includes(s.value) && s.label?.trim()) {
          return {
            label: s.label,
            value: s.value ? convertTo62(s.value) : s.value,
          };
        }

        return [];
      });
    }

    filters.push({
      key: 'assignee_groups',
      label: t('web.admin.serviceRequest.assigneeGroups'),
      type: NativeFilterTypes.Multiselect,
      options: teams || [],
    });

    filters.push({
      key: 'created_at',
      label: t('web.admin.serviceRequest.createdAt'),
      type: NativeFilterTypes.DateRange,
    });

    if (statusChangedBetweenFilterFlag) {
      filters.push({
        key: 'status_changed_between',
        label: t('web.admin.serviceRequest.statusChangedBetween'),
        type: NativeFilterTypes.Transition,
        options: getFilterableStatuses(statusChangedBetweenFilter) || [],
      });
    }

    if (isCrossProperty) {
      filters.unshift({
        key: 'channel',
        label: t('web.admin.serviceRequest.property'),
        type: NativeFilterTypes.Multiselect,
        options: [
          { label: channel?.name, value: channel?._id },
          ...(subChannelsResult?.data?.channelsByPermission?.items?.map(
            (subChannel: ChannelType) => ({
              label: subChannel.name,
              value: subChannel._id,
            })
          ) || []),
        ],
      });
    }

    return (
      <Table
        columns={columns}
        data={tableRows}
        hasKeywordFilter
        showColumnVisibility
        exportOptions={exportOptions}
        totalRows={Number(searchParams.total)}
        filters={filters}
        tableKey={tableCacheKey}
        emptyMessage={t('web.admin.serviceRequest.emptyTable.message')}
        keywordFilterTooltip={t('web.admin.serviceRequest.search.id')}
        queryStringsEnabled
        isLoading={isLoading}
        pagination="server"
      />
    );
  };

  const renderAssignedTabs = () => {
    return (
      <ControlMenu>
        <TabStrip
          tabs={tabs}
          selected={selectedTab}
          onSelectTab={setSelectedTab}
          className={styles.ServiceRequestDetailsTabStrip}
        />
      </ControlMenu>
    );
  };

  function exportCurrentPageAsCSV() {
    setExportLoading(true);
    exportCSV(
      tableRows,
      columns,
      `service-requests-${new Date().toISOString()}.csv`,
      tableCacheKey
    );
    setExportLoading(false);
  }

  async function exportAllAsCSV() {
    try {
      setExportLoading(true);
      const { data } = await getClient().query({
        query: adminSearchServiceRequestsQuery,
        variables: { ...queryVariables, forExport: true },
        fetchPolicy: 'network-only',
      });

      const serviceRequests = parseServiceRequests(
        data.adminSearchServiceRequests.serviceRequests
      );

      exportCSV(
        serviceRequests,
        columns,
        `service-requests-${new Date().toISOString()}.csv`,
        tableCacheKey
      );
      setExportLoading(false);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setExportLoading(false);
      window.Toast.show(
        t`web.admin.workOrder.table.export.error`,
        TOASTER_SECONDS
      );
    }
  }

  const hasFilterValue =
    searchParams?.channel ||
    searchParams?.company ||
    searchParams?.status ||
    searchParams?.issue ||
    searchParams?.location ||
    searchParams?.assignee ||
    searchParams?.assignee_groups ||
    searchParams?.created_at ||
    searchParams?.equipment ||
    searchParams?.isBillable ||
    searchParams?.isEscalated ||
    searchParams?.keyword ||
    searchParams?.status_changed_between;

  return (
    <div className={styles.serviceRequests}>
      <Flex className={styles.controlMenu} m={1} mb={2}>
        <div className={styles.titleWrapper}>
          <H3>{t('web.admin.serviceRequest.title')}</H3>
          {!serviceRequestFeatures.createServiceRequest && (
            <p className={styles.serviceRequestHeader}>
              {t('web.admin.serviceRequest.integration.description')}
            </p>
          )}
        </div>
        {!isCrossProperty ? (
          <Flex gap={2}>
            {user?.isSuperUser ||
            hasPermission(
              user?.roles,
              [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS],
              channel?._id,
              false
            ) ? (
              <Link
                to={routes.channelAdminWorkOrdersServiceRequestsSettings.replace(
                  ':id',
                  channel?.slug
                )}
              >
                <Button
                  variant="outlined"
                  size="medium"
                  testId="settings-button"
                >
                  {t('web.admin.serviceRequest.settings')}
                </Button>
              </Link>
            ) : null}
            {serviceRequestFeatures.createServiceRequest &&
            canCreateServiceRequest ? (
              <Link
                to={routes.channelAdminWorkOrdersServiceRequestsCreate.replace(
                  ':id',
                  channel?.slug
                )}
                className={styles.createWOLink}
              >
                <Button
                  variant="contained"
                  className={styles.createWOButton}
                  testId="create-button"
                >
                  {t('web.admin.serviceRequest.create')}
                </Button>
              </Link>
            ) : null}
          </Flex>
        ) : null}
      </Flex>

      {!viewAllRequestsUser && renderAssignedTabs()}
      {((isCrossProperty && !hasFilterValue) || !isCrossProperty) &&
      (totalServiceRequests === undefined ||
        Number(totalServiceRequests) === 0) &&
      !isLoading &&
      serviceRequestFeatures.createServiceRequest &&
      canCreateServiceRequest &&
      viewAllRequestsUser &&
      !hasFilterValue ? (
        <EmptyPageView
          icon="tools"
          title={t('web.admin.serviceRequest.emptyTable.title')}
          message={
            <DangerousTranslate
              translationKey="web.admin.serviceRequest.emptyTable.subtext"
              values={{}}
            />
          }
          primaryButton={{
            href: routes.channelAdminWorkOrdersServiceRequestsCreate.replace(
              ':id',
              channel?.slug
            ),
            label: t`web.admin.serviceRequest.create`,
          }}
          secondaryButton={
            dataImporterFlag
              ? {
                  href: routes.channelAdminDataImportNew.replace(
                    ':id',
                    channel?.slug
                  ),
                  label: t`web.admin.importer.workorders.list.newImport`,
                }
              : undefined
          }
        />
      ) : (
        renderTable()
      )}
    </div>
  );
}
