import React, { useEffect, useContext } from 'react';

import { ChannelSearch, ChannelSearchCircleListView } from 'components';
import { Link } from 'react-router-dom';

import { AnalyticsContext } from 'lane-shared/contexts';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { shortAddress } from 'lane-shared/helpers/formatters';

import { AdminPage } from 'components/layout';

import styles from './styles.scss';

export default function Channels() {
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    analytics.track(ANALYTIC_KEYS.ANALYTIC_SEARCH_CHANNELS_PAGE_VISITED, {});
  }, [window.location.pathname]);

  return (
    <AdminPage className={styles.Channels}>
      <ChannelSearch
        renderItem={channel => (
          <Link key={channel._id} to={`/l/channel/${channel.slug}/admin`}>
            <ChannelSearchCircleListView
              channel={channel}
              className={styles.channel}
              description={shortAddress(channel.address!)}
            />
          </Link>
        )}
      />
    </AdminPage>
  );
}
