import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  ListTransactionsRequest,
  Transaction,
  currencyFromJSON,
  TransactionPaidStatus,
} from 'lane-shared/domains/billingPayments/types';
import { CurrencyFormatMap } from 'lane-shared/domains/billingPayments/constants';

import { GroupType, PaymentMethod } from 'graphql-query-contracts';
import { dates } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'uuid-encoding';
import {
  currencyFormatter,
  dateFormatter,
  getThreeLetterTimeZoneAbbreviation,
} from 'lane-shared/helpers/formatters';
import type { PillColor } from 'lane-shared/types/general/pill';
import { getTimeZoneByGeoLocation, hasPermission } from 'lane-shared/helpers';
import { FeatureFlag } from 'constants-flags';

import { PageSizeType, Sort, Table, Column } from 'design-system-web';
import Pill from 'components/general/Pill';
import { AdminPage, PageHeader } from 'components/layout';
import ChannelAdminContext from 'pages/portal/admin/channel/ChannelAdminContext';

import styles from './styles.scss';
import { PERMISSION_CAN_REFUND_TRANSACTIONS } from 'lane-shared/helpers/constants/permissions';
import { useUserDataContext, useFlag } from 'lane-shared/hooks';
import {
  refundTransactionMutation,
  listTransactionsQuery,
} from 'graphql-queries';

export function TransactionsReports() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const { user: loggedInUser } = useUserDataContext();
  const [refundTransaction] = useMutation(refundTransactionMutation);
  const enableRefund = useFlag(FeatureFlag.PaymentRefund, false);

  const DEFAULT_SORT_KEY = 'created_at';
  const DEFAULT_SORT_DIRECTION = 'desc';

  const [sort, setSort] = useState<Sort>({
    id: DEFAULT_SORT_KEY,
    direction: DEFAULT_SORT_DIRECTION,
  });

  const paymentTypeFormatMap = {
    [PaymentMethod.PaymentMethodUnknown]: t(
      'abp.transaction.paymentType.Unknown'
    ),
    [PaymentMethod.PaymentMethodCreditCard]: t(
      'abp.transaction.paymentType.CreditCard'
    ),
    [PaymentMethod.PaymentMethodCredits]: t(
      'abp.transaction.paymentType.Credits'
    ),
    [PaymentMethod.PaymentMethodApplePay]: t(
      'abp.transaction.paymentType.ApplePay'
    ),
  };

  const statusMap: { [key: string]: [string, PillColor] } = {
    SUCCEEDED: [t('abp.transaction.status.paid'), 'green'],
    FAILED: [t('abp.transaction.status.failed'), 'red'],
    REFUND_INITIATED: [t('abp.transaction.status.refunding'), 'honey'],
    REFUND_IN_PROGRESS: [t('abp.transaction.status.refunding'), 'honey'],
    REFUND_SUCCEEDED: [t('abp.transaction.status.refunded'), 'gray'],
    REFUND_FAILED: [t('abp.transaction.status.refundFailed'), 'red'],
  };

  const { channel } = useContext(ChannelAdminContext);
  const timeZone = channel?.address?.geo
    ? getTimeZoneByGeoLocation({
        longitude: channel.address.geo[0],
        latitude: channel.address.geo[1],
      })
    : '';

  const transactionIdPrefix = 'transaction_';

  const onRefund = async (transaction: Transaction) => {
    const formattedAmount = currencyFormatter({
      currency: CurrencyFormatMap[currencyFromJSON(transaction.currency)],
      currencyDisplay: 'code',
    })(transaction.amount);

    try {
      await window.Alert.confirm({
        title: t('abp.transaction.modal.refund.title', {
          user: transaction.paidBy,
        }),
        message: t('abp.transaction.modal.refund.message', {
          amount: formattedAmount,
        }),
        confirmText: t('abp.transaction.modal.refund.confirm'),
        cancelText: t('abp.transaction.modal.refund.cancel'),
      });
    } catch (err) {
      // FIXME: we should be logging cancels, beacuse it is indicitive of users not wanting to refund, and perhaps clicked incorrectly.
      // eslint-disable-next-line no-console
      console.log(err);
      // user cancelled
      return;
    }

    try {
      await refundTransaction({
        variables: {
          refundTransactionRequest: {
            transactionId: transaction.transactionId,
          },
        },
      });

      refetchTransactionHistory();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      window.Toast.show(t('abp.transaction.modal.refund.error'));
    }
  };

  const transactionColumns: Column<Transaction>[] = [
    {
      key: 'status',
      header: t('abp.transaction.status'),
      disableSorting: true,
      renderCell: (cell: string) => (
        <Pill
          text={statusMap[cell]?.[0] || t('abp.transaction.status.processing')}
          color={statusMap[cell]?.[1] || 'honey'}
          className={styles.Pill}
        />
      ),
    },
    {
      key: 'paidBy',
      header: t('abp.transaction.paidBy'),
      disableSorting: true,
    },
    { key: 'productName', header: t('abp.transaction.product') },
    {
      key: 'paymentType',
      header: t('abp.transaction.paymentType'),
      renderCell: (cell: string) => (
        <span>{paymentTypeFormatMap[cell as PaymentMethod]}</span>
      ),
    },
    {
      key: 'createdAt',
      header: t('abp.transaction.transactionDate'),
      renderCell: (cell: string) => (
        <span>{`${dateFormatter(
          cell,
          dates.SIMPLE_DATE_TIME,
          timeZone
        )} ${getThreeLetterTimeZoneAbbreviation(timeZone)}`}</span>
      ),
    },
    {
      key: 'amount',
      header: t('abp.transaction.amount'),
      renderCell: (cell: number, row) => (
        <span>
          {currencyFormatter({
            currency: CurrencyFormatMap[currencyFromJSON(row.currency)],
            currencyDisplay: 'code',
          })(cell)}
        </span>
      ),
    },
    {
      key: 'transactionId',
      header: t('abp.transaction.transactionId'),
      disableSorting: true,
      renderCell: (cell: string) => (
        <span>{cell.replace(transactionIdPrefix, '')}</span>
      ),
    },
  ];

  const rowActions = [
    ...((enableRefund && [
      {
        label: t('abp.transaction.actions.refund'),
        // Option is available to super users, or users with refund transactions permission
        isHidden: (transaction: Transaction) =>
          !(
            loggedInUser?.isSuperUser ||
            hasPermission(
              loggedInUser?.roles,
              [PERMISSION_CAN_REFUND_TRANSACTIONS],
              transaction.channelId
            )
          ),
        isDisabled: (transaction: Transaction) =>
          transaction.status !== TransactionPaidStatus.SUCCESS,
        onClick: (transaction: Transaction) => onRefund(transaction),
      },
    ]) ||
      []),
  ];

  const [
    loadTransactionHistory,
    { data, loading, refetch: refetchTransactionHistory },
  ] = useLazyQuery(listTransactionsQuery);

  useEffect(() => {
    if (channel) {
      loadTransactionHistory({
        variables: {
          listTransactionsRequest: {
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
            pagination: {
              start: page * pageSize,
              perPage: pageSize,
            },
            sortBy: {
              key: 'id' in sort ? sort.id : DEFAULT_SORT_KEY,
              dir:
                'direction' in sort ? sort.direction : DEFAULT_SORT_DIRECTION,
            },
          } as ListTransactionsRequest,
        },
      });
    }
  }, [channel, loadTransactionHistory, page, pageSize, sort]);

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.transactionsAndReports.transactions')}
        headerLevel="h3"
      />
      <Table
        columns={transactionColumns}
        rowActions={rowActions}
        data={data ? data.accounts.listTransactions.transactions : []}
        isLoading={loading}
        pagination="server"
        totalRows={data ? data.accounts.listTransactions.pageInfo.total : 200}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
      />
    </AdminPage>
  );
}
