import React from 'react';
import { Modal } from 'components/lds';
import { useTranslation } from 'react-i18next';

import { Button } from 'design-system-web';
import { Flex } from 'components';

import styles from './MeterReadingModal.scss';

import {
  createMultipleMeterReadingValueMutation,
  getMostRecentMeterReadingValues,
} from 'graphql-queries';
import { MeterReadingInput } from './MeterReadingInput';
import { useMutation, useQuery } from '@apollo/client';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { getMeterReadingUnitLabel } from 'domains/workOrder/equipment/utils/meterReadingUnitOptions';
import { convertToUUID } from 'uuid-encoding';
import { useMeterReadingList } from '../../details/tabs/meterReadings/meterReadingListContext';

export interface MeterReadingFormProps {
  sourceType: string;
  startingMeterReadingValues?: any;
  isOpen: boolean;
  closeModal: () => void;
}

export function CreateMeterReadingModal({
  sourceType,
  isOpen,
  closeModal,
}: MeterReadingFormProps) {
  const { t } = useTranslation();
  const { channel, equipmentId, allSettings, refetch } = useMeterReadingList();

  const form = useForm({
    defaultValues: {
      meterReadings: allSettings.map(meterReadingSetting => ({
        meterReadingSettingId: meterReadingSetting.id,
        value: null,
        notes: null,
        meterReadingSetting,
      })),
    },
  });
  const { fields: meterReadingFields } = useFieldArray({
    control: form.control,
    name: 'meterReadings',
  });

  const { data: mostRecentMeterReadingValues, loading: isLoading } = useQuery(
    getMostRecentMeterReadingValues,
    {
      variables: {
        equipmentId,
      },
    }
  );

  const [createMeterReadings] = useMutation(
    createMultipleMeterReadingValueMutation
  );

  const onSave = async () => {
    try {
      const meterReadings = form
        .getValues()
        // ignore empty meter readings
        .meterReadings.filter(reading => !!reading.value)
        .map(reading => ({
          meterReadingId: reading.meterReadingSettingId,
          sourceType,
          value: Number(reading.value!),
          notes: reading.notes,
        }));

      await createMeterReadings({
        variables: {
          createMultipleMeterReadingValue: meterReadings,
          channelId: channel?._id || '',
        },
      });

      window.Toast.show(
        t(
          'web.admin.serviceRequest.equipment.MeterReadingInput.add.successToast'
        )
      );

      closeModal();
      refetch();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      window.Toast.show(
        t('web.admin.serviceRequest.equipment.MeterReadingInput.submit.error')
      );
    }
  };

  const mostRecentReading = (meterReadingSettingId: string) => {
    return mostRecentMeterReadingValues?.getMostRecentMeterReadingValues.find(
      v => convertToUUID(v.meterReadingSettingId) === meterReadingSettingId
    );
  };

  return (
    <Modal
      isOpen={isOpen && !isLoading}
      onClose={() => {
        closeModal();
      }}
      className={styles.modalContainer}
      title={t(
        'web.admin.serviceRequest.equipment.meterReadingValues.add.title'
      )}
      actions={
        <Flex className={styles.modalFooter}>
          <Button
            variant="primary"
            onClick={onSave}
            testId="meterReadingModalConfirmation"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.submit')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            testId="meterReadingModalCancel"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.cancel')}
          </Button>
        </Flex>
      }
    >
      <Flex direction="column">
        <div className="text-text-subdued">
          {t(
            'web.admin.serviceRequest.equipment.meterReadingValues.description'
          )}
        </div>
        <FormProvider {...form}>
          {meterReadingFields.map((reading, index) => (
            <MeterReadingInput
              key={reading.id}
              previousValue={mostRecentReading(reading.meterReadingSettingId)}
              index={index}
              settingName={
                reading.meterReadingSetting.name ||
                t(getMeterReadingUnitLabel(reading.meterReadingSetting.unit))
              }
              unit={reading.meterReadingSetting.unit}
              addlineBreak={index < allSettings.length - 1}
              channel={channel}
            />
          ))}
        </FormProvider>
      </Flex>
    </Modal>
  );
}
