import React from 'react';

import { Icon, Table } from 'design-system-web';
import { Button, AdminPage, Loading } from 'components';
import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import {
  listPropertyFloors,
  ListPropertyFloorsQueryResponse,
} from 'lane-shared/graphql/floors';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { H3, H4, M } from 'components/typography';

import history from 'helpers/history';

import styles from './style.scss';
import { getSharedTranslationKeys } from '../utils';
import { useFloorDeleteDisabledAlert } from '../hooks/useFloorDeleteDisabledAlert';

type Props = {
  channel: ChannelType;
};

type FloorRow = {
  name: string;
  id: string;
  index: number;
  units: {
    name: string;
  }[];
};

type UnitColumn = {
  name: string;
};

export const FloorsList = ({ channel }: Props) => {
  const { t } = useTranslation();
  const { listPage, detailsPage } = getSharedTranslationKeys(
    channel.experienceType
  );
  const { handleDeleteAlert } = useFloorDeleteDisabledAlert();

  const { data, loading } = useQuery<ListPropertyFloorsQueryResponse>(
    listPropertyFloors,
    {
      fetchPolicy: 'network-only',
      variables: {
        propertyId: channel._id,
      },
    }
  );

  const keywordFilter = (row: FloorRow, keyword: string) => {
    const searchableString = [
      row.name,
      ...row.units.map((f: UnitColumn) => f.name),
    ]
      .join(' ')
      .toLowerCase();

    return searchableString.includes(keyword.toLowerCase());
  };

  const floorColumns = [
    {
      key: 'index',
      header: t('web.pages.portal.admin.floors.list.columns.index'),
      renderCell: (cell: string | undefined, row: any) => {
        const url = routes.channelAdminFloorDetails
          .replace(':id', channel?.slug || '')
          .replace(':floorId', row.id);

        return (
          <Link style={{ textDecoration: 'underline' }} to={url}>
            {cell}
          </Link>
        );
      },
      renderCSV: (row: FloorRow) => row.index,
    },
    {
      key: 'name',
      header: t('web.pages.portal.admin.floors.list.columns.name'),
      renderCell: (cell: string | undefined) => <span>{cell || '--'}</span>,
      renderCSV: (row: FloorRow) => row.name,
    },
    {
      key: 'units',
      header: t(listPage.columns.space),
      renderCell: (units: UnitColumn[]) => {
        const floorNames = units.map(u => u.name);

        return <span>{floorNames.join(', ') || '--'}</span>;
      },
      renderCSV: (row: FloorRow) => row.units.map(unit => unit.name).join(','),
    },
  ];

  const handleExportToCSV = (floors: FloorRow[]) => {
    const csvData = [];
    const headers = floorColumns.map(column => column.header);

    csvData.push(headers);

    floors.forEach(floor => {
      const rowData: any[] = [];

      floorColumns.map(column => {
        const value = column.renderCSV(floor);

        rowData.push(value);
      });

      csvData.push(rowData);
    });

    const csv = Papa.unparse(csvData);
    const filename = `floors-${Date.now()}.csv`;

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  const exportAll = () => {
    const floors = data?.property.floors || [];

    handleExportToCSV(floors);
  };

  const exportCurrentPage = (currentPageData: FloorRow[] = []) => {
    handleExportToCSV(currentPageData);
  };

  const exportOptions = [
    {
      label: t('web.pages.portal.admin.floors.list.export.currentPage'),
      onClick: exportCurrentPage,
    },
    {
      label: t('web.pages.portal.admin.floors.list.export.all'),
      onClick: exportAll,
    },
  ];

  const rowActions = [
    {
      label: t(
        'web.pages.portal.admin.floors.list.columns.actions.viewDetails'
      ),
      onClick: (row: FloorRow) => {
        const url = routes.channelAdminFloorDetails
          .replace(':id', channel.slug)
          .replace(':floorId', row.id);

        history.push(url);
      },
    },
    {
      label: t('web.pages.portal.admin.floors.list.columns.actions.delete'),
      onClick: async () => {
        handleDeleteAlert();
      },
      isDisabled: (row: FloorRow) => Boolean(row.units.length),
      tooltipText: (row: FloorRow) =>
        row.units.length ? t(detailsPage.deleteTooltip) : '',
    },
  ];

  const floorCTAs = () => {
    const linkToNewFloor = routes.channelAdminFloorsNew.replace(
      ':id',
      channel.slug
    );

    return (
      <div className={styles.buttons}>
        <Link to={linkToNewFloor}>
          <Button testId="addFloorButton" variant="contained">
            {t('web.pages.portal.admin.floors.index.addFloorButton')}
          </Button>
        </Link>
      </div>
    );
  };

  const emptyState = () => {
    return (
      <div className={styles.emptyStateBox}>
        <div className={styles.emptyState}>
          <Icon className={styles.icon} name="building" size="large" />
          <div>
            <H4 className={styles.noFloors}>
              {t('web.pages.portal.admin.floors.index.noFloors.header')}
            </H4>
            <M>{t('web.pages.portal.admin.floors.index.noFloors.subtext')}</M>
          </div>
          {floorCTAs()}
        </div>
      </div>
    );
  };

  const showTable = data && data.property?.floors?.length > 0;

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.floorsList}>
        <div className={styles.header}>
          <H3>{t('web.pages.portal.admin.floors.index.header')}</H3>
          {floorCTAs()}
        </div>
        {!showTable && !loading && emptyState()}
        {!showTable && loading && <Loading />}

        {showTable && (
          <Table
            columns={floorColumns}
            data={data.property.floors}
            pagination="client"
            hasKeywordFilter
            customKeywordFilterFn={keywordFilter}
            exportOptions={exportOptions}
            rowActions={rowActions}
            isLoading={loading}
          />
        )}
      </div>
      {/* <DeleteFloorModal
        deleteFloorModalOpen={deleteFloorModalOpen}
        setDeleteFloorModalOpen={setDeleteFloorModalOpen}
        floorName={rowToDelete.name}
        id={rowToDelete.id}
        refetch={refetch}
      /> */}
    </AdminPage>
  );
};
