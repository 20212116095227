import React from 'react';

import { MediaLibrary } from 'components';
import { useHistory } from 'react-router-dom';

import getDisplayName from 'lane-shared/helpers/getDisplayName';
import { MediaType } from 'lane-shared/types/media';

import { AdminPage } from 'components/layout';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './styles.scss';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';

export default function ChannelMedia() {
  const history = useHistory();
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return null;
  }

  return (
    <AdminPage className={styles.ChannelMedia}>
      <MediaLibrary
        className={styles.mediaLibrary}
        storageKey={channel._id}
        onMediaDoubleClicked={(media: MediaType, libraryItemId: string) =>
          history.push(
            `${history.location.pathname}/${media._id}/edit?libraryItemId=${libraryItemId}`
          )
        }
        library={{
          type: LibraryTypeEnum.Channel,
          _id: channel._id,
          name: getDisplayName(channel),
        }}
      />
    </AdminPage>
  );
}
