import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  NativeFilterTypes,
  Table,
  QueryString,
  convertStringsToDates,
  getPageSizeFromQueryString,
  Column,
  FilterType,
} from 'design-system-web';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getTaskStatusLabel } from 'lane-shared/domains/workOrder/helpers';
import {
  AdminUser,
  useAssignableMembers,
} from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { queryChannelsByPermission } from 'lane-shared/graphql/channel';
import { hasPermission } from 'lane-shared/helpers';
import { convertTo62, safeConvertToUUID } from 'uuid-encoding';
import {
  PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
  PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
  PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
  PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
} from 'lane-shared/helpers/constants/permissions';
import {
  ChannelType,
  ActiveChannelTypeEnum,
} from 'lane-shared/types/ChannelType';

import { EmptyPageView } from 'components/layout';
import { usePersistedParams, useQueryString } from 'hooks';

import { IdNamePair, ClientTask } from 'graphql-query-contracts';
import { exportCSVTasksQuery, searchTasksQuery } from 'graphql-queries';

import { PMTaskStatusDropdown } from '../TaskStatusDropDown/PMTaskStatusDropdown';

import styles from './styles.scss';
import { DangerousTranslate } from 'components/DangerousTranslate';
import { WORK_ORDER_TASK_TABLE_STATE } from 'helpers/constants/localstorageKey';
import { exportCSV } from '../../../../helpers/exportCSV';

const PER_PAGE = 25;
const TOASTER_SECONDS = 5000;

const DEFAULT_SEARCH_PARAMS = {
  // table params
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
  sortBy: 'created_at',
  sortDirection: 'desc',
  // filters
  assignee: '',
  assignee_groups: '',
  equipment: '',
  keyword: '',
  location: '',
  next_due_date: '',
  recurrence: '',
};

type TaskQueryString = {
  equipment: string;
  schedule: string;
  status: string;
  assignee: string;
  due_date: string;
  completed_at: string;
  tab: string;
} & QueryString;

function TaskList({ channel }: any) {
  const { t } = useTranslation();
  const dataImporterFlag = useFlag(FeatureFlag.DataImporter, false);
  const transitionFilterFlag = useFlag(
    FeatureFlag.TaskStatusChangedBetweenFilter,
    false
  );

  const storedTableState = window.localStorage.getItem(
    WORK_ORDER_TASK_TABLE_STATE
  );
  const initialTableParams = storedTableState
    ? JSON.parse(storedTableState)
    : DEFAULT_SEARCH_PARAMS;
  const [searchParams, setSearchParams] =
    useQueryString<TaskQueryString>(initialTableParams);

  const tableCacheKey = 'workOrder.taskListTable';

  const { user } = useContext(UserDataContext);

  const canCreateSchedule =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE],
      channel?._id,
      false
    );

  const hasScheduleAccess =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW],
      channel?._id,
      false
    );

  const [filterData, setFilterData] = useState<any>();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [_, __, assignableTeams] = useAssignableMembers(channel?._id);

  const teams = assignableTeams?.map((s: AdminUser) => ({
    label: s.label,
    value: convertTo62(s.value),
  }));

  const isCrossProperty = channel?.settings?.hasWorkOrderCrossPropertyEnabled;

  const subChannelsResult = useQuery(queryChannelsByPermission, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        // isSub: true,
        parent: {
          _id: channel?._id,
        },
        permissions: [PERMISSION_WORK_ORDERS_PM_TASK_VIEW],
      },
    },
  });

  let subChannelIds: string[] = [];

  if (isCrossProperty) {
    subChannelIds =
      subChannelsResult?.data?.channels?.items?.map(
        (subChannel: ChannelType) => subChannel?._id
      ) || [];
  }

  const getTransitionStatusFilter = (transitionParam: string) => {
    const [transitionStatus, transitionDateStart, transitionDateEnd] =
      transitionParam.split('_');

    if (transitionStatus && (transitionDateStart || transitionDateEnd)) {
      return {
        transitionStatus,
        transitionDateStart: transitionDateStart || undefined,
        transitionDateEnd: transitionDateEnd || undefined,
      };
    }

    return {};
  };

  const { data, loading, refetch } = useQuery(searchTasksQuery, {
    skip: !channel?._id,
    variables: {
      groupIds: channel?._id ? [channel?._id, ...subChannelIds] : [],
      search: {
        ...(searchParams.sortBy
          ? {
              sortBy: {
                key: searchParams.sortBy,
                dir: searchParams.sortDirection,
              },
            }
          : {}),
        ...(searchParams.keyword
          ? {
              search: {
                type: 'like',
                value: searchParams.keyword,
              },
            }
          : {}),
      },
      pagination: {
        start: ((searchParams?.page || 0) as number) * PER_PAGE,
        perPage: getPageSizeFromQueryString(searchParams?.pageSize),
      },
      filter: {
        equipments: searchParams?.equipment
          ? searchParams?.equipment?.split(',')
          : [],
        schedules: searchParams?.schedule
          ? searchParams?.schedule?.split(',')
          : [],
        statuses: searchParams?.status ? searchParams?.status?.split(',') : [],
        ...(searchParams?.assignee
          ? {
              assignees: searchParams?.assignee
                .split(',')
                .map(safeConvertToUUID),
            }
          : {}),
        ...(searchParams?.assignee_groups
          ? {
              assigneeGroups: searchParams?.assignee_groups
                .split(',')
                .map(safeConvertToUUID),
            }
          : {}),
        ...(searchParams?.due_date
          ? {
              dueDateStart: convertStringsToDates(searchParams?.due_date)
                ?.startDate,
              dueDateEnd: convertStringsToDates(searchParams?.due_date)
                ?.endDate,
            }
          : {}),
        ...(searchParams?.completed_at
          ? {
              completedDateStart: convertStringsToDates(
                searchParams?.completed_at
              )?.startDate,
              completedDateEnd: convertStringsToDates(
                searchParams?.completed_at
              )?.endDate,
            }
          : {}),
        ...(searchParams?.status_changed_between
          ? getTransitionStatusFilter(searchParams?.status_changed_between)
          : {}),
      },
    },
    fetchPolicy: 'network-only',
  });
  const totalTasks = data?.searchTasks?.pageInfo?.total;
  const handleExportToCSV = async () => {
    setExportLoading(true);

    try {
      const { data: csvData } = await getClient().query({
        query: exportCSVTasksQuery,
        variables: {
          groupIds: channel?._id ? [channel?._id, ...subChannelIds] : [],
          search: {
            ...(searchParams.sortBy
              ? {
                  sortBy: {
                    key: searchParams.sortBy,
                    dir: searchParams.sortDirection,
                  },
                }
              : {}),
            ...(searchParams.keyword
              ? {
                  search: {
                    type: 'like',
                    value: searchParams.keyword,
                  },
                }
              : {}),
          },
          filter: {
            equipments: searchParams?.equipment
              ? searchParams?.equipment?.split(',')
              : [],
            schedules: searchParams?.schedule
              ? searchParams?.schedule?.split(',')
              : [],
            statuses: searchParams?.status
              ? searchParams?.status?.split(',')
              : [],
            ...(searchParams?.assignee
              ? {
                  assignees: searchParams?.assignee
                    .split(',')
                    .map(safeConvertToUUID),
                }
              : {}),
            ...(searchParams?.assignee_groups
              ? {
                  assigneeGroups: searchParams?.assignee_groups
                    .split(',')
                    .map(safeConvertToUUID),
                }
              : {}),
            ...(searchParams?.due_date
              ? {
                  dueDateStart: convertStringsToDates(searchParams?.due_date)
                    ?.startDate,
                  dueDateEnd: convertStringsToDates(searchParams?.due_date)
                    ?.endDate,
                }
              : {}),
            ...(searchParams?.completed_at
              ? {
                  completedDateStart: convertStringsToDates(
                    searchParams?.completed_at
                  )?.startDate,
                  completedDateEnd: convertStringsToDates(
                    searchParams?.completed_at
                  )?.endDate,
                }
              : {}),
          },
        },
        fetchPolicy: 'network-only',
      });
      const tasks = csvData?.exportCSVTasks?.tasks || [];

      setExportLoading(false);
      exportCSV(
        tasks,
        columns,
        `preventive-maintenance-tasks-${new Date().toISOString()}.csv`,
        tableCacheKey
      );
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setExportLoading(false);
      window.Toast.show(
        t`web.admin.workOrder.table.export.error`,
        TOASTER_SECONDS
      );
    }
  };

  const exportOptions = [
    {
      label: t`web.admin.serviceRequest.equipment.csvName`,
      onClick: handleExportToCSV,
      loading: exportLoading,
    },
  ];

  useEffect(() => {
    if (totalTasks) {
      setSearchParams({ total: totalTasks });
    }
  }, [data?.searchTasks?.pageInfo]);

  usePersistedParams({
    searchParams,
    initialTableParams,
    tableStorageVariable: WORK_ORDER_TASK_TABLE_STATE,
  });

  useEffect(() => {
    setSearchParams(initialTableParams);
  }, [searchParams?.tab]);

  const userHasEditStatusPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS],
      channel?._id,
      false
    );

  const tableRows = useMemo(() => {
    if (!data?.searchTasks?.tasks) return [];

    const rows = data?.searchTasks?.tasks.map((task: ClientTask) => ({
      ...task,
      assignee_group: task.assigneeGroup,
      due_date: task.dueDate,
      created_at: task.createdAt,
      updated_at: task.updatedAt,
      completed_at: task.completedAt,
      completed_by: task.completedBy,
      created_by: task.createdBy,
      actual_time_to_complete: task.actualTimeToComplete,
      complete_notes: task.completeNotes,
      estimate_time_to_complete: task.estimateTimeToComplete,
      is_archived: task.isArchived,
      meter_reading: task.meterReading,
      friendly_id: task.friendlyID,
      user_friendly_id: task.userFriendlyID,
      channel: task.groupBy?.name,
    }));

    setFilterData(data?.searchTasks?.filterOptions);

    return rows;
  }, [data?.searchTasks?.task, data?.searchTasks?.filterOptions]);

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  let columns: Column<any>[] = [
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.id`,
      key: 'user_friendly_id',
      renderCell: (user_friendly_id: string, row: any) => (
        <Link
          to={routes.channelAdminWorkOrdersPMTaskDetails
            .replace(':id', channel?.slug)
            .replace(':taskId', row.id)}
        >
          {user_friendly_id}
        </Link>
      ),
      renderForCSV: (_: string, row: any) => row.userFriendlyID,
      disableVisibilityToggle: true,
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.status`,
      key: 'status',
      renderCell: (_: any, row: any) => (
        <PMTaskStatusDropdown
          taskData={row}
          isTaskList
          refetch={refetch}
          disableEdit={!userHasEditStatusPermission}
        />
      ),
      renderForCSV: (status: any) => status,
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.schedule`,
      key: 'schedule',
      renderCell: (schedule: any) => {
        if (!schedule) return '';

        return hasScheduleAccess ? (
          <Link
            to={routes.channelAdminWorkOrdersPMScheduleDetails
              .replace(':id', channel?.slug)
              .replace(':scheduleId', schedule.id)}
          >
            {schedule.title}
          </Link>
        ) : (
          schedule.title
        );
      },
      renderForCSV: (schedule: any) => schedule?.title || '',
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.assignee`,
      key: 'assignee',
      disableSorting: true,
      renderCell: (assignee: IdNamePair) => {
        return assignee?.name || '';
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.assigneeGroup`,
      key: 'assignee_group',
      renderCell: (assigneeGroup: { _id: string; name: string }[]) => {
        const assigneeGroupNames = assigneeGroup
          ?.filter(group => group !== null)
          .map(group => group.name);

        return assigneeGroupNames?.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.location`,
      key: 'location',
      disableSorting: true,
      renderCell: (location: string, row: any) => {
        if (!row.equipment || row.equipment.length === 0) {
          return <div className={styles.locationCell}>{location}</div>;
        }

        return row.equipment.map((eq: any) => eq.location).join(', ');
      },
      renderForCSV: (location: string, row: any) => {
        if (!row.equipment || row.equipment.length === 0) {
          return location;
        }

        return row.equipment.map((eq: any) => eq.location).join(', ');
      },
    },
    ...(isPropertyChannel
      ? [
          {
            header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.floor`,
            key: 'floor',
            disableSorting: true,
            renderCell: (_: string, row: any) => {
              if (!row.equipment || row.equipment.length === 0) {
                return '';
              }

              const floors: string[] = [];

              row.equipment.forEach((eq: any) => {
                if (eq.floor) {
                  floors.push(eq.floor);
                }
              });

              return floors.join(', ');
            },
          },
          {
            header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.suite`,
            key: 'suite',
            disableSorting: true,
            renderCell: (_: string, row: any) => {
              if (!row.equipment || row.equipment.length === 0) {
                return '';
              }

              const suites: string[] = [];

              row.equipment.forEach((eq: any) => {
                if (eq.suite) {
                  suites.push(eq.suite);
                }
              });

              return suites.join(', ');
            },
          },
        ]
      : []),
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.dueDate`,
      key: 'due_date',
      type: 'date',
      renderForCSV: (_: any, row: any) => new Date(row.dueDate),
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.completedAt`,
      key: 'completed_at',
      type: 'date',
      renderCell: (completed_at: Date, row: any) => {
        if (!row.completed_by) return '';

        return new Date(completed_at).toDateString();
      },
      renderForCSV: (_: any, row: any) => {
        if (!row.completedBy) return '';

        return new Date(row.completedAt);
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.recurrence`,
      key: 'repeats',
      renderCell: (repeats: any) => {
        return repeats?.label || '';
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.equipment`,
      key: 'equipment',
      renderCell: (equipments: any[]) => {
        return equipments.map((eq: any) => eq.name).join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.createdAt`,
      key: 'created_at',
      type: 'date',
      renderForCSV: (_: any, row: any) => new Date(row.createdAt),
    },
  ];

  if (isCrossProperty) {
    columns.splice(1, 0, {
      key: 'channel',
      header: t('web.admin.serviceRequest.property'),
      type: 'text',
    });
  }

  if (!channel?.settings?.hasWorkOrderEquipmentEnabled) {
    columns = columns.filter(column => column.key !== 'equipment');
  }

  type FilterOption = {
    label: string;
    value: string;
  };
  const hasFilterValue =
    searchParams?.equipment ||
    searchParams?.schedule ||
    searchParams?.status ||
    searchParams?.assignee ||
    searchParams?.assignee_groups ||
    searchParams?.due_date ||
    searchParams?.completed_at ||
    searchParams?.status_changed_between ||
    searchParams?.keyword;

  let filters: FilterType[] = [
    {
      key: 'equipment',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tableColumn.equipment'
      ),
      type: NativeFilterTypes.Multiselect,
      options:
        filterData?.equipment
          ?.map((c: IdNamePair) => ({
            label: c.name,
            value: c.id,
          }))
          ?.sort((a: FilterOption, b: FilterOption) =>
            a.label.localeCompare(b.label)
          ) || [],
    },
    {
      key: 'schedule',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tableColumn.schedule'
      ),
      type: NativeFilterTypes.Multiselect,
      options:
        filterData?.schedule
          ?.map((c: IdNamePair) => ({
            label: c.name,
            value: c.id,
          }))
          ?.sort((a: FilterOption, b: FilterOption) =>
            a.label.localeCompare(b.label)
          ) || [],
    },
    {
      key: 'status',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tableColumn.status'
      ),
      type: NativeFilterTypes.Multiselect,
      options:
        filterData?.status?.map((s: string) => ({
          label: getTaskStatusLabel(s),
          value: s,
        })) || [],
    },
    {
      key: 'assignee_groups',
      label: t('web.admin.serviceRequest.assigneeGroups'),
      type: NativeFilterTypes.Multiselect,
      options: [
        {
          label: t('web.admin.serviceRequest.unassigned'),
          value: 'unassigned',
        },
        ...teams
          .filter((team: FilterOption) => team.value !== 'unassigned')
          .sort((a: FilterOption, b: FilterOption) =>
            a.label.localeCompare(b.label)
          ),
      ],
    },
    {
      key: 'assignee',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tableColumn.assignee'
      ),
      type: NativeFilterTypes.Multiselect,
      options: [
        {
          label: t('web.admin.serviceRequest.unassigned'),
          value: 'unassigned',
        },
        ...(filterData?.assignee
          ?.map((s: { name: string; _id: string }) => ({
            label: s.name,
            value: s._id,
          }))
          ?.sort((a: FilterOption, b: FilterOption) =>
            a.label.localeCompare(b.label)
          ) || []),
      ],
    },
    {
      key: 'due_date',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tableColumn.dueDate'
      ),
      type: NativeFilterTypes.DateRange,
    },
    {
      key: 'completed_at',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tableColumn.completedAt'
      ),
      type: NativeFilterTypes.DateRange,
    },
  ];

  if (transitionFilterFlag) {
    filters.push({
      key: 'status_changed_between',
      label: t(
        'web.admin.preventiveMaintenance.task.tableColumn.statusChangedBetween'
      ),
      type: NativeFilterTypes.Transition,
      options: filterData?.transitionStatus?.map((s: string) => ({
        label: getTaskStatusLabel(s),
        value: s,
      })),
    });
  }

  if (!channel?.settings?.hasWorkOrderEquipmentEnabled) {
    filters = filters.filter(f => f.key !== 'equipment');
  }

  return (totalTasks === undefined || Number(totalTasks) === 0) &&
    !loading &&
    canCreateSchedule &&
    !hasFilterValue ? (
    <EmptyPageView
      icon="tools"
      title={t(
        'web.admin.workOrder.preventiveMaintenance.task.emptyTable.title'
      )}
      message={
        <DangerousTranslate
          translationKey="web.admin.workOrder.preventiveMaintenance.task.emptyTable.subtext"
          values={{}}
        />
      }
      primaryButton={{
        href: routes.channelAdminWorkOrdersPMScheduleCreate.replace(
          ':id',
          channel?.slug
        ),
        label: t`web.admin.workOrder.preventiveMaintenance.addSchedule`,
      }}
      secondaryButton={
        dataImporterFlag
          ? {
              href: routes.channelAdminDataImportNew.replace(
                ':id',
                channel?.slug
              ),
              label: t`web.admin.importer.workorders.list.newImport`,
            }
          : undefined
      }
    />
  ) : (
    <Table
      isLoading={loading}
      columns={columns}
      hasKeywordFilter
      data={tableRows}
      totalRows={Number(searchParams.total)}
      keywordFilterLabel={t(
        'web.admin.workOrder.preventiveMaintenance.search.task.label'
      )}
      emptyMessage={t('web.admin.serviceRequest.emptyTable.message')}
      showColumnVisibility
      exportOptions={exportOptions}
      tableKey={tableCacheKey}
      pagination="server"
      queryStringsEnabled
      filters={filters}
    />
  );
}

export default TaskList;
