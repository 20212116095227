import React, { useMemo, useState } from 'react';
import { ChannelRolesType } from 'lane-shared/helpers/user/collapseMemberChannels';
import Dropdown from 'components/form/Dropdown';
import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { updateUser } from 'lane-shared/graphql/user';
import { useTranslation } from 'react-i18next';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';

import { ANALYTIC_KEYS } from 'constants-analytics';

type Props = {
  data: {
    userId: LaneType.UUID;
    primaryLocation: {
      _id: LaneType.UUID;
    } | null;
    channels: ChannelRolesType[];
  };
};

const TRANSLATION_KEYS = {
  placeholder:
    'web.admin.channel.teamManagement.team.view.tabMembers.member.view.primaryLocation.placeholder',
  label:
    'web.admin.channel.teamManagement.team.view.tabMembers.member.view.primaryLocation.label',
  primaryLocationSuccessMessage:
    'web.admin.channel.teamManagement.team.view.tabMembers.member.view.primaryLocation.successMessage',
  primaryLocationErrorMessage:
    'web.admin.channel.teamManagement.team.view.tabMembers.member.view.primaryLocation.errorMessage',
};

function PrimaryLocation({
  data: { userId, primaryLocation, channels },
}: Props) {
  const { t } = useTranslation();
  const apolloClient = getClient();
  const [selected, setSelected] = useState<string | null>(
    primaryLocation?._id ?? null
  );
  const channelItems = useMemo(() => {
    return channels
      .filter(channel => channel.type === ChannelTypeEnum.Property)
      .map(channel => ({
        value: channel._id,
        label: channel.profile.name,
      }));
  }, [channels]);
  const simpleTrack = useSimpleTrack();

  async function updatePrimaryLocation(channelId: string) {
    try {
      await apolloClient.mutate({
        mutation: updateUser,
        variables: {
          user: {
            _id: userId,
            primaryLocationId: channelId,
          },
        },
      });

      setSelected(channelId);
      window.Toast.show(t(TRANSLATION_KEYS.primaryLocationSuccessMessage));
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      window.Toast.show(t(TRANSLATION_KEYS.primaryLocationErrorMessage));
    }
  }

  return (
    <Dropdown
      fixedLabel
      isFullWidth
      placeholder={t(TRANSLATION_KEYS.placeholder)}
      doTranslation={false}
      items={channelItems}
      value={selected}
      onChange={({ value }) => {
        simpleTrack(ANALYTIC_KEYS.ANALYTIC_PRIMARY_LOCATION_UPDATE, {
          userId,
          primaryLocationId: value,
        });
        updatePrimaryLocation(value);
      }}
      label={t(TRANSLATION_KEYS.label)}
    />
  );
}

export { PrimaryLocation };
