import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon, getPageSizeFromQueryString, Table } from 'design-system-web';
import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { Providers } from 'lane-shared/types/integrations/FileImport';

import { H3, H4, M } from 'components/typography';
import { Button, Flex } from 'components';
import { ChipSelect } from 'components/ads';
import { getIntegrationProcesses } from 'lane-shared/graphql/fileImport';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import styles from './styles.scss';
import { DangerousTranslate } from 'components/DangerousTranslate';
import { convertTo62, safeConvertToUUID } from 'uuid-encoding';
import {
  getChipStyleFromStatus,
  getProcessGroups,
  IntegrationProcess,
} from '../util/getProcessGroups';
import useQueryString from 'hooks/useQueryString';

const INITIAL_SEARCH_PARAMS = {
  page: 0,
  pageSize: 100,
  total: 0,
  sortBy: 'importedDate',
  sortDirection: 'desc',
};

type searchParams = {
  page: string;
  pageSize: string;
  total: string;
  sortBy: string;
  sortDirection: string;
};

export function ImportJobList({ channel }: any) {
  const { t } = useTranslation();
  const converterFlag = useFlag(FeatureFlag.ImporterConverter, false);
  const superConverterFlag = useFlag(
    FeatureFlag.WorkOrdersSuperConverter,
    false
  );
  const [processes, setProcesses] = useState<any>([]);
  const [searchParams] = useQueryString<searchParams>(INITIAL_SEARCH_PARAMS);
  let processesStore: IntegrationProcess[];
  const { data } = useQuery(getIntegrationProcesses, {
    variables: {
      input: {
        integrationIdentifier: {
          provider: Providers.IMPORTER,
          vts_organization_id: safeConvertToUUID(channel?._id),
        },
        pagination: {
          page: searchParams?.page,
          pageSize: getPageSizeFromQueryString(searchParams?.pageSize),
        },
      },
    },
    onCompleted: () => {
      setProcesses(data?.getIntegrationProcesses?.processes);
    },
  });

  const tableRows = useMemo(() => {
    return getProcessGroups(processes);
  }, [processes]);

  const columns = [
    {
      key: 'processGroupId',
      header: t('web.admin.importer.workorders.list.importId.title'),
      type: 'text',
      disableSorting: true,
      renderCell: (_: any, row: any) => {
        if (!row.processGroupId) return;

        const jobId = convertTo62(row.processGroupId);

        return (
          <Link
            to={routes.channelAdminDataImportDetailView
              .replace(':id', channel?.slug)
              .replace(':jobId', jobId)}
          >
            {jobId}
          </Link>
        );
      },
      renderForCSV: (importId: any) => importId,
    },
    {
      key: 'status',
      header: t('web.admin.importer.workorders.list.status.title'),
      type: 'text',
      disableSorting: true,
      renderForCSV: (status: any) => status,
      renderCell: (status: string | undefined) => {
        return (
          <div>
            <ChipSelect.NonInteractive
              value={status!}
              type={getChipStyleFromStatus(status!)}
            />
          </div>
        );
      },
    },
    {
      key: 'module',
      header: t('web.admin.importer.workorders.list.module.title'),
      type: 'text',
      disableSorting: true,
      renderCell: (module: string) => {
        return <p>{t(module)}</p>;
      },
    },
    {
      key: 'count',
      header: t('web.admin.importer.workorders.list.csvAmount.title'),
      type: 'text',
      disableSorting: true,
    },
    {
      key: 'importedDate',
      header: t('web.admin.importer.workorders.list.ImportedDate.title'),
      type: 'date',
      disableSorting: true,
    },
  ];

  function handleSearch(keywordFilter: string): void {
    processesStore = data?.getIntegrationProcesses?.processes;
    const filteredProcesses = processesStore?.filter((process: any) =>
      convertTo62(process.processGroupId).includes(keywordFilter)
    );

    setProcesses(filteredProcesses);
  }

  return (
    <div className={styles.imports}>
      <Flex justify="space-between" align="center" mb={6}>
        <H3 className={styles.Heading}>
          {t('web.admin.importer.workorders.list.title')}
        </H3>
        <Flex gap={2}>
          {converterFlag ? (
            <Link
              to={routes.channelAdminDataImportConverter.replace(
                ':id',
                channel?.slug
              )}
              className={styles.newImportJobLink}
            >
              <Button variant="outlined">
                {t('web.admin.importer.workorders.list.converter')}
              </Button>
            </Link>
          ) : null}
          {superConverterFlag ? (
            <Link
              to={routes.channelAdminDataSuperConverter.replace(
                ':id',
                channel?.slug
              )}
              className={styles.newImportJobLink}
            >
              <Button variant="outlined">
                {t('web.admin.importer.workorders.list.superConverter')}
              </Button>
            </Link>
          ) : null}
          <Link
            to={routes.channelAdminDataImportNew.replace(':id', channel?.slug)}
            className={styles.newImportJobLink}
          >
            <Button variant="activate-contained">
              {t('web.admin.importer.workorders.list.newImport')}
            </Button>
          </Link>
        </Flex>
      </Flex>
      {processes?.length !== 0 ? (
        <Table
          columns={columns}
          data={tableRows}
          hasKeywordFilter
          keywordFilterLabel={t(
            'web.admin.importer.workorders.list.search.placeholder'
          )}
          onKeywordFilterChange={handleSearch}
          totalRows={processes}
        />
      ) : (
        <Flex className={styles.emptyStateBox}>
          <Flex className={styles.emptyState}>
            <Icon
              set="FontAwesome"
              className={styles.icon}
              name="upload"
              size="large"
            />
            <div>
              <H4 className={styles.noImports}>
                {t('web.admin.importer.workorders.list.noImports.header')}
              </H4>
              <M className={styles.subtext}>
                <DangerousTranslate
                  translationKey="web.admin.importer.workorders.list.noImpors.subtext"
                  values={{}}
                />
              </M>
            </div>
            <Flex gap={2}>
              <Link
                to={routes.channelAdminDataImportNew.replace(
                  ':id',
                  channel?.slug
                )}
                className={styles.newImportJobLink}
              >
                <Button variant="activate-contained">
                  {t('web.admin.importer.workorders.list.newImport')}
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      )}
    </div>
  );
}
