import { RecurrenceStatus } from 'graphql-query-contracts';

export const RECURRING_CHARGE_STATUS_MAP: {
  [key: string]: [string, string];
} = {
  EXPIRED: ['abp.subscription.status.expired', 'grey'],
  CANCELLED: ['abp.subscription.status.cancelled', 'red'],
  ACTIVE: ['abp.subscription.status.active', 'green'],
};

export function getRecurringChargeStatus(
  active: boolean,
  status: RecurrenceStatus | undefined
): [string, string] {
  if (active) {
    return RECURRING_CHARGE_STATUS_MAP.ACTIVE;
  }

  if (status === RecurrenceStatus.Cancelled) {
    return RECURRING_CHARGE_STATUS_MAP.CANCELLED;
  }

  return RECURRING_CHARGE_STATUS_MAP.EXPIRED;
}
