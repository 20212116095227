import { useMemo } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import findAccessRestrictedError from '../helpers/findAccessRestrictedError';
import byOrder from '../helpers/sort/byOrder';
import { cloneDeep } from 'lodash';

type Props = {
  channelSlug: string;
};

const getChannelPagesQuery = gql`
  query getChannelPagesQuery($channelSlug: String!) {
    channel(slug: $channelSlug) {
      _id
      _updated
      slug
      name
      pages {
        _id
        _created
        _updated
        _order
        name
        slug
        icon
        iconSet
        iconWeight
        label
        label_l10n
        content {
          _id
          _created
          _updated
          integration {
            _id
            integration {
              _id
              name
            }
          }
        }
      }
    }
  }
`;

export default function useChannelPages({ channelSlug }: Props) {
  // we may to need load the channel if we don't have it already.
  const { data, error, loading } = useQuery(getChannelPagesQuery, {
    skip: !channelSlug,
    fetchPolicy: 'cache-and-network',
    variables: {
      channelSlug,
    },
  });

  const pages = useMemo(
    () => cloneDeep(data?.channel?.pages || []).sort(byOrder),
    [data?.channel?.pages]
  );

  const accessRestricted = useMemo(
    () => findAccessRestrictedError(error),
    [error]
  );

  return { pages, error, loading, accessRestricted };
}
