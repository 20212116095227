import React, { useState } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { useLaunchDarklyWebClient } from 'hooks/useLaunchDarklyWebClient';
import { config } from 'lane-shared/config';
import LaunchDarklyContext from 'lane-shared/contexts/LaunchDarklyContext';
import { useLaunchDarklyContext } from 'lane-shared/hooks';
import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';

type LDEnvConfig = {
  baseUri: string;
  clientId: string;
  mobileId: string;
};

export function LaunchDarklyContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [ldReady, setLDReady] = useState(false);
  const whitelabelByIndexHTML = useWhitelabelByIndexHTML();

  // Casting type from multiple JSON objects which are implicitly typed.
  const envConfig: LDEnvConfig = config.launchDarkly as LDEnvConfig;
  const ldConfig: LDClient.LDOptions = {};

  // This overrides the default launch darkly API URL,
  // which is used to route to the local development server when provided.
  if (envConfig.baseUri) {
    ldConfig.baseUrl = envConfig.baseUri;
    ldConfig.eventsUrl = envConfig.baseUri;
    ldConfig.streamUrl = envConfig.baseUri;
  }

  const client = useLaunchDarklyWebClient({
    clientId: envConfig.clientId,
    whitelabelName: whitelabelByIndexHTML?.name,
    config: ldConfig,
    onReady: () => setLDReady(true),
  });

  const launchDarklyContext = useLaunchDarklyContext(client);

  return (
    <LaunchDarklyContext.Provider value={launchDarklyContext}>
      {ldReady ? children : null}
    </LaunchDarklyContext.Provider>
  );
}
