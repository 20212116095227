import React from 'react';
import { useTranslation } from 'react-i18next';

import { H4 } from 'components/typography';
import { UserType } from 'lane-shared/types/User';
import { ErrorMessage } from 'components/general';

import { useUserCreditWallets } from 'lane-shared/domains/billingPayments/hooks';
import { CreditWallets } from './CreditWallets';
import styles from './styles.scss';

export function Credits({ user }: { user: Pick<UserType, '_id'> | null }) {
  const { t } = useTranslation();

  const { wallets, userCreditWalletsError } = useUserCreditWallets({ user });

  return (
    <>
      <div className={styles.CreditsHeader}>
        <H4>{t('abp.credits.availableHeader')}</H4>
      </div>
      <div className={styles.CreditsAvailable}>
        <CreditWallets creditWallets={wallets} />
      </div>

      {userCreditWalletsError && (
        <ErrorMessage error={t(userCreditWalletsError.message)} />
      )}
    </>
  );
}
