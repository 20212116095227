import React from 'react';

import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import Loading from 'components/general/Loading';

import NonPropertyChannelRelationships from './NonPropertyChannelRelationships';
import { NewPropertyChannelRelationships } from './NewPropertyChannelRelationships';
import { PropertyChannelRelationships } from './PropertyChannelRelationships';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

export default function ChannelRelationships() {
  const { channel } = useChannelAdminContext();
  const showNewTenantManagementView = useFlag(
    FeatureFlag.TenantManagement,
    false
  );

  if (!channel) {
    return <Loading />;
  }

  if (channel.type === ChannelTypeEnum.Property) {
    if (showNewTenantManagementView) {
      return <NewPropertyChannelRelationships />;
    }

    return <PropertyChannelRelationships />;
  }

  return <NonPropertyChannelRelationships />;
}
