import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import { ShopifyMultipassNoVerifiedEmailError } from 'activate-errors';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { convertToUUID } from 'uuid-encoding';
import injectShopifyMultipassIntegration from 'lane-shared/helpers/integrations/ShopifyMultipass/injectShopifyMultipassIntegration';
import useShopifyMultipassIntegration from 'lane-shared/hooks/useShopifyMultipassIntegration';
import { ChannelIntegrationType } from 'lane-shared/types/ChannelIntegrationType';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { FlexDirectionEnum, FlexWrapEnum } from 'lane-shared/types/blocks/Flex';
import { ContentType } from 'lane-shared/types/content/Content';

import EmailVerificationModal from 'components/integrations/ShopifyMultipass/EmailVerificationModal';
import ErrorModal from 'components/integrations/ShopifyMultipass/ErrorModal';

import { WebBlockProps } from '../WebBlockProps';
import SectionContentListBlock from './SectionContentListBlock';
import useIsAdminView from 'hooks/useIsAdminView';

type BlockProps = WebBlockProps & {
  channelIntegration: ChannelIntegrationType;
  listView: string;
  showFilters: boolean;
  section: DocumentType;
  header: string;
  autoHide: boolean;
  perPage: number;
  flexDirection: FlexDirectionEnum;
  flexWrap: FlexWrapEnum;
  errorMsg: any;
  setErrorMsg: any;
};

export default function ShopifyMultipassSectionBlock({
  channelIntegration,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const [isAdminView] = useIsAdminView();
  const [queuedContent, setQueuedContent] = useState<ContentType | null>(null);
  const channelIntegrationId = channelIntegration?._id
    ? convertToUUID(channelIntegration?._id)
    : undefined;

  const {
    triggerShopifyMultipassFlow,
    error,
    isEmailVerificationModalOpen,
    closeEmailVerificationModal,
    createNewAccount,
    isErrorModalOpen,
    closeErrorModal,
  } = useShopifyMultipassIntegration({
    isAdminView,
    openUrl: (loginUrl: string) => window.open(loginUrl),
  });

  function onContentLinkClick(
    event: React.MouseEvent<HTMLAnchorElement>,
    content: ContentType
  ) {
    if (channelIntegrationId && content.externalUrl?.url) {
      event.preventDefault();
      captureAnalytics(content);
      setQueuedContent(content);
      triggerShopifyMultipassFlow(content);
    }
  }

  function captureAnalytics(content: ContentType) {
    analytics.track(
      ANALYTIC_KEYS.ANALYTIC_CONTENT_DETAILS_SHOPIFY_MULTIPASS_SECTION,
      {
        contentId: content?._id,
        channelId: content?.channel?._id,
        userId: user?._id,
        channelIntegrationId,
      }
    );
  }

  function transformContent(content: any) {
    return content
      ? injectShopifyMultipassIntegration(channelIntegration?._id, content)
      : content;
  }

  const unverifiedEmailError = error?.message?.includes(
    ShopifyMultipassNoVerifiedEmailError.errorName
  );

  // trigger shopify multipass flow after user added shopify login
  async function onCloseModal() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ContentType | null' is not assig... Remove this comment to see the full error message
    await triggerShopifyMultipassFlow(queuedContent);
    setQueuedContent(null);
  }

  function onExitModal() {
    closeEmailVerificationModal();
    setQueuedContent(null);
  }

  return (
    <>
      {!unverifiedEmailError && error?.message ? (
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          errorHeader={t(
            'web.admin.channel.content.layout.editor.components.shopifyMultipassSection.error.header'
          )}
          errorMessage={t(
            'web.admin.channel.content.layout.editor.components.shopifyMultipassSection.error.header'
          )}
        />
      ) : null}
      {channelIntegrationId ? (
        <EmailVerificationModal
          channelIntegrationId={channelIntegrationId}
          unverifiedEmailError={unverifiedEmailError}
          isOpen={isEmailVerificationModalOpen}
          onClose={onCloseModal}
          onExit={onExitModal}
          onCreateNewAccount={createNewAccount}
        />
      ) : null}
      <SectionContentListBlock
        {...otherProps}
        showViewAll={false}
        onContentClick={onContentLinkClick}
        transformContent={transformContent}
      />
    </>
  );
}
