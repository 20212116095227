import React, { useState, useMemo, ReactNode } from 'react';

import {
  OnboardingContext,
  OnboardingData,
  OnboardingProfile,
  OnboardingState,
} from '.';
import { ChannelType } from '../../types/ChannelType';

type Props = {
  defaultData: {
    userName?: string;
    building?: ChannelType;
    company?: ChannelType;
  };
  asyncOnComplete: (data: OnboardingData) => Promise<void>;
  asyncOnError: (error: any) => Promise<string>;
  children: ReactNode;
};

const defaultProfile = {
  fullName: undefined,
  isMarketingOptInSelected: false,
};

export const OnboardingContextProvider: React.FC<Props> = ({
  defaultData: { userName, building, company },
  asyncOnComplete,
  asyncOnError,
  children,
}) => {
  const [onboardingState, setOnboardingState] = useState<OnboardingState>(
    OnboardingState.Idle
  );
  const [completionError, setCompletionError] = useState<string | undefined>(
    undefined
  );
  const [profile, setProfile] = useState<OnboardingProfile>({
    ...defaultProfile,
    fullName: userName,
  });

  const [updatedBuilding, setBuilding] = useState<ChannelType | null>(
    building ?? null
  );

  const handleProfileChange = ({
    fullName,
    marketingOptIn,
  }: {
    fullName: string;
    marketingOptIn: boolean;
  }) => {
    setProfile({
      ...profile,
      fullName,
      isMarketingOptInSelected: marketingOptIn,
    });
    setCompletionError(undefined);
  };

  const handleBuildingChange = (building: ChannelType) => {
    setBuilding(building);
  };

  const handleOnboardingComplete = async (data: OnboardingData) => {
    setOnboardingState(OnboardingState.Loading);

    try {
      await asyncOnComplete(data);
      setOnboardingState(OnboardingState.Idle);
    } catch (err) {
      const error = await asyncOnError(err);

      setCompletionError(error);
      setOnboardingState(OnboardingState.Error);
    }
  };

  const handleBuildingOrCompanyChange = () => {
    if (completionError) {
      setCompletionError(undefined);
      setOnboardingState(OnboardingState.Idle);
    }
  };

  const contextValue = useMemo(
    () => ({
      data: {
        profile,
        building: updatedBuilding,
        company,
      },
      errors: {
        completionError,
      },
      onboardingState,
      handlers: {
        updateProfile: handleProfileChange,
        updateBuilding: handleBuildingChange,
        completeOnboarding: handleOnboardingComplete,
        onBuildingOrCompanySelectionChange: handleBuildingOrCompanyChange,
      },
    }),
    [
      profile.fullName,
      profile.isMarketingOptInSelected,
      updatedBuilding?._id,
      company?._id,
      onboardingState,
      completionError,
    ]
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
