import React from 'react';

import { Document, Page, View, Text, Svg, Line } from '@react-pdf/renderer';

import { simpleDate } from 'lane-shared/helpers/formatters';
import { ClientTask, TaskAuditLog } from 'graphql-query-contracts';

import { styles } from './styles';
import { ActivityLogPDF } from 'domains/workOrder/activityLog/export/ActivityLogPDF';

type PMTaskDetailsPDFProps = {
  taskData: ClientTask;
  createdUserName: string;
  auditLogs: TaskAuditLog[];
  t: (val: string, opts?: any) => string;
  isAuditLogsEnabled?: boolean;
};

const line = () => (
  <Svg height="20" width="550" style={styles.line}>
    <Line
      x1="1000"
      y1="0"
      x2="0"
      y2="0"
      strokeWidth={1}
      stroke="rgb(128,128,128)"
    />
  </Svg>
);

const PMTaskDetailsPDF = ({
  taskData,
  createdUserName,
  auditLogs,
  t,
  isAuditLogsEnabled,
}: PMTaskDetailsPDFProps) => {
  const labels = {
    created: t('web.admin.serviceRequest.activity.created'),
    by: t('web.admin.serviceRequest.activity.by'),
    activity: t('web.admin.serviceRequest.activity'),
    automatic: t('web.admin.serviceRequest.activity.automatic'),
    pageCounter: 'web.admin.workOrder.pdf.pageCounter',
  };

  const stepTypeText = (type: string, subtype: string) => {
    if (type === 'instruction') {
      return t('web.admin.workOrder.steps.instruction.label');
    }

    if (type === 'acknowledge') {
      return t('web.admin.workOrder.steps.acknowledge.label');
    }

    if (type === 'response' && subtype === 'long') {
      return `${t('web.admin.workOrder.steps.prompt')} - ${t('web.admin.workOrder.steps.responseType.longText')}`;
    }

    if (type === 'response' && subtype === 'short') {
      return `${t('web.admin.workOrder.steps.prompt')} - ${t('web.admin.workOrder.steps.responseType.shortText')}`;
    }

    if (type === 'response' && subtype === 'numerical') {
      return `${t('web.admin.workOrder.steps.prompt')} - ${t('web.admin.workOrder.steps.responseType.numerical')}`;
    }

    if (type === 'choice' && subtype === 'multiple') {
      return `${t('web.admin.workOrder.steps.choice.label')} - ${t('web.admin.workOrder.steps.responseType.multiSelect')}`;
    }

    if (type === 'choice' && subtype === 'single') {
      return `${t('web.admin.workOrder.steps.choice.label')} - ${t('web.admin.workOrder.steps.responseType.singleSelect')}`;
    }

    if (type === 'meter') {
      return `${t('web.admin.workOrder.steps.meter.label')}`;
    }

    return '';
  };

  const isEmptyValue = (value: string | number | undefined | null) =>
    value === undefined ||
    value === null ||
    value === '' ||
    Number.isNaN(value);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Title & Version */}
        <Text style={styles.header}>{taskData?.userFriendlyID}</Text>
        <Text style={styles.subheadings}>
          {t(
            'web.admin.workOrder.preventiveMaintenance.schedule.details.updatedBy',
            {
              date: simpleDate(taskData?.updatedAt),
              name: createdUserName,
            }
          )}
        </Text>
        {line()}

        {/* Schedule Details */}
        <>
          <Text style={styles.sectionHeader}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.heading'
            )}
          </Text>
          <View style={styles.dualView}>
            <View>
              <Text style={styles.subheadings}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.schedule'
                )}
              </Text>
              <Text style={styles.infoText}>{taskData?.schedule?.title}</Text>
            </View>
          </View>
          <View style={styles.dualView}>
            <View style={styles.dualViewItem}>
              <Text style={styles.subheadings}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.repeats'
                )}
              </Text>
              <Text style={styles.infoText}>{taskData?.repeats?.label}</Text>
            </View>
            <View style={styles.dualViewItem}>
              <Text style={styles.subheadings}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.timeToComplete'
                )}
              </Text>
              <Text style={styles.infoText}>
                {taskData?.estimateTimeToComplete}{' '}
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.hoursUnit'
                )}
              </Text>
            </View>
          </View>
          <View style={styles.dualView}>
            <View style={styles.dualViewItem}>
              <Text style={styles.subheadings}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.dueDate'
                )}
              </Text>
              <Text style={styles.infoText}>
                {simpleDate(taskData?.dueDate!)}
              </Text>
            </View>
            <View style={styles.dualViewItem}>
              <Text style={styles.subheadings}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.completedDate'
                )}
              </Text>
              <Text style={styles.infoText}>
                {taskData?.completedBy
                  ? simpleDate(taskData?.completedAt!)
                  : '-'}
              </Text>
            </View>
          </View>
          <View style={styles.dualView}>
            <View>
              <Text style={styles.subheadings}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.scheduleNotes'
                )}
              </Text>
              <Text style={styles.infoText}>{taskData?.scheduleNotes}</Text>
            </View>
          </View>
        </>
        {line()}

        {/* Equipment */}
        {taskData?.equipment && taskData?.equipment.length > 0 && (
          <>
            <Text style={styles.sectionHeader}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.task.details.equipment.heading'
              )}
            </Text>
            {taskData?.equipment?.map(eq => (
              <View style={styles.equipmentSection} key={eq.id}>
                <View style={styles.dualView}>
                  <View>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.category'
                      )}
                    </Text>
                    <Text style={styles.infoText}>{eq.category}</Text>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.name'
                      )}
                    </Text>
                    <Text style={styles.infoText}>{eq.name}</Text>
                  </View>
                  <View>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.task.details.equipment.location'
                      )}
                    </Text>
                    <Text style={styles.infoText}>{eq.location}</Text>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.task.details.equipment.floor'
                      )}
                    </Text>
                    <Text style={styles.infoText}>{eq.floor || ' '}</Text>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.task.details.equipment.suite'
                      )}
                    </Text>
                    <Text style={styles.infoText}>{eq.suite || ' '}</Text>
                  </View>
                </View>
              </View>
            ))}
            {line()}
          </>
        )}

        {/* Steps */}
        {((taskData?.stepExecution && taskData?.stepExecution?.length > 0) ||
          taskData?.steps ||
          taskData?.meterReading) && (
          <>
            <Text style={styles.sectionHeader}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.task.details.steps'
              )}
            </Text>
            {taskData?.stepExecution?.map((step, index) => (
              <View style={styles.stepSection} key={index}>
                <Text style={[styles.infoText, styles.stepIndex]}>
                  {index + 1}.
                </Text>
                <View style={styles.stepContent}>
                  <Text style={styles.stepSubHeader}>
                    {stepTypeText(step?.type!, step?.options?.subtype || '')}
                  </Text>
                  <Text style={styles.infoText}>{step?.name}</Text>
                  {step?.options?.description && (
                    <Text style={styles.infoText}>
                      {step?.options?.description}
                    </Text>
                  )}
                  {step?.response?.map((response, index) => (
                    <Text key={index} style={styles.infoText}>
                      {response}
                    </Text>
                  ))}
                </View>
              </View>
            ))}

            {taskData?.stepExecution &&
              taskData?.stepExecution?.length <= 0 &&
              (taskData?.steps || taskData?.meterReading) && (
                <>
                  <Text style={[styles.stepSubHeader, styles.notes]}>
                    {taskData?.steps}
                  </Text>
                  <Text style={styles.sectionHeader}>
                    {t(
                      'web.admin.workOrder.preventiveMaintenance.task.details.meterReading'
                    )}
                  </Text>
                  <View>
                    <Text style={[styles.stepSubHeader, styles.notes]}>
                      {taskData?.meterReading}
                    </Text>
                  </View>
                </>
              )}
            {line()}
          </>
        )}

        {/* Time & Notes */}
        {(!isEmptyValue(taskData?.hours) ||
          !isEmptyValue(taskData?.minutes) ||
          taskData?.notes) && (
          <>
            <Text style={styles.sectionHeader}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.task.tabs.hoursAndNotes'
              )}
            </Text>
            <View>
              <Text style={styles.subheadings}>
                {t('web.admin.workOrders.notesAndHours.taskTime.field.hours')}
              </Text>
              <Text style={styles.infoText}>
                {!isEmptyValue(taskData?.hours) &&
                  `${t(
                    'web.admin.workOrders.notesAndHours.taskTime.hours-count',
                    {
                      count: Number(taskData?.hours),
                    }
                  )} `}
                {!isEmptyValue(taskData?.minutes) &&
                  !(
                    taskData?.minutes === 0 && !isEmptyValue(taskData?.hours)
                  ) &&
                  t(
                    'web.admin.workOrders.notesAndHours.taskTime.minutes-count',
                    {
                      count: Number(taskData?.minutes),
                    }
                  )}
              </Text>
            </View>
            <View>
              <Text style={styles.subheadings}>
                {t('web.admin.serviceRequest.notes')}
              </Text>
              <Text style={[styles.infoText, styles.notes]}>
                {taskData?.notes}
              </Text>
            </View>
          </>
        )}

        {/* Footer */}
        <View style={styles.pageNumber} fixed>
          <Text>
            {new Date().toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              t('web.admin.workOrder.pdf.pageCounter', {
                pageNumber,
                totalPages,
              })
            }
          />
        </View>
      </Page>
      {isAuditLogsEnabled && (
        <ActivityLogPDF
          userFriendlyID={taskData?.userFriendlyID}
          createdAt={taskData.createdAt}
          createdBy={createdUserName}
          auditLogs={auditLogs}
          labels={labels}
          t={t}
        />
      )}
    </Document>
  );
};

export default PMTaskDetailsPDF;
