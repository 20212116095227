import downloadBulkFiles from '../helpers/downloadBulkFiles';
import downloadFile from '../helpers/downloadFile';
import { appUrl } from 'lane-shared/config';

export const useDownloadFile = <TBody = any,>() => {
  const downloadFileFromUrl = async ({ url }: { url: string }) => {
    await downloadFile(`${appUrl}${url}`);
  };

  const downloadBulkFilesFromUrl = async ({
    url,
    body,
    filename,
  }: {
    url: string;
    body: TBody;
    filename?: string;
  }) => {
    await downloadBulkFiles(`${appUrl}${url}`, body, filename);
  };

  return { downloadFileFromUrl, downloadBulkFilesFromUrl };
};
