import { Mapping, Ruling } from './types';

export const SUPER_CONVERTER_VERSION = 'v1.2.0';
export const AMF_VERSION = 'v.2024-12';

export const DEFAULT_HYPERCARE_DATE = new Date('2024-01-01');

export const RECURRENCE_TYPES = [
  'daily',
  'weekly',
  'bi-weekly',
  'monthly',
  'bi-monthly',
  'quarterly',
  'semi-annually',
  'annually',
];

export const EQUIPMENT_STATUSES = ['Active', 'Inactive'];

export const EQUIPMENT_CATEGORIES = [
  'BAS - Building Automation/Controls',
  'BAS - Control Systems',
  'Electrical - Panels/Swtchgr & Trnsfrmrs',
  'Electrical - Battery',
  'Electrical - Distribution',
  'Electrical - General',
  'Electrical - Emergency Lighting',
  'Electrical - Lighting',
  'Electrical - Lighting Control Center',
  'Electrical - Batteries',
  'Electrical - Navigation Lights',
  'Electrical - Parking Lot Lights Inspection',
  'Electrical - Motor Control Center',
  'Electrical - Motors / Electric',
  'Electrical - Transfer Switch',
  'Electrical - Uninterruptible Power Supplies',
  'Electrical - Variable Frequency Drives',
  'Fan - Condenser',
  'Fan - Exhaust',
  'Fan - Kitchen Exhaust Suction Tank Fan',
  'Fan - Perimeter Coil Unit',
  'Fan - Powered Induction Unit',
  'Fan - Relief',
  'Fan - Return Air',
  'Fan - Supply',
  'Fan - Toilet Exhaust',
  'Fire/Life/Safety - General',
  'Fire/Life/Safety - Fire Extinguisher',
  'Fire/Life/Safety - Annunciator System',
  'Fire/Life/Safety - Alarms & Detectors',
  'Generator - Emergency Generator',
  'Generator - Fuel Storage',
  'HVAC - Cooling Towers',
  'HVAC - General',
  'HVAC - Unit Heaters',
  'HVAC - Air Compressors',
  'HVAC - Air Conditioning',
  'HVAC - Air Handling Units',
  'HVAC - Back Flow Devices',
  'HVAC - Boilers',
  'HVAC - Chillers',
  'HVAC - Furnace',
  'HVAC - Heat Exchange',
  'HVAC - Reciprical HVAC Systems',
  'HVAC - CO Sensors',
  'HVAC - Liebert Glycol Unit',
  'HVAC - Package Unit',
  'HVAC - Split Systems',
  'HVAC - VAV Box',
  'Misc Equipment - Grounds and Maintenance Equipment',
  'Misc Equipment - Irrigation Equipment',
  'Misc Equipment - Kitchen Equipment',
  'Misc Equipment - Security Equipment',
  'Misc Equipment - Shop Tools & Equipment',
  'Misc Equipment - Building Equipment',
  'Misc Equipment - General',
  'Misc Equipment - Trash/Recycle Equipment',
  'Plumbing - Piping Systems',
  'Plumbing - General',
  'Plumbing - Systems',
  'Plumbing - Sprinklers',
  'Plumbing - Fountains - Drinking',
  'Plumbing - Solar Water Heater',
  'Pneumatic - Compressed Air',
  'Pneumatic - Control Air Dryer',
  'Pneumatic - Control Air Systems',
  'Pump - Chill Water',
  'Pump - Condenser Water',
  'Pump - Cooling Tower',
  'Pump - Domestic Water',
  'Pump - Fire',
  'Pump - Glycol Pump',
  'Pump - Hot Water',
  'Pump - Hot Well',
  'Pump - Jockey Fire',
  'Pump - Package Units',
  'Pump - Reclamation Pump',
  'Pump - Reheat Pump',
  'Pump - Rinkler Sump Pump/Suction Tanker',
  'Pump - Secondary Water',
  'Pump - Sewer Ejector',
  'Pump - Sump Pump',
  'Safety - Ladder Inspection',
  'Security - CCTV',
  'Structure - Dock Equipment',
  'Structure - Doors',
  'Structure - Garages and Parking Structures',
  'Structure - Gates/Fences',
  'Structure - Landscaping',
  'Structure - Restrooms',
  'Structure - Roofs',
  'Structure - Automatic Sliding Doors',
  'Structure - Drinking Fountains',
  'Structure - Elevators & Escalators',
  'Water - Domestic Systems',
  'Water - Filters',
  'Water - Heaters - Electrical',
  'Water - Heaters - Gas',
  'Water - Storage Tanks',
  'Water - Treatment',
  'Water - Systems',
  'UTL - Utility meters & submeters',
  'Pump - General',
  'Janitorial',
];

export const TASK_STATUSES = [
  'Created',
  'Accepted',
  'In Progress',
  'On Hold',
  'Closed',
  'Declined',
  'Cancelled',
  'Withdrawn',
  'Completed',
];

export const SR_STATUSES = [
  'Created',
  'Accepted',
  'Open',
  'In Progress',
  'On Hold',
  'Closed',
  'Declined',
  'Cancelled',
  'Complete',
];

export const USER_MARKETING_OPT_IN = ['TRUE', 'FALSE'];

export const USER_STATUSES = ['Active', 'Inactive'];

export const HYPERCARE_IMPORTS: { [key: string]: string } = {
  PMSetupEquipSched: 'Equipment list',
  PMScheduleList: 'PM Schedules list',
  PMSetupTasksSteps: 'TasksImports',
  WOListTR: 'ServiceRequestImports',
};

export const HYPERCARE_EQUIPMENT_MAPPING: Mapping = {
  equipment_id: { keys: ['Equipment ID'], header: 'Equipment ID' },
  name: { keys: ['Equipment Name'], header: 'Equipment name' },
  category: {
    keys: ['Equipment Class'],
    header: 'Category',
    dropdownOptions: {
      sheetName: 'Reference',
      start: 'B2',
      end: 'B105',
    },
  },
  status: {
    keys: ['Active'],
    transforms: ['status'],
    default: 'Active',
    header: 'Status',
  },
  location: { keys: ['Building'], header: 'Location' },
  floor: { keys: ['Floor'], header: 'Floor' },
  suite: { keys: ['Suite'], header: 'Suite (tenant space)' },
  make: { keys: ['Make'], header: 'Make' },
  model: { keys: ['Model'], header: 'Model' },
  asset: { keys: ['Asset Code'], header: 'Asset #' },
  serial: { keys: ['Serial Number'], header: 'Serial #' },
  warranty_expiration_date: {
    keys: ['Warranty Expiry'],
    transforms: ['date'],
    header: 'Warranty expiration date',
  },
  install_date: {
    keys: ['Date Installed'],
    transforms: ['date'],
    header: 'Install date',
  },
  notes: { keys: ['Notes'], header: 'Additional notes' },
  created_by: {
    keys: [],
    default: 'zach.targos+tempeng@vts.com',
    header: 'Created By',
  },
  created_at: { keys: [], header: 'Created At' },
  updated_by: {
    keys: [],
    default: 'zach.targos+tempeng@vts.com',
    header: 'Updated By',
  },
  updated_at: { keys: [], header: 'Updated At' },
};

export const HYPERCARE_SERVICE_REQUESTS_MAPPING: Mapping = {
  service_request_id: { keys: ['ID'] },
  company: { keys: ['Tenant'] },
  description: { keys: ['WO Description'] },
  category: { keys: ['Trade'] },
  issue: { keys: ['Request Type'] },
  location: { keys: ['Building'] },
  floor: { keys: ['Floor'] },
  suite: { keys: ['Suite'] },
  created_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  assignee: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  status: { keys: ['Status'] },
  submit_on_behalf_of: { keys: [] },
  updated_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  notes: { keys: ['WO Detail'] },
  created_at: { keys: ['Date Received'], transforms: ['date'] },
  updated_at: { keys: ['Date Closed'], transforms: ['date'] },
  is_billable: { keys: [] },
};

export const HYPERCARE_SCHEDULE_MAPPING: Mapping = {
  schedule_id: { keys: ['Schedule #'], header: 'Schedule ID' },
  title: { keys: ['Task'], header: 'Schedule title' },
  notes: { keys: ['Schedule Description'], header: 'Notes' },
  assignee: {
    keys: [],
    default: 'zach.targos+tempeng@vts.com',
    header: 'Assignee',
  },
  time_to_complete: {
    keys: ['Est. Time (h:mm)'],
    transforms: ['hours_to_number'],
    header: 'Estimated time to complete (hrs)',
  },
  repeats: {
    keys: ['Rec. Period'],
    transforms: ['repeats'],
    header: 'Repeats',
  },
  days_ahead: {
    keys: [],
    default: '0',
    header: 'Number of days in advance the task is created',
  },
  next_due_date: {
    keys: ['Start / End'],
    transforms: ['date_range_start'],
    header: 'First due date',
  },
  until_date: {
    keys: ['Start / End'],
    transforms: ['date_range_end'],
    header: 'End date',
  },
  location: { keys: ['Floor / Suite'], header: 'Location' },
  steps: { keys: [], header: 'Steps' },
  meter_reading: { keys: [], header: 'Meter reading' },
  created_by: {
    keys: [],
    default: 'zach.targos+tempeng@vts.com',
    header: 'Created By',
  },
  created_at: { keys: [], header: 'Created At' },
  updated_by: {
    keys: [],
    default: 'zach.targos+tempeng@vts.com',
    header: 'Updated By',
  },
  updated_at: { keys: [], header: 'Updated At' },
};

export const HYPERCARE_TASK_MAPPING: Mapping = {
  task_id: { keys: ['Task ID'] },
  due_date: { keys: [] },
  estimate_time_to_complete: { keys: ['Est. Time (h:mm)'] },
  assignee: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  notes: { keys: ['WO Description'] },
  repeats: { keys: ['Rec. Period'], transforms: ['repeats'] },
  hours: { keys: ['Est. Time (h:mm)'] },
  steps: { keys: ['Task Line Details'] },
  created_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  created_at: { keys: [] },
  updated_at: { keys: [] },
  updated_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  meter_reading: { keys: [] },
  location: { keys: ['Floor'] },
  status: { keys: [], default: 'Completed' },
  schedule_id: { keys: ['Schedule ID'] },
  schedule_notes: { keys: [] },
};

export const HYPERCARE_MAPPINGS: { [key: string]: Mapping } = {
  PMSetupEquipSched: HYPERCARE_EQUIPMENT_MAPPING,
  PMSetupTasksSteps: HYPERCARE_TASK_MAPPING,
  PMScheduleList: HYPERCARE_SCHEDULE_MAPPING,
  WOListTR: HYPERCARE_SERVICE_REQUESTS_MAPPING,
};

export const HYPERCARE_SOURCES: { [key: string]: Mapping } = {
  'Equipment list': HYPERCARE_EQUIPMENT_MAPPING,
  'PM Schedules list': HYPERCARE_SCHEDULE_MAPPING,
  TasksImports: HYPERCARE_TASK_MAPPING,
  ServiceRequestImports: HYPERCARE_SERVICE_REQUESTS_MAPPING,
};

export const HYPERCARE_ID_HEADERS: { [key: string]: string } = {
  'Equipment list': 'Equipment ID',
  'PM Schedules list': 'Schedule #',
  TasksImports: 'Task ID',
  ServiceRequestImports: 'ID',
};

export const SCHEDULE_RULINGS: Ruling = {
  schedule_id: ['required'],
  title: ['required'],
  notes: [],
  assignee: [],
  time_to_complete: ['required'],
  repeats: ['required', 'recurrence'],
  days_ahead: ['required', 'positive'],
  next_due_date: ['required', 'datetime'],
  until_date: ['required', 'datetime'],
  location: [],
  steps: [],
  meter_reading: [],
  created_by: ['required'],
  created_at: ['datetime'],
  updated_by: ['required'],
  updated_at: ['datetime'],
};

export const EQUIPMENT_RULINGS: Ruling = {
  equipment_id: ['required'],
  name: ['required'],
  category: ['required'],
  status: ['required', 'active'],
  location: [],
  floor: [],
  suite: [],
  make: [],
  model: [],
  asset: [],
  serial: [],
  warranty_expiration_date: ['required', 'datetime'],
  install_date: ['required', 'datetime'],
  notes: [],
  created_by: ['required'],
  created_at: ['datetime'],
  updated_by: ['required'],
  updated_at: ['datetime'],
};

export const TASK_RULINGS: Ruling = {
  task_id: ['required'],
  due_date: ['required', 'datetime'],
  estimate_time_to_complete: ['required', 'positive'],
  assignee: [],
  notes: [],
  repeats: ['required', 'recurrence'],
  hours: [],
  steps: [],
  created_by: ['required'],
  created_at: ['datetime'],
  updated_at: ['datetime'],
  updated_by: ['required'],
  meter_reading: [],
  location: [],
  status: ['required', 'task_status'],
  schedule_id: ['required'],
  schedule_notes: [],
};

export const SR_RULINGS: Ruling = {
  service_request_id: ['required'],
  company: ['required', 'uuid'],
  description: ['required'],
  category: ['required'],
  issue: ['required'],
  location: ['required'],
  floor: ['required'],
  suite: [],
  created_by: ['required'],
  assignee: ['required'],
  status: ['required', 'sr_status'],
  submit_on_behalf_of: [],
  updated_by: ['required'],
  notes: [],
  created_at: ['datetime'],
  updated_at: ['datetime'],
  is_billable: ['boolean'],
};

export const IMPORT_RULINGS: { [key: string]: Ruling } = {
  'Equipment list': EQUIPMENT_RULINGS,
  'PM Schedules list': SCHEDULE_RULINGS,
  TasksImports: TASK_RULINGS,
  ServiceRequestImports: {},
};
