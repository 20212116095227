import { useLazyQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { Workflow, WorkflowTypeEnum } from 'constants-workflows';

import {
  getWorkflowsByChannelAndType as getWorkflowsByChannelAndTypeQuery,
  createWorkflow as createWorkflowMutation,
  updateWorkflow as updateWorkflowMutation,
  deleteWorkflow as deleteWorkflowMutation,
  restoreDefaultWorkflows as restoreDefaultWorkflowsMutation,
  updateWorkflows as updateWorkflowsMutation,
} from 'lane-shared/graphql/workflow';

import {
  CreateWorkflowMutation,
  UpdateWorkflowMutation,
  GetWorkflowsByChannelAndTypeQuery,
  DeleteWorkflowMutation,
  RestoreDefaultWorkflowsMutation,
  UpdateWorkflowsMutation,
  UpdateWorkflowInput,
} from 'graphql-query-contracts';
import { useEffect, useState } from 'react';
import { convertTo62, convertToUUID } from 'uuid-encoding';
import { constructWorkflow } from 'lane-shared/helpers/workflows/constructWorkflow';

export type RestoreDefaultWorkflowsParams = {
  channelId: string;
  type: WorkflowTypeEnum;
  onSuccess?: (workflows: Workflow[]) => void;
  onFailure?: (error: any) => void;
};

const deriveWorkflowsToSet = (
  workflowsFromState: Workflow[],
  workflowsFromServer: Workflow[],
  workflowsQueryResponseData: GetWorkflowsByChannelAndTypeQuery['getWorkflowsByChannelAndType']
): Workflow[] => {
  const workflowsToAdd =
    workflowsQueryResponseData
      ?.filter(
        w => w !== null && !workflowsFromServer.some(wf => wf._id === w._id)
      )
      .map(w => ({ ...w })) || [];

  const workflowsToRemove = workflowsFromServer.filter(
    w =>
      w !== null &&
      !workflowsQueryResponseData?.some(wf => wf !== null && wf._id === w._id)
  );

  const workflowsToUpdateMap: Record<string, Partial<Workflow>> = {};

  workflowsQueryResponseData?.forEach(w => {
    if (w === null) return;

    const existingWorkflow = workflowsFromServer.find(wf => wf._id === w._id);

    if (!existingWorkflow) return;

    if (JSON.stringify(w) === JSON.stringify(existingWorkflow)) {
      return;
    }

    const fieldsToUpdate = Object.keys(w)
      .filter((key: string) => {
        if (!Object.hasOwn(existingWorkflow, key)) {
          return false;
        }

        return (
          w[key as keyof typeof w] !==
          existingWorkflow[key as keyof typeof existingWorkflow]
        );
      })
      .reduce<Partial<Workflow>>((acc, curr) => {
        acc[curr as keyof typeof existingWorkflow] = w[curr as keyof typeof w];

        return acc;
      }, {});

    workflowsToUpdateMap[w._id] = fieldsToUpdate;
  });

  const workflowsToReturn = [
    ...workflowsFromState.filter(
      w => !workflowsToRemove.some(wf => wf._id === w._id)
    ),
    ...workflowsToAdd,
  ];

  return workflowsToReturn
    .filter(w => w !== null)
    .map(w => {
      const updatedFields = workflowsToUpdateMap[w._id];

      if (!updatedFields) return w as Workflow;

      return { ...w, ...updatedFields } as Workflow;
    });
};

export const useWorkflows = (channelId?: string, type?: WorkflowTypeEnum) => {
  const [loading, setLoading] = useState(true);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [workflowsFromServer, setWorkflowsFromServer] = useState<Workflow[]>(
    []
  );

  const [getWorkflowsByChannelAndType, { data }] =
    useLazyQuery<GetWorkflowsByChannelAndTypeQuery>(
      getWorkflowsByChannelAndTypeQuery,
      {
        fetchPolicy: 'network-only',
      }
    );
  const [createWorkflow] = useMutation<CreateWorkflowMutation>(
    createWorkflowMutation
  );
  const [updateWorkflow] = useMutation<UpdateWorkflowMutation>(
    updateWorkflowMutation
  );
  const [deleteWorkflow] = useMutation<DeleteWorkflowMutation>(
    deleteWorkflowMutation
  );
  const [restoreDefaultWorkflows] =
    useMutation<RestoreDefaultWorkflowsMutation>(
      restoreDefaultWorkflowsMutation
    );
  const [updateWorkflows] = useMutation<UpdateWorkflowsMutation>(
    updateWorkflowsMutation
  );

  const getWorkflowsQueryData = data?.getWorkflowsByChannelAndType || [];

  const fetchWorkflows = async () => {
    if (channelId && type) {
      const response = await getWorkflowsByChannelAndType({
        variables: {
          channelId: convertToUUID(channelId),
          type,
        },
        fetchPolicy: 'network-only',
      });

      const workflowResponse =
        response?.data?.getWorkflowsByChannelAndType || [];

      return workflowResponse;
    }

    return [];
  };

  useEffect(() => {
    const getWorkflows = async () => {
      try {
        setLoading(true);
        await fetchWorkflows();
      } catch (error) {
        Sentry.captureException(error, {
          contexts: {
            params: {
              channelId,
              type,
            },
          },
        });
      } finally {
        setLoading(false);
      }
    };

    getWorkflows();
  }, [channelId, type]);

  useEffect(() => {
    setWorkflows(prevWorkflows => {
      const workflowsToSet = deriveWorkflowsToSet(
        prevWorkflows,
        workflowsFromServer,
        getWorkflowsQueryData
      );

      return workflowsToSet;
    });

    setWorkflowsFromServer(getWorkflowsQueryData as unknown as Workflow[]);
  }, [JSON.stringify(getWorkflowsQueryData)]);

  const handleCreateWorkflowMutation = async (workflow: Workflow) => {
    return createWorkflow({
      variables: {
        workflow: {
          createdBy: workflow._createdBy,
          updatedBy: workflow._updatedBy,
          event: workflow.event,
          name: workflow.name,
          when: workflow.when,
          whenContext: workflow.whenContext,
          inStatus: workflow.inStatus,
          time: workflow.time,
          order: workflow._order,
          action: workflow.action,
          type: workflow.type,
          target: workflow.target,
          targetType: workflow.targetType,
          channelId: convertToUUID(channelId),
          payload: workflow.payload,
          workflow: workflow.workflow,
          dataValidationSchema: workflow.dataValidationSchema,
        },
      },
      update: (cache, { data }) => {
        const createdWorkflow = data?.createWorkflow;

        if (!createdWorkflow) return;

        const variables = {
          channelId: convertToUUID(channelId),
          type,
        };

        const cachedData = cache.readQuery<GetWorkflowsByChannelAndTypeQuery>({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
        });

        const cachedWorkflows = cachedData?.getWorkflowsByChannelAndType || [];
        const updatedWorkflows = [...cachedWorkflows, createdWorkflow];

        cache.writeQuery({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
          data: {
            getWorkflowsByChannelAndType: updatedWorkflows,
          },
        });
      },
    });
  };

  const handleUpdateWorkflowMutation = async (workflow: Workflow) => {
    return updateWorkflow({
      variables: {
        workflowId: convertToUUID(workflow._id),
        workflow: {
          createdBy: workflow._createdBy,
          updatedBy: workflow._updatedBy,
          event: workflow.event,
          name: workflow.name,
          when: workflow.when,
          whenContext: workflow.whenContext,
          inStatus: workflow.inStatus,
          time: workflow.time,
          order: workflow._order,
          action: workflow.action,
          type: workflow.type,
          target: workflow.target,
          targetType: workflow.targetType,
          channelId: convertToUUID(channelId),
          payload: workflow.payload,
          workflow: workflow.workflow,
          dataValidationSchema: workflow.dataValidationSchema,
        },
      },
      update: (cache, { data }) => {
        const updatedWorkflow = data?.updateWorkflow;

        if (!updatedWorkflow) return;

        const variables = {
          channelId: convertToUUID(channelId),
          type,
        };

        const cachedData = cache.readQuery<GetWorkflowsByChannelAndTypeQuery>({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
        });
        const cachedWorkflows = cachedData?.getWorkflowsByChannelAndType || [];
        const updatedWorkflows = cachedWorkflows
          .filter(w => w !== null)
          .map(w => (w._id === updatedWorkflow._id ? updatedWorkflow : w));

        cache.writeQuery({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
          data: {
            getWorkflowsByChannelAndType: updatedWorkflows,
          },
        });
      },
    });
  };

  const handleUpdateWorkflowsMutation = async (workflows: Workflow[]) => {
    const workflowInputs: UpdateWorkflowInput[] = workflows.map(
      w =>
        ({
          id: convertToUUID(w._id),
          workflow: {
            createdBy: w._createdBy,
            updatedBy: w._updatedBy,
            event: w.event,
            name: w.name,
            when: w.when,
            whenContext: w.whenContext,
            inStatus: w.inStatus,
            time: w.time,
            order: w._order,
            action: w.action,
            type: w.type,
            target: w.target,
            targetType: w.targetType,
            channelId: convertToUUID(channelId),
            payload: w.payload,
            workflow: w.workflow,
            dataValidationSchema: w.dataValidationSchema,
          },
        }) as unknown as UpdateWorkflowInput
    );

    return updateWorkflows({
      variables: {
        workflowInputs,
      },
      update: (cache, { data }) => {
        const updatedWorkflows = data?.updateWorkflows;

        if (!updatedWorkflows) return;

        const variables = {
          channelId: convertToUUID(channelId),
          type,
        };

        const cachedData = cache.readQuery<GetWorkflowsByChannelAndTypeQuery>({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
        });
        const cachedWorkflows = cachedData?.getWorkflowsByChannelAndType || [];
        const workflowsToSet = cachedWorkflows
          .filter(w => w !== null)
          .map(w => {
            const updatedWorkflow = updatedWorkflows.find(
              wf => wf !== null && wf._id === w._id
            );

            return updatedWorkflow || w;
          });

        cache.writeQuery({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
          data: {
            getWorkflowsByChannelAndType: workflowsToSet,
          },
        });
      },
    });
  };

  const handleDeleteWorkflowMutation = async (workflowId: string) => {
    return deleteWorkflow({
      variables: {
        workflowId: convertToUUID(workflowId),
      },
      update: (cache, { data }) => {
        const deletedWorkflowId = data?.deleteWorkflow;

        if (!deletedWorkflowId) return;

        const deletedWorkflowId62 = convertTo62(deletedWorkflowId);
        const variables = {
          channelId: convertToUUID(channelId),
          type,
        };

        const cachedData = cache.readQuery<GetWorkflowsByChannelAndTypeQuery>({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
        });
        const cachedWorkflows = cachedData?.getWorkflowsByChannelAndType || [];
        const updatedWorkflows = cachedWorkflows.filter(
          w => w !== null && w._id !== deletedWorkflowId62
        );

        cache.writeQuery({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
          data: {
            getWorkflowsByChannelAndType: [...updatedWorkflows],
          },
        });
      },
    });
  };

  const handleRestoreDefaultWorkflowsMutation = async (
    channelId: string,
    type: WorkflowTypeEnum
  ) => {
    return restoreDefaultWorkflows({
      variables: {
        channelId,
        type,
      },
      update: (cache, { data }) => {
        const restoredWorkflows = data?.restoreDefaultWorkflows || [];
        const variables = {
          channelId: convertToUUID(channelId),
          type,
        };

        cache.writeQuery({
          query: getWorkflowsByChannelAndTypeQuery,
          variables,
          data: {
            getWorkflowsByChannelAndType: [...restoredWorkflows],
          },
        });
      },
    });
  };

  const handleAddWorkflow = (workflowToAdd: Workflow): Workflow => {
    setWorkflows(prevWorkflows => [...prevWorkflows, workflowToAdd]);

    return workflowToAdd;
  };

  const handleEditWorkflow = (workflowToEdit: Workflow): Workflow => {
    const workflow = workflows.find(w => w._id === workflowToEdit._id);

    if (!workflow) {
      throw new Error('Workflow to edit not found');
    }

    setWorkflows(prevWorkflows => {
      return prevWorkflows.map(w => {
        if (w._id === workflowToEdit._id) {
          return workflowToEdit;
        }

        return w;
      });
    });

    return workflowToEdit;
  };

  const handleSaveWorkflow = async (workflow: Workflow): Promise<Workflow> => {
    const existingWorkflow = workflowsFromServer.find(
      w => w._id === workflow._id
    );

    if (!existingWorkflow) {
      const response = await handleCreateWorkflowMutation(workflow);

      // remove workflow from state. It's already in the cache with a different ID
      setWorkflows(prevWorkflows => {
        return prevWorkflows.filter(w => w._id !== workflow._id);
      });

      const newWorkflow = response.data!.createWorkflow;

      return newWorkflow as unknown as Workflow;
    }

    const response = await handleUpdateWorkflowMutation(workflow);

    return response.data!.updateWorkflow as unknown as Workflow;
  };

  const handleDeleteWorkflow = async (workflowId: string): Promise<string> => {
    const workflowToDelete = workflows.find(w => w._id === workflowId);

    if (!workflowToDelete) {
      throw new Error('Workflow to delete not found');
    }

    const existingWorkflow = workflowsFromServer.find(
      w => w._id === workflowId
    );

    if (!existingWorkflow) {
      // only remove from state
      setWorkflows(prevWorkflows => {
        return prevWorkflows.filter(w => w._id !== workflowId);
      });
    } else {
      await handleDeleteWorkflowMutation(workflowId);
    }

    return workflowId;
  };

  const handleCloneWorkflow = async (
    workflowId: string,
    userId: string
  ): Promise<Workflow> => {
    const workflowToClone = workflows.find(
      workflow => workflow._id === workflowId
    );

    if (!workflowToClone) {
      throw new Error('Workflow to clone not found');
    }

    const clonedWorkflow = constructWorkflow({
      ...workflowToClone,
      whenContext: workflowToClone!.whenContext,
      type: workflowToClone!.type,
      channelId: channelId!,
      order: workflows.length,
      userId,
    });

    const newWorkflow = {
      ...workflowToClone,
      ...clonedWorkflow,
    };

    await handleCreateWorkflowMutation(newWorkflow);

    return newWorkflow;
  };

  const handleReorderWorkflows = async (
    workflowsToReorder: Pick<Workflow, '_id' | '_order'>[]
  ) => {
    const workflowsToUpdate: Workflow[] = [];

    workflowsToReorder.forEach(w => {
      const existingWorkflow = workflowsFromServer.find(wf => wf._id === w._id);

      if (!existingWorkflow || existingWorkflow._order === w._order) return;

      workflowsToUpdate.push({ ...existingWorkflow, _order: w._order });
    });

    if (workflowsToUpdate.length === 0) return;

    const currentWorkflowsState = workflows;

    setWorkflows(prevWorkflows => {
      return prevWorkflows.map(prevWorkflow => {
        const newOrder =
          workflowsToReorder.find(wf => wf._id === prevWorkflow._id)?._order ??
          prevWorkflow._order;

        return { ...prevWorkflow, _order: newOrder };
      });
    });

    try {
      await handleUpdateWorkflowsMutation(workflowsToUpdate);
    } catch (error) {
      setWorkflows(currentWorkflowsState);

      throw error;
    }
  };

  const handleRestoreDefaultWorkflows = async (
    params: RestoreDefaultWorkflowsParams
  ) => {
    try {
      setLoading(true);

      const response = await handleRestoreDefaultWorkflowsMutation(
        params.channelId,
        params.type
      );

      const defaultWorkflows = (response?.data?.restoreDefaultWorkflows ||
        []) as unknown as Workflow[];

      if (params.onSuccess) {
        params.onSuccess(defaultWorkflows);
      }
    } catch (error) {
      if (params.onFailure) {
        params.onFailure(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    workflows,
    addWorkflow: handleAddWorkflow,
    editWorkflow: handleEditWorkflow,
    saveWorkflow: handleSaveWorkflow,
    deleteWorkflow: handleDeleteWorkflow,
    cloneWorkflow: handleCloneWorkflow,
    reorderWorkflows: handleReorderWorkflows,
    restoreDefaultWorkflows: handleRestoreDefaultWorkflows,
  };
};
