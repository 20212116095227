import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  ExceptionDetails,
  TaxAmountType,
  MarkupAmountType,
  ExternalPayerType,
  UpdateProductExceptionRequest,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'uuid-encoding';

export async function updateProductExceptionMutation(
  exceptionId: string,
  exceptionDetails: ExceptionDetails,
  channelId: string
) {
  const updateProductExceptionMutation = gql`
    mutation updateProductException(
      $updateProductExceptionRequest: UpdateProductExceptionRequest!
    ) {
      updateProductException(
        updateProductExceptionRequest: $updateProductExceptionRequest
      ) {
        id
      }
    }
  `;

  const productCategoryId = exceptionDetails.applyToAllProducts
    ? exceptionDetails.productCategoryId
    : undefined;

  const productIds = exceptionDetails.applyToAllProducts
    ? undefined
    : exceptionDetails.productIds.map(item => item.value).join(',');

  const requestDetails = {
    id: exceptionId,
    group: {
      id: convertToUUID(channelId),
      type: GroupType.GroupTypeActivateChannel,
    },
    payer: {
      id: convertToUUID(exceptionDetails.invoicee?._id),
      type:
        exceptionDetails.invoicee?.type ||
        ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER,
    },
    productIds,
    productCategoryId,
    amount:
      exceptionDetails.rate !== undefined && exceptionDetails.rate !== null
        ? Number(exceptionDetails.rate)
        : undefined,
  } as UpdateProductExceptionRequest;

  if (exceptionDetails.markup) {
    requestDetails.markup = {
      value: Number(exceptionDetails.markup.value),
      markupAmountType:
        exceptionDetails.markup?.type ||
        MarkupAmountType.MarkupAmountTypePercentage,
    };
  }

  if (exceptionDetails.tax) {
    requestDetails.tax = {
      value: Number(exceptionDetails.tax.value),
      taxAmountType:
        exceptionDetails.tax?.type || TaxAmountType.TaxAmountTypePercentage,
    };
  }

  return await getClient().mutate<
    any,
    { updateProductExceptionRequest: UpdateProductExceptionRequest }
  >({
    mutation: updateProductExceptionMutation,
    variables: {
      updateProductExceptionRequest: requestDetails,
    },
  });
}
