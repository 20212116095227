import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useContentQuery } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import ErrorMessage from 'components/general/ErrorMessage';
import { Prompt, useHistory } from 'react-router-dom';
import ContentPreview from 'components/lane/ContentPreview';
import { H4 } from 'components/typography';
import styles from './ChannelPublicProfileEdit.scss';
import { Button, Icon, Input, M } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { HeroContentCard } from 'components/cards';
import { ConfirmationModal } from '../../general/ConfirmationModal';
import { AddPageModal } from 'pages/portal/admin/channel/sections/view/AddPageModal';
import { LaneType } from 'common-types';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { useQuery } from '@apollo/client';
import * as Histoy from 'history';
import { getWhitelabelsByChannelId } from 'lane-shared/graphql/query';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { routes } from 'lane-shared/config';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  channel: ChannelType | null;
  setUpdatedChannel: (channel?: ChannelType | null) => void;
  updatedChannel: ChannelType | null;
  onChannelUpdated: (channel?: ChannelType) => void;
  isSaveDisabled: boolean;
};

type WhiteLabel = {
  _id: string;
  name: string;
  url: string;
};

export default function ChannelPublicProfileEdit({
  channel,
  onChannelUpdated,
  setUpdatedChannel,
  style,
  className,
  updatedChannel,
  isSaveDisabled,
}: Props) {
  const { t } = useTranslation();
  const simpleTrack = useSimpleTrack();
  const [contentId, setContentId] = useState<LaneType.UUID | null | undefined>(
    () => {
      return channel?.profile?.content?._id;
    }
  );
  const [isUnsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [isDeactivateProfileModalOpen, setDeactivateProfileModalOpen] =
    useState(false);
  const [isContentSelectorOpen, setIsContentSelectorOpen] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(
    null
  );
  const { data } = useQuery(getWhitelabelsByChannelId, {
    variables: {
      channelId: channel?._id,
    },
  });

  const channelAdminProfileRoute = channel?.slug
    ? routes.channelAdminProfile.replace(':id', channel.slug)
    : undefined;

  const isChannelAdminProfileRoute =
    window.location.pathname === channelAdminProfileRoute;

  useEffect(() => {
    function handleCopy(event: ClipboardEvent) {
      const whitelabels = data?.getWhitelabelsByChannelId;
      const selection = (document.getSelection() ?? '').toString();

      if (!whitelabels?.length || !isChannelAdminProfileRoute || !selection)
        return;

      const selectedWhitelabel = whitelabels.find((whitelabel: WhiteLabel) =>
        selection.includes(whitelabel.url)
      );

      if (selectedWhitelabel) {
        simpleTrack(ANALYTIC_KEYS.ANALYTIC_ADMIN_PROFILE_URL_COPIED, {
          whitelabelId: selectedWhitelabel._id,
          method: 'byCopy',
        });
      }

      event.preventDefault();
    }

    window.addEventListener('copy', handleCopy);

    return () => window.removeEventListener('copy', handleCopy);
  }, [data?.getWhitelabelsByChannelId, isChannelAdminProfileRoute]);

  const history = useHistory();

  const { content, error } = useContentQuery({
    includeInteractions: false,
    forPublic: false,
    contentId,
  });

  const handleAddContent = async (content: DocumentType | null | undefined) => {
    setContentId(content?._id);
    onChannelUpdated({
      profile: {
        ...channel!.profile,
        content: content ? { _id: content?._id } : null,
      },
    } as ChannelType);

    simpleTrack(ANALYTIC_KEYS.ANALYTIC_ADMIN_CHANNEL_PROFILE_SELECTED, {
      contentId: content?._id,
      channelId: channel?._id,
    });
    setIsContentSelectorOpen(false);
  };

  const handleDeactiveProfile = () => {
    setContentId(null);
    onChannelUpdated({
      profile: {
        ...channel!.profile,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: any; } | null' is not assignable to t... Remove this comment to see the full error message
        content: null,
      },
    });

    simpleTrack(ANALYTIC_KEYS.ANALYTIC_ADMIN_CHANNEL_PROFILE_REMOVED, {
      contentId,
      channelId: channel?._id,
    });
    setDeactivateProfileModalOpen(false);
  };

  const hasUnsavedChanges = (
    original: ChannelType | null,
    updated: ChannelType | null
  ): boolean => {
    if (!original || !updated) return false;
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  const handleModalConfirm = () => {
    if (pendingNavigation) {
      setContentId(channel?.profile?.content?._id);
      setUpdatedChannel(null);
      setUnsavedChangesModal(false);
      setTimeout(() => {
        history.push(pendingNavigation);
      }, 0);
    }
  };

  const copyToClipboard = async ({
    copiedValue,
    whitelabelId,
  }: {
    copiedValue?: string | null;
    whitelabelId: string;
  }) => {
    if (!copiedValue) return;

    await navigator.clipboard.writeText(copiedValue);
    window.Toast.show(t('web.media.mediaEdit.copiedToClipboard'));
    simpleTrack(ANALYTIC_KEYS.ANALYTIC_ADMIN_PROFILE_URL_COPIED, {
      whitelabelId,
      method: 'byClick',
    });
  };

  const handleModalCancel = () => {
    setUnsavedChangesModal(false);
    setPendingNavigation(null);
  };

  const getPublicProfileUrl = (url: string) => {
    return new URL(`/p/${channel?.slug}`, url).href;
  };

  const promptWhen =
    hasUnsavedChanges(channel, updatedChannel) && !isSaveDisabled;

  const handleUnsavedChangesPropmt = (location: Histoy.Location) => {
    setPendingNavigation(location.pathname);
    setUnsavedChangesModal(true);
    return false;
  };
  return (
    <div className={cx(styles.ChannelProfileEdit, className)} style={style}>
      <ErrorMessage error={error} />

      {contentId && content ? (
        <>
          <div className={styles.columnContainer}>
            <HeroContentCard content={content} style={style} />
            <Button
              onClick={() => setIsContentSelectorOpen(true)}
              variant="secondary"
              size="large"
              className={styles.button}
            >
              {t(
                'web.admin.channel.settings.profile.publicProfileTab.changePage.button.text'
              )}
            </Button>

            {channel?.profile?.content && (
              <Button
                onClick={() => setDeactivateProfileModalOpen(true)}
                variant="secondary"
                size="large"
                className={styles.button}
              >
                {t(
                  'web.admin.channel.settings.profile.publicProfileTab.deactivateProfile.text'
                )}
              </Button>
            )}
            <hr />
            {data?.getWhitelabelsByChannelId &&
              data?.getWhitelabelsByChannelId.map((whiteLabel: WhiteLabel) => (
                <div className={styles.input}>
                  <label>
                    {t(
                      'web.admin.channel.settings.profile.publicProfileTab.whiteLabel.publicUrl.label',
                      {
                        whiteLabelName: whiteLabel.name,
                      }
                    )}
                  </label>
                  <Input
                    value={getPublicProfileUrl(whiteLabel.url)}
                    readOnly
                    iconRight
                    icon="copy"
                    onChange={() => {}}
                    onRightIconClick={copiedValue =>
                      copyToClipboard({
                        copiedValue,
                        whitelabelId: whiteLabel._id,
                      })
                    }
                  />
                </div>
              ))}
          </div>
          <ContentPreview contentId={contentId} />
        </>
      ) : (
        <div className={cx(styles.columnContainer, styles.noContentContainer)}>
          <Icon
            className={styles.buildingIcon}
            name="building"
            set={ICON_SET_FONTAWESOME}
            type="fal"
            size="large"
          />
          <H4 mt={6} mb={4}>
            {t(
              'web.admin.channel.settings.profile.publicProfileTab.noPublicProfileBlurb.heading'
            )}
          </H4>
          <h3>
            {t(
              'web.admin.channel.settings.profile.publicProfileTab.noPublicProfileBlurb.description'
            )}
          </h3>
          <Button
            onClick={() => setIsContentSelectorOpen(true)}
            variant="primary"
            size="medium"
            className={styles.selectPageBtn}
          >
            {t(
              'web.admin.channel.settings.profile.publicProfileTab.selectPage.button.text'
            )}
          </Button>
        </div>
      )}
      <ConfirmationModal
        isOpen={isDeactivateProfileModalOpen}
        onClose={() => setDeactivateProfileModalOpen(false)}
        handleConfirm={handleDeactiveProfile}
        title={t(
          'web.admin.channel.settings.profile.publicProfileTab.deactivateProfile.text'
        )}
        confirmText={t(
          'web.admin.channel.settings.profile.publicProfileTab.deativate.button.text'
        )}
        cancelText={t(
          'web.admin.channel.settings.profile.publicProfileTab.back.button.text'
        )}
      >
        <M>
          {t(
            'web.admin.channel.settings.profile.publicProfileTab.deactivateProfile.description'
          )}
        </M>
      </ConfirmationModal>

      <Prompt when={promptWhen} message={handleUnsavedChangesPropmt} />
      <ConfirmationModal
        isOpen={isUnsavedChangesModal}
        onClose={handleModalCancel}
        handleConfirm={handleModalConfirm}
        title={t(
          'web.admin.channel.settings.profile.publicProfileTab.unsavedChanges.heading'
        )}
        confirmText={t(
          'web.admin.channel.settings.profile.publicProfileTab.unsavedChanges.confirm.text'
        )}
        cancelText={t(
          'web.admin.channel.settings.profile.publicProfileTab.back.button.text'
        )}
      >
        <M>
          {t(
            'web.admin.channel.settings.profile.publicProfileTab.unsavedChanges.description'
          )}
        </M>
      </ConfirmationModal>

      <AddPageModal
        channel={channel!}
        isAddOpen={isContentSelectorOpen}
        setIsAddOpen={setIsContentSelectorOpen}
        onAddContent={handleAddContent}
      />
    </div>
  );
}
