import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW } from 'lane-shared/helpers/constants/permissions';
import { convertToUUID } from 'uuid-encoding';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { UserType } from 'lane-shared/types/User';
import {
  AttachmentDocumentContentTypeEnum,
  AttachmentImageContentTypeEnum,
  AttachmentResponse,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';

import { AddAttachment, Flex, Label } from 'components';
import { H4, P, TextLink } from 'components/typography';
import { EquipmentStatusOptions } from 'domains/workOrder/equipment/utils/constants';

import { updateEquipmentMutation } from 'graphql-queries';
import { Equipment, PublicUser } from 'graphql-query-contracts';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { ChipSelect, ChipStyle } from 'components/ads';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import EquipmentSchedulesList from '../../components/EquipmentSchedulesList';

import styles from './Details.scss';
import { AmazonS3Buckets } from 'lane-shared/types/media';

export interface EquipmentDetailsProps {
  channel?: UseChannelForAdminQueryResponse['channel'];
  user: UserType;
  equipment?: Equipment | null;
  userData?: PublicUser;
}

function DetailsTab({ channel, user, equipment }: EquipmentDetailsProps) {
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const { t } = useTranslation();

  const hasScheduleAccess = hasPermission(
    user?.roles,
    [PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW],
    channel?._id,
    false
  );

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  const afterAttachmentCreated = async (attachments: AttachmentResponse[]) => {
    if (!equipment?.id) {
      return;
    }

    await getClient().mutate({
      mutation: updateEquipmentMutation,
      variables: {
        updateEquipment: {
          id: equipment?.id,
          name: equipment?.name,
          category: equipment?.category,
          location: equipment?.location,
          status: equipment?.status,
          notes: equipment?.notes,
          attachments: attachments.map(attachment => attachment.id),
        },
      },
    });
  };

  return (
    <div className={styles.EquipmentDetailsPage}>
      <Flex direction="column" width="full" gap={5}>
        <Flex
          className={styles.EquipmentDetailsData}
          width="full"
          direction="column"
          gap={4}
        >
          <H4 mt={2}>{t('web.admin.serviceRequest.details')}</H4>

          <Flex direction="row">
            <Flex direction="column" className={styles.twoFieldContainer}>
              <Label className={styles.FieldLabel}>
                {t('web.admin.serviceRequest.equipment.status.title')}
              </Label>
              <Flex>
                <ChipSelect.NonInteractive
                  value={equipment?.status!}
                  size="sm"
                  type={
                    equipment?.status === EquipmentStatusOptions.ACTIVE
                      ? ChipStyle.Blue
                      : ChipStyle.Grey
                  }
                />
              </Flex>
            </Flex>

            <Flex direction="column" className={styles.twoFieldContainer}>
              <Label className={styles.FieldLabel}>
                {t('web.admin.serviceRequest.equipment.category.title')}
              </Label>
              <P>{equipment?.category || '-'}</P>
            </Flex>
          </Flex>

          <Flex direction="column">
            <Label className={styles.FieldLabel}>
              {t('web.admin.serviceRequest.equipment.location.title')}
            </Label>
            <P>{equipment?.location || '-'}</P>
          </Flex>
          {isPropertyChannel && (
            <Flex direction="row">
              <Flex direction="column" className={styles.twoFieldContainer}>
                <Flex>
                  <Label className={styles.FieldLabel}>
                    {t('web.admin.serviceRequest.equipment.floor.title')}
                  </Label>
                </Flex>
                <Flex>
                  <P>{equipment?.floor || '-'}</P>
                </Flex>
              </Flex>

              <Flex direction="column" className={styles.twoFieldContainer}>
                <Flex>
                  <Label className={styles.FieldLabel}>
                    {t('web.admin.serviceRequest.equipment.suite.title')}
                  </Label>
                </Flex>
                <Flex>
                  <P>{equipment?.suite || '-'}</P>
                </Flex>
              </Flex>
            </Flex>
          )}

          <Flex direction="row">
            <Flex direction="column" className={styles.twoFieldContainer}>
              <Flex>
                <Label className={styles.FieldLabel}>
                  {t('web.admin.serviceRequest.equipment.make.title')}
                </Label>
              </Flex>
              <Flex>
                <P>{equipment?.make || '-'}</P>
              </Flex>
            </Flex>

            <Flex direction="column" className={styles.twoFieldContainer}>
              <Flex>
                <Label className={styles.FieldLabel}>
                  {t('web.admin.serviceRequest.equipment.model.title')}
                </Label>
              </Flex>
              <Flex>
                <P>{equipment?.model || '-'}</P>
              </Flex>
            </Flex>
          </Flex>

          <Flex direction="row">
            <Flex direction="column" className={styles.twoFieldContainer}>
              <Label className={styles.FieldLabel}>
                {t('web.admin.serviceRequest.equipment.serial.title')}
              </Label>
              <P>{equipment?.serial || '-'}</P>
            </Flex>

            <Flex direction="column" className={styles.twoFieldContainer}>
              <Label className={styles.FieldLabel}>
                {t('web.admin.serviceRequest.equipment.asset.title')}
              </Label>
              <P>{equipment?.asset || '-'}</P>
            </Flex>
          </Flex>

          <Flex direction="row">
            <Flex direction="column" className={styles.twoFieldContainer}>
              <Flex>
                <Label className={styles.FieldLabel}>
                  {t(
                    'web.admin.serviceRequest.equipment.warrantyExpirationDate.title'
                  )}
                </Label>
              </Flex>
              <Flex>
                <P>{simpleDate(equipment?.warrantyExpirationDate) || '-'}</P>
              </Flex>
            </Flex>

            <Flex direction="column" className={styles.twoFieldContainer}>
              <Flex>
                <Label className={styles.FieldLabel}>
                  {t('web.admin.serviceRequest.equipment.installDate.title')}
                </Label>
              </Flex>
              <Flex>
                <P>{simpleDate(equipment?.installDate) || '-'}</P>
              </Flex>
            </Flex>
          </Flex>

          <Flex direction="column" className={styles.notesContainer} mb={2}>
            <Label className={styles.FieldLabel}>
              {t('web.admin.serviceRequest.equipment.notes.title')}
            </Label>
            <P className={styles.notesPara}>{equipment?.notes || '-'}</P>
          </Flex>
        </Flex>

        {equipment?.hyperlinks && equipment?.hyperlinks?.length > 0 && (
          <Flex
            direction="column"
            width="full"
            className={styles.EquipmentDetailsBlocks}
            gap={4}
          >
            <H4 mb={2} mt={2}>
              {t`web.admin.serviceRequest.equipment.hyperlinks.title`}
            </H4>
            <Flex direction="row" className={styles.HyperlinkItemsList} gap={4}>
              {equipment?.hyperlinks?.map((link: any, index: number) => (
                <Flex key={index} className={styles.HyperlinkItems}>
                  <TextLink
                    href={link.url}
                    target="_blank"
                    className={styles.Hyperlinks}
                  >
                    {link.name}
                  </TextLink>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}

        <EquipmentSchedulesList
          channel={channel}
          equipmentId={equipmentId}
          enableLinkToSchedule={hasScheduleAccess}
        />

        <Flex
          direction="column"
          width="full"
          className={styles.EquipmentDetailsBlocks}
        >
          <Flex direction="column" gap={2}>
            <H4 mb={2} mt={2}>
              {t`web.admin.serviceRequest.equipment.attachments.title`}
            </H4>
            <P>{t`web.admin.serviceRequest.equipment.attachments.disclaimer`}</P>
            <AddAttachment
              entityId={convertToUUID(equipmentId)}
              entityType="Equipment"
              editMode={false}
              afterAttachmentCreated={afterAttachmentCreated}
              variant={AttachmentVariantEnum.WorkOrder}
              acceptedFileTypes={[
                ...Object.values(AttachmentImageContentTypeEnum),
                AttachmentDocumentContentTypeEnum.pdf,
              ]}
              canAddAttachments={!equipment?.isArchived}
              preventDelete={!!equipment?.isArchived}
              s3Bucket={AmazonS3Buckets.Activate}
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}

export default DetailsTab;
