import { Dispatch, SetStateAction } from 'react';
import { AllowedFeatures as RiseMigrationAllowedFeatures } from 'graphql-query-contracts/src/__generated__/graphql';

export type AutoSetupConfig = {
  dataSource: DataSourceEnum | null;
  templateType: Template | null;
  templateId: string | null;
  blankBuilding?: boolean;
};

export type SourceLocation = { index: number; value: string };
export type SourceLocations = SourceLocation[];

export type SourceLocationAction =
  | { type: 'ADD_SOURCE_LOCATION' }
  | { type: 'DELETE_SOURCE_LOCATION'; index: number }
  | { type: 'UPDATE_SOURCE_LOCATION'; sourceLocation: SourceLocation }
  | { type: 'CLEAR_SOURCE_LOCATIONS' };

export enum Template {
  EXISTING = 'template',
  BLANK = 'blank_building',
}

export enum DataSourceEnum {
  sFTP = 'sftp',
  CSV = 'csv',
  RISE = 'rise',
  CES = 'ces',
}

export type DataSourceType = {
  value: DataSourceEnum;
  text: string;
};

export type RiseMigrationFeaturesType = {
  addRandomSuffixToSlug: boolean;
  migrateNonReservableAmenities: boolean;
  migrateAmenities: boolean;
  migrateDiscoveryLinks: boolean;
  migrateCommunityNews: boolean;
  migrateEvents: boolean;
  migrateForms: boolean;
  migrateTenants: boolean;
  migrateUsers: boolean;
  migrateUCIs: boolean;
  turnRedirect: boolean;
  migrateCatUsers: string[];
};

export type RiseMigrationFeaturesListType = {
  key: string;
  name: string;
  description?: string;
};

export type RiseMigrationOptionsType = {
  features: RiseMigrationFeaturesType;
  setFeatures: Dispatch<SetStateAction<RiseMigrationFeaturesType>>;
  allowedFeatures?: RiseMigrationAllowedFeatures;
  redirectUrl: string | null;
  setRedirectUrl: Dispatch<SetStateAction<string | null>>;
  redirectLinks: string[];
};

/**
 * Enum representing the SFTP modes.
 *
 * @enum {string}
 * @property {string} data-sync-to-acm - Represents the old CBRE onboarding flow where data-sync creates channels in ACM. This process has a flaw of not saving Lease and Suits data properly.
 * @property {string} data-sync-to-ces - New onboarding flow, where data-sync will just create entites in CES. Another process will use this data as source of truth and will create channels and tenants in ACM
 */
export type sFTPmode = 'data-sync-to-acm' | 'data-sync-to-ces';

export type SelectChannelTemplateIdOptionsType = {
  config: AutoSetupConfig;
  setConfig: Dispatch<SetStateAction<AutoSetupConfig>>;
  templateValidation: string;
  setCbreOnboardingMode: Dispatch<SetStateAction<sFTPmode>>;
  cbreOnboardingMode: sFTPmode;
};

export type CesOptionsType = {
  cesOrganizationId: string;
  setCesOrganizationId?: Dispatch<SetStateAction<string>>;
  vcmBuildingIds: string;
  setVcmBuildingIds?: Dispatch<SetStateAction<string>>;
};
