import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers';
import styles from '../addItemsToChargeStyles.scss';
import { Input, Tooltip } from 'components';
import Label from 'components/general/Label';
import { Alert } from 'components/lds';
import { H3, M, S, L } from 'components/typography';
import useUserLocale from 'hooks/useUserLocale';
import {
  ProductType,
  SupportedPaymentCurrency,
  AddProductToChargeDetails,
  PriceInput,
} from 'lane-shared/domains/billingPayments/types';
import { Receivable } from 'graphql-query-contracts';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import {
  currencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';
import { AddCreditBundleButtonGroup } from 'pages/portal/admin/channel/charges-invoices/add-items-to-charge/AddCreditBundleForm/AddCreditBundleButtonGroup';
import { AddCreditBundleSelection } from 'pages/portal/admin/channel/charges-invoices/add-items-to-charge/AddCreditBundleForm/AddCreditBundleSelection';
import {
  ModifierType,
  RateModifierInput,
} from 'pages/portal/admin/channel/products-services/products/RateModifierInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { dates } from 'lane-shared/helpers/constants';
import { isNumberTwoDecimalPlaces } from '../../../products-services/helpers';
import { EMPTY_TABLE_ROW_RPODUCT_DETAILS } from '../../constants';

export function AddCreditBundleModalForm({
  addProduct,
  editProduct,
  closeModal,
  currency,
  productToEdit,
  inclusivePricing = false,
}: {
  addProduct: Function;
  editProduct: Function;
  closeModal: Function;
  currency: SupportedPaymentCurrency;
  productToEdit?: AddProductToChargeDetails;
  inclusivePricing?: boolean;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const [productDetails, setProductDetails] =
    useState<AddProductToChargeDetails>(() => {
      if (productToEdit) {
        return productToEdit;
      }

      return {
        ...EMPTY_TABLE_ROW_RPODUCT_DETAILS,
        productCategoryType: ProductType.PRODUCT_TYPE_CREDIT_BUNDLE,
        tableRowId: uuid(),
        tableProductType: undefined,
      };
    });

  const handleProductDetailsUpdate = (value: any, field: string) => {
    if (!field) {
      setProductDetails({ ...productDetails, ...value });

      return;
    }

    setProductDetails({ ...productDetails, [field]: value });
  };

  const resetProductDetails = () => {
    setProductDetails({
      ...EMPTY_TABLE_ROW_RPODUCT_DETAILS,
      tableRowId: uuid(),
      tableProductType: undefined,
    });
  };

  useEffect(() => {
    const priceInput = {
      rate: productDetails.rate,
      tax: productDetails.tax,
    } as PriceInput;

    const priceComponents = priceInputToComponents(priceInput);
    const { netPrice, formatPrice } = getPricing({
      currency,
      locale,
      priceComponents,
      inclusivePricing,
    });

    setProductDetails(prev => ({
      ...prev,
      total: formatPrice(netPrice * Number(productDetails.quantity)),
      netPrice: netPrice.toString(),
    }));
  }, [
    productDetails.tax,
    productDetails.quantity,
    productDetails.rate,
    currency,
    locale,
    inclusivePricing,
  ]);

  const resetAndCloseModal = () => {
    resetProductDetails();
    closeModal();
  };

  const onAddProductClick = () => {
    if (productToEdit) {
      editProduct(productDetails);
      resetAndCloseModal();

      return;
    }

    addProduct(productDetails);
    resetAndCloseModal();
  };

  const onCancelClick = () => {
    if (productToEdit) {
      editProduct(undefined);
    }

    resetAndCloseModal();
  };

  const rateInputError = () => {
    if (!isNumberTwoDecimalPlaces(parseFloat(productDetails.rate))) {
      return [t('abp.productsServices.createProduct.validation')];
    }

    return null;
  };

  const currentDate = new Date();
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const formattedDate = dateFormatter(
    lastDayOfMonth,
    dates.LONG_MONTH_DATE_YEAR
  );

  const totalCreditFromReceivable = (r: Receivable) => {
    const creditBundle = r.creditBundle;

    if (creditBundle) {
      return creditBundle.creditCount;
    }

    return 0;
  };

  const totalCreditsIssued = () => {
    if (productDetails.receivables.length === 0) {
      return 0;
    }

    let total = 0;

    productDetails.receivables.forEach((r: Receivable) => {
      total += totalCreditFromReceivable(r);
    });

    return total * Number(productDetails.quantity);
  };

  return (
    <div className={styles.addProductModal}>
      <H3 className={styles.modalHeader} mb={4} bold>
        {t('abp.charges.addCreditBundle.modal.header')}
      </H3>
      <M>{t('abp.charges.addCreditBundle.modal.subtitle')}</M>
      <Alert type="warning">
        <S>
          {t('abp.charges.addCreditBundle.modal.expiryInfo', {
            lastDateOfMonth: formattedDate,
          })}
        </S>
      </Alert>
      <div className={styles.modalFormContent}>
        <AddCreditBundleSelection
          productDetails={productDetails}
          handleProductDetailsUpdate={handleProductDetailsUpdate}
          currency={currency}
        />

        <div className={styles.rateInputs}>
          <div className={styles.priceContainer}>
            <Input
              className={styles.inputFields}
              label={t('abp.productsServices.createProduct.rate.label')}
              fixedLabel
              isRequired
              value={productDetails.rate}
              onChange={(value: string) =>
                handleProductDetailsUpdate(value, 'rate')
              }
              testId="productRateInput"
              error={rateInputError()}
              type="number"
            />
            <M variant="secondary" mb={2} className={styles.rateCurrency}>
              {currency}
            </M>
          </div>

          <div>
            <Label className={styles.labels}>
              {t('abp.charges.addCreditBundle.modal.creditsInBundle')}
            </Label>
            <M className={styles.values}>
              {productDetails?.receivables[0]?.creditBundle?.creditCount || 0}
            </M>
          </div>

          <RateModifierInput
            modifierType={productDetails.tax?.type}
            modifierValue={productDetails.tax?.value}
            type={ModifierType.TAX}
            updateDetails={handleProductDetailsUpdate}
          />

          <Input
            className={styles.quantityInput}
            label="Quantity"
            fixedLabel
            isRequired
            value={productDetails.quantity}
            onChange={(value: string) =>
              handleProductDetailsUpdate(value, 'quantity')
            }
            testId="productQuantityInput"
          />

          <div className={styles.creditsAndExpiry}>
            <div className={styles.creditsAndExpiryChild}>
              <Label className={styles.labels}>
                {t('abp.charges.addCreditBundle.modal.totalCreditsIssued')}
              </Label>
              <M className={styles.values}>{totalCreditsIssued()}</M>
            </div>
            <div className={styles.creditsAndExpiryChild}>
              <Tooltip
                TooltipComponent={t(
                  'abp.charges.addCreditBundle.modal.expiryTooltip'
                )}
                zIndex={1000}
              >
                <Label TooltipComponent className={styles.labels}>
                  {t('abp.charges.addCreditBundle.modal.expiryLabel')}
                </Label>
              </Tooltip>
              <M>{formattedDate}</M>
            </div>
          </div>
        </div>
        <div className={styles.totalSection}>
          <Label className={styles.labels}>
            {t('abp.charges.addCreditBundle.modal.totalCost')}
          </Label>
          <div className={styles.currencyWithTotal}>
            <L variant="secondary" className={styles.currency}>
              {currency}
            </L>
            <H3>
              {currencyFormatter({ currency, currencyDisplay: 'none' })(
                Number(productDetails.total)
              )}
            </H3>
          </div>
        </div>
      </div>
      <AddCreditBundleButtonGroup
        productDetails={productDetails}
        addProduct={onAddProductClick}
        onCancel={onCancelClick}
      />
    </div>
  );
}
